import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: null,
  reducers: {
    setUser: (state, action) => {
      window.user = action.payload;
      return action.payload;
    },
  },
});

export const userReducer = userSlice.reducer;

// Action creators are generated for each case reducer function
export const { setUser } = userSlice.actions;