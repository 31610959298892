const customEmojis = [
  {
    "name": "Alien Monster.png",
    "path": "Emojis/Smilies/Alien Monster.png",
    "sha": "2e422840d16eaca185e87c8fa60d7a42c612315c",
    "size": 656107,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Alien%20Monster.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Alien%20Monster.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e422840d16eaca185e87c8fa60d7a42c612315c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Alien%20Monster.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Alien%20Monster.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e422840d16eaca185e87c8fa60d7a42c612315c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Alien%20Monster.png"
    }
  },
  {
    "name": "Alien.png",
    "path": "Emojis/Smilies/Alien.png",
    "sha": "da6b10f29b88def06f23e29360344a4e771f4a6b",
    "size": 1093829,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Alien.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Alien.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/da6b10f29b88def06f23e29360344a4e771f4a6b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Alien.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Alien.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/da6b10f29b88def06f23e29360344a4e771f4a6b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Alien.png"
    }
  },
  {
    "name": "Anger Symbol.png",
    "path": "Emojis/Smilies/Anger Symbol.png",
    "sha": "1c3d66ea68a0b70342fbfa74c6f36783a51ffd84",
    "size": 14290,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anger%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anger%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c3d66ea68a0b70342fbfa74c6f36783a51ffd84",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Anger%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anger%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c3d66ea68a0b70342fbfa74c6f36783a51ffd84",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anger%20Symbol.png"
    }
  },
  {
    "name": "Angry Face with Horns.png",
    "path": "Emojis/Smilies/Angry Face with Horns.png",
    "sha": "38793a96ee7f86666b6a5792d86666f0bc24d84f",
    "size": 1426624,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Angry%20Face%20with%20Horns.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Angry%20Face%20with%20Horns.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/38793a96ee7f86666b6a5792d86666f0bc24d84f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Angry%20Face%20with%20Horns.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Angry%20Face%20with%20Horns.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/38793a96ee7f86666b6a5792d86666f0bc24d84f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Angry%20Face%20with%20Horns.png"
    }
  },
  {
    "name": "Angry Face.png",
    "path": "Emojis/Smilies/Angry Face.png",
    "sha": "35d8642159712e4b63ebcc158f85c820036b93cf",
    "size": 1176374,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Angry%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Angry%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35d8642159712e4b63ebcc158f85c820036b93cf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Angry%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Angry%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35d8642159712e4b63ebcc158f85c820036b93cf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Angry%20Face.png"
    }
  },
  {
    "name": "Anguished Face.png",
    "path": "Emojis/Smilies/Anguished Face.png",
    "sha": "fd79d4bfbd46d6593d8eb1ea96d1c76beadacd73",
    "size": 1131772,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anguished%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anguished%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd79d4bfbd46d6593d8eb1ea96d1c76beadacd73",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Anguished%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anguished%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd79d4bfbd46d6593d8eb1ea96d1c76beadacd73",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anguished%20Face.png"
    }
  },
  {
    "name": "Anxious Face with Sweat.png",
    "path": "Emojis/Smilies/Anxious Face with Sweat.png",
    "sha": "9d8602cd6bd3bc1996babc2d9bb2cdfb8ce07d0e",
    "size": 1073780,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anxious%20Face%20with%20Sweat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anxious%20Face%20with%20Sweat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d8602cd6bd3bc1996babc2d9bb2cdfb8ce07d0e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Anxious%20Face%20with%20Sweat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Anxious%20Face%20with%20Sweat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d8602cd6bd3bc1996babc2d9bb2cdfb8ce07d0e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Anxious%20Face%20with%20Sweat.png"
    }
  },
  {
    "name": "Astonished Face.png",
    "path": "Emojis/Smilies/Astonished Face.png",
    "sha": "54b23632d218cd66d07cd8f94571d8dc8a2d077c",
    "size": 1146899,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Astonished%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Astonished%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54b23632d218cd66d07cd8f94571d8dc8a2d077c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Astonished%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Astonished%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54b23632d218cd66d07cd8f94571d8dc8a2d077c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Astonished%20Face.png"
    }
  },
  {
    "name": "Beaming Face with Smiling Eyes.png",
    "path": "Emojis/Smilies/Beaming Face with Smiling Eyes.png",
    "sha": "2b7f93b2183afe9268705727236083a352ab05f2",
    "size": 1124747,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Beaming%20Face%20with%20Smiling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Beaming%20Face%20with%20Smiling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b7f93b2183afe9268705727236083a352ab05f2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Beaming%20Face%20with%20Smiling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Beaming%20Face%20with%20Smiling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b7f93b2183afe9268705727236083a352ab05f2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Beaming%20Face%20with%20Smiling%20Eyes.png"
    }
  },
  {
    "name": "Beating Heart.png",
    "path": "Emojis/Smilies/Beating Heart.png",
    "sha": "72f5686c053522e006051f17511a0ceb9fe72307",
    "size": 405222,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Beating%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Beating%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/72f5686c053522e006051f17511a0ceb9fe72307",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Beating%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Beating%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/72f5686c053522e006051f17511a0ceb9fe72307",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Beating%20Heart.png"
    }
  },
  {
    "name": "Biting Lip.png",
    "path": "Emojis/Smilies/Biting Lip.png",
    "sha": "0fff662544c93729beca7b2293bfdf06e73368a8",
    "size": 1222607,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Biting%20Lip.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Biting%20Lip.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0fff662544c93729beca7b2293bfdf06e73368a8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Biting%20Lip.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Biting%20Lip.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0fff662544c93729beca7b2293bfdf06e73368a8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Biting%20Lip.png"
    }
  },
  {
    "name": "Black Heart.png",
    "path": "Emojis/Smilies/Black Heart.png",
    "sha": "8c5d55769f51abeb7f47697397364ed653ae2498",
    "size": 831828,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Black%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Black%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c5d55769f51abeb7f47697397364ed653ae2498",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Black%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Black%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c5d55769f51abeb7f47697397364ed653ae2498",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Black%20Heart.png"
    }
  },
  {
    "name": "Blue Heart.png",
    "path": "Emojis/Smilies/Blue Heart.png",
    "sha": "716604713ba0dd8a2140c8b6c56adf3433e77ee3",
    "size": 904491,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Blue%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Blue%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/716604713ba0dd8a2140c8b6c56adf3433e77ee3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Blue%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Blue%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/716604713ba0dd8a2140c8b6c56adf3433e77ee3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Blue%20Heart.png"
    }
  },
  {
    "name": "Bomb.png",
    "path": "Emojis/Smilies/Bomb.png",
    "sha": "abfdd571744ffa356117988d3fa9f1fb4c7254b5",
    "size": 753626,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Bomb.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Bomb.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/abfdd571744ffa356117988d3fa9f1fb4c7254b5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Bomb.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Bomb.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/abfdd571744ffa356117988d3fa9f1fb4c7254b5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Bomb.png"
    }
  },
  {
    "name": "Broken Heart.png",
    "path": "Emojis/Smilies/Broken Heart.png",
    "sha": "c3b2988d5699bc87d8d0c74df8d97a5d6462ee5d",
    "size": 918336,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Broken%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Broken%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c3b2988d5699bc87d8d0c74df8d97a5d6462ee5d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Broken%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Broken%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c3b2988d5699bc87d8d0c74df8d97a5d6462ee5d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Broken%20Heart.png"
    }
  },
  {
    "name": "Brown Heart.png",
    "path": "Emojis/Smilies/Brown Heart.png",
    "sha": "167b9d3883250551b48376cc3977877b7c90136c",
    "size": 959354,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Brown%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Brown%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/167b9d3883250551b48376cc3977877b7c90136c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Brown%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Brown%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/167b9d3883250551b48376cc3977877b7c90136c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Brown%20Heart.png"
    }
  },
  {
    "name": "Cat with Tears of Joy.png",
    "path": "Emojis/Smilies/Cat with Tears of Joy.png",
    "sha": "9719553fae746631bf1c825858010d756ab481b3",
    "size": 726370,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cat%20with%20Tears%20of%20Joy.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cat%20with%20Tears%20of%20Joy.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9719553fae746631bf1c825858010d756ab481b3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Cat%20with%20Tears%20of%20Joy.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cat%20with%20Tears%20of%20Joy.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9719553fae746631bf1c825858010d756ab481b3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cat%20with%20Tears%20of%20Joy.png"
    }
  },
  {
    "name": "Cat with Wry Smile.png",
    "path": "Emojis/Smilies/Cat with Wry Smile.png",
    "sha": "8b7faba8fe2612dbcc66cc53f0ce1b8433ff02d8",
    "size": 1105830,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cat%20with%20Wry%20Smile.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cat%20with%20Wry%20Smile.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8b7faba8fe2612dbcc66cc53f0ce1b8433ff02d8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Cat%20with%20Wry%20Smile.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cat%20with%20Wry%20Smile.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8b7faba8fe2612dbcc66cc53f0ce1b8433ff02d8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cat%20with%20Wry%20Smile.png"
    }
  },
  {
    "name": "Clown Face.png",
    "path": "Emojis/Smilies/Clown Face.png",
    "sha": "3c650616eece6e2c74e7aa97b666a29033520f47",
    "size": 1260478,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Clown%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Clown%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c650616eece6e2c74e7aa97b666a29033520f47",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Clown%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Clown%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c650616eece6e2c74e7aa97b666a29033520f47",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Clown%20Face.png"
    }
  },
  {
    "name": "Cold Face.png",
    "path": "Emojis/Smilies/Cold Face.png",
    "sha": "e7d1d6a8a2edbae2cc0b61bf41cfe6e3a24cba84",
    "size": 1149711,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cold%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cold%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e7d1d6a8a2edbae2cc0b61bf41cfe6e3a24cba84",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Cold%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cold%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e7d1d6a8a2edbae2cc0b61bf41cfe6e3a24cba84",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cold%20Face.png"
    }
  },
  {
    "name": "Collision.png",
    "path": "Emojis/Smilies/Collision.png",
    "sha": "73b77975f0ff657ea4f088e54f85a206ffb044a6",
    "size": 10714,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Collision.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Collision.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/73b77975f0ff657ea4f088e54f85a206ffb044a6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Collision.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Collision.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/73b77975f0ff657ea4f088e54f85a206ffb044a6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Collision.png"
    }
  },
  {
    "name": "Confounded Face.png",
    "path": "Emojis/Smilies/Confounded Face.png",
    "sha": "51004ac0d0774c5c217eb72e64ee8aeb16894e38",
    "size": 1241662,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Confounded%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Confounded%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/51004ac0d0774c5c217eb72e64ee8aeb16894e38",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Confounded%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Confounded%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/51004ac0d0774c5c217eb72e64ee8aeb16894e38",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Confounded%20Face.png"
    }
  },
  {
    "name": "Confused Face.png",
    "path": "Emojis/Smilies/Confused Face.png",
    "sha": "010763f655a36bb49939e59cfc57be249aefba4b",
    "size": 1113950,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Confused%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Confused%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/010763f655a36bb49939e59cfc57be249aefba4b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Confused%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Confused%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/010763f655a36bb49939e59cfc57be249aefba4b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Confused%20Face.png"
    }
  },
  {
    "name": "Cowboy Hat Face.png",
    "path": "Emojis/Smilies/Cowboy Hat Face.png",
    "sha": "89723cc368a6946bb39321f8417ac3103affbb39",
    "size": 650242,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cowboy%20Hat%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cowboy%20Hat%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89723cc368a6946bb39321f8417ac3103affbb39",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Cowboy%20Hat%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Cowboy%20Hat%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89723cc368a6946bb39321f8417ac3103affbb39",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Cowboy%20Hat%20Face.png"
    }
  },
  {
    "name": "Crying Cat.png",
    "path": "Emojis/Smilies/Crying Cat.png",
    "sha": "7ff5abd77749d929efda3456df152a1c6903b71d",
    "size": 1368705,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Crying%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Crying%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7ff5abd77749d929efda3456df152a1c6903b71d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Crying%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Crying%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7ff5abd77749d929efda3456df152a1c6903b71d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Crying%20Cat.png"
    }
  },
  {
    "name": "Crying Face.png",
    "path": "Emojis/Smilies/Crying Face.png",
    "sha": "47bfc717ad9285ff26a61bcc1e4318b35efaada0",
    "size": 1496132,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Crying%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Crying%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47bfc717ad9285ff26a61bcc1e4318b35efaada0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Crying%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Crying%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47bfc717ad9285ff26a61bcc1e4318b35efaada0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Crying%20Face.png"
    }
  },
  {
    "name": "Dashing Away.png",
    "path": "Emojis/Smilies/Dashing Away.png",
    "sha": "245bc7577ae8ce8f5ff47b7c5cc8e70370ab3d18",
    "size": 14275,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dashing%20Away.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dashing%20Away.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/245bc7577ae8ce8f5ff47b7c5cc8e70370ab3d18",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Dashing%20Away.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dashing%20Away.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/245bc7577ae8ce8f5ff47b7c5cc8e70370ab3d18",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dashing%20Away.png"
    }
  },
  {
    "name": "Disappointed Face.png",
    "path": "Emojis/Smilies/Disappointed Face.png",
    "sha": "5afc98210056f54a3fe1d4567444a6351a4ce143",
    "size": 1545228,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Disappointed%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Disappointed%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5afc98210056f54a3fe1d4567444a6351a4ce143",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Disappointed%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Disappointed%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5afc98210056f54a3fe1d4567444a6351a4ce143",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Disappointed%20Face.png"
    }
  },
  {
    "name": "Disguised Face.png",
    "path": "Emojis/Smilies/Disguised Face.png",
    "sha": "661ab9da36670560fd9773f2d38e792fbd1a0bba",
    "size": 1196806,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Disguised%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Disguised%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/661ab9da36670560fd9773f2d38e792fbd1a0bba",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Disguised%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Disguised%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/661ab9da36670560fd9773f2d38e792fbd1a0bba",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Disguised%20Face.png"
    }
  },
  {
    "name": "Dizzy Face.png",
    "path": "Emojis/Smilies/Dizzy Face.png",
    "sha": "243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
    "size": 1259310,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dizzy%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dizzy%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Dizzy%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dizzy%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dizzy%20Face.png"
    }
  },
  {
    "name": "Dizzy.png",
    "path": "Emojis/Smilies/Dizzy.png",
    "sha": "b0a2fe70c7a80fb768222880e9f3adb5af306f86",
    "size": 10813,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dizzy.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dizzy.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0a2fe70c7a80fb768222880e9f3adb5af306f86",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Dizzy.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dizzy.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0a2fe70c7a80fb768222880e9f3adb5af306f86",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dizzy.png"
    }
  },
  {
    "name": "Dotted Line Face.png",
    "path": "Emojis/Smilies/Dotted Line Face.png",
    "sha": "af6effcfdb1bd4c1c6400a58618684f4e2b384e0",
    "size": 2564941,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dotted%20Line%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dotted%20Line%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af6effcfdb1bd4c1c6400a58618684f4e2b384e0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Dotted%20Line%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Dotted%20Line%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af6effcfdb1bd4c1c6400a58618684f4e2b384e0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Dotted%20Line%20Face.png"
    }
  },
  {
    "name": "Downcast Face with Sweat.png",
    "path": "Emojis/Smilies/Downcast Face with Sweat.png",
    "sha": "79e52071e2e8272898bbcda97062aed5a780dfda",
    "size": 1156250,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Downcast%20Face%20with%20Sweat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Downcast%20Face%20with%20Sweat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79e52071e2e8272898bbcda97062aed5a780dfda",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Downcast%20Face%20with%20Sweat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Downcast%20Face%20with%20Sweat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79e52071e2e8272898bbcda97062aed5a780dfda",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Downcast%20Face%20with%20Sweat.png"
    }
  },
  {
    "name": "Drooling Face.png",
    "path": "Emojis/Smilies/Drooling Face.png",
    "sha": "41b6a25dbcd7048e05cc40a2031d9041213d8e0c",
    "size": 1240351,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Drooling%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Drooling%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41b6a25dbcd7048e05cc40a2031d9041213d8e0c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Drooling%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Drooling%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41b6a25dbcd7048e05cc40a2031d9041213d8e0c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Drooling%20Face.png"
    }
  },
  {
    "name": "Enraged Face.png",
    "path": "Emojis/Smilies/Enraged Face.png",
    "sha": "d7700270b74d5c8f9a49edbeb93c00455ab539fb",
    "size": 1144658,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Enraged%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Enraged%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7700270b74d5c8f9a49edbeb93c00455ab539fb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Enraged%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Enraged%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7700270b74d5c8f9a49edbeb93c00455ab539fb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Enraged%20Face.png"
    }
  },
  {
    "name": "Exploding Head.png",
    "path": "Emojis/Smilies/Exploding Head.png",
    "sha": "82ae84aec4872cbf3c2b595d2b8c3828f72a20cd",
    "size": 1129516,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Exploding%20Head.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Exploding%20Head.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/82ae84aec4872cbf3c2b595d2b8c3828f72a20cd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Exploding%20Head.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Exploding%20Head.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/82ae84aec4872cbf3c2b595d2b8c3828f72a20cd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Exploding%20Head.png"
    }
  },
  {
    "name": "Expressionless Face.png",
    "path": "Emojis/Smilies/Expressionless Face.png",
    "sha": "1035e994171282988e154182ee7d3b08e99d5257",
    "size": 17146,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Expressionless%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Expressionless%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1035e994171282988e154182ee7d3b08e99d5257",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Expressionless%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Expressionless%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1035e994171282988e154182ee7d3b08e99d5257",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Expressionless%20Face.png"
    }
  },
  {
    "name": "Eye in Speech Bubble.png",
    "path": "Emojis/Smilies/Eye in Speech Bubble.png",
    "sha": "0cd08a2b2f60fb903cbc73e5b148abe1c52fa9f6",
    "size": 9831,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Eye%20in%20Speech%20Bubble.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Eye%20in%20Speech%20Bubble.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0cd08a2b2f60fb903cbc73e5b148abe1c52fa9f6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Eye%20in%20Speech%20Bubble.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Eye%20in%20Speech%20Bubble.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0cd08a2b2f60fb903cbc73e5b148abe1c52fa9f6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Eye%20in%20Speech%20Bubble.png"
    }
  },
  {
    "name": "Face Blowing a Kiss.png",
    "path": "Emojis/Smilies/Face Blowing a Kiss.png",
    "sha": "a76cdeec8452b2b4889e81ec4833915078c80c58",
    "size": 1260984,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Blowing%20a%20Kiss.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Blowing%20a%20Kiss.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a76cdeec8452b2b4889e81ec4833915078c80c58",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Blowing%20a%20Kiss.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Blowing%20a%20Kiss.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a76cdeec8452b2b4889e81ec4833915078c80c58",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Blowing%20a%20Kiss.png"
    }
  },
  {
    "name": "Face Exhaling.png",
    "path": "Emojis/Smilies/Face Exhaling.png",
    "sha": "41a3eef6b5dfe600e09db32f685bbff8d766021b",
    "size": 1224690,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Exhaling.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Exhaling.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41a3eef6b5dfe600e09db32f685bbff8d766021b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Exhaling.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Exhaling.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41a3eef6b5dfe600e09db32f685bbff8d766021b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Exhaling.png"
    }
  },
  {
    "name": "Face Holding Back Tears.png",
    "path": "Emojis/Smilies/Face Holding Back Tears.png",
    "sha": "ada8d70c58eab5e835edb6962c2ee0d54b06fea4",
    "size": 3421414,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Holding%20Back%20Tears.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Holding%20Back%20Tears.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ada8d70c58eab5e835edb6962c2ee0d54b06fea4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Holding%20Back%20Tears.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Holding%20Back%20Tears.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ada8d70c58eab5e835edb6962c2ee0d54b06fea4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Holding%20Back%20Tears.png"
    }
  },
  {
    "name": "Face Savoring Food.png",
    "path": "Emojis/Smilies/Face Savoring Food.png",
    "sha": "9ec0863f6dd6286d8e782710a20a7df89734e411",
    "size": 1227020,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Savoring%20Food.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Savoring%20Food.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ec0863f6dd6286d8e782710a20a7df89734e411",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Savoring%20Food.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Savoring%20Food.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ec0863f6dd6286d8e782710a20a7df89734e411",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Savoring%20Food.png"
    }
  },
  {
    "name": "Face Screaming in Fear.png",
    "path": "Emojis/Smilies/Face Screaming in Fear.png",
    "sha": "63a1137569de54fde4517001ad48dc567c364382",
    "size": 882777,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Screaming%20in%20Fear.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Screaming%20in%20Fear.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/63a1137569de54fde4517001ad48dc567c364382",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Screaming%20in%20Fear.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Screaming%20in%20Fear.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/63a1137569de54fde4517001ad48dc567c364382",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Screaming%20in%20Fear.png"
    }
  },
  {
    "name": "Face Vomiting.png",
    "path": "Emojis/Smilies/Face Vomiting.png",
    "sha": "53d3fe0cc7f2552465238c5978e452d1567eac9a",
    "size": 679524,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Vomiting.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Vomiting.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53d3fe0cc7f2552465238c5978e452d1567eac9a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Vomiting.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Vomiting.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53d3fe0cc7f2552465238c5978e452d1567eac9a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Vomiting.png"
    }
  },
  {
    "name": "Face Without Mouth.png",
    "path": "Emojis/Smilies/Face Without Mouth.png",
    "sha": "6b1b9ef61ce945d18a9df8f29c6954ec63458911",
    "size": 529810,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Without%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Without%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b1b9ef61ce945d18a9df8f29c6954ec63458911",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20Without%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20Without%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b1b9ef61ce945d18a9df8f29c6954ec63458911",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20Without%20Mouth.png"
    }
  },
  {
    "name": "Face in Clouds.png",
    "path": "Emojis/Smilies/Face in Clouds.png",
    "sha": "cb486ad976878b47302c04804c35d3bbc5dc379d",
    "size": 1808199,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20in%20Clouds.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20in%20Clouds.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cb486ad976878b47302c04804c35d3bbc5dc379d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20in%20Clouds.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20in%20Clouds.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cb486ad976878b47302c04804c35d3bbc5dc379d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20in%20Clouds.png"
    }
  },
  {
    "name": "Face with Crossed-Out Eyes.png",
    "path": "Emojis/Smilies/Face with Crossed-Out Eyes.png",
    "sha": "243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
    "size": 1259310,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Crossed-Out%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Crossed-Out%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Crossed-Out%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Crossed-Out%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/243df9305ed5cc50f7f3c9aab6f1739a9bfbace6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Crossed-Out%20Eyes.png"
    }
  },
  {
    "name": "Face with Diagonal Mouth.png",
    "path": "Emojis/Smilies/Face with Diagonal Mouth.png",
    "sha": "cd20613da99c98928be6182643defca56f4890e7",
    "size": 3038867,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Diagonal%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Diagonal%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd20613da99c98928be6182643defca56f4890e7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Diagonal%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Diagonal%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd20613da99c98928be6182643defca56f4890e7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Diagonal%20Mouth.png"
    }
  },
  {
    "name": "Face with Hand Over Mouth.png",
    "path": "Emojis/Smilies/Face with Hand Over Mouth.png",
    "sha": "e27265467fa2d6c70e0c545ed239b86c76555797",
    "size": 1310947,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Hand%20Over%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Hand%20Over%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e27265467fa2d6c70e0c545ed239b86c76555797",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Hand%20Over%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Hand%20Over%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e27265467fa2d6c70e0c545ed239b86c76555797",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Hand%20Over%20Mouth.png"
    }
  },
  {
    "name": "Face with Head-Bandage.png",
    "path": "Emojis/Smilies/Face with Head-Bandage.png",
    "sha": "ed68507d92b5d9fa0e3d03abe7641b2ea143ec92",
    "size": 1115293,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Head-Bandage.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Head-Bandage.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ed68507d92b5d9fa0e3d03abe7641b2ea143ec92",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Head-Bandage.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Head-Bandage.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ed68507d92b5d9fa0e3d03abe7641b2ea143ec92",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Head-Bandage.png"
    }
  },
  {
    "name": "Face with Medical Mask.png",
    "path": "Emojis/Smilies/Face with Medical Mask.png",
    "sha": "8c04c3092886d118d839f9681a4e7d27930db8d8",
    "size": 1216861,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Medical%20Mask.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Medical%20Mask.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c04c3092886d118d839f9681a4e7d27930db8d8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Medical%20Mask.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Medical%20Mask.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c04c3092886d118d839f9681a4e7d27930db8d8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Medical%20Mask.png"
    }
  },
  {
    "name": "Face with Monocle.png",
    "path": "Emojis/Smilies/Face with Monocle.png",
    "sha": "484f66cbbb16c6a3c3a1bdd3aaf116c3e1a9c14e",
    "size": 1071305,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Monocle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Monocle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/484f66cbbb16c6a3c3a1bdd3aaf116c3e1a9c14e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Monocle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Monocle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/484f66cbbb16c6a3c3a1bdd3aaf116c3e1a9c14e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Monocle.png"
    }
  },
  {
    "name": "Face with Open Eyes and Hand Over Mouth.png",
    "path": "Emojis/Smilies/Face with Open Eyes and Hand Over Mouth.png",
    "sha": "611c6e7079b602191d1b6b38277d4352ac5474e4",
    "size": 3381171,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Open%20Eyes%20and%20Hand%20Over%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Open%20Eyes%20and%20Hand%20Over%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/611c6e7079b602191d1b6b38277d4352ac5474e4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Open%20Eyes%20and%20Hand%20Over%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Open%20Eyes%20and%20Hand%20Over%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/611c6e7079b602191d1b6b38277d4352ac5474e4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Open%20Eyes%20and%20Hand%20Over%20Mouth.png"
    }
  },
  {
    "name": "Face with Open Mouth.png",
    "path": "Emojis/Smilies/Face with Open Mouth.png",
    "sha": "4b6f5c57f359b5f38cdd52dff5d7279a258e0a4d",
    "size": 1139288,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Open%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Open%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b6f5c57f359b5f38cdd52dff5d7279a258e0a4d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Open%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Open%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b6f5c57f359b5f38cdd52dff5d7279a258e0a4d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Open%20Mouth.png"
    }
  },
  {
    "name": "Face with Peeking Eye.png",
    "path": "Emojis/Smilies/Face with Peeking Eye.png",
    "sha": "1de977af069bbcd1fd90242fb58231630b31486e",
    "size": 2904456,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Peeking%20Eye.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Peeking%20Eye.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1de977af069bbcd1fd90242fb58231630b31486e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Peeking%20Eye.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Peeking%20Eye.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1de977af069bbcd1fd90242fb58231630b31486e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Peeking%20Eye.png"
    }
  },
  {
    "name": "Face with Raised Eyebrow.png",
    "path": "Emojis/Smilies/Face with Raised Eyebrow.png",
    "sha": "61113a61649eccc0f27b9760de54d21760abd44f",
    "size": 1159906,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Raised%20Eyebrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Raised%20Eyebrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/61113a61649eccc0f27b9760de54d21760abd44f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Raised%20Eyebrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Raised%20Eyebrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/61113a61649eccc0f27b9760de54d21760abd44f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Raised%20Eyebrow.png"
    }
  },
  {
    "name": "Face with Rolling Eyes.png",
    "path": "Emojis/Smilies/Face with Rolling Eyes.png",
    "sha": "bdfccd645767939904b4064be1fd433c9ad0c3f8",
    "size": 878467,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Rolling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Rolling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdfccd645767939904b4064be1fd433c9ad0c3f8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Rolling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Rolling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdfccd645767939904b4064be1fd433c9ad0c3f8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Rolling%20Eyes.png"
    }
  },
  {
    "name": "Face with Spiral Eyes.png",
    "path": "Emojis/Smilies/Face with Spiral Eyes.png",
    "sha": "109aac26c29a39af2922614ca9713f406230e3da",
    "size": 1337180,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Spiral%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Spiral%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/109aac26c29a39af2922614ca9713f406230e3da",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Spiral%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Spiral%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/109aac26c29a39af2922614ca9713f406230e3da",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Spiral%20Eyes.png"
    }
  },
  {
    "name": "Face with Steam From Nose.png",
    "path": "Emojis/Smilies/Face with Steam From Nose.png",
    "sha": "f074e697e71e0b14f3acaa45376cf8eedbd8a2bd",
    "size": 1241933,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Steam%20From%20Nose.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Steam%20From%20Nose.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f074e697e71e0b14f3acaa45376cf8eedbd8a2bd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Steam%20From%20Nose.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Steam%20From%20Nose.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f074e697e71e0b14f3acaa45376cf8eedbd8a2bd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Steam%20From%20Nose.png"
    }
  },
  {
    "name": "Face with Symbols on Mouth.png",
    "path": "Emojis/Smilies/Face with Symbols on Mouth.png",
    "sha": "87e45d5b792cc5e188f05ccd7e1bba090d81170d",
    "size": 1136295,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Symbols%20on%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Symbols%20on%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87e45d5b792cc5e188f05ccd7e1bba090d81170d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Symbols%20on%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Symbols%20on%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87e45d5b792cc5e188f05ccd7e1bba090d81170d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Symbols%20on%20Mouth.png"
    }
  },
  {
    "name": "Face with Tears of Joy.png",
    "path": "Emojis/Smilies/Face with Tears of Joy.png",
    "sha": "a9b3d529c9b338d37ad62b215634c368ae5deba0",
    "size": 744988,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Tears%20of%20Joy.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Tears%20of%20Joy.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a9b3d529c9b338d37ad62b215634c368ae5deba0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Tears%20of%20Joy.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Tears%20of%20Joy.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a9b3d529c9b338d37ad62b215634c368ae5deba0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Tears%20of%20Joy.png"
    }
  },
  {
    "name": "Face with Thermometer.png",
    "path": "Emojis/Smilies/Face with Thermometer.png",
    "sha": "0368e4b119b12f1fcd1f4738d44d38ec1114b9ea",
    "size": 1184042,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Thermometer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Thermometer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0368e4b119b12f1fcd1f4738d44d38ec1114b9ea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Thermometer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Thermometer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0368e4b119b12f1fcd1f4738d44d38ec1114b9ea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Thermometer.png"
    }
  },
  {
    "name": "Face with Tongue.png",
    "path": "Emojis/Smilies/Face with Tongue.png",
    "sha": "b6bf2b8edbe4214318b88fd1d6857a9c9b407a96",
    "size": 1009648,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Tongue.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Tongue.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b6bf2b8edbe4214318b88fd1d6857a9c9b407a96",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Face%20with%20Tongue.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Face%20with%20Tongue.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b6bf2b8edbe4214318b88fd1d6857a9c9b407a96",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Face%20with%20Tongue.png"
    }
  },
  {
    "name": "Fearful Face.png",
    "path": "Emojis/Smilies/Fearful Face.png",
    "sha": "f18529e3a7b24ed544c97f62ac74940aaa9cdd96",
    "size": 1000664,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Fearful%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Fearful%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f18529e3a7b24ed544c97f62ac74940aaa9cdd96",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Fearful%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Fearful%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f18529e3a7b24ed544c97f62ac74940aaa9cdd96",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Fearful%20Face.png"
    }
  },
  {
    "name": "Flushed Face.png",
    "path": "Emojis/Smilies/Flushed Face.png",
    "sha": "3685409c0f51d9f6cc6cfbd8358598717cde5017",
    "size": 1158572,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Flushed%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Flushed%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3685409c0f51d9f6cc6cfbd8358598717cde5017",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Flushed%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Flushed%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3685409c0f51d9f6cc6cfbd8358598717cde5017",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Flushed%20Face.png"
    }
  },
  {
    "name": "Frowning Face with Open Mouth.png",
    "path": "Emojis/Smilies/Frowning Face with Open Mouth.png",
    "sha": "952a635b73f0221a91a379beb161a34f8a83d08c",
    "size": 1107561,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Frowning%20Face%20with%20Open%20Mouth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Frowning%20Face%20with%20Open%20Mouth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/952a635b73f0221a91a379beb161a34f8a83d08c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Frowning%20Face%20with%20Open%20Mouth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Frowning%20Face%20with%20Open%20Mouth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/952a635b73f0221a91a379beb161a34f8a83d08c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Frowning%20Face%20with%20Open%20Mouth.png"
    }
  },
  {
    "name": "Frowning Face.png",
    "path": "Emojis/Smilies/Frowning Face.png",
    "sha": "8561fdf78ebf0783e64583c3631b16f12555cbc4",
    "size": 1136577,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Frowning%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Frowning%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8561fdf78ebf0783e64583c3631b16f12555cbc4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Frowning%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Frowning%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8561fdf78ebf0783e64583c3631b16f12555cbc4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Frowning%20Face.png"
    }
  },
  {
    "name": "Ghost.png",
    "path": "Emojis/Smilies/Ghost.png",
    "sha": "8cf4548cb66896051db0f15033d0e2387318580b",
    "size": 1053635,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Ghost.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Ghost.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8cf4548cb66896051db0f15033d0e2387318580b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Ghost.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Ghost.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8cf4548cb66896051db0f15033d0e2387318580b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Ghost.png"
    }
  },
  {
    "name": "Goblin.png",
    "path": "Emojis/Smilies/Goblin.png",
    "sha": "8a7d5c2fe1db3f38dfb9ee4a312eb234a4de77ee",
    "size": 1194564,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Goblin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Goblin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a7d5c2fe1db3f38dfb9ee4a312eb234a4de77ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Goblin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Goblin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a7d5c2fe1db3f38dfb9ee4a312eb234a4de77ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Goblin.png"
    }
  },
  {
    "name": "Green Heart.png",
    "path": "Emojis/Smilies/Green Heart.png",
    "sha": "0dbc12bbc7289824f5174e797dcdeaaf58ad18f7",
    "size": 997584,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Green%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Green%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0dbc12bbc7289824f5174e797dcdeaaf58ad18f7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Green%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Green%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0dbc12bbc7289824f5174e797dcdeaaf58ad18f7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Green%20Heart.png"
    }
  },
  {
    "name": "Grey Heart.png",
    "path": "Emojis/Smilies/Grey Heart.png",
    "sha": "352f8c6dadb7fec366838e0b6599aa750a80ece3",
    "size": 2093005,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grey%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grey%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/352f8c6dadb7fec366838e0b6599aa750a80ece3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grey%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grey%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/352f8c6dadb7fec366838e0b6599aa750a80ece3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grey%20Heart.png"
    }
  },
  {
    "name": "Grimacing Face.png",
    "path": "Emojis/Smilies/Grimacing Face.png",
    "sha": "7841dd3cff0fdb23f096b5463efa5297a007ddc4",
    "size": 1089730,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grimacing%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grimacing%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7841dd3cff0fdb23f096b5463efa5297a007ddc4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grimacing%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grimacing%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7841dd3cff0fdb23f096b5463efa5297a007ddc4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grimacing%20Face.png"
    }
  },
  {
    "name": "Grinning Cat with Smiling Eyes.png",
    "path": "Emojis/Smilies/Grinning Cat with Smiling Eyes.png",
    "sha": "c0dfa82b5a4e4fd18f8cd2768ad753edd5681b77",
    "size": 1139713,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Cat%20with%20Smiling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Cat%20with%20Smiling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c0dfa82b5a4e4fd18f8cd2768ad753edd5681b77",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Cat%20with%20Smiling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Cat%20with%20Smiling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c0dfa82b5a4e4fd18f8cd2768ad753edd5681b77",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Cat%20with%20Smiling%20Eyes.png"
    }
  },
  {
    "name": "Grinning Cat.png",
    "path": "Emojis/Smilies/Grinning Cat.png",
    "sha": "6dfc8c9bcd1861029d1bb8cc6efcf2691d3e8e8d",
    "size": 1123283,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6dfc8c9bcd1861029d1bb8cc6efcf2691d3e8e8d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6dfc8c9bcd1861029d1bb8cc6efcf2691d3e8e8d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Cat.png"
    }
  },
  {
    "name": "Grinning Face with Big Eyes.png",
    "path": "Emojis/Smilies/Grinning Face with Big Eyes.png",
    "sha": "3b53185ef930e7169ac6f6d79df6905d3a3ef78c",
    "size": 1126157,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Big%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Big%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b53185ef930e7169ac6f6d79df6905d3a3ef78c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Face%20with%20Big%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Big%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b53185ef930e7169ac6f6d79df6905d3a3ef78c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Big%20Eyes.png"
    }
  },
  {
    "name": "Grinning Face with Smiling Eyes.png",
    "path": "Emojis/Smilies/Grinning Face with Smiling Eyes.png",
    "sha": "e2fa6ff8801d7de367509056dd6868469a552af2",
    "size": 1136991,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Smiling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Smiling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2fa6ff8801d7de367509056dd6868469a552af2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Face%20with%20Smiling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Smiling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2fa6ff8801d7de367509056dd6868469a552af2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Smiling%20Eyes.png"
    }
  },
  {
    "name": "Grinning Face with Sweat.png",
    "path": "Emojis/Smilies/Grinning Face with Sweat.png",
    "sha": "91a5f89ba2a06d01898ab5fee84e853709a879ee",
    "size": 1147444,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Sweat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Sweat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91a5f89ba2a06d01898ab5fee84e853709a879ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Face%20with%20Sweat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face%20with%20Sweat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91a5f89ba2a06d01898ab5fee84e853709a879ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face%20with%20Sweat.png"
    }
  },
  {
    "name": "Grinning Face.png",
    "path": "Emojis/Smilies/Grinning Face.png",
    "sha": "1b86d1e4c511fad12ceee63664c727f11b97dffd",
    "size": 865796,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1b86d1e4c511fad12ceee63664c727f11b97dffd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1b86d1e4c511fad12ceee63664c727f11b97dffd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Face.png"
    }
  },
  {
    "name": "Grinning Squinting Face.png",
    "path": "Emojis/Smilies/Grinning Squinting Face.png",
    "sha": "5f58aaaa892585177c5313593cb39605e68cca16",
    "size": 1141955,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Squinting%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Squinting%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f58aaaa892585177c5313593cb39605e68cca16",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Grinning%20Squinting%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Grinning%20Squinting%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f58aaaa892585177c5313593cb39605e68cca16",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Grinning%20Squinting%20Face.png"
    }
  },
  {
    "name": "Growing Heart.png",
    "path": "Emojis/Smilies/Growing Heart.png",
    "sha": "c2574b1a5518b1e9488fb884c2c819d59281867c",
    "size": 542255,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Growing%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Growing%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2574b1a5518b1e9488fb884c2c819d59281867c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Growing%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Growing%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2574b1a5518b1e9488fb884c2c819d59281867c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Growing%20Heart.png"
    }
  },
  {
    "name": "Hear-No-Evil Monkey.png",
    "path": "Emojis/Smilies/Hear-No-Evil Monkey.png",
    "sha": "09b38586da044bd0ec2f17da8e3d057502f70f55",
    "size": 1258277,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hear-No-Evil%20Monkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hear-No-Evil%20Monkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/09b38586da044bd0ec2f17da8e3d057502f70f55",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hear-No-Evil%20Monkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hear-No-Evil%20Monkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/09b38586da044bd0ec2f17da8e3d057502f70f55",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hear-No-Evil%20Monkey.png"
    }
  },
  {
    "name": "Heart Decoration.png",
    "path": "Emojis/Smilies/Heart Decoration.png",
    "sha": "4d8c898d520efd64b2700e3c33b5af5a6c939640",
    "size": 740706,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20Decoration.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20Decoration.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d8c898d520efd64b2700e3c33b5af5a6c939640",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20Decoration.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20Decoration.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d8c898d520efd64b2700e3c33b5af5a6c939640",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20Decoration.png"
    }
  },
  {
    "name": "Heart Exclamation.png",
    "path": "Emojis/Smilies/Heart Exclamation.png",
    "sha": "7d2148ebbf76b01153309021d0fa9ce2be68ba29",
    "size": 751385,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20Exclamation.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20Exclamation.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d2148ebbf76b01153309021d0fa9ce2be68ba29",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20Exclamation.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20Exclamation.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d2148ebbf76b01153309021d0fa9ce2be68ba29",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20Exclamation.png"
    }
  },
  {
    "name": "Heart on Fire.png",
    "path": "Emojis/Smilies/Heart on Fire.png",
    "sha": "123ab00a4984c1a04778b7522032125199b7f840",
    "size": 1130729,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20on%20Fire.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20on%20Fire.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/123ab00a4984c1a04778b7522032125199b7f840",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20on%20Fire.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20on%20Fire.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/123ab00a4984c1a04778b7522032125199b7f840",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20on%20Fire.png"
    }
  },
  {
    "name": "Heart with Arrow.png",
    "path": "Emojis/Smilies/Heart with Arrow.png",
    "sha": "4f35600758b06c81e5c874f3fc1c1d7b27ba8155",
    "size": 668319,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20with%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20with%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4f35600758b06c81e5c874f3fc1c1d7b27ba8155",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20with%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20with%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4f35600758b06c81e5c874f3fc1c1d7b27ba8155",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20with%20Arrow.png"
    }
  },
  {
    "name": "Heart with Ribbon.png",
    "path": "Emojis/Smilies/Heart with Ribbon.png",
    "sha": "5fbcd97556f6a2b6b013d4d6f9549f7302e118aa",
    "size": 736979,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20with%20Ribbon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20with%20Ribbon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5fbcd97556f6a2b6b013d4d6f9549f7302e118aa",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Heart%20with%20Ribbon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Heart%20with%20Ribbon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5fbcd97556f6a2b6b013d4d6f9549f7302e118aa",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Heart%20with%20Ribbon.png"
    }
  },
  {
    "name": "Hole.png",
    "path": "Emojis/Smilies/Hole.png",
    "sha": "c2a3dc3584dd3104c61259c5da3b82a2399c912c",
    "size": 6504,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hole.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hole.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2a3dc3584dd3104c61259c5da3b82a2399c912c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hole.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hole.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2a3dc3584dd3104c61259c5da3b82a2399c912c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hole.png"
    }
  },
  {
    "name": "Hot Face.png",
    "path": "Emojis/Smilies/Hot Face.png",
    "sha": "00d4437e88c392f8b4cc456a655cb48fb3b89c1e",
    "size": 1167284,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hot%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hot%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00d4437e88c392f8b4cc456a655cb48fb3b89c1e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hot%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hot%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00d4437e88c392f8b4cc456a655cb48fb3b89c1e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hot%20Face.png"
    }
  },
  {
    "name": "Hugging Face.png",
    "path": "Emojis/Smilies/Hugging Face.png",
    "sha": "20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
    "size": 1426763,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hugging%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hugging%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hugging%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hugging%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hugging%20Face.png"
    }
  },
  {
    "name": "Hundred Points.png",
    "path": "Emojis/Smilies/Hundred Points.png",
    "sha": "811036d306baa1b3267d855bdc66b70862c253ee",
    "size": 619788,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hundred%20Points.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hundred%20Points.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/811036d306baa1b3267d855bdc66b70862c253ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hundred%20Points.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hundred%20Points.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/811036d306baa1b3267d855bdc66b70862c253ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hundred%20Points.png"
    }
  },
  {
    "name": "Hushed Face.png",
    "path": "Emojis/Smilies/Hushed Face.png",
    "sha": "62a76252f6a76d4960e58b5481234b2fb06194b9",
    "size": 753572,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hushed%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hushed%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/62a76252f6a76d4960e58b5481234b2fb06194b9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Hushed%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Hushed%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/62a76252f6a76d4960e58b5481234b2fb06194b9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Hushed%20Face.png"
    }
  },
  {
    "name": "Kiss Mark.png",
    "path": "Emojis/Smilies/Kiss Mark.png",
    "sha": "6e536e0e3a03e8bba18f64e766b3d5115c54ae2b",
    "size": 15562,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kiss%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kiss%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6e536e0e3a03e8bba18f64e766b3d5115c54ae2b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Kiss%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kiss%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6e536e0e3a03e8bba18f64e766b3d5115c54ae2b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kiss%20Mark.png"
    }
  },
  {
    "name": "Kissing Cat.png",
    "path": "Emojis/Smilies/Kissing Cat.png",
    "sha": "f0b21f01fd9a52905d518baab9267965099bfbbe",
    "size": 1192097,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f0b21f01fd9a52905d518baab9267965099bfbbe",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Kissing%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f0b21f01fd9a52905d518baab9267965099bfbbe",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Cat.png"
    }
  },
  {
    "name": "Kissing Face with Closed Eyes.png",
    "path": "Emojis/Smilies/Kissing Face with Closed Eyes.png",
    "sha": "6b802b886ac00841951bc5e926466cdae16c1247",
    "size": 1106594,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face%20with%20Closed%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face%20with%20Closed%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b802b886ac00841951bc5e926466cdae16c1247",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Kissing%20Face%20with%20Closed%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face%20with%20Closed%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b802b886ac00841951bc5e926466cdae16c1247",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face%20with%20Closed%20Eyes.png"
    }
  },
  {
    "name": "Kissing Face with Smiling Eyes.png",
    "path": "Emojis/Smilies/Kissing Face with Smiling Eyes.png",
    "sha": "be7650ecd413bbf841fc14e13a5d43360f0e288a",
    "size": 1228570,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face%20with%20Smiling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face%20with%20Smiling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/be7650ecd413bbf841fc14e13a5d43360f0e288a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Kissing%20Face%20with%20Smiling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face%20with%20Smiling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/be7650ecd413bbf841fc14e13a5d43360f0e288a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face%20with%20Smiling%20Eyes.png"
    }
  },
  {
    "name": "Kissing Face.png",
    "path": "Emojis/Smilies/Kissing Face.png",
    "sha": "b14dc287b6dbb25af697f2b8f885906da91670f7",
    "size": 1166072,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b14dc287b6dbb25af697f2b8f885906da91670f7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Kissing%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Kissing%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b14dc287b6dbb25af697f2b8f885906da91670f7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Kissing%20Face.png"
    }
  },
  {
    "name": "Left Speech Bubble.png",
    "path": "Emojis/Smilies/Left Speech Bubble.png",
    "sha": "eaa92244c74ec4ea5b27f2a2e79dbb3c7cab7501",
    "size": 10458,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Left%20Speech%20Bubble.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Left%20Speech%20Bubble.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eaa92244c74ec4ea5b27f2a2e79dbb3c7cab7501",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Left%20Speech%20Bubble.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Left%20Speech%20Bubble.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eaa92244c74ec4ea5b27f2a2e79dbb3c7cab7501",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Left%20Speech%20Bubble.png"
    }
  },
  {
    "name": "Light Blue Heart.png",
    "path": "Emojis/Smilies/Light Blue Heart.png",
    "sha": "a4195b471943368f7e7d7c33f5f6a2dd1e2b6638",
    "size": 2088853,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Light%20Blue%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Light%20Blue%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a4195b471943368f7e7d7c33f5f6a2dd1e2b6638",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Light%20Blue%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Light%20Blue%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a4195b471943368f7e7d7c33f5f6a2dd1e2b6638",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Light%20Blue%20Heart.png"
    }
  },
  {
    "name": "Loudly Crying Face.png",
    "path": "Emojis/Smilies/Loudly Crying Face.png",
    "sha": "42388b4e61276c07a019d56241380ef6b2ab389c",
    "size": 1161274,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Loudly%20Crying%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Loudly%20Crying%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/42388b4e61276c07a019d56241380ef6b2ab389c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Loudly%20Crying%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Loudly%20Crying%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/42388b4e61276c07a019d56241380ef6b2ab389c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Loudly%20Crying%20Face.png"
    }
  },
  {
    "name": "Love Letter.png",
    "path": "Emojis/Smilies/Love Letter.png",
    "sha": "632ba2a5c6aacf5c16d652ce25800e6d40f18765",
    "size": 913848,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Love%20Letter.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Love%20Letter.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/632ba2a5c6aacf5c16d652ce25800e6d40f18765",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Love%20Letter.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Love%20Letter.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/632ba2a5c6aacf5c16d652ce25800e6d40f18765",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Love%20Letter.png"
    }
  },
  {
    "name": "Lying Face.png",
    "path": "Emojis/Smilies/Lying Face.png",
    "sha": "af389434c7bd206b4ac64e97f7be7d4c530806ff",
    "size": 1201566,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Lying%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Lying%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af389434c7bd206b4ac64e97f7be7d4c530806ff",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Lying%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Lying%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af389434c7bd206b4ac64e97f7be7d4c530806ff",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Lying%20Face.png"
    }
  },
  {
    "name": "Mending Heart.png",
    "path": "Emojis/Smilies/Mending Heart.png",
    "sha": "330999cac5d983f66fa5957e26a3ac1e9017d544",
    "size": 440419,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Mending%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Mending%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/330999cac5d983f66fa5957e26a3ac1e9017d544",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Mending%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Mending%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/330999cac5d983f66fa5957e26a3ac1e9017d544",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Mending%20Heart.png"
    }
  },
  {
    "name": "Money-Mouth Face.png",
    "path": "Emojis/Smilies/Money-Mouth Face.png",
    "sha": "9cb893eb908a91aecc3b23dcb439973fb4438010",
    "size": 1108198,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Money-Mouth%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Money-Mouth%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cb893eb908a91aecc3b23dcb439973fb4438010",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Money-Mouth%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Money-Mouth%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cb893eb908a91aecc3b23dcb439973fb4438010",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Money-Mouth%20Face.png"
    }
  },
  {
    "name": "Nauseated Face.png",
    "path": "Emojis/Smilies/Nauseated Face.png",
    "sha": "5c06aa7af7624afc845fb5858f07fb366e8eb2c1",
    "size": 1167596,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Nauseated%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Nauseated%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c06aa7af7624afc845fb5858f07fb366e8eb2c1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nauseated%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Nauseated%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c06aa7af7624afc845fb5858f07fb366e8eb2c1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Nauseated%20Face.png"
    }
  },
  {
    "name": "Nerd Face.png",
    "path": "Emojis/Smilies/Nerd Face.png",
    "sha": "93c002b64274ef6086b21d667dba98c1896715a5",
    "size": 995711,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Nerd%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Nerd%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93c002b64274ef6086b21d667dba98c1896715a5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Nerd%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Nerd%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93c002b64274ef6086b21d667dba98c1896715a5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Nerd%20Face.png"
    }
  },
  {
    "name": "Neutral Face.png",
    "path": "Emojis/Smilies/Neutral Face.png",
    "sha": "d319fbcaa58f7f653799f0620d3a43786cd4fc37",
    "size": 544757,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Neutral%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Neutral%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d319fbcaa58f7f653799f0620d3a43786cd4fc37",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Neutral%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Neutral%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d319fbcaa58f7f653799f0620d3a43786cd4fc37",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Neutral%20Face.png"
    }
  },
  {
    "name": "Ogre.png",
    "path": "Emojis/Smilies/Ogre.png",
    "sha": "27782da06a213cf9f9961351437783f236b158e6",
    "size": 877823,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Ogre.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Ogre.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27782da06a213cf9f9961351437783f236b158e6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Ogre.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Ogre.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27782da06a213cf9f9961351437783f236b158e6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Ogre.png"
    }
  },
  {
    "name": "Orange Heart.png",
    "path": "Emojis/Smilies/Orange Heart.png",
    "sha": "29d433b8fcb1a172860749be64a3afabe9c70a3a",
    "size": 861914,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Orange%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Orange%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/29d433b8fcb1a172860749be64a3afabe9c70a3a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Orange%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Orange%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/29d433b8fcb1a172860749be64a3afabe9c70a3a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Orange%20Heart.png"
    }
  },
  {
    "name": "Partying Face.png",
    "path": "Emojis/Smilies/Partying Face.png",
    "sha": "128171a72fffcbd417138db09d9d8252ef933758",
    "size": 1250529,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Partying%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Partying%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/128171a72fffcbd417138db09d9d8252ef933758",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Partying%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Partying%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/128171a72fffcbd417138db09d9d8252ef933758",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Partying%20Face.png"
    }
  },
  {
    "name": "Pensive Face.png",
    "path": "Emojis/Smilies/Pensive Face.png",
    "sha": "47db6e6682ec35d362c4013e0d6022a09c18c289",
    "size": 1243761,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pensive%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pensive%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47db6e6682ec35d362c4013e0d6022a09c18c289",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pensive%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pensive%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47db6e6682ec35d362c4013e0d6022a09c18c289",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pensive%20Face.png"
    }
  },
  {
    "name": "Persevering Face.png",
    "path": "Emojis/Smilies/Persevering Face.png",
    "sha": "00b0c01add499dc206e88c6bdd982a2dc73b28ef",
    "size": 1254496,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Persevering%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Persevering%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00b0c01add499dc206e88c6bdd982a2dc73b28ef",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Persevering%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Persevering%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00b0c01add499dc206e88c6bdd982a2dc73b28ef",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Persevering%20Face.png"
    }
  },
  {
    "name": "Pile of Poo.png",
    "path": "Emojis/Smilies/Pile of Poo.png",
    "sha": "f7396e24421135bfaa08d9cfd6e1c5b4a0fd9776",
    "size": 1065043,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pile%20of%20Poo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pile%20of%20Poo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7396e24421135bfaa08d9cfd6e1c5b4a0fd9776",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pile%20of%20Poo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pile%20of%20Poo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7396e24421135bfaa08d9cfd6e1c5b4a0fd9776",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pile%20of%20Poo.png"
    }
  },
  {
    "name": "Pink Heart.png",
    "path": "Emojis/Smilies/Pink Heart.png",
    "sha": "fc84a2e02d0e1567e80a1d5af6e9b6458e80002e",
    "size": 2035161,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pink%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pink%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc84a2e02d0e1567e80a1d5af6e9b6458e80002e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pink%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pink%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc84a2e02d0e1567e80a1d5af6e9b6458e80002e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pink%20Heart.png"
    }
  },
  {
    "name": "Pleading Face.png",
    "path": "Emojis/Smilies/Pleading Face.png",
    "sha": "3ee231116c7926f6b592f688955afdd8ef442326",
    "size": 1227493,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pleading%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pleading%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ee231116c7926f6b592f688955afdd8ef442326",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pleading%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pleading%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ee231116c7926f6b592f688955afdd8ef442326",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pleading%20Face.png"
    }
  },
  {
    "name": "Pouting Cat.png",
    "path": "Emojis/Smilies/Pouting Cat.png",
    "sha": "3205f7f78257c0e48fb40cdcbb0414fa0252956c",
    "size": 16067,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pouting%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pouting%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3205f7f78257c0e48fb40cdcbb0414fa0252956c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Pouting%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Pouting%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3205f7f78257c0e48fb40cdcbb0414fa0252956c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Pouting%20Cat.png"
    }
  },
  {
    "name": "Purple Heart.png",
    "path": "Emojis/Smilies/Purple Heart.png",
    "sha": "321ac1b53b831f67614d13bb815f3bb089eb4f43",
    "size": 851610,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Purple%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Purple%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/321ac1b53b831f67614d13bb815f3bb089eb4f43",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Purple%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Purple%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/321ac1b53b831f67614d13bb815f3bb089eb4f43",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Purple%20Heart.png"
    }
  },
  {
    "name": "Red Heart.png",
    "path": "Emojis/Smilies/Red Heart.png",
    "sha": "12644aba0341ea0ee7da468705926bb11e0a566a",
    "size": 909548,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Red%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Red%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/12644aba0341ea0ee7da468705926bb11e0a566a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Red%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Red%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/12644aba0341ea0ee7da468705926bb11e0a566a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Red%20Heart.png"
    }
  },
  {
    "name": "Relieved Face.png",
    "path": "Emojis/Smilies/Relieved Face.png",
    "sha": "d581c6cf9a0e1903c745462f859b0d7f62c23807",
    "size": 1275842,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Relieved%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Relieved%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d581c6cf9a0e1903c745462f859b0d7f62c23807",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Relieved%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Relieved%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d581c6cf9a0e1903c745462f859b0d7f62c23807",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Relieved%20Face.png"
    }
  },
  {
    "name": "Revolving Hearts.png",
    "path": "Emojis/Smilies/Revolving Hearts.png",
    "sha": "4107ab441dd11d5bbda7c145302eb02286c87283",
    "size": 585951,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Revolving%20Hearts.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Revolving%20Hearts.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4107ab441dd11d5bbda7c145302eb02286c87283",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Revolving%20Hearts.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Revolving%20Hearts.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4107ab441dd11d5bbda7c145302eb02286c87283",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Revolving%20Hearts.png"
    }
  },
  {
    "name": "Right Anger Bubble.png",
    "path": "Emojis/Smilies/Right Anger Bubble.png",
    "sha": "e36b5804d5258174d75364461aec5799cf93a891",
    "size": 10397,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Right%20Anger%20Bubble.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Right%20Anger%20Bubble.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e36b5804d5258174d75364461aec5799cf93a891",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Right%20Anger%20Bubble.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Right%20Anger%20Bubble.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e36b5804d5258174d75364461aec5799cf93a891",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Right%20Anger%20Bubble.png"
    }
  },
  {
    "name": "Robot.png",
    "path": "Emojis/Smilies/Robot.png",
    "sha": "d0a8897fca372f91d63b7c8a060a9803a6cdd9fe",
    "size": 736227,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Robot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Robot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0a8897fca372f91d63b7c8a060a9803a6cdd9fe",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Robot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Robot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0a8897fca372f91d63b7c8a060a9803a6cdd9fe",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Robot.png"
    }
  },
  {
    "name": "Rolling on the Floor Laughing.png",
    "path": "Emojis/Smilies/Rolling on the Floor Laughing.png",
    "sha": "fb13f15ee758075594a63f10c65d4abdd5f77d75",
    "size": 1230432,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Rolling%20on%20the%20Floor%20Laughing.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Rolling%20on%20the%20Floor%20Laughing.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb13f15ee758075594a63f10c65d4abdd5f77d75",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Rolling%20on%20the%20Floor%20Laughing.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Rolling%20on%20the%20Floor%20Laughing.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb13f15ee758075594a63f10c65d4abdd5f77d75",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Rolling%20on%20the%20Floor%20Laughing.png"
    }
  },
  {
    "name": "Sad but Relieved Face.png",
    "path": "Emojis/Smilies/Sad but Relieved Face.png",
    "sha": "3f5eae5bd771f1afc01928ae3f4fad5f82cc9556",
    "size": 1195989,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sad%20but%20Relieved%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sad%20but%20Relieved%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f5eae5bd771f1afc01928ae3f4fad5f82cc9556",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sad%20but%20Relieved%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sad%20but%20Relieved%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f5eae5bd771f1afc01928ae3f4fad5f82cc9556",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sad%20but%20Relieved%20Face.png"
    }
  },
  {
    "name": "Saluting Face.png",
    "path": "Emojis/Smilies/Saluting Face.png",
    "sha": "f1ac3e571998019f7004a96d2b51ad795718d4c9",
    "size": 2189807,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Saluting%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Saluting%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1ac3e571998019f7004a96d2b51ad795718d4c9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Saluting%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Saluting%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1ac3e571998019f7004a96d2b51ad795718d4c9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Saluting%20Face.png"
    }
  },
  {
    "name": "See-No-Evil Monkey.png",
    "path": "Emojis/Smilies/See-No-Evil Monkey.png",
    "sha": "b576393d152cc3db59aabd4d61a173b13dc6ecd5",
    "size": 1318620,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/See-No-Evil%20Monkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/See-No-Evil%20Monkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b576393d152cc3db59aabd4d61a173b13dc6ecd5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/See-No-Evil%20Monkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/See-No-Evil%20Monkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b576393d152cc3db59aabd4d61a173b13dc6ecd5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/See-No-Evil%20Monkey.png"
    }
  },
  {
    "name": "Shaking Face.png",
    "path": "Emojis/Smilies/Shaking Face.png",
    "sha": "235a03925f3afe59e5606408cc5992dd6d359a5e",
    "size": 1954725,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Shaking%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Shaking%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/235a03925f3afe59e5606408cc5992dd6d359a5e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Shaking%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Shaking%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/235a03925f3afe59e5606408cc5992dd6d359a5e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Shaking%20Face.png"
    }
  },
  {
    "name": "Shushing Face.png",
    "path": "Emojis/Smilies/Shushing Face.png",
    "sha": "b289be860b5ffbb92884630030c2b1aced5ba40a",
    "size": 1220050,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Shushing%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Shushing%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b289be860b5ffbb92884630030c2b1aced5ba40a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Shushing%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Shushing%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b289be860b5ffbb92884630030c2b1aced5ba40a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Shushing%20Face.png"
    }
  },
  {
    "name": "Skull and Crossbones.png",
    "path": "Emojis/Smilies/Skull and Crossbones.png",
    "sha": "36f6f58e0a4fddbc243e3632579f1434ce2bc7d8",
    "size": 1155894,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Skull%20and%20Crossbones.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Skull%20and%20Crossbones.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/36f6f58e0a4fddbc243e3632579f1434ce2bc7d8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Skull%20and%20Crossbones.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Skull%20and%20Crossbones.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/36f6f58e0a4fddbc243e3632579f1434ce2bc7d8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Skull%20and%20Crossbones.png"
    }
  },
  {
    "name": "Skull.png",
    "path": "Emojis/Smilies/Skull.png",
    "sha": "7523cd146de5cd544af40b182aff0144dfa4350b",
    "size": 1171676,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Skull.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Skull.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7523cd146de5cd544af40b182aff0144dfa4350b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Skull.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Skull.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7523cd146de5cd544af40b182aff0144dfa4350b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Skull.png"
    }
  },
  {
    "name": "Sleeping Face.png",
    "path": "Emojis/Smilies/Sleeping Face.png",
    "sha": "40af852dc399def3293083e3e2f9c6d3a970c124",
    "size": 1258759,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sleeping%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sleeping%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/40af852dc399def3293083e3e2f9c6d3a970c124",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sleeping%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sleeping%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/40af852dc399def3293083e3e2f9c6d3a970c124",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sleeping%20Face.png"
    }
  },
  {
    "name": "Sleepy Face.png",
    "path": "Emojis/Smilies/Sleepy Face.png",
    "sha": "000da8a2c5b692ffd2ca780fd497873291a37aa8",
    "size": 1216431,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sleepy%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sleepy%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/000da8a2c5b692ffd2ca780fd497873291a37aa8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sleepy%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sleepy%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/000da8a2c5b692ffd2ca780fd497873291a37aa8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sleepy%20Face.png"
    }
  },
  {
    "name": "Slightly Frowning Face.png",
    "path": "Emojis/Smilies/Slightly Frowning Face.png",
    "sha": "1bd00a32f3c04d2de81ee483c1e149f55371dc03",
    "size": 1124336,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Slightly%20Frowning%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Slightly%20Frowning%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1bd00a32f3c04d2de81ee483c1e149f55371dc03",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Slightly%20Frowning%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Slightly%20Frowning%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1bd00a32f3c04d2de81ee483c1e149f55371dc03",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Slightly%20Frowning%20Face.png"
    }
  },
  {
    "name": "Slightly Smiling Face.png",
    "path": "Emojis/Smilies/Slightly Smiling Face.png",
    "sha": "4480f8d40cf8c53e22451c7d6998cc38d6d4c758",
    "size": 1143985,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Slightly%20Smiling%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Slightly%20Smiling%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4480f8d40cf8c53e22451c7d6998cc38d6d4c758",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Slightly%20Smiling%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Slightly%20Smiling%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4480f8d40cf8c53e22451c7d6998cc38d6d4c758",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Slightly%20Smiling%20Face.png"
    }
  },
  {
    "name": "Smiling Cat with Heart-Eyes.png",
    "path": "Emojis/Smilies/Smiling Cat with Heart-Eyes.png",
    "sha": "77fe4cb8579938c5f17944656a65766f1e0482ed",
    "size": 1122286,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Cat%20with%20Heart-Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Cat%20with%20Heart-Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77fe4cb8579938c5f17944656a65766f1e0482ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Cat%20with%20Heart-Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Cat%20with%20Heart-Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77fe4cb8579938c5f17944656a65766f1e0482ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Cat%20with%20Heart-Eyes.png"
    }
  },
  {
    "name": "Smiling Face with Halo.png",
    "path": "Emojis/Smilies/Smiling Face with Halo.png",
    "sha": "05d4017b3a6bfde234294b791bcf1d4bfc8f159f",
    "size": 1313803,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Halo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/05d4017b3a6bfde234294b791bcf1d4bfc8f159f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Halo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/05d4017b3a6bfde234294b791bcf1d4bfc8f159f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Halo.png"
    }
  },
  {
    "name": "Smiling Face with Heart-Eyes.png",
    "path": "Emojis/Smilies/Smiling Face with Heart-Eyes.png",
    "sha": "fe560d6d11b925998bf3e2643adee2bd885ef218",
    "size": 1173940,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe560d6d11b925998bf3e2643adee2bd885ef218",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe560d6d11b925998bf3e2643adee2bd885ef218",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Heart-Eyes.png"
    }
  },
  {
    "name": "Smiling Face with Hearts.png",
    "path": "Emojis/Smilies/Smiling Face with Hearts.png",
    "sha": "30637d7dcb811675f4c63a67ccfce92a590ae283",
    "size": 1344144,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Hearts.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Hearts.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/30637d7dcb811675f4c63a67ccfce92a590ae283",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Hearts.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Hearts.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/30637d7dcb811675f4c63a67ccfce92a590ae283",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Hearts.png"
    }
  },
  {
    "name": "Smiling Face with Horns.png",
    "path": "Emojis/Smilies/Smiling Face with Horns.png",
    "sha": "69e8ea4607e40e67a854fd5ecca1f6fc23c2172d",
    "size": 1346910,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Horns.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Horns.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/69e8ea4607e40e67a854fd5ecca1f6fc23c2172d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Horns.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Horns.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/69e8ea4607e40e67a854fd5ecca1f6fc23c2172d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Horns.png"
    }
  },
  {
    "name": "Smiling Face with Open Hands.png",
    "path": "Emojis/Smilies/Smiling Face with Open Hands.png",
    "sha": "20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
    "size": 1426763,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Open%20Hands.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Open%20Hands.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Open%20Hands.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Open%20Hands.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20eb3462c4d2516c3426d662fc7dce71c0cc9f88",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Open%20Hands.png"
    }
  },
  {
    "name": "Smiling Face with Smiling Eyes.png",
    "path": "Emojis/Smilies/Smiling Face with Smiling Eyes.png",
    "sha": "a78471beb79dd784cd4f7e87dc41fe7963b79c95",
    "size": 1237479,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Smiling%20Eyes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Smiling%20Eyes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a78471beb79dd784cd4f7e87dc41fe7963b79c95",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Smiling%20Eyes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Smiling%20Eyes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a78471beb79dd784cd4f7e87dc41fe7963b79c95",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Smiling%20Eyes.png"
    }
  },
  {
    "name": "Smiling Face with Sunglasses.png",
    "path": "Emojis/Smilies/Smiling Face with Sunglasses.png",
    "sha": "d2fcbb19fcb30e126353fc6aff6d48c974e4893e",
    "size": 1251699,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2fcbb19fcb30e126353fc6aff6d48c974e4893e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2fcbb19fcb30e126353fc6aff6d48c974e4893e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Sunglasses.png"
    }
  },
  {
    "name": "Smiling Face with Tear.png",
    "path": "Emojis/Smilies/Smiling Face with Tear.png",
    "sha": "4691e136f000410fcc6f26e9d0ce8a0177f7458b",
    "size": 1052232,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Tear.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Tear.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4691e136f000410fcc6f26e9d0ce8a0177f7458b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face%20with%20Tear.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face%20with%20Tear.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4691e136f000410fcc6f26e9d0ce8a0177f7458b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face%20with%20Tear.png"
    }
  },
  {
    "name": "Smiling Face.png",
    "path": "Emojis/Smilies/Smiling Face.png",
    "sha": "4f63fb2a5a6df3f30104d672432e57fa91d91ff4",
    "size": 1268718,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4f63fb2a5a6df3f30104d672432e57fa91d91ff4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smiling%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smiling%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4f63fb2a5a6df3f30104d672432e57fa91d91ff4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smiling%20Face.png"
    }
  },
  {
    "name": "Smirking Face.png",
    "path": "Emojis/Smilies/Smirking Face.png",
    "sha": "711385a6b67e08c95583c9c500f4901a4c6a8bb4",
    "size": 1191000,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smirking%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smirking%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/711385a6b67e08c95583c9c500f4901a4c6a8bb4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Smirking%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Smirking%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/711385a6b67e08c95583c9c500f4901a4c6a8bb4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Smirking%20Face.png"
    }
  },
  {
    "name": "Sneezing Face.png",
    "path": "Emojis/Smilies/Sneezing Face.png",
    "sha": "a501776fc6a2ed425a86f1a0d465e0c04c7761ba",
    "size": 1227925,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sneezing%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sneezing%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a501776fc6a2ed425a86f1a0d465e0c04c7761ba",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sneezing%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sneezing%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a501776fc6a2ed425a86f1a0d465e0c04c7761ba",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sneezing%20Face.png"
    }
  },
  {
    "name": "Sparkling Heart.png",
    "path": "Emojis/Smilies/Sparkling Heart.png",
    "sha": "62bf579e612bedd0d77963331fc3c82db52a58fc",
    "size": 540473,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sparkling%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sparkling%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/62bf579e612bedd0d77963331fc3c82db52a58fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sparkling%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sparkling%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/62bf579e612bedd0d77963331fc3c82db52a58fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sparkling%20Heart.png"
    }
  },
  {
    "name": "Speak-No-Evil Monkey.png",
    "path": "Emojis/Smilies/Speak-No-Evil Monkey.png",
    "sha": "eb5ada61fcfbdf54d284ed9d156554b56965807d",
    "size": 1319036,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Speak-No-Evil%20Monkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Speak-No-Evil%20Monkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb5ada61fcfbdf54d284ed9d156554b56965807d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Speak-No-Evil%20Monkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Speak-No-Evil%20Monkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb5ada61fcfbdf54d284ed9d156554b56965807d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Speak-No-Evil%20Monkey.png"
    }
  },
  {
    "name": "Speech Balloon.png",
    "path": "Emojis/Smilies/Speech Balloon.png",
    "sha": "964e4041d94582ae3967c8f9e074d98388b67da0",
    "size": 9595,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Speech%20Balloon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Speech%20Balloon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/964e4041d94582ae3967c8f9e074d98388b67da0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Speech%20Balloon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Speech%20Balloon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/964e4041d94582ae3967c8f9e074d98388b67da0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Speech%20Balloon.png"
    }
  },
  {
    "name": "Squinting Face with Tongue.png",
    "path": "Emojis/Smilies/Squinting Face with Tongue.png",
    "sha": "5a0e27d1015d3fc8314125a62fa75537ea13cf37",
    "size": 1182510,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Squinting%20Face%20with%20Tongue.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Squinting%20Face%20with%20Tongue.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a0e27d1015d3fc8314125a62fa75537ea13cf37",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Squinting%20Face%20with%20Tongue.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Squinting%20Face%20with%20Tongue.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a0e27d1015d3fc8314125a62fa75537ea13cf37",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Squinting%20Face%20with%20Tongue.png"
    }
  },
  {
    "name": "Star-Struck.png",
    "path": "Emojis/Smilies/Star-Struck.png",
    "sha": "98580065b39275ea72e975ed5d53bf88756990de",
    "size": 1179397,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Star-Struck.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Star-Struck.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/98580065b39275ea72e975ed5d53bf88756990de",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Star-Struck.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Star-Struck.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/98580065b39275ea72e975ed5d53bf88756990de",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Star-Struck.png"
    }
  },
  {
    "name": "Sweat Droplets.png",
    "path": "Emojis/Smilies/Sweat Droplets.png",
    "sha": "a875f9f94a8bd794e75f0d3f255f46b0920ef2b4",
    "size": 13966,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sweat%20Droplets.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sweat%20Droplets.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a875f9f94a8bd794e75f0d3f255f46b0920ef2b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Sweat%20Droplets.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Sweat%20Droplets.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a875f9f94a8bd794e75f0d3f255f46b0920ef2b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Sweat%20Droplets.png"
    }
  },
  {
    "name": "Thinking Face.png",
    "path": "Emojis/Smilies/Thinking Face.png",
    "sha": "59fb4ba149f7b714924806d4ac84855612767657",
    "size": 1314914,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Thinking%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Thinking%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59fb4ba149f7b714924806d4ac84855612767657",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thinking%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Thinking%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59fb4ba149f7b714924806d4ac84855612767657",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Thinking%20Face.png"
    }
  },
  {
    "name": "Thought Balloon.png",
    "path": "Emojis/Smilies/Thought Balloon.png",
    "sha": "5c9f0e392feb6b2dbe828bd562939c68972fc8a2",
    "size": 12578,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Thought%20Balloon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Thought%20Balloon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c9f0e392feb6b2dbe828bd562939c68972fc8a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Thought%20Balloon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Thought%20Balloon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c9f0e392feb6b2dbe828bd562939c68972fc8a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Thought%20Balloon.png"
    }
  },
  {
    "name": "Tired Face.png",
    "path": "Emojis/Smilies/Tired Face.png",
    "sha": "11bf97d32bb5638f2c620444fa9295194369033e",
    "size": 1203528,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Tired%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Tired%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11bf97d32bb5638f2c620444fa9295194369033e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Tired%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Tired%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11bf97d32bb5638f2c620444fa9295194369033e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Tired%20Face.png"
    }
  },
  {
    "name": "Two Hearts.png",
    "path": "Emojis/Smilies/Two Hearts.png",
    "sha": "415fbba26afd448d75f21caee668aab42b3f4971",
    "size": 585456,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Two%20Hearts.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Two%20Hearts.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/415fbba26afd448d75f21caee668aab42b3f4971",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Two%20Hearts.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Two%20Hearts.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/415fbba26afd448d75f21caee668aab42b3f4971",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Two%20Hearts.png"
    }
  },
  {
    "name": "Unamused Face.png",
    "path": "Emojis/Smilies/Unamused Face.png",
    "sha": "5038716be2b8ba38e0f501e231d86e65dbd5625e",
    "size": 1550476,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Unamused%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Unamused%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5038716be2b8ba38e0f501e231d86e65dbd5625e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Unamused%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Unamused%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5038716be2b8ba38e0f501e231d86e65dbd5625e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Unamused%20Face.png"
    }
  },
  {
    "name": "Upside-Down Face.png",
    "path": "Emojis/Smilies/Upside-Down Face.png",
    "sha": "f4e228198ba093a3e814da23f9e385c54dec096b",
    "size": 1129909,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Upside-Down%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Upside-Down%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4e228198ba093a3e814da23f9e385c54dec096b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Upside-Down%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Upside-Down%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4e228198ba093a3e814da23f9e385c54dec096b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Upside-Down%20Face.png"
    }
  },
  {
    "name": "Weary Cat.png",
    "path": "Emojis/Smilies/Weary Cat.png",
    "sha": "c612f25f5f9ef039adfb2b69cdd6b00fbc7d309f",
    "size": 940635,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Weary%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Weary%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c612f25f5f9ef039adfb2b69cdd6b00fbc7d309f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Weary%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Weary%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c612f25f5f9ef039adfb2b69cdd6b00fbc7d309f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Weary%20Cat.png"
    }
  },
  {
    "name": "Weary Face.png",
    "path": "Emojis/Smilies/Weary Face.png",
    "sha": "ef6a9f7f116817082c6937af49f85a309748ce91",
    "size": 1201888,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Weary%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Weary%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef6a9f7f116817082c6937af49f85a309748ce91",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Weary%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Weary%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef6a9f7f116817082c6937af49f85a309748ce91",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Weary%20Face.png"
    }
  },
  {
    "name": "White Heart.png",
    "path": "Emojis/Smilies/White Heart.png",
    "sha": "21f72e9dc0ceeb208232cf630590227038430586",
    "size": 814803,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/White%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/White%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21f72e9dc0ceeb208232cf630590227038430586",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/White%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/White%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21f72e9dc0ceeb208232cf630590227038430586",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/White%20Heart.png"
    }
  },
  {
    "name": "Winking Face with Tongue.png",
    "path": "Emojis/Smilies/Winking Face with Tongue.png",
    "sha": "886f8a8828d133ef709ec7c97898ba531d3e851b",
    "size": 880545,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Winking%20Face%20with%20Tongue.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Winking%20Face%20with%20Tongue.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/886f8a8828d133ef709ec7c97898ba531d3e851b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Winking%20Face%20with%20Tongue.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Winking%20Face%20with%20Tongue.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/886f8a8828d133ef709ec7c97898ba531d3e851b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Winking%20Face%20with%20Tongue.png"
    }
  },
  {
    "name": "Winking Face.png",
    "path": "Emojis/Smilies/Winking Face.png",
    "sha": "d7858900099bb431b60538170bf5b35b4d51adcf",
    "size": 871914,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Winking%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Winking%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7858900099bb431b60538170bf5b35b4d51adcf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Winking%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Winking%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7858900099bb431b60538170bf5b35b4d51adcf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Winking%20Face.png"
    }
  },
  {
    "name": "Woozy Face.png",
    "path": "Emojis/Smilies/Woozy Face.png",
    "sha": "6fcbff64a2a47bf2d7f1ed9fd693a0afeb43053f",
    "size": 1227920,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Woozy%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Woozy%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fcbff64a2a47bf2d7f1ed9fd693a0afeb43053f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Woozy%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Woozy%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fcbff64a2a47bf2d7f1ed9fd693a0afeb43053f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Woozy%20Face.png"
    }
  },
  {
    "name": "Worried Face.png",
    "path": "Emojis/Smilies/Worried Face.png",
    "sha": "e9aad9990819cc7099389dc2c0045bd2443c1858",
    "size": 1206131,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Worried%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Worried%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9aad9990819cc7099389dc2c0045bd2443c1858",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Worried%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Worried%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9aad9990819cc7099389dc2c0045bd2443c1858",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Worried%20Face.png"
    }
  },
  {
    "name": "Yawning Face.png",
    "path": "Emojis/Smilies/Yawning Face.png",
    "sha": "a2558b498bcab3c0354a03122faef63f68340da9",
    "size": 1294167,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Yawning%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Yawning%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a2558b498bcab3c0354a03122faef63f68340da9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Yawning%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Yawning%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a2558b498bcab3c0354a03122faef63f68340da9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Yawning%20Face.png"
    }
  },
  {
    "name": "Yellow Heart.png",
    "path": "Emojis/Smilies/Yellow Heart.png",
    "sha": "b26e76d98afe28ce35b2f9a92729c3e512a67abc",
    "size": 998684,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Yellow%20Heart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Yellow%20Heart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b26e76d98afe28ce35b2f9a92729c3e512a67abc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Yellow%20Heart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Yellow%20Heart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b26e76d98afe28ce35b2f9a92729c3e512a67abc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Yellow%20Heart.png"
    }
  },
  {
    "name": "Zany Face.png",
    "path": "Emojis/Smilies/Zany Face.png",
    "sha": "636eb7a1489c9826482663576e6bad187a2a1b4c",
    "size": 1147736,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zany%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zany%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/636eb7a1489c9826482663576e6bad187a2a1b4c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Zany%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zany%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/636eb7a1489c9826482663576e6bad187a2a1b4c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zany%20Face.png"
    }
  },
  {
    "name": "Zipper-Mouth Face.png",
    "path": "Emojis/Smilies/Zipper-Mouth Face.png",
    "sha": "6655985a8234ef65a2548be2e22c3440c7511a74",
    "size": 1554070,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zipper-Mouth%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zipper-Mouth%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6655985a8234ef65a2548be2e22c3440c7511a74",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Zipper-Mouth%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zipper-Mouth%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6655985a8234ef65a2548be2e22c3440c7511a74",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zipper-Mouth%20Face.png"
    }
  },
  {
    "name": "Zzz.png",
    "path": "Emojis/Smilies/Zzz.png",
    "sha": "a130e1b4a8690e29862d5b8230d7ce0652ab1548",
    "size": 422953,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zzz.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zzz.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a130e1b4a8690e29862d5b8230d7ce0652ab1548",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Smilies/Zzz.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Smilies/Zzz.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a130e1b4a8690e29862d5b8230d7ce0652ab1548",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Smilies/Zzz.png"
    }
  }
]

export default {
  id: 'smiles',
  name: 'Smiles',
  emojis: customEmojis.map((emoji) => {
    return {
      id: emoji.sha,
      name: emoji.name,
      skins: [{ src: emoji.download_url }]
    }
  }).reduce((accumulator, current) => {
    if (!accumulator.seen.has(current.id)) {
        accumulator.seen.add(current.id);
        accumulator.result.push(current);
    }
    return accumulator;
  }, { result: [], seen: new Set() }).result
}