import { createSlice } from "@reduxjs/toolkit";

const listSlice = createSlice({
    name : "list",
    initialState : null,
    reducers:{
        setList : (state,action) =>{
            return action.payload
        }
    }
})



export const listReducer =  listSlice.reducer

export const {setList} = listSlice.actions