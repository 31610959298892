import { PlusOutlined, DeleteOutlined, EditOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Progress, Popconfirm, Table, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { get, xdelete } from "../services/api";
import moment from "moment";
import ServiceCreate from '../components/ServiceCreate';
import ServiceTransactionCreate from "../components/ServiceTransactionCreate";
import ServiceEdit from "../components/ServiceEdit";

export default function Services () {
  const [services, setServices] = useState([]);
  const [service, setService] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTransactModalOpen, setIsTransactModalOpen] = useState(false);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name, service) => {
        return <div className="avatar-with-profile">
          <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} />
          <div className="heading-with-tagline">
            <a href="#">{name}</a>
            <span></span>
          </div>
        </div>
      },
    },
    {
      title: 'Cost',
      dataIndex: 'rate',
      render: (rate, { quantity, period }) => {
        return <div className="heading-with-tagline">
          <Typography.Text className="amount" type={ rate < 0 ? "success" : "danger" }>{
            new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(Math.abs(rate*quantity))
          }</Typography.Text>
          <span>/ {period}</span>
        </div>
      }
    },
    {
      title: 'Service Expiring',
      render: (service) => {
        let validTill = service.validTill || null;
        if(service.transactions && service.transactions.length) {
          validTill = service.transactions[0].endAt;
        }
        const remainingDays = moment().add(12, 'months').diff(moment(validTill), 'days');
        const percent = remainingDays*100/365;
        let status = 'active';
        if(percent < 25) { status = 'success' }
        if(percent > 75) { status = 'exception' }
        return <div className="heading-with-tagline">
          <Progress percent={percent} showInfo={false} status={status}/>
          <span>{validTill ? moment(validTill).fromNow() : null}</span>
        </div>
      }
    },
    {
      title: 'Actions',
      dataIndex: '',
      align: 'left',
      render: (service) => {
        return <>
          <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <Button onClick={() => showTransactModal(service)} type="default" shape="circle" icon={<PlusOutlined />}></Button>
          <Button onClick={() => showEditModal(service)} type="default" shape="circle" icon={<EditOutlined />}></Button>
            <Popconfirm
              title="Delete Service"
              placement="bottomRight"
              description="Are you sure to delete this Service?"
              onConfirm={() => deleteService(service)}
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          {/* <Button onClick={() => deleteService(service)} type="default" shape="circle" icon={<DeleteOutlined />}></Button> */}
          </div>
        </>
      }
    },
  ]

  const deleteService = async (asset) => {
    await xdelete(`/services/${asset.id}`);
    await refreshServices();
  };

  const showEditModal = (service) => {
    setService(service)
    setIsEditModalOpen(true);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const showTransactModal = (service) => {
    setService(service);
    setIsTransactModalOpen(true);
  };
  const refreshServices = async () => {
    return get("/services", {
      include: [{
        relation: "transactions",
        scope: {
          limit: 1,
          order: 'endAt DESC'
        }
      }]
    }).then(setServices);
  }

  const onServiceCreate = async () => {
    await refreshServices();
  }
  const onServiceEdit = async () => {
    await refreshServices();
  }

  const onServiceTransactionCreate = async () => {
    setService(null);
    await refreshServices();
  }
  const selectType = async (type) => {
    if(type === 'all') { return await onServiceCreate() }
    const services = await get('/services?filter={"where":{"type":"'+type+'"}}');
    return setServices(services);
  }
  useEffect(() => {
    refreshServices();
  }, []);
  return <Flex gap={40}>
    <div>
      <Button type="primary" className="align bold" icon={<PlusOutlined />} style={{ margin: "10px 0 36px" }} onClick={showModal}>
        Add Service
      </Button>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `All`,
          key: 'all'
        }]}
      />
    </div>
    <Table style={{ flex: '1' }} scroll={{ y: window.innerHeight-120 }} pagination={false} dataSource={services} rowKey={'id'} columns={columns} />
    <ServiceCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onServiceCreate={onServiceCreate} service={service} />
    <ServiceEdit isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} onServiceEdit={onServiceEdit} service={service} setService={setService} />
    <ServiceTransactionCreate isModalOpen={isTransactModalOpen} setIsModalOpen={setIsTransactModalOpen} service={service} setService={setService} onServiceTransactionCreate={onServiceTransactionCreate} />
  </Flex>
}