import { Button, DatePicker, Flex, Form, Input, Modal, Select } from "antd";
import { patch,  } from "../services/api";
import dayjs from "dayjs";
import moment from "moment";

export default function AssetEdit({ isModalOpen, setIsModalOpen, onAssetEdit, asset, setAsset }) {

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setAsset(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      lastUsed: values.lastUsed ? moment(values.lastUsed.unix() * 1000) : null
    };
    if (!values.lastUsed) {
      delete data.lastUsed;
    }
    const resp = await patch(`/assets/${asset.id}`, data);
    setIsModalOpen(false);
    onAssetEdit()
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  if (!asset) { return null; }
  return <Modal title="Edit Asset" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="editAssetForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="editAssetForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={asset.name}
          rules={[{
            required: true,
            message: 'Please input asset name!',
          }]}>
          <Input autoFocus={true} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>

        <Form.Item
          style={{ flex: 1 }}
          label="End"
          name="lastUsed"
          initialValue={dayjs().endOf('month').endOf('day')}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}