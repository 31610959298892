const customEmojis = [
  {
    "name": "Ant.png",
    "path": "Emojis/Animals/Ant.png",
    "sha": "ecfc48784099190510ddbccbade7dcb7be6dd864",
    "size": 891219,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ant.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ant.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ecfc48784099190510ddbccbade7dcb7be6dd864",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Ant.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ant.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ecfc48784099190510ddbccbade7dcb7be6dd864",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ant.png"
    }
  },
  {
    "name": "Baby Chick.png",
    "path": "Emojis/Animals/Baby Chick.png",
    "sha": "e202d842b3aa0a6755d273b83081a58173a6ab6d",
    "size": 844755,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Baby%20Chick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Baby%20Chick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e202d842b3aa0a6755d273b83081a58173a6ab6d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Baby%20Chick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Baby%20Chick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e202d842b3aa0a6755d273b83081a58173a6ab6d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Baby%20Chick.png"
    }
  },
  {
    "name": "Badger.png",
    "path": "Emojis/Animals/Badger.png",
    "sha": "dce35f856f009f9958b4cd241da5fbe88be9202a",
    "size": 660493,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Badger.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Badger.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dce35f856f009f9958b4cd241da5fbe88be9202a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Badger.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Badger.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dce35f856f009f9958b4cd241da5fbe88be9202a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Badger.png"
    }
  },
  {
    "name": "Bat.png",
    "path": "Emojis/Animals/Bat.png",
    "sha": "1176425474abdd6f26565caac968992874364fd5",
    "size": 724795,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1176425474abdd6f26565caac968992874364fd5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1176425474abdd6f26565caac968992874364fd5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bat.png"
    }
  },
  {
    "name": "Bear.png",
    "path": "Emojis/Animals/Bear.png",
    "sha": "98c135c4e65496e14295d10a4898d06d9f016a76",
    "size": 1269840,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bear.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bear.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/98c135c4e65496e14295d10a4898d06d9f016a76",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bear.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bear.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/98c135c4e65496e14295d10a4898d06d9f016a76",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bear.png"
    }
  },
  {
    "name": "Beaver.png",
    "path": "Emojis/Animals/Beaver.png",
    "sha": "c8a1779ca668ddbf9a07013dafd1ab6e95f8107a",
    "size": 990238,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Beaver.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Beaver.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8a1779ca668ddbf9a07013dafd1ab6e95f8107a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Beaver.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Beaver.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8a1779ca668ddbf9a07013dafd1ab6e95f8107a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Beaver.png"
    }
  },
  {
    "name": "Beetle.png",
    "path": "Emojis/Animals/Beetle.png",
    "sha": "acf1690e4618ba71055469e5a1fb7c7b7e1dcd05",
    "size": 979816,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Beetle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Beetle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/acf1690e4618ba71055469e5a1fb7c7b7e1dcd05",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Beetle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Beetle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/acf1690e4618ba71055469e5a1fb7c7b7e1dcd05",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Beetle.png"
    }
  },
  {
    "name": "Bird.png",
    "path": "Emojis/Animals/Bird.png",
    "sha": "71d6bbb7abbe1fdfb1e96499a7f0461ca6e1c24e",
    "size": 913715,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bird.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bird.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/71d6bbb7abbe1fdfb1e96499a7f0461ca6e1c24e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bird.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bird.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/71d6bbb7abbe1fdfb1e96499a7f0461ca6e1c24e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bird.png"
    }
  },
  {
    "name": "Bison.png",
    "path": "Emojis/Animals/Bison.png",
    "sha": "52bd5c5e160e3331e718fbed6b6ddd83fc57a3e0",
    "size": 1019256,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bison.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bison.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/52bd5c5e160e3331e718fbed6b6ddd83fc57a3e0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bison.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bison.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/52bd5c5e160e3331e718fbed6b6ddd83fc57a3e0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bison.png"
    }
  },
  {
    "name": "Black Bird.png",
    "path": "Emojis/Animals/Black Bird.png",
    "sha": "477a16aa8b2192beeecacda6bd97406502c8da0a",
    "size": 2123424,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Black%20Bird.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Black%20Bird.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/477a16aa8b2192beeecacda6bd97406502c8da0a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Black%20Bird.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Black%20Bird.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/477a16aa8b2192beeecacda6bd97406502c8da0a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Black%20Bird.png"
    }
  },
  {
    "name": "Black Cat.png",
    "path": "Emojis/Animals/Black Cat.png",
    "sha": "e6483b6d9c7f4e5fd3027462657199bb9de0404f",
    "size": 893531,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Black%20Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Black%20Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6483b6d9c7f4e5fd3027462657199bb9de0404f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Black%20Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Black%20Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6483b6d9c7f4e5fd3027462657199bb9de0404f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Black%20Cat.png"
    }
  },
  {
    "name": "Blossom.png",
    "path": "Emojis/Animals/Blossom.png",
    "sha": "1eb66b82f26c198b1250064c2b93ee57d4f0894e",
    "size": 13275,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Blossom.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Blossom.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1eb66b82f26c198b1250064c2b93ee57d4f0894e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Blossom.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Blossom.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1eb66b82f26c198b1250064c2b93ee57d4f0894e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Blossom.png"
    }
  },
  {
    "name": "Blowfish.png",
    "path": "Emojis/Animals/Blowfish.png",
    "sha": "d95f7fa16881b22ca02fcc16841e6bc26a4ef157",
    "size": 658899,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Blowfish.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Blowfish.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d95f7fa16881b22ca02fcc16841e6bc26a4ef157",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Blowfish.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Blowfish.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d95f7fa16881b22ca02fcc16841e6bc26a4ef157",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Blowfish.png"
    }
  },
  {
    "name": "Boar.png",
    "path": "Emojis/Animals/Boar.png",
    "sha": "cdbcdd6e5d6db339e97ecfea481d42c8bc06ef81",
    "size": 947683,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Boar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Boar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cdbcdd6e5d6db339e97ecfea481d42c8bc06ef81",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Boar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Boar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cdbcdd6e5d6db339e97ecfea481d42c8bc06ef81",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Boar.png"
    }
  },
  {
    "name": "Bouquet.png",
    "path": "Emojis/Animals/Bouquet.png",
    "sha": "b5d429be4e20adcdaba942095c4773cf43aab8a1",
    "size": 12530,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bouquet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bouquet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5d429be4e20adcdaba942095c4773cf43aab8a1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bouquet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bouquet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5d429be4e20adcdaba942095c4773cf43aab8a1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bouquet.png"
    }
  },
  {
    "name": "Bug.png",
    "path": "Emojis/Animals/Bug.png",
    "sha": "4bf406cf6fd9904aa154d10ed26c4987c153ea4b",
    "size": 708650,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bug.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bug.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4bf406cf6fd9904aa154d10ed26c4987c153ea4b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Bug.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Bug.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4bf406cf6fd9904aa154d10ed26c4987c153ea4b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Bug.png"
    }
  },
  {
    "name": "Butterfly.png",
    "path": "Emojis/Animals/Butterfly.png",
    "sha": "23a2912700c8411826555b74667f6c1675acb0b3",
    "size": 1193103,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Butterfly.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Butterfly.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23a2912700c8411826555b74667f6c1675acb0b3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Butterfly.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Butterfly.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23a2912700c8411826555b74667f6c1675acb0b3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Butterfly.png"
    }
  },
  {
    "name": "Cactus.png",
    "path": "Emojis/Animals/Cactus.png",
    "sha": "ce16585adeb0c508617a2b65d5149d6c861faa07",
    "size": 15210,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cactus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cactus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce16585adeb0c508617a2b65d5149d6c861faa07",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cactus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cactus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce16585adeb0c508617a2b65d5149d6c861faa07",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cactus.png"
    }
  },
  {
    "name": "Camel.png",
    "path": "Emojis/Animals/Camel.png",
    "sha": "c195372ac121a85f4740370a016c5b7c7f62bd1e",
    "size": 737230,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Camel.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Camel.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c195372ac121a85f4740370a016c5b7c7f62bd1e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Camel.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Camel.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c195372ac121a85f4740370a016c5b7c7f62bd1e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Camel.png"
    }
  },
  {
    "name": "Cat Face.png",
    "path": "Emojis/Animals/Cat Face.png",
    "sha": "ff4dbd26c0238307273e4235837c949b427fb179",
    "size": 1117564,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cat%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cat%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ff4dbd26c0238307273e4235837c949b427fb179",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cat%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cat%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ff4dbd26c0238307273e4235837c949b427fb179",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cat%20Face.png"
    }
  },
  {
    "name": "Cat.png",
    "path": "Emojis/Animals/Cat.png",
    "sha": "3391a68aebcd48d63d2c1f91304d6732847d0a3d",
    "size": 1006168,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3391a68aebcd48d63d2c1f91304d6732847d0a3d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3391a68aebcd48d63d2c1f91304d6732847d0a3d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cat.png"
    }
  },
  {
    "name": "Cherry Blossom.png",
    "path": "Emojis/Animals/Cherry Blossom.png",
    "sha": "1303ca1fb47c817c8463794d1cdfe9d0dcfe4992",
    "size": 16084,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cherry%20Blossom.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cherry%20Blossom.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1303ca1fb47c817c8463794d1cdfe9d0dcfe4992",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cherry%20Blossom.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cherry%20Blossom.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1303ca1fb47c817c8463794d1cdfe9d0dcfe4992",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cherry%20Blossom.png"
    }
  },
  {
    "name": "Chicken.png",
    "path": "Emojis/Animals/Chicken.png",
    "sha": "563a438592a76f81f8278c7f1aac321485396734",
    "size": 922367,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Chicken.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Chicken.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/563a438592a76f81f8278c7f1aac321485396734",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Chicken.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Chicken.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/563a438592a76f81f8278c7f1aac321485396734",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Chicken.png"
    }
  },
  {
    "name": "Chipmunk.png",
    "path": "Emojis/Animals/Chipmunk.png",
    "sha": "5f23f2bb9c9c6503901940ccd597bba309b946e0",
    "size": 1075060,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Chipmunk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Chipmunk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f23f2bb9c9c6503901940ccd597bba309b946e0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Chipmunk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Chipmunk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f23f2bb9c9c6503901940ccd597bba309b946e0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Chipmunk.png"
    }
  },
  {
    "name": "Cockroach.png",
    "path": "Emojis/Animals/Cockroach.png",
    "sha": "fdf6061903e277146619bdf9416af19c37fff82b",
    "size": 1111787,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cockroach.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cockroach.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fdf6061903e277146619bdf9416af19c37fff82b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cockroach.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cockroach.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fdf6061903e277146619bdf9416af19c37fff82b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cockroach.png"
    }
  },
  {
    "name": "Coral.png",
    "path": "Emojis/Animals/Coral.png",
    "sha": "a028b15b29a35f09ca407be9ca22b5e297be624e",
    "size": 3230455,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Coral.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Coral.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a028b15b29a35f09ca407be9ca22b5e297be624e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Coral.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Coral.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a028b15b29a35f09ca407be9ca22b5e297be624e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Coral.png"
    }
  },
  {
    "name": "Cow Face.png",
    "path": "Emojis/Animals/Cow Face.png",
    "sha": "6eaffdb49efc9272229efc462f2ec754900a492f",
    "size": 920028,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cow%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cow%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6eaffdb49efc9272229efc462f2ec754900a492f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cow%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cow%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6eaffdb49efc9272229efc462f2ec754900a492f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cow%20Face.png"
    }
  },
  {
    "name": "Cow.png",
    "path": "Emojis/Animals/Cow.png",
    "sha": "767de4a3c6e0771190155b7d8199a67fe5d09fbb",
    "size": 954254,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/767de4a3c6e0771190155b7d8199a67fe5d09fbb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/767de4a3c6e0771190155b7d8199a67fe5d09fbb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cow.png"
    }
  },
  {
    "name": "Cricket.png",
    "path": "Emojis/Animals/Cricket.png",
    "sha": "5ecbe10a9d3f7e53756c4e84507dbd1ea397375a",
    "size": 850013,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cricket.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cricket.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5ecbe10a9d3f7e53756c4e84507dbd1ea397375a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Cricket.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Cricket.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5ecbe10a9d3f7e53756c4e84507dbd1ea397375a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Cricket.png"
    }
  },
  {
    "name": "Crocodile.png",
    "path": "Emojis/Animals/Crocodile.png",
    "sha": "6525095284f204524b4922b0a89e05ca4915aaeb",
    "size": 1027536,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Crocodile.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Crocodile.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6525095284f204524b4922b0a89e05ca4915aaeb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Crocodile.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Crocodile.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6525095284f204524b4922b0a89e05ca4915aaeb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Crocodile.png"
    }
  },
  {
    "name": "Deciduous Tree.png",
    "path": "Emojis/Animals/Deciduous Tree.png",
    "sha": "18e07de6f899051d02a348434ec6c62e85f6be95",
    "size": 13285,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Deciduous%20Tree.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Deciduous%20Tree.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18e07de6f899051d02a348434ec6c62e85f6be95",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Deciduous%20Tree.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Deciduous%20Tree.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18e07de6f899051d02a348434ec6c62e85f6be95",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Deciduous%20Tree.png"
    }
  },
  {
    "name": "Deer.png",
    "path": "Emojis/Animals/Deer.png",
    "sha": "2d69b06977f41576ada8482ec00786672fce7f40",
    "size": 785959,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Deer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Deer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2d69b06977f41576ada8482ec00786672fce7f40",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Deer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Deer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2d69b06977f41576ada8482ec00786672fce7f40",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Deer.png"
    }
  },
  {
    "name": "Dodo.png",
    "path": "Emojis/Animals/Dodo.png",
    "sha": "d16f6e894de210332b479e83b0a6cfc594532a1d",
    "size": 899443,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dodo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dodo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d16f6e894de210332b479e83b0a6cfc594532a1d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dodo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dodo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d16f6e894de210332b479e83b0a6cfc594532a1d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dodo.png"
    }
  },
  {
    "name": "Dog Face.png",
    "path": "Emojis/Animals/Dog Face.png",
    "sha": "8bfa0912be1006d262943e29ab97d1353188eb05",
    "size": 1184225,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dog%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dog%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8bfa0912be1006d262943e29ab97d1353188eb05",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dog%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dog%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8bfa0912be1006d262943e29ab97d1353188eb05",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dog%20Face.png"
    }
  },
  {
    "name": "Dog.png",
    "path": "Emojis/Animals/Dog.png",
    "sha": "f5cc8bd811c2167faface8630af40f6ec1514b8e",
    "size": 912042,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5cc8bd811c2167faface8630af40f6ec1514b8e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5cc8bd811c2167faface8630af40f6ec1514b8e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dog.png"
    }
  },
  {
    "name": "Dolphin.png",
    "path": "Emojis/Animals/Dolphin.png",
    "sha": "93850b35658c4f05643ac3407347aef92b422fa8",
    "size": 846481,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dolphin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dolphin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93850b35658c4f05643ac3407347aef92b422fa8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dolphin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dolphin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93850b35658c4f05643ac3407347aef92b422fa8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dolphin.png"
    }
  },
  {
    "name": "Donkey.png",
    "path": "Emojis/Animals/Donkey.png",
    "sha": "42bcdd30ed596a5aedf6c871577511dbdabcaa87",
    "size": 1383355,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Donkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Donkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/42bcdd30ed596a5aedf6c871577511dbdabcaa87",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Donkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Donkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/42bcdd30ed596a5aedf6c871577511dbdabcaa87",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Donkey.png"
    }
  },
  {
    "name": "Dove.png",
    "path": "Emojis/Animals/Dove.png",
    "sha": "4ad55ec5438bf3842f666927b03668e460d375ce",
    "size": 863041,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dove.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dove.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ad55ec5438bf3842f666927b03668e460d375ce",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dove.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dove.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ad55ec5438bf3842f666927b03668e460d375ce",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dove.png"
    }
  },
  {
    "name": "Dragon Face.png",
    "path": "Emojis/Animals/Dragon Face.png",
    "sha": "3f94bfb1f6f8a06896fbba6218708874ac8606a5",
    "size": 1077325,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dragon%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dragon%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f94bfb1f6f8a06896fbba6218708874ac8606a5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dragon%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dragon%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f94bfb1f6f8a06896fbba6218708874ac8606a5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dragon%20Face.png"
    }
  },
  {
    "name": "Dragon.png",
    "path": "Emojis/Animals/Dragon.png",
    "sha": "9b6da981db4c644a70899df0913024cd6ab9f64d",
    "size": 1036319,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dragon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dragon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9b6da981db4c644a70899df0913024cd6ab9f64d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Dragon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Dragon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9b6da981db4c644a70899df0913024cd6ab9f64d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Dragon.png"
    }
  },
  {
    "name": "Duck.png",
    "path": "Emojis/Animals/Duck.png",
    "sha": "02d957fc76f16e31ba02f7d45343a3876b1403c6",
    "size": 909592,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Duck.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Duck.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/02d957fc76f16e31ba02f7d45343a3876b1403c6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Duck.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Duck.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/02d957fc76f16e31ba02f7d45343a3876b1403c6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Duck.png"
    }
  },
  {
    "name": "Eagle.png",
    "path": "Emojis/Animals/Eagle.png",
    "sha": "2b5892bf60f3162242144eddd9a61100f398a502",
    "size": 726028,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Eagle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Eagle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b5892bf60f3162242144eddd9a61100f398a502",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Eagle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Eagle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b5892bf60f3162242144eddd9a61100f398a502",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Eagle.png"
    }
  },
  {
    "name": "Elephant.png",
    "path": "Emojis/Animals/Elephant.png",
    "sha": "c193f770e449fc4354c7243a568e7aac5a9d8828",
    "size": 997981,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Elephant.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Elephant.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c193f770e449fc4354c7243a568e7aac5a9d8828",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Elephant.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Elephant.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c193f770e449fc4354c7243a568e7aac5a9d8828",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Elephant.png"
    }
  },
  {
    "name": "Empty Nest.png",
    "path": "Emojis/Animals/Empty Nest.png",
    "sha": "165223b8cf721e021c686de7cefe325c0641a0f7",
    "size": 2893495,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Empty%20Nest.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Empty%20Nest.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/165223b8cf721e021c686de7cefe325c0641a0f7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Empty%20Nest.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Empty%20Nest.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/165223b8cf721e021c686de7cefe325c0641a0f7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Empty%20Nest.png"
    }
  },
  {
    "name": "Evergreen Tree.png",
    "path": "Emojis/Animals/Evergreen Tree.png",
    "sha": "abf53302ab38fcf1510a6c599f172d978975088f",
    "size": 9417,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Evergreen%20Tree.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Evergreen%20Tree.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/abf53302ab38fcf1510a6c599f172d978975088f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Evergreen%20Tree.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Evergreen%20Tree.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/abf53302ab38fcf1510a6c599f172d978975088f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Evergreen%20Tree.png"
    }
  },
  {
    "name": "Ewe.png",
    "path": "Emojis/Animals/Ewe.png",
    "sha": "6ee529e788f66f0ce383637ae0b4b680ca9f21b4",
    "size": 783264,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ewe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ewe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ee529e788f66f0ce383637ae0b4b680ca9f21b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Ewe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ewe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ee529e788f66f0ce383637ae0b4b680ca9f21b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ewe.png"
    }
  },
  {
    "name": "Fallen Leaf.png",
    "path": "Emojis/Animals/Fallen Leaf.png",
    "sha": "d54134f6e06ff6ad0f976cb3da33c4ca79d604b8",
    "size": 12251,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fallen%20Leaf.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fallen%20Leaf.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d54134f6e06ff6ad0f976cb3da33c4ca79d604b8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Fallen%20Leaf.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fallen%20Leaf.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d54134f6e06ff6ad0f976cb3da33c4ca79d604b8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fallen%20Leaf.png"
    }
  },
  {
    "name": "Feather.png",
    "path": "Emojis/Animals/Feather.png",
    "sha": "66228304f6cab7f13694ae3cb696bcaa4207d715",
    "size": 9961,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Feather.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Feather.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/66228304f6cab7f13694ae3cb696bcaa4207d715",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Feather.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Feather.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/66228304f6cab7f13694ae3cb696bcaa4207d715",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Feather.png"
    }
  },
  {
    "name": "Fish.png",
    "path": "Emojis/Animals/Fish.png",
    "sha": "0a11cfce815fd44f4cbf1dcfed3cbb86f5b1eaf9",
    "size": 852224,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fish.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fish.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a11cfce815fd44f4cbf1dcfed3cbb86f5b1eaf9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Fish.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fish.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a11cfce815fd44f4cbf1dcfed3cbb86f5b1eaf9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fish.png"
    }
  },
  {
    "name": "Flamingo.png",
    "path": "Emojis/Animals/Flamingo.png",
    "sha": "c54a6b9ffad3b5b9d5b0b3eef3f71c92f49ddf5b",
    "size": 699381,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Flamingo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Flamingo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c54a6b9ffad3b5b9d5b0b3eef3f71c92f49ddf5b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Flamingo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Flamingo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c54a6b9ffad3b5b9d5b0b3eef3f71c92f49ddf5b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Flamingo.png"
    }
  },
  {
    "name": "Fly.png",
    "path": "Emojis/Animals/Fly.png",
    "sha": "3e37c190d7dfe3788ffd285af0f6549f101f6ffb",
    "size": 847168,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fly.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fly.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e37c190d7dfe3788ffd285af0f6549f101f6ffb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Fly.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fly.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e37c190d7dfe3788ffd285af0f6549f101f6ffb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fly.png"
    }
  },
  {
    "name": "Four Leaf Clover.png",
    "path": "Emojis/Animals/Four Leaf Clover.png",
    "sha": "d3898e79ad3021e1a2731e63d4bea45c39d955dc",
    "size": 15031,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Four%20Leaf%20Clover.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Four%20Leaf%20Clover.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d3898e79ad3021e1a2731e63d4bea45c39d955dc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Four%20Leaf%20Clover.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Four%20Leaf%20Clover.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d3898e79ad3021e1a2731e63d4bea45c39d955dc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Four%20Leaf%20Clover.png"
    }
  },
  {
    "name": "Fox.png",
    "path": "Emojis/Animals/Fox.png",
    "sha": "de8868837adb74954ca8da055eca93aacec96236",
    "size": 1110665,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fox.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fox.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/de8868837adb74954ca8da055eca93aacec96236",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Fox.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Fox.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/de8868837adb74954ca8da055eca93aacec96236",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Fox.png"
    }
  },
  {
    "name": "Frog.png",
    "path": "Emojis/Animals/Frog.png",
    "sha": "c134ff5b1685bf9ba570b5c2eb5bee0bd0898ade",
    "size": 1181841,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Frog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Frog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c134ff5b1685bf9ba570b5c2eb5bee0bd0898ade",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Frog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Frog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c134ff5b1685bf9ba570b5c2eb5bee0bd0898ade",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Frog.png"
    }
  },
  {
    "name": "Front-Facing Baby Chick.png",
    "path": "Emojis/Animals/Front-Facing Baby Chick.png",
    "sha": "4a1ed6482e4fbb8125b2e0f861b93851d2f85d00",
    "size": 892113,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Front-Facing%20Baby%20Chick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Front-Facing%20Baby%20Chick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4a1ed6482e4fbb8125b2e0f861b93851d2f85d00",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Front-Facing%20Baby%20Chick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Front-Facing%20Baby%20Chick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4a1ed6482e4fbb8125b2e0f861b93851d2f85d00",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Front-Facing%20Baby%20Chick.png"
    }
  },
  {
    "name": "Giraffe.png",
    "path": "Emojis/Animals/Giraffe.png",
    "sha": "efbe87e03edf9179c33db5468216c64a3c8e9434",
    "size": 840153,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Giraffe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Giraffe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/efbe87e03edf9179c33db5468216c64a3c8e9434",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Giraffe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Giraffe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/efbe87e03edf9179c33db5468216c64a3c8e9434",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Giraffe.png"
    }
  },
  {
    "name": "Goat.png",
    "path": "Emojis/Animals/Goat.png",
    "sha": "b00e3e2e496476cc7d6591731822feeb6a8a160b",
    "size": 737702,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Goat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Goat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b00e3e2e496476cc7d6591731822feeb6a8a160b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Goat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Goat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b00e3e2e496476cc7d6591731822feeb6a8a160b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Goat.png"
    }
  },
  {
    "name": "Goose.png",
    "path": "Emojis/Animals/Goose.png",
    "sha": "9cb1a6e2426d13a7073a10d8eef18ca0fe4f49b0",
    "size": 3246407,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Goose.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Goose.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cb1a6e2426d13a7073a10d8eef18ca0fe4f49b0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Goose.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Goose.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cb1a6e2426d13a7073a10d8eef18ca0fe4f49b0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Goose.png"
    }
  },
  {
    "name": "Gorilla.png",
    "path": "Emojis/Animals/Gorilla.png",
    "sha": "7e735575096de2bab894d536e560f32cb65f5ac4",
    "size": 924999,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Gorilla.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Gorilla.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7e735575096de2bab894d536e560f32cb65f5ac4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Gorilla.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Gorilla.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7e735575096de2bab894d536e560f32cb65f5ac4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Gorilla.png"
    }
  },
  {
    "name": "Guide Dog.png",
    "path": "Emojis/Animals/Guide Dog.png",
    "sha": "adac23f04d01f047bfa0a4756d4e1825fbc90456",
    "size": 934631,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Guide%20Dog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Guide%20Dog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adac23f04d01f047bfa0a4756d4e1825fbc90456",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Guide%20Dog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Guide%20Dog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adac23f04d01f047bfa0a4756d4e1825fbc90456",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Guide%20Dog.png"
    }
  },
  {
    "name": "Hamster.png",
    "path": "Emojis/Animals/Hamster.png",
    "sha": "4d44210da0e71f2977d55fccf0a18778ac5e1d69",
    "size": 1149836,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hamster.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hamster.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d44210da0e71f2977d55fccf0a18778ac5e1d69",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hamster.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hamster.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d44210da0e71f2977d55fccf0a18778ac5e1d69",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hamster.png"
    }
  },
  {
    "name": "Hatching Chick.png",
    "path": "Emojis/Animals/Hatching Chick.png",
    "sha": "d96b1cef148325eed0bdfc15c6aad99fe5b1b49c",
    "size": 1018213,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hatching%20Chick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hatching%20Chick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d96b1cef148325eed0bdfc15c6aad99fe5b1b49c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hatching%20Chick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hatching%20Chick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d96b1cef148325eed0bdfc15c6aad99fe5b1b49c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hatching%20Chick.png"
    }
  },
  {
    "name": "Hedgehog.png",
    "path": "Emojis/Animals/Hedgehog.png",
    "sha": "c855712431101a6bc56e37a6ad9871991e8d6093",
    "size": 898826,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hedgehog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hedgehog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c855712431101a6bc56e37a6ad9871991e8d6093",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hedgehog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hedgehog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c855712431101a6bc56e37a6ad9871991e8d6093",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hedgehog.png"
    }
  },
  {
    "name": "Herb.png",
    "path": "Emojis/Animals/Herb.png",
    "sha": "4c54173d95759e5158beda43616f262be638528c",
    "size": 11254,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Herb.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Herb.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c54173d95759e5158beda43616f262be638528c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Herb.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Herb.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c54173d95759e5158beda43616f262be638528c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Herb.png"
    }
  },
  {
    "name": "Hibiscus.png",
    "path": "Emojis/Animals/Hibiscus.png",
    "sha": "b5097882de1b0b02a3190ba88be28ccb1085b9d3",
    "size": 15745,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hibiscus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hibiscus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5097882de1b0b02a3190ba88be28ccb1085b9d3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hibiscus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hibiscus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5097882de1b0b02a3190ba88be28ccb1085b9d3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hibiscus.png"
    }
  },
  {
    "name": "Hippopotamus.png",
    "path": "Emojis/Animals/Hippopotamus.png",
    "sha": "43b2a894d17c09ccb4ea68d6da2772fa7d034182",
    "size": 789358,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hippopotamus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hippopotamus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43b2a894d17c09ccb4ea68d6da2772fa7d034182",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hippopotamus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hippopotamus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43b2a894d17c09ccb4ea68d6da2772fa7d034182",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hippopotamus.png"
    }
  },
  {
    "name": "Honeybee.png",
    "path": "Emojis/Animals/Honeybee.png",
    "sha": "e6812cf85b17a7de1f7b48d3722ca2ffcce0acb4",
    "size": 856472,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Honeybee.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Honeybee.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6812cf85b17a7de1f7b48d3722ca2ffcce0acb4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Honeybee.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Honeybee.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6812cf85b17a7de1f7b48d3722ca2ffcce0acb4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Honeybee.png"
    }
  },
  {
    "name": "Horse Face.png",
    "path": "Emojis/Animals/Horse Face.png",
    "sha": "9f7dfaf968fa88ddfa4117d4e54f87b502dab5a9",
    "size": 1069231,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Horse%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Horse%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f7dfaf968fa88ddfa4117d4e54f87b502dab5a9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Horse%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Horse%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f7dfaf968fa88ddfa4117d4e54f87b502dab5a9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Horse%20Face.png"
    }
  },
  {
    "name": "Horse.png",
    "path": "Emojis/Animals/Horse.png",
    "sha": "113a5c541184fd17d5eafd9d0082a3659e1b810e",
    "size": 923758,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Horse.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Horse.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/113a5c541184fd17d5eafd9d0082a3659e1b810e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Horse.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Horse.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/113a5c541184fd17d5eafd9d0082a3659e1b810e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Horse.png"
    }
  },
  {
    "name": "Hyacinth.png",
    "path": "Emojis/Animals/Hyacinth.png",
    "sha": "ad49a7df1ea3ea781f0b05209bafb087c654b478",
    "size": 52491,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hyacinth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hyacinth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad49a7df1ea3ea781f0b05209bafb087c654b478",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Hyacinth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Hyacinth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad49a7df1ea3ea781f0b05209bafb087c654b478",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Hyacinth.png"
    }
  },
  {
    "name": "Jellyfish.png",
    "path": "Emojis/Animals/Jellyfish.png",
    "sha": "9ea7d5efdd286a19be831c6fe323b38c496434c4",
    "size": 2354292,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Jellyfish.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Jellyfish.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ea7d5efdd286a19be831c6fe323b38c496434c4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Jellyfish.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Jellyfish.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ea7d5efdd286a19be831c6fe323b38c496434c4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Jellyfish.png"
    }
  },
  {
    "name": "Kangaroo.png",
    "path": "Emojis/Animals/Kangaroo.png",
    "sha": "1d520d0c69b2f12f10ecac0613b8486ffa861df0",
    "size": 598890,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Kangaroo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Kangaroo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1d520d0c69b2f12f10ecac0613b8486ffa861df0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Kangaroo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Kangaroo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1d520d0c69b2f12f10ecac0613b8486ffa861df0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Kangaroo.png"
    }
  },
  {
    "name": "Koala.png",
    "path": "Emojis/Animals/Koala.png",
    "sha": "a682616020f0633a1d6292b28f561842eba38ba8",
    "size": 1053243,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Koala.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Koala.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a682616020f0633a1d6292b28f561842eba38ba8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Koala.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Koala.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a682616020f0633a1d6292b28f561842eba38ba8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Koala.png"
    }
  },
  {
    "name": "Lady Beetle.png",
    "path": "Emojis/Animals/Lady Beetle.png",
    "sha": "21ec68ff3fac2770849d15d50d54e637e25cd4db",
    "size": 1027139,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lady%20Beetle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lady%20Beetle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21ec68ff3fac2770849d15d50d54e637e25cd4db",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Lady%20Beetle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lady%20Beetle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21ec68ff3fac2770849d15d50d54e637e25cd4db",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lady%20Beetle.png"
    }
  },
  {
    "name": "Leopard.png",
    "path": "Emojis/Animals/Leopard.png",
    "sha": "59f5ca62b1d280199802df13859c2f2682461546",
    "size": 848697,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Leopard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Leopard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59f5ca62b1d280199802df13859c2f2682461546",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Leopard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Leopard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59f5ca62b1d280199802df13859c2f2682461546",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Leopard.png"
    }
  },
  {
    "name": "Lion.png",
    "path": "Emojis/Animals/Lion.png",
    "sha": "a377008ca1df1eea215c39d6f6d9ac026353ba02",
    "size": 1401372,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lion.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lion.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a377008ca1df1eea215c39d6f6d9ac026353ba02",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Lion.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lion.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a377008ca1df1eea215c39d6f6d9ac026353ba02",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lion.png"
    }
  },
  {
    "name": "Lizard.png",
    "path": "Emojis/Animals/Lizard.png",
    "sha": "ac6dae128000adce00e88ba3c77b9256b3a8a28b",
    "size": 790377,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lizard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lizard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac6dae128000adce00e88ba3c77b9256b3a8a28b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Lizard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Lizard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac6dae128000adce00e88ba3c77b9256b3a8a28b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Lizard.png"
    }
  },
  {
    "name": "Llama.png",
    "path": "Emojis/Animals/Llama.png",
    "sha": "0c136ab0d94cbaba02ba5920fc9cedd37039f163",
    "size": 752344,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Llama.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Llama.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0c136ab0d94cbaba02ba5920fc9cedd37039f163",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Llama.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Llama.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0c136ab0d94cbaba02ba5920fc9cedd37039f163",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Llama.png"
    }
  },
  {
    "name": "Mammoth.png",
    "path": "Emojis/Animals/Mammoth.png",
    "sha": "9237567f14cc385041f2cc87ea420485739b7ff9",
    "size": 1055955,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mammoth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mammoth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9237567f14cc385041f2cc87ea420485739b7ff9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Mammoth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mammoth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9237567f14cc385041f2cc87ea420485739b7ff9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mammoth.png"
    }
  },
  {
    "name": "Maple Leaf.png",
    "path": "Emojis/Animals/Maple Leaf.png",
    "sha": "dfeab53bef8195fa5da7e29fb4a9ed7d8a00ed26",
    "size": 12210,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Maple%20Leaf.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Maple%20Leaf.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dfeab53bef8195fa5da7e29fb4a9ed7d8a00ed26",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Maple%20Leaf.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Maple%20Leaf.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dfeab53bef8195fa5da7e29fb4a9ed7d8a00ed26",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Maple%20Leaf.png"
    }
  },
  {
    "name": "Microbe.png",
    "path": "Emojis/Animals/Microbe.png",
    "sha": "fd855e3cae56b7171ccc4e69dba18d371822712d",
    "size": 962799,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Microbe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Microbe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd855e3cae56b7171ccc4e69dba18d371822712d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Microbe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Microbe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd855e3cae56b7171ccc4e69dba18d371822712d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Microbe.png"
    }
  },
  {
    "name": "Monkey Face.png",
    "path": "Emojis/Animals/Monkey Face.png",
    "sha": "eab9c6dbf920d014475eb942e80b554808803cea",
    "size": 698107,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Monkey%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Monkey%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eab9c6dbf920d014475eb942e80b554808803cea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Monkey%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Monkey%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eab9c6dbf920d014475eb942e80b554808803cea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Monkey%20Face.png"
    }
  },
  {
    "name": "Monkey.png",
    "path": "Emojis/Animals/Monkey.png",
    "sha": "138e617ed1b00ae29262095283c0b13bc68d9a9d",
    "size": 1182232,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Monkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Monkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/138e617ed1b00ae29262095283c0b13bc68d9a9d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Monkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Monkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/138e617ed1b00ae29262095283c0b13bc68d9a9d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Monkey.png"
    }
  },
  {
    "name": "Moose.png",
    "path": "Emojis/Animals/Moose.png",
    "sha": "d9d78966cb49f13a39532dd8c05bfdb5ffdcd6e6",
    "size": 5455772,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Moose.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Moose.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9d78966cb49f13a39532dd8c05bfdb5ffdcd6e6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Moose.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Moose.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9d78966cb49f13a39532dd8c05bfdb5ffdcd6e6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Moose.png"
    }
  },
  {
    "name": "Mosquito.png",
    "path": "Emojis/Animals/Mosquito.png",
    "sha": "8a216efaab20cc537e930413e2dd7f32eb84e9a5",
    "size": 758964,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mosquito.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mosquito.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a216efaab20cc537e930413e2dd7f32eb84e9a5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Mosquito.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mosquito.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a216efaab20cc537e930413e2dd7f32eb84e9a5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mosquito.png"
    }
  },
  {
    "name": "Mouse Face.png",
    "path": "Emojis/Animals/Mouse Face.png",
    "sha": "31327007c15644277dd262fdc258d14cc7f0c06b",
    "size": 959413,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mouse%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mouse%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/31327007c15644277dd262fdc258d14cc7f0c06b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Mouse%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mouse%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/31327007c15644277dd262fdc258d14cc7f0c06b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mouse%20Face.png"
    }
  },
  {
    "name": "Mouse.png",
    "path": "Emojis/Animals/Mouse.png",
    "sha": "e0a37392dd4294819e786f3b7aa99eac0317996f",
    "size": 969538,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mouse.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mouse.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e0a37392dd4294819e786f3b7aa99eac0317996f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Mouse.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Mouse.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e0a37392dd4294819e786f3b7aa99eac0317996f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Mouse.png"
    }
  },
  {
    "name": "Nest with Eggs.png",
    "path": "Emojis/Animals/Nest with Eggs.png",
    "sha": "afda145c43379316919280b1e2b08b2507003113",
    "size": 3221237,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Nest%20with%20Eggs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Nest%20with%20Eggs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/afda145c43379316919280b1e2b08b2507003113",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Nest%20with%20Eggs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Nest%20with%20Eggs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/afda145c43379316919280b1e2b08b2507003113",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Nest%20with%20Eggs.png"
    }
  },
  {
    "name": "Octopus.png",
    "path": "Emojis/Animals/Octopus.png",
    "sha": "2394f4283732abaaddaee4fc09a45fd030676e2e",
    "size": 944748,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Octopus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Octopus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2394f4283732abaaddaee4fc09a45fd030676e2e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Octopus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Octopus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2394f4283732abaaddaee4fc09a45fd030676e2e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Octopus.png"
    }
  },
  {
    "name": "Orangutan.png",
    "path": "Emojis/Animals/Orangutan.png",
    "sha": "0a095ebf9834b8ccd08b360714565b4ec39e39f2",
    "size": 1290051,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Orangutan.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Orangutan.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a095ebf9834b8ccd08b360714565b4ec39e39f2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Orangutan.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Orangutan.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a095ebf9834b8ccd08b360714565b4ec39e39f2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Orangutan.png"
    }
  },
  {
    "name": "Otter.png",
    "path": "Emojis/Animals/Otter.png",
    "sha": "0ea39b3576d4d02a99fcb2b5488a47546b4a6c45",
    "size": 958090,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Otter.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Otter.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0ea39b3576d4d02a99fcb2b5488a47546b4a6c45",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Otter.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Otter.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0ea39b3576d4d02a99fcb2b5488a47546b4a6c45",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Otter.png"
    }
  },
  {
    "name": "Owl.png",
    "path": "Emojis/Animals/Owl.png",
    "sha": "1bb7b46120d29255a73665803b90a29fac3549e1",
    "size": 1023806,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Owl.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Owl.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1bb7b46120d29255a73665803b90a29fac3549e1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Owl.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Owl.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1bb7b46120d29255a73665803b90a29fac3549e1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Owl.png"
    }
  },
  {
    "name": "Ox.png",
    "path": "Emojis/Animals/Ox.png",
    "sha": "b16c7fb5cf39a14703201dd3f3b37c184e1b3f9f",
    "size": 934355,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ox.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ox.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b16c7fb5cf39a14703201dd3f3b37c184e1b3f9f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Ox.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ox.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b16c7fb5cf39a14703201dd3f3b37c184e1b3f9f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ox.png"
    }
  },
  {
    "name": "Palm Tree.png",
    "path": "Emojis/Animals/Palm Tree.png",
    "sha": "7551a0609abc2f9279d3824547a41091799b7624",
    "size": 10526,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Palm%20Tree.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Palm%20Tree.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7551a0609abc2f9279d3824547a41091799b7624",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Palm%20Tree.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Palm%20Tree.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7551a0609abc2f9279d3824547a41091799b7624",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Palm%20Tree.png"
    }
  },
  {
    "name": "Panda.png",
    "path": "Emojis/Animals/Panda.png",
    "sha": "1d113820117d244ca542c0c54ff2513567f46f2d",
    "size": 1226701,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Panda.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Panda.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1d113820117d244ca542c0c54ff2513567f46f2d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Panda.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Panda.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1d113820117d244ca542c0c54ff2513567f46f2d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Panda.png"
    }
  },
  {
    "name": "Parrot.png",
    "path": "Emojis/Animals/Parrot.png",
    "sha": "bad919464dbf450a773ae7be00c708e77ec68ad5",
    "size": 791879,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Parrot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Parrot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bad919464dbf450a773ae7be00c708e77ec68ad5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Parrot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Parrot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bad919464dbf450a773ae7be00c708e77ec68ad5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Parrot.png"
    }
  },
  {
    "name": "Paw Prints.png",
    "path": "Emojis/Animals/Paw Prints.png",
    "sha": "d1996a5c8eafbf4f5582c06be64d23d6d1b51694",
    "size": 630603,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Paw%20Prints.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Paw%20Prints.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1996a5c8eafbf4f5582c06be64d23d6d1b51694",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Paw%20Prints.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Paw%20Prints.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1996a5c8eafbf4f5582c06be64d23d6d1b51694",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Paw%20Prints.png"
    }
  },
  {
    "name": "Peacock.png",
    "path": "Emojis/Animals/Peacock.png",
    "sha": "76ae453a88c3be5ea88da5a183465c8e8a95d2a2",
    "size": 1086603,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Peacock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Peacock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/76ae453a88c3be5ea88da5a183465c8e8a95d2a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Peacock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Peacock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/76ae453a88c3be5ea88da5a183465c8e8a95d2a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Peacock.png"
    }
  },
  {
    "name": "Penguin.png",
    "path": "Emojis/Animals/Penguin.png",
    "sha": "6ced66612c9b44c6ce1367f2c881a79e4e04c5bc",
    "size": 580610,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Penguin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Penguin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ced66612c9b44c6ce1367f2c881a79e4e04c5bc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Penguin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Penguin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ced66612c9b44c6ce1367f2c881a79e4e04c5bc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Penguin.png"
    }
  },
  {
    "name": "Pig Face.png",
    "path": "Emojis/Animals/Pig Face.png",
    "sha": "7daecc07fe9e6321edb3eb1f96c4396ece8ca508",
    "size": 1156435,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7daecc07fe9e6321edb3eb1f96c4396ece8ca508",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Pig%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7daecc07fe9e6321edb3eb1f96c4396ece8ca508",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig%20Face.png"
    }
  },
  {
    "name": "Pig Nose.png",
    "path": "Emojis/Animals/Pig Nose.png",
    "sha": "fd2c9b9bedb2c304f54be05eac91b63a2cef3328",
    "size": 913533,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig%20Nose.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig%20Nose.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd2c9b9bedb2c304f54be05eac91b63a2cef3328",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Pig%20Nose.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig%20Nose.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd2c9b9bedb2c304f54be05eac91b63a2cef3328",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig%20Nose.png"
    }
  },
  {
    "name": "Pig.png",
    "path": "Emojis/Animals/Pig.png",
    "sha": "f1e7f05c7aa09656040f7b0331944a2a90551f08",
    "size": 765352,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1e7f05c7aa09656040f7b0331944a2a90551f08",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Pig.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Pig.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1e7f05c7aa09656040f7b0331944a2a90551f08",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Pig.png"
    }
  },
  {
    "name": "Polar Bear.png",
    "path": "Emojis/Animals/Polar Bear.png",
    "sha": "2fb810cefcc99815276221f137605edc70a0d357",
    "size": 1166763,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Polar%20Bear.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Polar%20Bear.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fb810cefcc99815276221f137605edc70a0d357",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Polar%20Bear.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Polar%20Bear.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fb810cefcc99815276221f137605edc70a0d357",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Polar%20Bear.png"
    }
  },
  {
    "name": "Poodle.png",
    "path": "Emojis/Animals/Poodle.png",
    "sha": "d57bfc18c0760677d18404e207baf823401d071a",
    "size": 820288,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Poodle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Poodle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d57bfc18c0760677d18404e207baf823401d071a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Poodle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Poodle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d57bfc18c0760677d18404e207baf823401d071a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Poodle.png"
    }
  },
  {
    "name": "Potted Plant.png",
    "path": "Emojis/Animals/Potted Plant.png",
    "sha": "56bde0331a33683b18a70ca572e6187132e9bfe3",
    "size": 11862,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Potted%20Plant.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Potted%20Plant.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/56bde0331a33683b18a70ca572e6187132e9bfe3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Potted%20Plant.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Potted%20Plant.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/56bde0331a33683b18a70ca572e6187132e9bfe3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Potted%20Plant.png"
    }
  },
  {
    "name": "Rabbit Face.png",
    "path": "Emojis/Animals/Rabbit Face.png",
    "sha": "ceaf84ba78bbcb3c91ab8c696ee75d876b76acb0",
    "size": 1004457,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rabbit%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rabbit%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ceaf84ba78bbcb3c91ab8c696ee75d876b76acb0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rabbit%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rabbit%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ceaf84ba78bbcb3c91ab8c696ee75d876b76acb0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rabbit%20Face.png"
    }
  },
  {
    "name": "Rabbit.png",
    "path": "Emojis/Animals/Rabbit.png",
    "sha": "69f3ca4effb5116fdb9d4622860454dd2aaac2c3",
    "size": 993295,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rabbit.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rabbit.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/69f3ca4effb5116fdb9d4622860454dd2aaac2c3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rabbit.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rabbit.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/69f3ca4effb5116fdb9d4622860454dd2aaac2c3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rabbit.png"
    }
  },
  {
    "name": "Raccoon.png",
    "path": "Emojis/Animals/Raccoon.png",
    "sha": "2c0bcc535e622bcfb952f413276438b7ebf34ac2",
    "size": 1036015,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Raccoon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Raccoon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c0bcc535e622bcfb952f413276438b7ebf34ac2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Raccoon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Raccoon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c0bcc535e622bcfb952f413276438b7ebf34ac2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Raccoon.png"
    }
  },
  {
    "name": "Ram.png",
    "path": "Emojis/Animals/Ram.png",
    "sha": "8db4c0fcbc833015b17b36712dee2d70a96d3c69",
    "size": 919488,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ram.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ram.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8db4c0fcbc833015b17b36712dee2d70a96d3c69",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Ram.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Ram.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8db4c0fcbc833015b17b36712dee2d70a96d3c69",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Ram.png"
    }
  },
  {
    "name": "Rat.png",
    "path": "Emojis/Animals/Rat.png",
    "sha": "af61030c6903a6ee2c496221213d99a06d71c7fc",
    "size": 982658,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af61030c6903a6ee2c496221213d99a06d71c7fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af61030c6903a6ee2c496221213d99a06d71c7fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rat.png"
    }
  },
  {
    "name": "Rhinoceros.png",
    "path": "Emojis/Animals/Rhinoceros.png",
    "sha": "81453220c00a4cd278d3b55f0c5d516f2e17a61c",
    "size": 784364,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rhinoceros.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rhinoceros.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/81453220c00a4cd278d3b55f0c5d516f2e17a61c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rhinoceros.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rhinoceros.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/81453220c00a4cd278d3b55f0c5d516f2e17a61c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rhinoceros.png"
    }
  },
  {
    "name": "Rooster.png",
    "path": "Emojis/Animals/Rooster.png",
    "sha": "52d0718b1db5b15bdaa49de420efe53c6914fdba",
    "size": 946837,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rooster.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rooster.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/52d0718b1db5b15bdaa49de420efe53c6914fdba",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rooster.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rooster.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/52d0718b1db5b15bdaa49de420efe53c6914fdba",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rooster.png"
    }
  },
  {
    "name": "Rose.png",
    "path": "Emojis/Animals/Rose.png",
    "sha": "123ad3e3face950df3b0ade0a87ac79a38376876",
    "size": 9869,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rose.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rose.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/123ad3e3face950df3b0ade0a87ac79a38376876",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rose.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rose.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/123ad3e3face950df3b0ade0a87ac79a38376876",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rose.png"
    }
  },
  {
    "name": "Rosette.png",
    "path": "Emojis/Animals/Rosette.png",
    "sha": "5032203c52404dc6f46e8bc7efc9ba5b21ec345e",
    "size": 22065,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rosette.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rosette.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5032203c52404dc6f46e8bc7efc9ba5b21ec345e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Rosette.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Rosette.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5032203c52404dc6f46e8bc7efc9ba5b21ec345e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Rosette.png"
    }
  },
  {
    "name": "Sauropod.png",
    "path": "Emojis/Animals/Sauropod.png",
    "sha": "6b0a1d2222659111f84ca2402384190c89c7f3ea",
    "size": 267489,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sauropod.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sauropod.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b0a1d2222659111f84ca2402384190c89c7f3ea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Sauropod.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sauropod.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b0a1d2222659111f84ca2402384190c89c7f3ea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sauropod.png"
    }
  },
  {
    "name": "Scorpion.png",
    "path": "Emojis/Animals/Scorpion.png",
    "sha": "35f945d10b489725a33ed8895ed0e402da5ef10f",
    "size": 889297,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Scorpion.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Scorpion.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35f945d10b489725a33ed8895ed0e402da5ef10f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Scorpion.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Scorpion.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35f945d10b489725a33ed8895ed0e402da5ef10f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Scorpion.png"
    }
  },
  {
    "name": "Seal.png",
    "path": "Emojis/Animals/Seal.png",
    "sha": "fc71e57d3cf9ba128eb4afbb313b67383e201e51",
    "size": 811997,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Seal.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Seal.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc71e57d3cf9ba128eb4afbb313b67383e201e51",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Seal.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Seal.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc71e57d3cf9ba128eb4afbb313b67383e201e51",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Seal.png"
    }
  },
  {
    "name": "Seedling.png",
    "path": "Emojis/Animals/Seedling.png",
    "sha": "640fa765dcfea591833d0e01f47ebf4882ca598f",
    "size": 11000,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Seedling.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Seedling.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/640fa765dcfea591833d0e01f47ebf4882ca598f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Seedling.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Seedling.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/640fa765dcfea591833d0e01f47ebf4882ca598f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Seedling.png"
    }
  },
  {
    "name": "Service Dog.png",
    "path": "Emojis/Animals/Service Dog.png",
    "sha": "a70eab0bb6a52eec3ccf96bab8c3e7ac2d9596d8",
    "size": 923909,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Service%20Dog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Service%20Dog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a70eab0bb6a52eec3ccf96bab8c3e7ac2d9596d8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Service%20Dog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Service%20Dog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a70eab0bb6a52eec3ccf96bab8c3e7ac2d9596d8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Service%20Dog.png"
    }
  },
  {
    "name": "Shamrock.png",
    "path": "Emojis/Animals/Shamrock.png",
    "sha": "708a2a9d6d9fbd545dc42c7761cea582fd9ce44c",
    "size": 14364,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Shamrock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Shamrock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/708a2a9d6d9fbd545dc42c7761cea582fd9ce44c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Shamrock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Shamrock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/708a2a9d6d9fbd545dc42c7761cea582fd9ce44c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Shamrock.png"
    }
  },
  {
    "name": "Shark.png",
    "path": "Emojis/Animals/Shark.png",
    "sha": "6ad916156741fc8a82fe0fef820288d675ec6bed",
    "size": 887098,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Shark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Shark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ad916156741fc8a82fe0fef820288d675ec6bed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Shark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Shark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ad916156741fc8a82fe0fef820288d675ec6bed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Shark.png"
    }
  },
  {
    "name": "Sheaf of Rice.png",
    "path": "Emojis/Animals/Sheaf of Rice.png",
    "sha": "deaee4e45422ae01c1e3ff5287ee13d6872f3cdb",
    "size": 8641,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sheaf%20of%20Rice.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sheaf%20of%20Rice.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/deaee4e45422ae01c1e3ff5287ee13d6872f3cdb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Sheaf%20of%20Rice.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sheaf%20of%20Rice.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/deaee4e45422ae01c1e3ff5287ee13d6872f3cdb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sheaf%20of%20Rice.png"
    }
  },
  {
    "name": "Skunk.png",
    "path": "Emojis/Animals/Skunk.png",
    "sha": "54818528df627cc870bc9b2c73a069f09bc69833",
    "size": 971711,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Skunk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Skunk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54818528df627cc870bc9b2c73a069f09bc69833",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Skunk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Skunk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54818528df627cc870bc9b2c73a069f09bc69833",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Skunk.png"
    }
  },
  {
    "name": "Sloth.png",
    "path": "Emojis/Animals/Sloth.png",
    "sha": "fe2d5ff483767f1fc8ac12ae9647d152fb88834a",
    "size": 1070913,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sloth.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sloth.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe2d5ff483767f1fc8ac12ae9647d152fb88834a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Sloth.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sloth.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe2d5ff483767f1fc8ac12ae9647d152fb88834a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sloth.png"
    }
  },
  {
    "name": "Snail.png",
    "path": "Emojis/Animals/Snail.png",
    "sha": "2e020b5806cf7d9b7f3ad64d1c6acea5ada7951d",
    "size": 1337525,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Snail.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Snail.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e020b5806cf7d9b7f3ad64d1c6acea5ada7951d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Snail.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Snail.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e020b5806cf7d9b7f3ad64d1c6acea5ada7951d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Snail.png"
    }
  },
  {
    "name": "Snake.png",
    "path": "Emojis/Animals/Snake.png",
    "sha": "0a8aab8350045b0629bedf978f246ed6ec2e39ae",
    "size": 1082439,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Snake.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Snake.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a8aab8350045b0629bedf978f246ed6ec2e39ae",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Snake.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Snake.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a8aab8350045b0629bedf978f246ed6ec2e39ae",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Snake.png"
    }
  },
  {
    "name": "Spider Web.png",
    "path": "Emojis/Animals/Spider Web.png",
    "sha": "8af767458ccd70fc4e6d990f0ab9b090c410e3d5",
    "size": 1244924,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spider%20Web.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spider%20Web.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8af767458ccd70fc4e6d990f0ab9b090c410e3d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Spider%20Web.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spider%20Web.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8af767458ccd70fc4e6d990f0ab9b090c410e3d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spider%20Web.png"
    }
  },
  {
    "name": "Spider.png",
    "path": "Emojis/Animals/Spider.png",
    "sha": "4ac6c01c4628deac53dc9d27ad0c22d6285e07cf",
    "size": 1046389,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spider.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spider.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ac6c01c4628deac53dc9d27ad0c22d6285e07cf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Spider.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spider.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ac6c01c4628deac53dc9d27ad0c22d6285e07cf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spider.png"
    }
  },
  {
    "name": "Spiral Shell.png",
    "path": "Emojis/Animals/Spiral Shell.png",
    "sha": "541b9f7820c6c5cd93f10485d0f3bfa6bac6f785",
    "size": 1007952,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spiral%20Shell.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spiral%20Shell.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/541b9f7820c6c5cd93f10485d0f3bfa6bac6f785",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Spiral%20Shell.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spiral%20Shell.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/541b9f7820c6c5cd93f10485d0f3bfa6bac6f785",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spiral%20Shell.png"
    }
  },
  {
    "name": "Spouting Whale.png",
    "path": "Emojis/Animals/Spouting Whale.png",
    "sha": "7ba2d2b9a71337212b83630766ca0f901686d092",
    "size": 1145222,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spouting%20Whale.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spouting%20Whale.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7ba2d2b9a71337212b83630766ca0f901686d092",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Spouting%20Whale.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Spouting%20Whale.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7ba2d2b9a71337212b83630766ca0f901686d092",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Spouting%20Whale.png"
    }
  },
  {
    "name": "Sunflower.png",
    "path": "Emojis/Animals/Sunflower.png",
    "sha": "c754dc5c0a496edec19e0f65a817034a2db34468",
    "size": 13334,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sunflower.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sunflower.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c754dc5c0a496edec19e0f65a817034a2db34468",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Sunflower.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Sunflower.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c754dc5c0a496edec19e0f65a817034a2db34468",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Sunflower.png"
    }
  },
  {
    "name": "Swan.png",
    "path": "Emojis/Animals/Swan.png",
    "sha": "2480754a6a07e18e1b2e44cf39b18313380a445b",
    "size": 772485,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Swan.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Swan.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2480754a6a07e18e1b2e44cf39b18313380a445b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Swan.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Swan.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2480754a6a07e18e1b2e44cf39b18313380a445b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Swan.png"
    }
  },
  {
    "name": "T-Rex.png",
    "path": "Emojis/Animals/T-Rex.png",
    "sha": "3d2ece59b215b86cd37695129a2bc5baa33628b8",
    "size": 933720,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/T-Rex.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/T-Rex.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d2ece59b215b86cd37695129a2bc5baa33628b8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/T-Rex.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/T-Rex.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d2ece59b215b86cd37695129a2bc5baa33628b8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/T-Rex.png"
    }
  },
  {
    "name": "Tiger Face.png",
    "path": "Emojis/Animals/Tiger Face.png",
    "sha": "d2d486ce72b5efb37d0c93770d4781fd5e3daf34",
    "size": 1158096,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tiger%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tiger%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2d486ce72b5efb37d0c93770d4781fd5e3daf34",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Tiger%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tiger%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2d486ce72b5efb37d0c93770d4781fd5e3daf34",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tiger%20Face.png"
    }
  },
  {
    "name": "Tiger.png",
    "path": "Emojis/Animals/Tiger.png",
    "sha": "76b9495353f279a9b326b69e523823c45e4ec47d",
    "size": 845113,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tiger.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tiger.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/76b9495353f279a9b326b69e523823c45e4ec47d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Tiger.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tiger.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/76b9495353f279a9b326b69e523823c45e4ec47d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tiger.png"
    }
  },
  {
    "name": "Tropical Fish.png",
    "path": "Emojis/Animals/Tropical Fish.png",
    "sha": "2a76c01c393f13fa703b39f9e72b20081019757e",
    "size": 830468,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tropical%20Fish.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tropical%20Fish.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2a76c01c393f13fa703b39f9e72b20081019757e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Tropical%20Fish.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tropical%20Fish.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2a76c01c393f13fa703b39f9e72b20081019757e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tropical%20Fish.png"
    }
  },
  {
    "name": "Tulip.png",
    "path": "Emojis/Animals/Tulip.png",
    "sha": "a75e84db0a6750855b9ca3c6242529a47e9e74a9",
    "size": 11729,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tulip.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tulip.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a75e84db0a6750855b9ca3c6242529a47e9e74a9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Tulip.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Tulip.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a75e84db0a6750855b9ca3c6242529a47e9e74a9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Tulip.png"
    }
  },
  {
    "name": "Turkey.png",
    "path": "Emojis/Animals/Turkey.png",
    "sha": "1a9fddd6773e18f09e3bdc098d6df61e6dae7176",
    "size": 1102417,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Turkey.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Turkey.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a9fddd6773e18f09e3bdc098d6df61e6dae7176",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Turkey.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Turkey.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a9fddd6773e18f09e3bdc098d6df61e6dae7176",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Turkey.png"
    }
  },
  {
    "name": "Turtle.png",
    "path": "Emojis/Animals/Turtle.png",
    "sha": "47b3f19e3bf0e80a3a5b62680e26dffba6278a20",
    "size": 642724,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Turtle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Turtle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47b3f19e3bf0e80a3a5b62680e26dffba6278a20",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Turtle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Turtle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47b3f19e3bf0e80a3a5b62680e26dffba6278a20",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Turtle.png"
    }
  },
  {
    "name": "Two-Hump Camel.png",
    "path": "Emojis/Animals/Two-Hump Camel.png",
    "sha": "30c6a8ea558b998dccb25d46911987a6bb50187c",
    "size": 774616,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Two-Hump%20Camel.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Two-Hump%20Camel.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/30c6a8ea558b998dccb25d46911987a6bb50187c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Two-Hump%20Camel.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Two-Hump%20Camel.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/30c6a8ea558b998dccb25d46911987a6bb50187c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Two-Hump%20Camel.png"
    }
  },
  {
    "name": "Unicorn.png",
    "path": "Emojis/Animals/Unicorn.png",
    "sha": "64638e44ce137c283f3745873940214472341a90",
    "size": 1026145,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Unicorn.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Unicorn.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/64638e44ce137c283f3745873940214472341a90",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Unicorn.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Unicorn.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/64638e44ce137c283f3745873940214472341a90",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Unicorn.png"
    }
  },
  {
    "name": "Water Buffalo.png",
    "path": "Emojis/Animals/Water Buffalo.png",
    "sha": "23128872ec6193e882ad16c0111c73346021a47a",
    "size": 737467,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Water%20Buffalo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Water%20Buffalo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23128872ec6193e882ad16c0111c73346021a47a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Water%20Buffalo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Water%20Buffalo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23128872ec6193e882ad16c0111c73346021a47a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Water%20Buffalo.png"
    }
  },
  {
    "name": "Whale.png",
    "path": "Emojis/Animals/Whale.png",
    "sha": "209d2c009c74b818685c40915bb8ef1eeed25145",
    "size": 969625,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Whale.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Whale.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/209d2c009c74b818685c40915bb8ef1eeed25145",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Whale.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Whale.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/209d2c009c74b818685c40915bb8ef1eeed25145",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Whale.png"
    }
  },
  {
    "name": "White Flower.png",
    "path": "Emojis/Animals/White Flower.png",
    "sha": "8c3e497fc7673896d96f6e3fe560118c6bb1c95f",
    "size": 18607,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/White%20Flower.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/White%20Flower.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c3e497fc7673896d96f6e3fe560118c6bb1c95f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/White%20Flower.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/White%20Flower.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c3e497fc7673896d96f6e3fe560118c6bb1c95f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/White%20Flower.png"
    }
  },
  {
    "name": "Wilted Flower.png",
    "path": "Emojis/Animals/Wilted Flower.png",
    "sha": "e4c650abb1db158081089381dc20121f72cb3d74",
    "size": 741277,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Wilted%20Flower.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Wilted%20Flower.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e4c650abb1db158081089381dc20121f72cb3d74",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Wilted%20Flower.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Wilted%20Flower.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e4c650abb1db158081089381dc20121f72cb3d74",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Wilted%20Flower.png"
    }
  },
  {
    "name": "Wolf.png",
    "path": "Emojis/Animals/Wolf.png",
    "sha": "df6f2f967d4553686072e02976af4d27e8fe1fd1",
    "size": 996651,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Wolf.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Wolf.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df6f2f967d4553686072e02976af4d27e8fe1fd1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Wolf.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Wolf.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df6f2f967d4553686072e02976af4d27e8fe1fd1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Wolf.png"
    }
  },
  {
    "name": "Worm.png",
    "path": "Emojis/Animals/Worm.png",
    "sha": "952932429477fd33c1a36fc95881430bb8b9677b",
    "size": 765227,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Worm.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Worm.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/952932429477fd33c1a36fc95881430bb8b9677b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Worm.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Worm.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/952932429477fd33c1a36fc95881430bb8b9677b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Worm.png"
    }
  },
  {
    "name": "Zebra.png",
    "path": "Emojis/Animals/Zebra.png",
    "sha": "e24a96ea6a5d4880ef139b00eeb529ee5b2d5b8e",
    "size": 1154208,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Zebra.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Zebra.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e24a96ea6a5d4880ef139b00eeb529ee5b2d5b8e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Animals/Zebra.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Animals/Zebra.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e24a96ea6a5d4880ef139b00eeb529ee5b2d5b8e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Animals/Zebra.png"
    }
  }
]

export default {
  id: 'animals',
  name: 'Animals',
  emojis: customEmojis.map((emoji) => {
    return {
      id: emoji.sha,
      name: emoji.name,
      skins: [{ src: emoji.download_url }]
    }
  })
}