import { DeleteOutlined, EditOutlined, PauseCircleFilled, PlayCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, Flex, Form, Popover, Popconfirm, Select, Space, Table, Tag, Typography, List } from "antd";
import { useEffect, useRef, useState } from "react";
import { get, patch, post, xdelete } from "../services/api";
import AvatarPicker from './AvatarPicker';
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentTimeLog } from "../features/currentTimeLog";
import CreateTaskInput from "./CreateTaskInput";
import EstimatedTimePicker from "./EstimatedTimePicker";
import StatusPicker from "./StatusPicker";
import DueDatePicker from "./DueDatePicker";
import TrackedPicker, { millisecondsToTime } from "./TrackedPicker";
const { RangePicker } = DatePicker;

const { Option } = Select;

export default function Tasks({
  list,
  users,
  tasks,
  onDelete,
  refreshTasks,
  onUpdateTask,
  setTasks
}) {
  const dispatch = useDispatch();
  const currentTaskTimerRef = useRef();
  const currentTimeLog = useSelector((state) => state.currentTimeLog);
  const user = useSelector((state) => state.user);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteTasks = () => {
    onDelete(selectedRowKeys);
    setSelectedRowKeys([]);
  };

  const updateStatus = async (id, status) => {
    const updated = await patch(`/users/${user.id}/tasks`, {
      status
    }, {
      id
    });
    if(updated.count) {
      onUpdateTask(tasks.map(task => {
        return task.id === id ? { ...task, status } : task
      }));
    }
  };

  const updateDueDate = async (id, dueDate) => {
    const updated = await patch(`/users/${user.id}/tasks`, { dueDate }, { id });
    if(updated.count) {
      onUpdateTask(tasks.map(task => {
        return task.id === id ? { ...task, dueDate  } : task
      }));
    }
  };

  const updateEstTime = async (id, estTime) => {
    const updated = await patch(`/users/${user.id}/tasks`, { estTime }, { id });
    if(updated.count) {
      onUpdateTask(tasks.map(task => {
        return task.id === id ? { ...task, estTime } : task
      }));
    }
  };

  const onAddUser = async (user, task, role) => {
    const taskAccess = task.taskAccesses.find(ta => ta.userId === user);
    if (taskAccess && taskAccess.role === 'watcher') {
      const res = await patch(`/tasks/${task.id}/task-accesses`, {
        role
      }, {
        userId: user
      });
      if (res.count === 1) {
        await onUpdateTask(tasks.map((t) => {
          return t.id !== task.id ? t : {
            ...t,
            taskAccesses: t.taskAccesses.map(ta => ta.userId !== user ? ta : { ...ta, role })
          }
        }));
      }
      return;
    }
    const access_granted = await post(`/tasks/${task.id}/task-accesses`, {
      role,
      userId: user
    });
    await onUpdateTask(tasks.map((t) => {
      return t.id === task.id ? {
        ...t,
        taskAccesses: [...t.taskAccesses, access_granted]
      } : t
    }));
    return;
  }

  const onRemoveUser = async (user, task, role) => {
    if (role === 'assignee') {
      await patch(`/tasks/${task.id}/task-accesses`, {
        role: 'watcher'
      }, {
        userId: user
      });
      return await onUpdateTask(tasks.map((t) => {
        return t.id !== task.id ? t : {
          ...t,
          taskAccesses: t.taskAccesses.map(ta => ta.userId !== user ? ta : { ...ta, role: 'watcher' })
        }
      }));
    }
  }

  const startTracking = async (taskId) => {
    const timeLog = await post(`/users/${user.id}/time-logs`, { taskId });
    dispatch(setCurrentTimeLog(timeLog));
  }

  const stopTracking = async () => {
    await patch(`/users/${user.id}/time-logs`, { stoppedAt: new Date() }, {
      id: currentTimeLog.id
    });
    const timeLog = await get(`/users/${user.id}/time-logs`, {
      where: { id: currentTimeLog.id }
    });
    onUpdateTask(tasks.map(task => {
      return task.id === currentTimeLog.taskId ? { ...task, timeLogs: [...((task.timeLogs || []).filter(tl => tl.id !== timeLog[0].id)), ...timeLog] } : task
    }));
    dispatch(setCurrentTimeLog(null));
  }

  const onAddTrackedTime = async (taskId, createdAt, stoppedAt) => {
    const timeLog = await post(`/users/${user.id}/time-logs`, {
      createdAt,
      stoppedAt,
      taskId
    });
    if(timeLog) {
      onUpdateTask(tasks.map(task => {
        return task.id === taskId ? { ...task, timeLogs: [...(task.timeLogs || []), timeLog] } : task
      }));
    }
  }

  const onDeleteTrackedTime = async ({id, taskId}) => {
    const deleted = await xdelete(`/users/${user.id}/time-logs`,{id});
    if(deleted.count) {
      onUpdateTask(tasks.map(task => {
        return task.id === taskId ? { ...task, timeLogs: task.timeLogs.filter(tl => tl.id !== id) } : task
      }));
    }
  }

  const onUpdateTrackedTime = async ({id, taskId}, createdAt, stoppedAt) => {
    const updated = await patch(`/users/${user.id}/time-logs`, { 
      createdAt,
      stoppedAt
     }, { id });
    if(updated.count) {
      onUpdateTask(tasks.map(task => {
        return task.id === taskId ? { ...task, timeLogs: task.timeLogs.map(tl => tl.id === id ? {
          ...tl,
          createdAt,
          stoppedAt
        } : tl) } : task
      }));
    }
  }

  const addTask = async (title) => {
    var task = await post(`/users/${user.id}/tasks`, {
      title, listId: list.id
    });
    setTasks([ ...tasks, {...task, taskAccesses: [{
      role: "watcher",
      userId: user.id
    }]} ]);
  }

  // -- for updating the timer on a task
  useEffect(() => {
    if (!currentTimeLog) { return; }
    const updateTimer = () => {
      var totalTime = dayjs().diff(dayjs(currentTimeLog.createdAt));
      var elem = currentTaskTimerRef.current;
      if (elem) {
        elem.innerHTML = millisecondsToTime(totalTime);
      }
    };
    var interval = setInterval(updateTimer, 1000);
    return () => {
      clearInterval(interval);
    }
  }, [currentTaskTimerRef.current, currentTimeLog])
  // -- for updating the timer on a task

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      render: (title, task) => {
        return <div className="avatar-with-profile" >
          {task.id === currentTimeLog?.taskId ? <PauseCircleFilled onClick={stopTracking} style={{ fontSize: "25px", color: "#ff398b" }} /> : <PlayCircleFilled onClick={() => startTracking(task.id)} style={{ fontSize: "25px", color: "#398bff" }} />}
          <div className="heading-with-tagline heading-with-tagline-absolute" style={{ maxWidth: "calc(100% - 30px)" }}>
            <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>
            {/* <span><Progress size={"small"} percent={20} style={{ margin: 0, maxWidth: '150px' }}/></span> */}
            <span>{list == null && `${task.list.project.name} / ${task.list.name}`}</span>
          </div>
        </div>
      },
    },
    {
      title: 'Assignee',
      render: (task) => {
        // console.log(task)
        return <AvatarPicker defaultSelectedUsers={task.taskAccesses.filter(ta => ta.role === 'assignee').map(ta => ta.userId)} onAddUser={(u) => onAddUser(u, task, 'assignee')} onRemoveUser={(u) => onRemoveUser(u, task, 'assignee')} users={users} />
      },
    },
    {
      title: 'Tracked',
      dataIndex: 'tracked',
      render: (title, task) => {
        return <TrackedPicker task={task} currentTimeLog={currentTimeLog} onAddTrackedTime={onAddTrackedTime} onDeleteTrackedTime={onDeleteTrackedTime} onUpdateTrackedTime={onUpdateTrackedTime} currentTaskTimerRef={currentTaskTimerRef} users={users}/>
      },
    },
    {
      title: 'Due Date',
      render: (task) => {
        return <DueDatePicker task={task} onUpdateDueDate={updateDueDate} />
      },
    },
    {
      title: 'Estimated Time',
      render: (task) => {
        return <EstimatedTimePicker task={task} onUpdateEstTime={updateEstTime} />
      },
    },
    {
      title: 'Status',
      render: (task) => {
        return <StatusPicker task={task} onUpdateStatus={updateStatus} />
      },
    },
  ]

  return <div style={{ paddingLeft: "90px", paddingRight: "25px" }}>
    <Flex gap={40} style={{ position: "relative" }}>
      {selectedRowKeys.length > 0 && <div className="selectedRowsActions">
      <Popconfirm
              title="Delete Task"
              placement="bottomLeft"
              description="Are you sure to delete this task?"
              onConfirm={deleteTasks}
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
       
      </div>}
      <Table
        style={{ flex: "1" }}
        scroll={{
          y: window.innerHeight - 210,
          x: 500,
        }}
        pagination={false}
        dataSource={tasks}
        rowKey={'id'}
        columns={columns}
        rowSelection={rowSelection}
      />
    </Flex>
    <Flex gap={0} style={{ flex: 0 }}>
      <div style={{ flex: 1, margin: "10px 0px 10px 30px", boxShadow: "0px -15px 45px 15px #f5f5f5", zIndex: 1 }}>
        <CreateTaskInput onCreateTask={addTask} />
      </div>
    </Flex>
  </div>
}