const customEmojis = [
  {
    "name": "A Button (Blood Type).png",
    "path": "Emojis/Symbols/A Button (Blood Type).png",
    "sha": "c06196629cbf41199a5ea660f288df738f755c43",
    "size": 14769,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/A%20Button%20(Blood%20Type).png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/A%20Button%20(Blood%20Type).png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c06196629cbf41199a5ea660f288df738f755c43",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/A%20Button%20(Blood%20Type).png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/A%20Button%20(Blood%20Type).png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c06196629cbf41199a5ea660f288df738f755c43",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/A%20Button%20(Blood%20Type).png"
    }
  },
  {
    "name": "AB Button (Blood Type).png",
    "path": "Emojis/Symbols/AB Button (Blood Type).png",
    "sha": "d7e105fc33801f332cafd577d5566e81dcf98bee",
    "size": 15902,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/AB%20Button%20(Blood%20Type).png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/AB%20Button%20(Blood%20Type).png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7e105fc33801f332cafd577d5566e81dcf98bee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/AB%20Button%20(Blood%20Type).png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/AB%20Button%20(Blood%20Type).png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d7e105fc33801f332cafd577d5566e81dcf98bee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/AB%20Button%20(Blood%20Type).png"
    }
  },
  {
    "name": "ATM Sign.png",
    "path": "Emojis/Symbols/ATM Sign.png",
    "sha": "adcdc391703174084be3e44e6d258a974c7fb354",
    "size": 14025,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/ATM%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/ATM%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adcdc391703174084be3e44e6d258a974c7fb354",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/ATM%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/ATM%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adcdc391703174084be3e44e6d258a974c7fb354",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/ATM%20Sign.png"
    }
  },
  {
    "name": "Antenna Bars.png",
    "path": "Emojis/Symbols/Antenna Bars.png",
    "sha": "fb619008e4b52ef628799468b82db9b11b6a8714",
    "size": 13858,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Antenna%20Bars.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Antenna%20Bars.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb619008e4b52ef628799468b82db9b11b6a8714",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Antenna%20Bars.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Antenna%20Bars.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb619008e4b52ef628799468b82db9b11b6a8714",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Antenna%20Bars.png"
    }
  },
  {
    "name": "Aquarius.png",
    "path": "Emojis/Symbols/Aquarius.png",
    "sha": "89e7f1a5d207c8a7e02d3e8ee1bd8532391af639",
    "size": 18335,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Aquarius.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Aquarius.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89e7f1a5d207c8a7e02d3e8ee1bd8532391af639",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Aquarius.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Aquarius.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89e7f1a5d207c8a7e02d3e8ee1bd8532391af639",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Aquarius.png"
    }
  },
  {
    "name": "Aries.png",
    "path": "Emojis/Symbols/Aries.png",
    "sha": "05e2bbecb359e5f51537cdfeb1f7624f7cba90c9",
    "size": 18477,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Aries.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Aries.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/05e2bbecb359e5f51537cdfeb1f7624f7cba90c9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Aries.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Aries.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/05e2bbecb359e5f51537cdfeb1f7624f7cba90c9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Aries.png"
    }
  },
  {
    "name": "Atom Symbol.png",
    "path": "Emojis/Symbols/Atom Symbol.png",
    "sha": "4827ffb1ed116c24d5eaf92f8e0d543d2b6e030e",
    "size": 20288,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Atom%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Atom%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4827ffb1ed116c24d5eaf92f8e0d543d2b6e030e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Atom%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Atom%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4827ffb1ed116c24d5eaf92f8e0d543d2b6e030e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Atom%20Symbol.png"
    }
  },
  {
    "name": "B Button (Blood Type).png",
    "path": "Emojis/Symbols/B Button (Blood Type).png",
    "sha": "817480182ba1af119ddd6dc4a32af52ce0140db2",
    "size": 14557,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/B%20Button%20(Blood%20Type).png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/B%20Button%20(Blood%20Type).png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/817480182ba1af119ddd6dc4a32af52ce0140db2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/B%20Button%20(Blood%20Type).png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/B%20Button%20(Blood%20Type).png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/817480182ba1af119ddd6dc4a32af52ce0140db2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/B%20Button%20(Blood%20Type).png"
    }
  },
  {
    "name": "Baby Symbol.png",
    "path": "Emojis/Symbols/Baby Symbol.png",
    "sha": "86bd0d791e5de5354cf0c06d2d265f98914c73bf",
    "size": 15969,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Baby%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Baby%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86bd0d791e5de5354cf0c06d2d265f98914c73bf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Baby%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Baby%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86bd0d791e5de5354cf0c06d2d265f98914c73bf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Baby%20Symbol.png"
    }
  },
  {
    "name": "Back Arrow.png",
    "path": "Emojis/Symbols/Back Arrow.png",
    "sha": "50efe5ee5f069cf9c77e209fd44b3702814f0919",
    "size": 14987,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Back%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Back%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/50efe5ee5f069cf9c77e209fd44b3702814f0919",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Back%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Back%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/50efe5ee5f069cf9c77e209fd44b3702814f0919",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Back%20Arrow.png"
    }
  },
  {
    "name": "Baggage Claim.png",
    "path": "Emojis/Symbols/Baggage Claim.png",
    "sha": "ca7ae4b3c1d863aa7ab690f61856c943d2d93170",
    "size": 14859,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Baggage%20Claim.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Baggage%20Claim.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ca7ae4b3c1d863aa7ab690f61856c943d2d93170",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Baggage%20Claim.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Baggage%20Claim.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ca7ae4b3c1d863aa7ab690f61856c943d2d93170",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Baggage%20Claim.png"
    }
  },
  {
    "name": "Biohazard.png",
    "path": "Emojis/Symbols/Biohazard.png",
    "sha": "0c59d45c0b0a9d8df5f6c24541c1542d2fd1e8f5",
    "size": 16928,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Biohazard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Biohazard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0c59d45c0b0a9d8df5f6c24541c1542d2fd1e8f5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Biohazard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Biohazard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0c59d45c0b0a9d8df5f6c24541c1542d2fd1e8f5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Biohazard.png"
    }
  },
  {
    "name": "Black Circle.png",
    "path": "Emojis/Symbols/Black Circle.png",
    "sha": "f087e0ecb55085ce4c818e53edcfe73c0539ae02",
    "size": 12979,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f087e0ecb55085ce4c818e53edcfe73c0539ae02",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f087e0ecb55085ce4c818e53edcfe73c0539ae02",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Circle.png"
    }
  },
  {
    "name": "Black Flag.png",
    "path": "Emojis/Symbols/Black Flag.png",
    "sha": "7288585d4384b8d3e8cc3330dbc44ea4913de563",
    "size": 594794,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7288585d4384b8d3e8cc3330dbc44ea4913de563",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7288585d4384b8d3e8cc3330dbc44ea4913de563",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Flag.png"
    }
  },
  {
    "name": "Black Large Square.png",
    "path": "Emojis/Symbols/Black Large Square.png",
    "sha": "061ed298adec351316395454d2c9624ff02847ed",
    "size": 10173,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Large%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Large%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/061ed298adec351316395454d2c9624ff02847ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Large%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Large%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/061ed298adec351316395454d2c9624ff02847ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Large%20Square.png"
    }
  },
  {
    "name": "Black Medium Square.png",
    "path": "Emojis/Symbols/Black Medium Square.png",
    "sha": "3f1a73f57efc6e38c67f7095ec24b2703644526f",
    "size": 7588,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Medium%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Medium%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f1a73f57efc6e38c67f7095ec24b2703644526f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Medium%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Medium%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f1a73f57efc6e38c67f7095ec24b2703644526f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Medium%20Square.png"
    }
  },
  {
    "name": "Black Medium-Small Square.png",
    "path": "Emojis/Symbols/Black Medium-Small Square.png",
    "sha": "d507d6ba7daa4baa681ba9c08e0da877b700a7e0",
    "size": 5287,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Medium-Small%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Medium-Small%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d507d6ba7daa4baa681ba9c08e0da877b700a7e0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Medium-Small%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Medium-Small%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d507d6ba7daa4baa681ba9c08e0da877b700a7e0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Medium-Small%20Square.png"
    }
  },
  {
    "name": "Black Small Square.png",
    "path": "Emojis/Symbols/Black Small Square.png",
    "sha": "c1ed0b2fcdcd1e452e1b80da40de6e6ecb7fc141",
    "size": 3296,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Small%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Small%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c1ed0b2fcdcd1e452e1b80da40de6e6ecb7fc141",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Small%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Small%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c1ed0b2fcdcd1e452e1b80da40de6e6ecb7fc141",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Small%20Square.png"
    }
  },
  {
    "name": "Black Square Button.png",
    "path": "Emojis/Symbols/Black Square Button.png",
    "sha": "0eea4d9344af736d5a202523724e82277e8c7e67",
    "size": 12123,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Square%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Square%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0eea4d9344af736d5a202523724e82277e8c7e67",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Black%20Square%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Black%20Square%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0eea4d9344af736d5a202523724e82277e8c7e67",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Black%20Square%20Button.png"
    }
  },
  {
    "name": "Blue Circle.png",
    "path": "Emojis/Symbols/Blue Circle.png",
    "sha": "f88f8ad17434194eef8949a9f2285e36ce4d07f3",
    "size": 17185,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Blue%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Blue%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f88f8ad17434194eef8949a9f2285e36ce4d07f3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Blue%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Blue%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f88f8ad17434194eef8949a9f2285e36ce4d07f3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Blue%20Circle.png"
    }
  },
  {
    "name": "Blue Square.png",
    "path": "Emojis/Symbols/Blue Square.png",
    "sha": "183b0cdd7ce1c35f0b22bfa880563019c97c65fc",
    "size": 7837,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Blue%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Blue%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/183b0cdd7ce1c35f0b22bfa880563019c97c65fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Blue%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Blue%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/183b0cdd7ce1c35f0b22bfa880563019c97c65fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Blue%20Square.png"
    }
  },
  {
    "name": "Bright Button.png",
    "path": "Emojis/Symbols/Bright Button.png",
    "sha": "eaa4057ead886b17c3360353c74310fa14120f5c",
    "size": 12066,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Bright%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Bright%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eaa4057ead886b17c3360353c74310fa14120f5c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Bright%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Bright%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eaa4057ead886b17c3360353c74310fa14120f5c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Bright%20Button.png"
    }
  },
  {
    "name": "Brown Circle.png",
    "path": "Emojis/Symbols/Brown Circle.png",
    "sha": "b4f6d132ecbdd89ce6b5b17a21ab9b65cfdb8c7f",
    "size": 16879,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Brown%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Brown%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b4f6d132ecbdd89ce6b5b17a21ab9b65cfdb8c7f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Brown%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Brown%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b4f6d132ecbdd89ce6b5b17a21ab9b65cfdb8c7f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Brown%20Circle.png"
    }
  },
  {
    "name": "Brown Square.png",
    "path": "Emojis/Symbols/Brown Square.png",
    "sha": "d266c4358b72dd2ec3f9db42e4d65051e9e9e0ea",
    "size": 8191,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Brown%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Brown%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d266c4358b72dd2ec3f9db42e4d65051e9e9e0ea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Brown%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Brown%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d266c4358b72dd2ec3f9db42e4d65051e9e9e0ea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Brown%20Square.png"
    }
  },
  {
    "name": "Bubbles.png",
    "path": "Emojis/Symbols/Bubbles.png",
    "sha": "d89a4a53ee43e96f077a231cbbf09fea2d72a140",
    "size": 2115069,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Bubbles.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Bubbles.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d89a4a53ee43e96f077a231cbbf09fea2d72a140",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Bubbles.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Bubbles.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d89a4a53ee43e96f077a231cbbf09fea2d72a140",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Bubbles.png"
    }
  },
  {
    "name": "CL Button.png",
    "path": "Emojis/Symbols/CL Button.png",
    "sha": "4c81ae1ef3973c72e714e520022d146e39c1f6f4",
    "size": 14384,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/CL%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/CL%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c81ae1ef3973c72e714e520022d146e39c1f6f4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/CL%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/CL%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c81ae1ef3973c72e714e520022d146e39c1f6f4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/CL%20Button.png"
    }
  },
  {
    "name": "Cancer.png",
    "path": "Emojis/Symbols/Cancer.png",
    "sha": "799a90be51aeb95d131cabddf27494b1d7897656",
    "size": 19541,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cancer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cancer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/799a90be51aeb95d131cabddf27494b1d7897656",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cancer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cancer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/799a90be51aeb95d131cabddf27494b1d7897656",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cancer.png"
    }
  },
  {
    "name": "Capricorn.png",
    "path": "Emojis/Symbols/Capricorn.png",
    "sha": "d88c9e7e8b20ef21e590f6b20d4f42b3199b7a75",
    "size": 18077,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Capricorn.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Capricorn.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d88c9e7e8b20ef21e590f6b20d4f42b3199b7a75",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Capricorn.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Capricorn.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d88c9e7e8b20ef21e590f6b20d4f42b3199b7a75",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Capricorn.png"
    }
  },
  {
    "name": "Check Box with Check.png",
    "path": "Emojis/Symbols/Check Box with Check.png",
    "sha": "77849312f99a3310ae56168dd5df2d736e725fc9",
    "size": 13569,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Box%20with%20Check.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Box%20with%20Check.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77849312f99a3310ae56168dd5df2d736e725fc9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Check%20Box%20with%20Check.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Box%20with%20Check.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77849312f99a3310ae56168dd5df2d736e725fc9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Box%20with%20Check.png"
    }
  },
  {
    "name": "Check Mark Button.png",
    "path": "Emojis/Symbols/Check Mark Button.png",
    "sha": "439bd31c6c45db3089df74bd1325fd4c23f946a1",
    "size": 12901,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Mark%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Mark%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/439bd31c6c45db3089df74bd1325fd4c23f946a1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Check%20Mark%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Mark%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/439bd31c6c45db3089df74bd1325fd4c23f946a1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Mark%20Button.png"
    }
  },
  {
    "name": "Check Mark.png",
    "path": "Emojis/Symbols/Check Mark.png",
    "sha": "691af84606611eb69495fd7f535808ea712aee62",
    "size": 7760,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/691af84606611eb69495fd7f535808ea712aee62",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Check%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Check%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/691af84606611eb69495fd7f535808ea712aee62",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Check%20Mark.png"
    }
  },
  {
    "name": "Chequered Flag.png",
    "path": "Emojis/Symbols/Chequered Flag.png",
    "sha": "bb46a4a62fedc7c437a0e20ea93947a9789c5db2",
    "size": 987681,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Chequered%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Chequered%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb46a4a62fedc7c437a0e20ea93947a9789c5db2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Chequered%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Chequered%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb46a4a62fedc7c437a0e20ea93947a9789c5db2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Chequered%20Flag.png"
    }
  },
  {
    "name": "Children Crossing.png",
    "path": "Emojis/Symbols/Children Crossing.png",
    "sha": "0e1e4bb334ec929e25802d7c40db1d21292c9e41",
    "size": 10170,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Children%20Crossing.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Children%20Crossing.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0e1e4bb334ec929e25802d7c40db1d21292c9e41",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Children%20Crossing.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Children%20Crossing.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0e1e4bb334ec929e25802d7c40db1d21292c9e41",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Children%20Crossing.png"
    }
  },
  {
    "name": "Cinema.png",
    "path": "Emojis/Symbols/Cinema.png",
    "sha": "aa6ecfd10e7e393a552535282d1a67185ea30bc0",
    "size": 15326,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cinema.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cinema.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/aa6ecfd10e7e393a552535282d1a67185ea30bc0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cinema.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cinema.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/aa6ecfd10e7e393a552535282d1a67185ea30bc0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cinema.png"
    }
  },
  {
    "name": "Circled M.png",
    "path": "Emojis/Symbols/Circled M.png",
    "sha": "dc6e9341ab0d531b3afdf5545dbf3d44f19a1044",
    "size": 13825,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Circled%20M.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Circled%20M.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dc6e9341ab0d531b3afdf5545dbf3d44f19a1044",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Circled%20M.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Circled%20M.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dc6e9341ab0d531b3afdf5545dbf3d44f19a1044",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Circled%20M.png"
    }
  },
  {
    "name": "Clockwise Vertical Arrows.png",
    "path": "Emojis/Symbols/Clockwise Vertical Arrows.png",
    "sha": "23aabd61841ef7b24273181b0b2423e260b01867",
    "size": 15243,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Clockwise%20Vertical%20Arrows.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Clockwise%20Vertical%20Arrows.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23aabd61841ef7b24273181b0b2423e260b01867",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Clockwise%20Vertical%20Arrows.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Clockwise%20Vertical%20Arrows.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/23aabd61841ef7b24273181b0b2423e260b01867",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Clockwise%20Vertical%20Arrows.png"
    }
  },
  {
    "name": "Cool Button.png",
    "path": "Emojis/Symbols/Cool Button.png",
    "sha": "c0dedd387a2f675f7fedefd66f7ba510504cb873",
    "size": 15344,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cool%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cool%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c0dedd387a2f675f7fedefd66f7ba510504cb873",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cool%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cool%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c0dedd387a2f675f7fedefd66f7ba510504cb873",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cool%20Button.png"
    }
  },
  {
    "name": "Copyright.png",
    "path": "Emojis/Symbols/Copyright.png",
    "sha": "4d3b55bb3f0dcc3a67590da6e43814dfcb7b4bd1",
    "size": 14104,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Copyright.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Copyright.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d3b55bb3f0dcc3a67590da6e43814dfcb7b4bd1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Copyright.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Copyright.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d3b55bb3f0dcc3a67590da6e43814dfcb7b4bd1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Copyright.png"
    }
  },
  {
    "name": "Counterclockwise Arrows Button.png",
    "path": "Emojis/Symbols/Counterclockwise Arrows Button.png",
    "sha": "6337258c5357999bf2372a9f97bd4dc8972302c0",
    "size": 15588,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Counterclockwise%20Arrows%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Counterclockwise%20Arrows%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6337258c5357999bf2372a9f97bd4dc8972302c0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Counterclockwise%20Arrows%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Counterclockwise%20Arrows%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6337258c5357999bf2372a9f97bd4dc8972302c0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Counterclockwise%20Arrows%20Button.png"
    }
  },
  {
    "name": "Cross Mark Button.png",
    "path": "Emojis/Symbols/Cross Mark Button.png",
    "sha": "551f4c4403556c705e3bbf07dd43bb7b5b9961a7",
    "size": 13658,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cross%20Mark%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cross%20Mark%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/551f4c4403556c705e3bbf07dd43bb7b5b9961a7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cross%20Mark%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cross%20Mark%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/551f4c4403556c705e3bbf07dd43bb7b5b9961a7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cross%20Mark%20Button.png"
    }
  },
  {
    "name": "Cross Mark.png",
    "path": "Emojis/Symbols/Cross Mark.png",
    "sha": "93c8f5e494cf18b107e2295691af5e13191a87e7",
    "size": 6367,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cross%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cross%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93c8f5e494cf18b107e2295691af5e13191a87e7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Cross%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Cross%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/93c8f5e494cf18b107e2295691af5e13191a87e7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Cross%20Mark.png"
    }
  },
  {
    "name": "Crossed Flags.png",
    "path": "Emojis/Symbols/Crossed Flags.png",
    "sha": "5720efd2601eb54c69076ee448f0681c44b9bf96",
    "size": 779371,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Crossed%20Flags.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Crossed%20Flags.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5720efd2601eb54c69076ee448f0681c44b9bf96",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Crossed%20Flags.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Crossed%20Flags.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5720efd2601eb54c69076ee448f0681c44b9bf96",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Crossed%20Flags.png"
    }
  },
  {
    "name": "Curly Loop.png",
    "path": "Emojis/Symbols/Curly Loop.png",
    "sha": "e1c802b5f91c82e3338bd9019daa8d992c74d66f",
    "size": 10535,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Curly%20Loop.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Curly%20Loop.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e1c802b5f91c82e3338bd9019daa8d992c74d66f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Curly%20Loop.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Curly%20Loop.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e1c802b5f91c82e3338bd9019daa8d992c74d66f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Curly%20Loop.png"
    }
  },
  {
    "name": "Currency Exchange.png",
    "path": "Emojis/Symbols/Currency Exchange.png",
    "sha": "d8a040d855b9f90c26d4dd590b5e459c1f89dbe7",
    "size": 11109,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Currency%20Exchange.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Currency%20Exchange.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8a040d855b9f90c26d4dd590b5e459c1f89dbe7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Currency%20Exchange.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Currency%20Exchange.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8a040d855b9f90c26d4dd590b5e459c1f89dbe7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Currency%20Exchange.png"
    }
  },
  {
    "name": "Customs.png",
    "path": "Emojis/Symbols/Customs.png",
    "sha": "34d718164992ef171cd9ca3059d0a0019f42dc53",
    "size": 15299,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Customs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Customs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/34d718164992ef171cd9ca3059d0a0019f42dc53",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Customs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Customs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/34d718164992ef171cd9ca3059d0a0019f42dc53",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Customs.png"
    }
  },
  {
    "name": "Diamond with a Dot.png",
    "path": "Emojis/Symbols/Diamond with a Dot.png",
    "sha": "4337b2a13b57f6708e1f441fb5aa4b5b453749ea",
    "size": 9024,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Diamond%20with%20a%20Dot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Diamond%20with%20a%20Dot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4337b2a13b57f6708e1f441fb5aa4b5b453749ea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Diamond%20with%20a%20Dot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Diamond%20with%20a%20Dot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4337b2a13b57f6708e1f441fb5aa4b5b453749ea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Diamond%20with%20a%20Dot.png"
    }
  },
  {
    "name": "Dim Button.png",
    "path": "Emojis/Symbols/Dim Button.png",
    "sha": "df16223e5019060daaae2b2a6b780afe2d579819",
    "size": 12642,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Dim%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Dim%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df16223e5019060daaae2b2a6b780afe2d579819",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Dim%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Dim%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df16223e5019060daaae2b2a6b780afe2d579819",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Dim%20Button.png"
    }
  },
  {
    "name": "Divide.png",
    "path": "Emojis/Symbols/Divide.png",
    "sha": "c480e5e241eba225edb10088bd6cc2e9b1aa21d0",
    "size": 8235,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Divide.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Divide.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c480e5e241eba225edb10088bd6cc2e9b1aa21d0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Divide.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Divide.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c480e5e241eba225edb10088bd6cc2e9b1aa21d0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Divide.png"
    }
  },
  {
    "name": "Dotted Six-Pointed Star.png",
    "path": "Emojis/Symbols/Dotted Six-Pointed Star.png",
    "sha": "9cd0dd9aa9d442bf95ca53ebb14fb8ba7e345d6f",
    "size": 19011,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Dotted%20Six-Pointed%20Star.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Dotted%20Six-Pointed%20Star.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cd0dd9aa9d442bf95ca53ebb14fb8ba7e345d6f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Dotted%20Six-Pointed%20Star.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Dotted%20Six-Pointed%20Star.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cd0dd9aa9d442bf95ca53ebb14fb8ba7e345d6f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Dotted%20Six-Pointed%20Star.png"
    }
  },
  {
    "name": "Double Curly Loop.png",
    "path": "Emojis/Symbols/Double Curly Loop.png",
    "sha": "d4ef4fa8ae82e9cfa92fd9fb0ce27a8ee4690a46",
    "size": 11784,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Double%20Curly%20Loop.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Double%20Curly%20Loop.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d4ef4fa8ae82e9cfa92fd9fb0ce27a8ee4690a46",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Double%20Curly%20Loop.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Double%20Curly%20Loop.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d4ef4fa8ae82e9cfa92fd9fb0ce27a8ee4690a46",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Double%20Curly%20Loop.png"
    }
  },
  {
    "name": "Double Exclamation Mark.png",
    "path": "Emojis/Symbols/Double Exclamation Mark.png",
    "sha": "0ce445c08664952c6e33859722bedc8aaf5a1664",
    "size": 7555,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Double%20Exclamation%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Double%20Exclamation%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0ce445c08664952c6e33859722bedc8aaf5a1664",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Double%20Exclamation%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Double%20Exclamation%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0ce445c08664952c6e33859722bedc8aaf5a1664",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Double%20Exclamation%20Mark.png"
    }
  },
  {
    "name": "Down Arrow.png",
    "path": "Emojis/Symbols/Down Arrow.png",
    "sha": "79eea03e7f6a47d86c385c0e2531cdb8ea9646b1",
    "size": 13976,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79eea03e7f6a47d86c385c0e2531cdb8ea9646b1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Down%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79eea03e7f6a47d86c385c0e2531cdb8ea9646b1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down%20Arrow.png"
    }
  },
  {
    "name": "Down-Left Arrow.png",
    "path": "Emojis/Symbols/Down-Left Arrow.png",
    "sha": "037f725a153cd4bccd7c95bd0af4435b1e51f851",
    "size": 13878,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down-Left%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down-Left%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/037f725a153cd4bccd7c95bd0af4435b1e51f851",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Down-Left%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down-Left%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/037f725a153cd4bccd7c95bd0af4435b1e51f851",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down-Left%20Arrow.png"
    }
  },
  {
    "name": "Down-Right Arrow.png",
    "path": "Emojis/Symbols/Down-Right Arrow.png",
    "sha": "9cbfe9c37335574db65fcdd2be0d79d7667d25b5",
    "size": 13879,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down-Right%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down-Right%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cbfe9c37335574db65fcdd2be0d79d7667d25b5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Down-Right%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Down-Right%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cbfe9c37335574db65fcdd2be0d79d7667d25b5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Down-Right%20Arrow.png"
    }
  },
  {
    "name": "Downwards Button.png",
    "path": "Emojis/Symbols/Downwards Button.png",
    "sha": "71c5165b15dea761f8481664e09f8ea050894188",
    "size": 13816,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Downwards%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Downwards%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/71c5165b15dea761f8481664e09f8ea050894188",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Downwards%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Downwards%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/71c5165b15dea761f8481664e09f8ea050894188",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Downwards%20Button.png"
    }
  },
  {
    "name": "Eight-Pointed Star.png",
    "path": "Emojis/Symbols/Eight-Pointed Star.png",
    "sha": "2b2be4b66e69fe1002641daf72794d9826efe1b4",
    "size": 15329,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eight-Pointed%20Star.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eight-Pointed%20Star.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b2be4b66e69fe1002641daf72794d9826efe1b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Eight-Pointed%20Star.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eight-Pointed%20Star.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2b2be4b66e69fe1002641daf72794d9826efe1b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eight-Pointed%20Star.png"
    }
  },
  {
    "name": "Eight-Spoked Asterisk.png",
    "path": "Emojis/Symbols/Eight-Spoked Asterisk.png",
    "sha": "c450e82b139b923cff057b2708e954bfa2479273",
    "size": 15533,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eight-Spoked%20Asterisk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eight-Spoked%20Asterisk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c450e82b139b923cff057b2708e954bfa2479273",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Eight-Spoked%20Asterisk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eight-Spoked%20Asterisk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c450e82b139b923cff057b2708e954bfa2479273",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eight-Spoked%20Asterisk.png"
    }
  },
  {
    "name": "Eject Button.png",
    "path": "Emojis/Symbols/Eject Button.png",
    "sha": "5d4f216ab5ebdaf59653f72b26a3820649e63849",
    "size": 13747,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eject%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eject%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5d4f216ab5ebdaf59653f72b26a3820649e63849",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Eject%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Eject%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5d4f216ab5ebdaf59653f72b26a3820649e63849",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Eject%20Button.png"
    }
  },
  {
    "name": "End Arrow.png",
    "path": "Emojis/Symbols/End Arrow.png",
    "sha": "842e43b079a02709ef508e3fe65c304e6bf167cd",
    "size": 12460,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/End%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/End%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/842e43b079a02709ef508e3fe65c304e6bf167cd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/End%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/End%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/842e43b079a02709ef508e3fe65c304e6bf167cd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/End%20Arrow.png"
    }
  },
  {
    "name": "Exclamation Question Mark.png",
    "path": "Emojis/Symbols/Exclamation Question Mark.png",
    "sha": "fb8b475d6b1b8ad69f8425643c0249eb037a60d5",
    "size": 11646,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Exclamation%20Question%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Exclamation%20Question%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb8b475d6b1b8ad69f8425643c0249eb037a60d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Exclamation%20Question%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Exclamation%20Question%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb8b475d6b1b8ad69f8425643c0249eb037a60d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Exclamation%20Question%20Mark.png"
    }
  },
  {
    "name": "Fast Down Button.png",
    "path": "Emojis/Symbols/Fast Down Button.png",
    "sha": "d5a36a329f182d11e2ed920779163258498009e9",
    "size": 14095,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Down%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Down%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5a36a329f182d11e2ed920779163258498009e9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Fast%20Down%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Down%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5a36a329f182d11e2ed920779163258498009e9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Down%20Button.png"
    }
  },
  {
    "name": "Fast Reverse Button.png",
    "path": "Emojis/Symbols/Fast Reverse Button.png",
    "sha": "bc0505e421ef354867bf90908c2577d026ae85d5",
    "size": 14543,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Reverse%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Reverse%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bc0505e421ef354867bf90908c2577d026ae85d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Fast%20Reverse%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Reverse%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bc0505e421ef354867bf90908c2577d026ae85d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Reverse%20Button.png"
    }
  },
  {
    "name": "Fast Up Button.png",
    "path": "Emojis/Symbols/Fast Up Button.png",
    "sha": "f7ee90d0cbe6fbb770f1a970659343ce443aa845",
    "size": 14179,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Up%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Up%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7ee90d0cbe6fbb770f1a970659343ce443aa845",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Fast%20Up%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast%20Up%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7ee90d0cbe6fbb770f1a970659343ce443aa845",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast%20Up%20Button.png"
    }
  },
  {
    "name": "Fast-Forward Button.png",
    "path": "Emojis/Symbols/Fast-Forward Button.png",
    "sha": "883da49c013448a11a152de0a44804f59ae3d4f5",
    "size": 14304,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast-Forward%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast-Forward%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/883da49c013448a11a152de0a44804f59ae3d4f5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Fast-Forward%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fast-Forward%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/883da49c013448a11a152de0a44804f59ae3d4f5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fast-Forward%20Button.png"
    }
  },
  {
    "name": "Female Sign.png",
    "path": "Emojis/Symbols/Female Sign.png",
    "sha": "709d6d8d83c08677e5e481bddc09b66f55d975e8",
    "size": 16897,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Female%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Female%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/709d6d8d83c08677e5e481bddc09b66f55d975e8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Female%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Female%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/709d6d8d83c08677e5e481bddc09b66f55d975e8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Female%20Sign.png"
    }
  },
  {
    "name": "Fleur-de-lis.png",
    "path": "Emojis/Symbols/Fleur-de-lis.png",
    "sha": "66395e2c9bebf0133c606bac30507a30d819923f",
    "size": 11454,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fleur-de-lis.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fleur-de-lis.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/66395e2c9bebf0133c606bac30507a30d819923f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Fleur-de-lis.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Fleur-de-lis.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/66395e2c9bebf0133c606bac30507a30d819923f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Fleur-de-lis.png"
    }
  },
  {
    "name": "Free Button.png",
    "path": "Emojis/Symbols/Free Button.png",
    "sha": "fb702eacd8d976ada94e8585f30714c6953c980e",
    "size": 14695,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Free%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Free%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb702eacd8d976ada94e8585f30714c6953c980e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Free%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Free%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb702eacd8d976ada94e8585f30714c6953c980e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Free%20Button.png"
    }
  },
  {
    "name": "Gemini.png",
    "path": "Emojis/Symbols/Gemini.png",
    "sha": "2af6b2e0ff5cc64cb72c135c491a424c9e65ae7a",
    "size": 17223,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Gemini.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Gemini.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2af6b2e0ff5cc64cb72c135c491a424c9e65ae7a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Gemini.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Gemini.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2af6b2e0ff5cc64cb72c135c491a424c9e65ae7a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Gemini.png"
    }
  },
  {
    "name": "Green Circle.png",
    "path": "Emojis/Symbols/Green Circle.png",
    "sha": "9c283e8ddeb3ab5949e9536b6d09fce204664401",
    "size": 17340,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Green%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Green%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c283e8ddeb3ab5949e9536b6d09fce204664401",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Green%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Green%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c283e8ddeb3ab5949e9536b6d09fce204664401",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Green%20Circle.png"
    }
  },
  {
    "name": "Green Square.png",
    "path": "Emojis/Symbols/Green Square.png",
    "sha": "68c0966d7cb0590ba034b2096254be79946e5d9f",
    "size": 9216,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Green%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Green%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/68c0966d7cb0590ba034b2096254be79946e5d9f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Green%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Green%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/68c0966d7cb0590ba034b2096254be79946e5d9f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Green%20Square.png"
    }
  },
  {
    "name": "Hamsa.png",
    "path": "Emojis/Symbols/Hamsa.png",
    "sha": "44e130ca5dbca9b22a5ab5c72cd7b1cfdd622028",
    "size": 2267467,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Hamsa.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Hamsa.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44e130ca5dbca9b22a5ab5c72cd7b1cfdd622028",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Hamsa.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Hamsa.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44e130ca5dbca9b22a5ab5c72cd7b1cfdd622028",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Hamsa.png"
    }
  },
  {
    "name": "Heavy Dollar Sign.png",
    "path": "Emojis/Symbols/Heavy Dollar Sign.png",
    "sha": "753fce851e8cb185c6a239bdc4788e95009cc401",
    "size": 9084,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Heavy%20Dollar%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Heavy%20Dollar%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/753fce851e8cb185c6a239bdc4788e95009cc401",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Heavy%20Dollar%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Heavy%20Dollar%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/753fce851e8cb185c6a239bdc4788e95009cc401",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Heavy%20Dollar%20Sign.png"
    }
  },
  {
    "name": "Hollow Red Circle.png",
    "path": "Emojis/Symbols/Hollow Red Circle.png",
    "sha": "8db0267b44880964753fd86688b82a47da8be8c1",
    "size": 10159,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Hollow%20Red%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Hollow%20Red%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8db0267b44880964753fd86688b82a47da8be8c1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Hollow%20Red%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Hollow%20Red%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8db0267b44880964753fd86688b82a47da8be8c1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Hollow%20Red%20Circle.png"
    }
  },
  {
    "name": "ID Button.png",
    "path": "Emojis/Symbols/ID Button.png",
    "sha": "12ee4419235898ae5548e4e150448ceee84f7930",
    "size": 17125,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/ID%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/ID%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/12ee4419235898ae5548e4e150448ceee84f7930",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/ID%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/ID%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/12ee4419235898ae5548e4e150448ceee84f7930",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/ID%20Button.png"
    }
  },
  {
    "name": "Infinity.png",
    "path": "Emojis/Symbols/Infinity.png",
    "sha": "f269a759222bd1bda89dcc33e0a99b4ad1d7831f",
    "size": 9427,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Infinity.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Infinity.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f269a759222bd1bda89dcc33e0a99b4ad1d7831f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Infinity.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Infinity.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f269a759222bd1bda89dcc33e0a99b4ad1d7831f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Infinity.png"
    }
  },
  {
    "name": "Information.png",
    "path": "Emojis/Symbols/Information.png",
    "sha": "6fb6a28c7d25fe26c85ef0c231daf5a3c6818386",
    "size": 13388,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Information.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Information.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fb6a28c7d25fe26c85ef0c231daf5a3c6818386",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Information.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Information.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fb6a28c7d25fe26c85ef0c231daf5a3c6818386",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Information.png"
    }
  },
  {
    "name": "Input Latin Letters.png",
    "path": "Emojis/Symbols/Input Latin Letters.png",
    "sha": "b43bfdb3c38b5b192667858e407f746429a56800",
    "size": 15962,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Letters.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Letters.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b43bfdb3c38b5b192667858e407f746429a56800",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Input%20Latin%20Letters.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Letters.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b43bfdb3c38b5b192667858e407f746429a56800",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Letters.png"
    }
  },
  {
    "name": "Input Latin Lowercase.png",
    "path": "Emojis/Symbols/Input Latin Lowercase.png",
    "sha": "5911efc7aa6ce621afc534d3d4029813aa56ca01",
    "size": 17440,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Lowercase.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Lowercase.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5911efc7aa6ce621afc534d3d4029813aa56ca01",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Input%20Latin%20Lowercase.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Lowercase.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5911efc7aa6ce621afc534d3d4029813aa56ca01",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Lowercase.png"
    }
  },
  {
    "name": "Input Latin Uppercase.png",
    "path": "Emojis/Symbols/Input Latin Uppercase.png",
    "sha": "764c05d725fc6d747fb2ac4c2ac8e673870c0b3c",
    "size": 17555,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Uppercase.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Uppercase.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/764c05d725fc6d747fb2ac4c2ac8e673870c0b3c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Input%20Latin%20Uppercase.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Latin%20Uppercase.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/764c05d725fc6d747fb2ac4c2ac8e673870c0b3c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Latin%20Uppercase.png"
    }
  },
  {
    "name": "Input Numbers.png",
    "path": "Emojis/Symbols/Input Numbers.png",
    "sha": "def86f8d60baee2b472239480c9bb576c08a5126",
    "size": 17085,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Numbers.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Numbers.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/def86f8d60baee2b472239480c9bb576c08a5126",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Input%20Numbers.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Numbers.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/def86f8d60baee2b472239480c9bb576c08a5126",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Numbers.png"
    }
  },
  {
    "name": "Input Symbols.png",
    "path": "Emojis/Symbols/Input Symbols.png",
    "sha": "53aaafb4af00f3f34718a7975e21f433b77ed1b5",
    "size": 18230,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Symbols.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Symbols.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53aaafb4af00f3f34718a7975e21f433b77ed1b5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Input%20Symbols.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Input%20Symbols.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53aaafb4af00f3f34718a7975e21f433b77ed1b5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Input%20Symbols.png"
    }
  },
  {
    "name": "Japanese Symbol for Beginner.png",
    "path": "Emojis/Symbols/Japanese Symbol for Beginner.png",
    "sha": "83a7a5ea7873bdba58f737d687ee2795bb1406b5",
    "size": 8526,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20Symbol%20for%20Beginner.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20Symbol%20for%20Beginner.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/83a7a5ea7873bdba58f737d687ee2795bb1406b5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese%20Symbol%20for%20Beginner.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20Symbol%20for%20Beginner.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/83a7a5ea7873bdba58f737d687ee2795bb1406b5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20Symbol%20for%20Beginner.png"
    }
  },
  {
    "name": "Japanese “Acceptable” Button.png",
    "path": "Emojis/Symbols/Japanese “Acceptable” Button.png",
    "sha": "9c329e23d51ddc9e891e64c4ac4b7f57ef5b3288",
    "size": 14050,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CAcceptable%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CAcceptable%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c329e23d51ddc9e891e64c4ac4b7f57ef5b3288",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Acceptable” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CAcceptable%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c329e23d51ddc9e891e64c4ac4b7f57ef5b3288",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CAcceptable%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Application” Button.png",
    "path": "Emojis/Symbols/Japanese “Application” Button.png",
    "sha": "0b9aeb8528e5f4d2efebb01d32c334c555824337",
    "size": 14652,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CApplication%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CApplication%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0b9aeb8528e5f4d2efebb01d32c334c555824337",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Application” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CApplication%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0b9aeb8528e5f4d2efebb01d32c334c555824337",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CApplication%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Bargain” Button.png",
    "path": "Emojis/Symbols/Japanese “Bargain” Button.png",
    "sha": "1ff8b0bb07183cbcdc63e0d6e59cf892d9b03c91",
    "size": 15629,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CBargain%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CBargain%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1ff8b0bb07183cbcdc63e0d6e59cf892d9b03c91",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Bargain” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CBargain%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1ff8b0bb07183cbcdc63e0d6e59cf892d9b03c91",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CBargain%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Congratulations” Button.png",
    "path": "Emojis/Symbols/Japanese “Congratulations” Button.png",
    "sha": "5327968334dca684fce8bef992935e3758d1a22f",
    "size": 15682,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CCongratulations%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CCongratulations%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5327968334dca684fce8bef992935e3758d1a22f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Congratulations” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CCongratulations%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5327968334dca684fce8bef992935e3758d1a22f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CCongratulations%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Discount” Button.png",
    "path": "Emojis/Symbols/Japanese “Discount” Button.png",
    "sha": "5a77d0afe6f41e4938a1fc67ad9380e14044ac58",
    "size": 16490,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CDiscount%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CDiscount%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a77d0afe6f41e4938a1fc67ad9380e14044ac58",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Discount” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CDiscount%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a77d0afe6f41e4938a1fc67ad9380e14044ac58",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CDiscount%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Free of Charge” Button.png",
    "path": "Emojis/Symbols/Japanese “Free of Charge” Button.png",
    "sha": "0f6c3bd1958f435cfa799ed8e7fd481682d9ccd3",
    "size": 16840,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CFree%20of%20Charge%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CFree%20of%20Charge%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f6c3bd1958f435cfa799ed8e7fd481682d9ccd3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Free of Charge” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CFree%20of%20Charge%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f6c3bd1958f435cfa799ed8e7fd481682d9ccd3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CFree%20of%20Charge%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Here” Button.png",
    "path": "Emojis/Symbols/Japanese “Here” Button.png",
    "sha": "0df7bdfdbb50d13129895fa7772169813c55d74a",
    "size": 14048,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CHere%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CHere%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0df7bdfdbb50d13129895fa7772169813c55d74a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Here” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CHere%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0df7bdfdbb50d13129895fa7772169813c55d74a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CHere%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Monthly Amount” Button.png",
    "path": "Emojis/Symbols/Japanese “Monthly Amount” Button.png",
    "sha": "47239d214ed24f1e6b8157e12059ea4af762bb51",
    "size": 14264,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CMonthly%20Amount%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CMonthly%20Amount%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47239d214ed24f1e6b8157e12059ea4af762bb51",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Monthly Amount” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CMonthly%20Amount%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47239d214ed24f1e6b8157e12059ea4af762bb51",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CMonthly%20Amount%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “No Vacancy” Button.png",
    "path": "Emojis/Symbols/Japanese “No Vacancy” Button.png",
    "sha": "a36e86d35d447b8c54e8f5a071037f694191c472",
    "size": 17268,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CNo%20Vacancy%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CNo%20Vacancy%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a36e86d35d447b8c54e8f5a071037f694191c472",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “No Vacancy” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CNo%20Vacancy%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a36e86d35d447b8c54e8f5a071037f694191c472",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CNo%20Vacancy%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Not Free of Charge” Button.png",
    "path": "Emojis/Symbols/Japanese “Not Free of Charge” Button.png",
    "sha": "6c7ed7f84733667d6d34aa84e72eb07b560e5369",
    "size": 14727,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CNot%20Free%20of%20Charge%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CNot%20Free%20of%20Charge%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6c7ed7f84733667d6d34aa84e72eb07b560e5369",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Not Free of Charge” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CNot%20Free%20of%20Charge%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6c7ed7f84733667d6d34aa84e72eb07b560e5369",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CNot%20Free%20of%20Charge%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Open for Business” Button.png",
    "path": "Emojis/Symbols/Japanese “Open for Business” Button.png",
    "sha": "77ad5ff62a2f4f852bf9bc7ffd2abd1665548f25",
    "size": 15005,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9COpen%20for%20Business%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9COpen%20for%20Business%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77ad5ff62a2f4f852bf9bc7ffd2abd1665548f25",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Open for Business” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9COpen%20for%20Business%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77ad5ff62a2f4f852bf9bc7ffd2abd1665548f25",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9COpen%20for%20Business%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Passing Grade” Button.png",
    "path": "Emojis/Symbols/Japanese “Passing Grade” Button.png",
    "sha": "df3f05af7e16a3854eb5979157851066c4a227a0",
    "size": 15630,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CPassing%20Grade%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CPassing%20Grade%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df3f05af7e16a3854eb5979157851066c4a227a0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Passing Grade” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CPassing%20Grade%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df3f05af7e16a3854eb5979157851066c4a227a0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CPassing%20Grade%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Prohibited” Button.png",
    "path": "Emojis/Symbols/Japanese “Prohibited” Button.png",
    "sha": "3143bef403c03832403e45e2105b6da8e801486b",
    "size": 17075,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CProhibited%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CProhibited%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3143bef403c03832403e45e2105b6da8e801486b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Prohibited” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CProhibited%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3143bef403c03832403e45e2105b6da8e801486b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CProhibited%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Reserved” Button.png",
    "path": "Emojis/Symbols/Japanese “Reserved” Button.png",
    "sha": "27735da9b98a9af485d340c962afb041b82fe1f7",
    "size": 16401,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CReserved%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CReserved%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27735da9b98a9af485d340c962afb041b82fe1f7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Reserved” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CReserved%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27735da9b98a9af485d340c962afb041b82fe1f7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CReserved%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Secret” Button.png",
    "path": "Emojis/Symbols/Japanese “Secret” Button.png",
    "sha": "2ae1c09420d09496534d9a3fe3a875f2ab2471c1",
    "size": 17345,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CSecret%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CSecret%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2ae1c09420d09496534d9a3fe3a875f2ab2471c1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Secret” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CSecret%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2ae1c09420d09496534d9a3fe3a875f2ab2471c1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CSecret%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Service Charge” Button.png",
    "path": "Emojis/Symbols/Japanese “Service Charge” Button.png",
    "sha": "fe8287df9b5c23440bafaf48ea741fed95a85d28",
    "size": 14824,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CService%20Charge%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CService%20Charge%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe8287df9b5c23440bafaf48ea741fed95a85d28",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Service Charge” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CService%20Charge%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe8287df9b5c23440bafaf48ea741fed95a85d28",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CService%20Charge%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Japanese “Vacancy” Button.png",
    "path": "Emojis/Symbols/Japanese “Vacancy” Button.png",
    "sha": "7b8d5fc4cadcfe6b195d6180da041ed5dce47024",
    "size": 16079,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CVacancy%E2%80%9D%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CVacancy%E2%80%9D%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b8d5fc4cadcfe6b195d6180da041ed5dce47024",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Japanese “Vacancy” Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Japanese%20%E2%80%9CVacancy%E2%80%9D%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b8d5fc4cadcfe6b195d6180da041ed5dce47024",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Japanese%20%E2%80%9CVacancy%E2%80%9D%20Button.png"
    }
  },
  {
    "name": "Keycap 10.png",
    "path": "Emojis/Symbols/Keycap 10.png",
    "sha": "b5b96e90880f0ded2cdd54b62a1de7bb72b2852e",
    "size": 15524,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%2010.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%2010.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5b96e90880f0ded2cdd54b62a1de7bb72b2852e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%2010.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%2010.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b5b96e90880f0ded2cdd54b62a1de7bb72b2852e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%2010.png"
    }
  },
  {
    "name": "Keycap Asterisk.png",
    "path": "Emojis/Symbols/Keycap Asterisk.png",
    "sha": "379aeb006e906597cc23715ab81ca4997d80cf8d",
    "size": 15305,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Asterisk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Asterisk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/379aeb006e906597cc23715ab81ca4997d80cf8d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Asterisk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Asterisk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/379aeb006e906597cc23715ab81ca4997d80cf8d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Asterisk.png"
    }
  },
  {
    "name": "Keycap Digit Eight.png",
    "path": "Emojis/Symbols/Keycap Digit Eight.png",
    "sha": "4a2de025b28509f394f3b16883546c173f9ce1c4",
    "size": 15269,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Eight.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Eight.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4a2de025b28509f394f3b16883546c173f9ce1c4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Eight.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Eight.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4a2de025b28509f394f3b16883546c173f9ce1c4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Eight.png"
    }
  },
  {
    "name": "Keycap Digit Five.png",
    "path": "Emojis/Symbols/Keycap Digit Five.png",
    "sha": "4ea3663dd2b7d710572b4dcb4e5f8b8ab94abc92",
    "size": 14890,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Five.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Five.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ea3663dd2b7d710572b4dcb4e5f8b8ab94abc92",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Five.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Five.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ea3663dd2b7d710572b4dcb4e5f8b8ab94abc92",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Five.png"
    }
  },
  {
    "name": "Keycap Digit Four.png",
    "path": "Emojis/Symbols/Keycap Digit Four.png",
    "sha": "235510fa396f22a593c2b05870e61fdeac8279a7",
    "size": 14144,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Four.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Four.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/235510fa396f22a593c2b05870e61fdeac8279a7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Four.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Four.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/235510fa396f22a593c2b05870e61fdeac8279a7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Four.png"
    }
  },
  {
    "name": "Keycap Digit Nine.png",
    "path": "Emojis/Symbols/Keycap Digit Nine.png",
    "sha": "8a274f2a08ff911807aff19e4633e1a4e9c08d9a",
    "size": 14813,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Nine.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Nine.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a274f2a08ff911807aff19e4633e1a4e9c08d9a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Nine.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Nine.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a274f2a08ff911807aff19e4633e1a4e9c08d9a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Nine.png"
    }
  },
  {
    "name": "Keycap Digit One.png",
    "path": "Emojis/Symbols/Keycap Digit One.png",
    "sha": "3f637504f0c5dad703a4a2e39ec5017b5bf0b6af",
    "size": 13404,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20One.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20One.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f637504f0c5dad703a4a2e39ec5017b5bf0b6af",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20One.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20One.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3f637504f0c5dad703a4a2e39ec5017b5bf0b6af",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20One.png"
    }
  },
  {
    "name": "Keycap Digit Seven.png",
    "path": "Emojis/Symbols/Keycap Digit Seven.png",
    "sha": "7b9d76533e12611cd1fe6db92d486ab64b88262c",
    "size": 14220,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Seven.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Seven.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b9d76533e12611cd1fe6db92d486ab64b88262c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Seven.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Seven.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b9d76533e12611cd1fe6db92d486ab64b88262c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Seven.png"
    }
  },
  {
    "name": "Keycap Digit Six.png",
    "path": "Emojis/Symbols/Keycap Digit Six.png",
    "sha": "c9d4a01ab85b76d3daf17e653fb0fac1df8c74e5",
    "size": 14588,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Six.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Six.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c9d4a01ab85b76d3daf17e653fb0fac1df8c74e5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Six.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Six.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c9d4a01ab85b76d3daf17e653fb0fac1df8c74e5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Six.png"
    }
  },
  {
    "name": "Keycap Digit Three.png",
    "path": "Emojis/Symbols/Keycap Digit Three.png",
    "sha": "1eae6de7344b73173cc5ee3ad32f90af0fe1f239",
    "size": 15003,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Three.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Three.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1eae6de7344b73173cc5ee3ad32f90af0fe1f239",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Three.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Three.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1eae6de7344b73173cc5ee3ad32f90af0fe1f239",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Three.png"
    }
  },
  {
    "name": "Keycap Digit Two.png",
    "path": "Emojis/Symbols/Keycap Digit Two.png",
    "sha": "c30ecf099ae148a859062d09cc11a090dfbef853",
    "size": 14831,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Two.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Two.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c30ecf099ae148a859062d09cc11a090dfbef853",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Two.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Two.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c30ecf099ae148a859062d09cc11a090dfbef853",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Two.png"
    }
  },
  {
    "name": "Keycap Digit Zero.png",
    "path": "Emojis/Symbols/Keycap Digit Zero.png",
    "sha": "ce5fd3c48f5c9e8b6bea6c9521987ab77439c5d0",
    "size": 14858,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Zero.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Zero.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce5fd3c48f5c9e8b6bea6c9521987ab77439c5d0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Digit%20Zero.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Digit%20Zero.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce5fd3c48f5c9e8b6bea6c9521987ab77439c5d0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Digit%20Zero.png"
    }
  },
  {
    "name": "Keycap Number Sign.png",
    "path": "Emojis/Symbols/Keycap Number Sign.png",
    "sha": "9efcf8ddeeaefced72a0ecbf180daa7ca154ce4f",
    "size": 15317,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Number%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Number%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9efcf8ddeeaefced72a0ecbf180daa7ca154ce4f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Keycap%20Number%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Keycap%20Number%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9efcf8ddeeaefced72a0ecbf180daa7ca154ce4f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Keycap%20Number%20Sign.png"
    }
  },
  {
    "name": "Khanda.png",
    "path": "Emojis/Symbols/Khanda.png",
    "sha": "974da36158aee6c080eb7328b215ca9b105f300d",
    "size": 42739,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Khanda.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Khanda.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/974da36158aee6c080eb7328b215ca9b105f300d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Khanda.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Khanda.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/974da36158aee6c080eb7328b215ca9b105f300d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Khanda.png"
    }
  },
  {
    "name": "Large Blue Diamond.png",
    "path": "Emojis/Symbols/Large Blue Diamond.png",
    "sha": "00246dd0bb01dab1f8c138e04e88028ccd654bf5",
    "size": 7010,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Large%20Blue%20Diamond.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Large%20Blue%20Diamond.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00246dd0bb01dab1f8c138e04e88028ccd654bf5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Large%20Blue%20Diamond.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Large%20Blue%20Diamond.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00246dd0bb01dab1f8c138e04e88028ccd654bf5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Large%20Blue%20Diamond.png"
    }
  },
  {
    "name": "Large Orange Diamond.png",
    "path": "Emojis/Symbols/Large Orange Diamond.png",
    "sha": "a8a28940cb017ff01c4ba24693904c9a1f6300a1",
    "size": 7593,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Large%20Orange%20Diamond.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Large%20Orange%20Diamond.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a8a28940cb017ff01c4ba24693904c9a1f6300a1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Large%20Orange%20Diamond.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Large%20Orange%20Diamond.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a8a28940cb017ff01c4ba24693904c9a1f6300a1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Large%20Orange%20Diamond.png"
    }
  },
  {
    "name": "Last Track Button.png",
    "path": "Emojis/Symbols/Last Track Button.png",
    "sha": "e6398a91b1f0633650e2dbea45e19064a941925c",
    "size": 14667,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Last%20Track%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Last%20Track%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6398a91b1f0633650e2dbea45e19064a941925c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Last%20Track%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Last%20Track%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6398a91b1f0633650e2dbea45e19064a941925c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Last%20Track%20Button.png"
    }
  },
  {
    "name": "Latin Cross.png",
    "path": "Emojis/Symbols/Latin Cross.png",
    "sha": "75ec29e12d1567fc44049f18dba1fb35e405919c",
    "size": 15236,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Latin%20Cross.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Latin%20Cross.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75ec29e12d1567fc44049f18dba1fb35e405919c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Latin%20Cross.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Latin%20Cross.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75ec29e12d1567fc44049f18dba1fb35e405919c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Latin%20Cross.png"
    }
  },
  {
    "name": "Left Arrow Curving Right.png",
    "path": "Emojis/Symbols/Left Arrow Curving Right.png",
    "sha": "ac69a5f015e39849ab8104b06625994c631fa54f",
    "size": 14595,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Arrow%20Curving%20Right.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Arrow%20Curving%20Right.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac69a5f015e39849ab8104b06625994c631fa54f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Left%20Arrow%20Curving%20Right.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Arrow%20Curving%20Right.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac69a5f015e39849ab8104b06625994c631fa54f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Arrow%20Curving%20Right.png"
    }
  },
  {
    "name": "Left Arrow.png",
    "path": "Emojis/Symbols/Left Arrow.png",
    "sha": "0d1b520c4e2ea5e718ec09a3c67841037c5f497d",
    "size": 13530,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d1b520c4e2ea5e718ec09a3c67841037c5f497d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Left%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d1b520c4e2ea5e718ec09a3c67841037c5f497d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Arrow.png"
    }
  },
  {
    "name": "Left Luggage.png",
    "path": "Emojis/Symbols/Left Luggage.png",
    "sha": "1873e3f8a8462747b923884870bdb7c52c83ac00",
    "size": 15915,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Luggage.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Luggage.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1873e3f8a8462747b923884870bdb7c52c83ac00",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Left%20Luggage.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left%20Luggage.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1873e3f8a8462747b923884870bdb7c52c83ac00",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left%20Luggage.png"
    }
  },
  {
    "name": "Left-Right Arrow.png",
    "path": "Emojis/Symbols/Left-Right Arrow.png",
    "sha": "e94373866c0468a8b773370b85749b4572864d9c",
    "size": 14013,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left-Right%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left-Right%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e94373866c0468a8b773370b85749b4572864d9c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Left-Right%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Left-Right%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e94373866c0468a8b773370b85749b4572864d9c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Left-Right%20Arrow.png"
    }
  },
  {
    "name": "Leo.png",
    "path": "Emojis/Symbols/Leo.png",
    "sha": "9d70b42415aa1108d0494b0f3923f266e62073ff",
    "size": 19640,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Leo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Leo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d70b42415aa1108d0494b0f3923f266e62073ff",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Leo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Leo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d70b42415aa1108d0494b0f3923f266e62073ff",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Leo.png"
    }
  },
  {
    "name": "Libra.png",
    "path": "Emojis/Symbols/Libra.png",
    "sha": "8a3b0c179cd86b0b6c06cdc8d34c91dea86a755c",
    "size": 17577,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Libra.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Libra.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a3b0c179cd86b0b6c06cdc8d34c91dea86a755c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Libra.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Libra.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8a3b0c179cd86b0b6c06cdc8d34c91dea86a755c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Libra.png"
    }
  },
  {
    "name": "Litter in Bin Sign.png",
    "path": "Emojis/Symbols/Litter in Bin Sign.png",
    "sha": "4e0a2f6b2b15f2c935fcd316ac7ce1baebed2499",
    "size": 15624,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Litter%20in%20Bin%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Litter%20in%20Bin%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4e0a2f6b2b15f2c935fcd316ac7ce1baebed2499",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Litter%20in%20Bin%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Litter%20in%20Bin%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4e0a2f6b2b15f2c935fcd316ac7ce1baebed2499",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Litter%20in%20Bin%20Sign.png"
    }
  },
  {
    "name": "Male Sign.png",
    "path": "Emojis/Symbols/Male Sign.png",
    "sha": "a23f2b441284aac5e0756fe8f18ce63fea73ac53",
    "size": 15841,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Male%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Male%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a23f2b441284aac5e0756fe8f18ce63fea73ac53",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Male%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Male%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a23f2b441284aac5e0756fe8f18ce63fea73ac53",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Male%20Sign.png"
    }
  },
  {
    "name": "Medical Symbol.png",
    "path": "Emojis/Symbols/Medical Symbol.png",
    "sha": "25cfe9abebbe09a79c3d305c2d5d49c676eb02c2",
    "size": 8970,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Medical%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Medical%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25cfe9abebbe09a79c3d305c2d5d49c676eb02c2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Medical%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Medical%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25cfe9abebbe09a79c3d305c2d5d49c676eb02c2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Medical%20Symbol.png"
    }
  },
  {
    "name": "Menorah.png",
    "path": "Emojis/Symbols/Menorah.png",
    "sha": "3d82324ccf1b73fc5d69eb1f4e0d130798b957aa",
    "size": 21573,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Menorah.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Menorah.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d82324ccf1b73fc5d69eb1f4e0d130798b957aa",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Menorah.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Menorah.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d82324ccf1b73fc5d69eb1f4e0d130798b957aa",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Menorah.png"
    }
  },
  {
    "name": "Men’s Room.png",
    "path": "Emojis/Symbols/Men’s Room.png",
    "sha": "1a015fe26eb0217f67fb071255be2b21a9e241ec",
    "size": 15276,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Men%E2%80%99s%20Room.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Men%E2%80%99s%20Room.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a015fe26eb0217f67fb071255be2b21a9e241ec",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Men’s Room.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Men%E2%80%99s%20Room.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a015fe26eb0217f67fb071255be2b21a9e241ec",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Men%E2%80%99s%20Room.png"
    }
  },
  {
    "name": "Minus.png",
    "path": "Emojis/Symbols/Minus.png",
    "sha": "ce432e99bdfac191a3ddfe1fc6985811f4613a57",
    "size": 5435,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Minus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Minus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce432e99bdfac191a3ddfe1fc6985811f4613a57",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Minus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Minus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce432e99bdfac191a3ddfe1fc6985811f4613a57",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Minus.png"
    }
  },
  {
    "name": "Mobile Phone Off.png",
    "path": "Emojis/Symbols/Mobile Phone Off.png",
    "sha": "3644ea7f2838534f0a4af61855876b05a57b7444",
    "size": 13886,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Mobile%20Phone%20Off.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Mobile%20Phone%20Off.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3644ea7f2838534f0a4af61855876b05a57b7444",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Mobile%20Phone%20Off.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Mobile%20Phone%20Off.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3644ea7f2838534f0a4af61855876b05a57b7444",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Mobile%20Phone%20Off.png"
    }
  },
  {
    "name": "Multiply.png",
    "path": "Emojis/Symbols/Multiply.png",
    "sha": "1792b5d5da8dd8b6bde1f39afbe5c6d2fd63b9b8",
    "size": 11507,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Multiply.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Multiply.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1792b5d5da8dd8b6bde1f39afbe5c6d2fd63b9b8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Multiply.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Multiply.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1792b5d5da8dd8b6bde1f39afbe5c6d2fd63b9b8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Multiply.png"
    }
  },
  {
    "name": "NG Button.png",
    "path": "Emojis/Symbols/NG Button.png",
    "sha": "106f5e454511f646f2de2dfa8377552895a4e9da",
    "size": 15880,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/NG%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/NG%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/106f5e454511f646f2de2dfa8377552895a4e9da",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/NG%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/NG%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/106f5e454511f646f2de2dfa8377552895a4e9da",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/NG%20Button.png"
    }
  },
  {
    "name": "Name Badge.png",
    "path": "Emojis/Symbols/Name Badge.png",
    "sha": "cf98ae46d48ac8b76b2514f4d45d07c6ed996706",
    "size": 12229,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Name%20Badge.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Name%20Badge.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cf98ae46d48ac8b76b2514f4d45d07c6ed996706",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Name%20Badge.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Name%20Badge.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cf98ae46d48ac8b76b2514f4d45d07c6ed996706",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Name%20Badge.png"
    }
  },
  {
    "name": "New Button.png",
    "path": "Emojis/Symbols/New Button.png",
    "sha": "8eadd743dded1da1311a55a8595c8e9c12fdd445",
    "size": 15219,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/New%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/New%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8eadd743dded1da1311a55a8595c8e9c12fdd445",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/New%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/New%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8eadd743dded1da1311a55a8595c8e9c12fdd445",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/New%20Button.png"
    }
  },
  {
    "name": "Next Track Button.png",
    "path": "Emojis/Symbols/Next Track Button.png",
    "sha": "b18fdad2ab64815af7ecebb6f7686da5c2824040",
    "size": 14349,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Next%20Track%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Next%20Track%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b18fdad2ab64815af7ecebb6f7686da5c2824040",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Next%20Track%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Next%20Track%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b18fdad2ab64815af7ecebb6f7686da5c2824040",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Next%20Track%20Button.png"
    }
  },
  {
    "name": "No Bicycles.png",
    "path": "Emojis/Symbols/No Bicycles.png",
    "sha": "a544a53671bf049e8f72bd427deef3c9c5e0242a",
    "size": 16479,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Bicycles.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Bicycles.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a544a53671bf049e8f72bd427deef3c9c5e0242a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Bicycles.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Bicycles.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a544a53671bf049e8f72bd427deef3c9c5e0242a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Bicycles.png"
    }
  },
  {
    "name": "No Entry.png",
    "path": "Emojis/Symbols/No Entry.png",
    "sha": "84037e5de3b70dad0a128b5ac2fa4b11e33ae359",
    "size": 11582,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Entry.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Entry.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/84037e5de3b70dad0a128b5ac2fa4b11e33ae359",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Entry.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Entry.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/84037e5de3b70dad0a128b5ac2fa4b11e33ae359",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Entry.png"
    }
  },
  {
    "name": "No Littering.png",
    "path": "Emojis/Symbols/No Littering.png",
    "sha": "a99f734ed6050a99939d215ea9eba53417f7b17a",
    "size": 15859,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Littering.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Littering.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a99f734ed6050a99939d215ea9eba53417f7b17a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Littering.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Littering.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a99f734ed6050a99939d215ea9eba53417f7b17a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Littering.png"
    }
  },
  {
    "name": "No Mobile Phones.png",
    "path": "Emojis/Symbols/No Mobile Phones.png",
    "sha": "b38e45ad4220119c31b3076e6ef28ae330d0023b",
    "size": 14427,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Mobile%20Phones.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Mobile%20Phones.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b38e45ad4220119c31b3076e6ef28ae330d0023b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Mobile%20Phones.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Mobile%20Phones.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b38e45ad4220119c31b3076e6ef28ae330d0023b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Mobile%20Phones.png"
    }
  },
  {
    "name": "No One Under Eighteen.png",
    "path": "Emojis/Symbols/No One Under Eighteen.png",
    "sha": "3adabd455520a70165b16f0329cb39fb937cc434",
    "size": 16138,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20One%20Under%20Eighteen.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20One%20Under%20Eighteen.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3adabd455520a70165b16f0329cb39fb937cc434",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20One%20Under%20Eighteen.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20One%20Under%20Eighteen.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3adabd455520a70165b16f0329cb39fb937cc434",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20One%20Under%20Eighteen.png"
    }
  },
  {
    "name": "No Pedestrians.png",
    "path": "Emojis/Symbols/No Pedestrians.png",
    "sha": "ae82693ed2b9b495780e5cd326f6bc736b0267fc",
    "size": 15224,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Pedestrians.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Pedestrians.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ae82693ed2b9b495780e5cd326f6bc736b0267fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Pedestrians.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Pedestrians.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ae82693ed2b9b495780e5cd326f6bc736b0267fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Pedestrians.png"
    }
  },
  {
    "name": "No Smoking.png",
    "path": "Emojis/Symbols/No Smoking.png",
    "sha": "6ff856980a8ad40d4b636f61e423d3245e35f220",
    "size": 13897,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Smoking.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Smoking.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ff856980a8ad40d4b636f61e423d3245e35f220",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/No%20Smoking.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/No%20Smoking.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ff856980a8ad40d4b636f61e423d3245e35f220",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/No%20Smoking.png"
    }
  },
  {
    "name": "Non-Potable Water.png",
    "path": "Emojis/Symbols/Non-Potable Water.png",
    "sha": "59d7160deae2ac24bd85d354dfe3e5b10de97996",
    "size": 15602,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Non-Potable%20Water.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Non-Potable%20Water.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59d7160deae2ac24bd85d354dfe3e5b10de97996",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Non-Potable%20Water.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Non-Potable%20Water.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59d7160deae2ac24bd85d354dfe3e5b10de97996",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Non-Potable%20Water.png"
    }
  },
  {
    "name": "O Button (Blood Type).png",
    "path": "Emojis/Symbols/O Button (Blood Type).png",
    "sha": "5febf726915eac573dd98b17f933cf74d4e18bc2",
    "size": 14567,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/O%20Button%20(Blood%20Type).png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/O%20Button%20(Blood%20Type).png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5febf726915eac573dd98b17f933cf74d4e18bc2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/O%20Button%20(Blood%20Type).png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/O%20Button%20(Blood%20Type).png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5febf726915eac573dd98b17f933cf74d4e18bc2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/O%20Button%20(Blood%20Type).png"
    }
  },
  {
    "name": "OK Button.png",
    "path": "Emojis/Symbols/OK Button.png",
    "sha": "ec98f67ee5b2428cbea70fba56ddb45d25251504",
    "size": 15615,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/OK%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/OK%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ec98f67ee5b2428cbea70fba56ddb45d25251504",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/OK%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/OK%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ec98f67ee5b2428cbea70fba56ddb45d25251504",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/OK%20Button.png"
    }
  },
  {
    "name": "Om.png",
    "path": "Emojis/Symbols/Om.png",
    "sha": "f43208ca939548ce6dcd04536ccd0b3d96a47909",
    "size": 19234,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Om.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Om.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f43208ca939548ce6dcd04536ccd0b3d96a47909",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Om.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Om.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f43208ca939548ce6dcd04536ccd0b3d96a47909",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Om.png"
    }
  },
  {
    "name": "On! Arrow.png",
    "path": "Emojis/Symbols/On! Arrow.png",
    "sha": "6d36a9d0b6b763a3fadbe50f45b093f1f38b102f",
    "size": 12761,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/On!%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/On!%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d36a9d0b6b763a3fadbe50f45b093f1f38b102f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/On!%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/On!%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d36a9d0b6b763a3fadbe50f45b093f1f38b102f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/On!%20Arrow.png"
    }
  },
  {
    "name": "Ophiuchus.png",
    "path": "Emojis/Symbols/Ophiuchus.png",
    "sha": "c13189323c4b5c1291c074792c862b28fa2eea17",
    "size": 17635,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Ophiuchus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Ophiuchus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c13189323c4b5c1291c074792c862b28fa2eea17",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Ophiuchus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Ophiuchus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c13189323c4b5c1291c074792c862b28fa2eea17",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Ophiuchus.png"
    }
  },
  {
    "name": "Orange Circle.png",
    "path": "Emojis/Symbols/Orange Circle.png",
    "sha": "095e31b75a6e4b9d321994af928b6a37cd238d3b",
    "size": 16436,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orange%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orange%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/095e31b75a6e4b9d321994af928b6a37cd238d3b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Orange%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orange%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/095e31b75a6e4b9d321994af928b6a37cd238d3b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orange%20Circle.png"
    }
  },
  {
    "name": "Orange Square.png",
    "path": "Emojis/Symbols/Orange Square.png",
    "sha": "a047cb015b336f8f22ce85e3fc88a9a5fc0a7fd8",
    "size": 9662,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orange%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orange%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a047cb015b336f8f22ce85e3fc88a9a5fc0a7fd8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Orange%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orange%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a047cb015b336f8f22ce85e3fc88a9a5fc0a7fd8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orange%20Square.png"
    }
  },
  {
    "name": "Orthodox Cross.png",
    "path": "Emojis/Symbols/Orthodox Cross.png",
    "sha": "3df7744aef80b36f065f3df9d907b64bd27a4943",
    "size": 16253,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orthodox%20Cross.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orthodox%20Cross.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3df7744aef80b36f065f3df9d907b64bd27a4943",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Orthodox%20Cross.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Orthodox%20Cross.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3df7744aef80b36f065f3df9d907b64bd27a4943",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Orthodox%20Cross.png"
    }
  },
  {
    "name": "P Button.png",
    "path": "Emojis/Symbols/P Button.png",
    "sha": "7c69d4fb8f5cf3075947e79a578b016809e9113f",
    "size": 13868,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/P%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/P%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c69d4fb8f5cf3075947e79a578b016809e9113f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/P%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/P%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c69d4fb8f5cf3075947e79a578b016809e9113f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/P%20Button.png"
    }
  },
  {
    "name": "Part Alternation Mark.png",
    "path": "Emojis/Symbols/Part Alternation Mark.png",
    "sha": "a4073eca9f46137d3c0e6396c6a6e7a0d8b10139",
    "size": 8095,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Part%20Alternation%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Part%20Alternation%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a4073eca9f46137d3c0e6396c6a6e7a0d8b10139",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Part%20Alternation%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Part%20Alternation%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a4073eca9f46137d3c0e6396c6a6e7a0d8b10139",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Part%20Alternation%20Mark.png"
    }
  },
  {
    "name": "Passport Control.png",
    "path": "Emojis/Symbols/Passport Control.png",
    "sha": "20538ebf500d1e14f2dccc9db3692e91d6ce73bf",
    "size": 15855,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Passport%20Control.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Passport%20Control.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20538ebf500d1e14f2dccc9db3692e91d6ce73bf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Passport%20Control.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Passport%20Control.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/20538ebf500d1e14f2dccc9db3692e91d6ce73bf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Passport%20Control.png"
    }
  },
  {
    "name": "Pause Button.png",
    "path": "Emojis/Symbols/Pause Button.png",
    "sha": "15a21e331b6de569b024efb7e9b30911ddfbba87",
    "size": 13744,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pause%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pause%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/15a21e331b6de569b024efb7e9b30911ddfbba87",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Pause%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pause%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/15a21e331b6de569b024efb7e9b30911ddfbba87",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pause%20Button.png"
    }
  },
  {
    "name": "Peace Symbol.png",
    "path": "Emojis/Symbols/Peace Symbol.png",
    "sha": "65aa52a8db37aa3f7a55489d8e266cc93b44b9d7",
    "size": 19188,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Peace%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Peace%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/65aa52a8db37aa3f7a55489d8e266cc93b44b9d7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Peace%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Peace%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/65aa52a8db37aa3f7a55489d8e266cc93b44b9d7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Peace%20Symbol.png"
    }
  },
  {
    "name": "Pirate Flag.png",
    "path": "Emojis/Symbols/Pirate Flag.png",
    "sha": "f5d5210f7cfb3af1ccb2e10c369e27eb35c1410b",
    "size": 784103,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pirate%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pirate%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5d5210f7cfb3af1ccb2e10c369e27eb35c1410b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Pirate%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pirate%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5d5210f7cfb3af1ccb2e10c369e27eb35c1410b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pirate%20Flag.png"
    }
  },
  {
    "name": "Pisces.png",
    "path": "Emojis/Symbols/Pisces.png",
    "sha": "9a38abcd3952fad35ebc3df0528c4f8b3ee7a841",
    "size": 18181,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pisces.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pisces.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9a38abcd3952fad35ebc3df0528c4f8b3ee7a841",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Pisces.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Pisces.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9a38abcd3952fad35ebc3df0528c4f8b3ee7a841",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Pisces.png"
    }
  },
  {
    "name": "Place of Worship.png",
    "path": "Emojis/Symbols/Place of Worship.png",
    "sha": "3090b50ede0f982b698a1b6c7db4804d7c27a50e",
    "size": 17752,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Place%20of%20Worship.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Place%20of%20Worship.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3090b50ede0f982b698a1b6c7db4804d7c27a50e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Place%20of%20Worship.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Place%20of%20Worship.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3090b50ede0f982b698a1b6c7db4804d7c27a50e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Place%20of%20Worship.png"
    }
  },
  {
    "name": "Play Button.png",
    "path": "Emojis/Symbols/Play Button.png",
    "sha": "707a6eb5d465c456da12680dac92c85edac35871",
    "size": 13160,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Play%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Play%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/707a6eb5d465c456da12680dac92c85edac35871",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Play%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Play%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/707a6eb5d465c456da12680dac92c85edac35871",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Play%20Button.png"
    }
  },
  {
    "name": "Play or Pause Button.png",
    "path": "Emojis/Symbols/Play or Pause Button.png",
    "sha": "b49a92e47e8e2fe4ca91cd14752c49aca86c8905",
    "size": 14083,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Play%20or%20Pause%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Play%20or%20Pause%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b49a92e47e8e2fe4ca91cd14752c49aca86c8905",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Play%20or%20Pause%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Play%20or%20Pause%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b49a92e47e8e2fe4ca91cd14752c49aca86c8905",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Play%20or%20Pause%20Button.png"
    }
  },
  {
    "name": "Plus.png",
    "path": "Emojis/Symbols/Plus.png",
    "sha": "f9d1e436efba6988c65b1c7dde32c502994fd437",
    "size": 8918,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Plus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Plus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f9d1e436efba6988c65b1c7dde32c502994fd437",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Plus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Plus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f9d1e436efba6988c65b1c7dde32c502994fd437",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Plus.png"
    }
  },
  {
    "name": "Potable Water.png",
    "path": "Emojis/Symbols/Potable Water.png",
    "sha": "666c5dc4f8a19114393a1308f86a63e195e3f494",
    "size": 14900,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Potable%20Water.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Potable%20Water.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/666c5dc4f8a19114393a1308f86a63e195e3f494",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Potable%20Water.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Potable%20Water.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/666c5dc4f8a19114393a1308f86a63e195e3f494",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Potable%20Water.png"
    }
  },
  {
    "name": "Prohibited.png",
    "path": "Emojis/Symbols/Prohibited.png",
    "sha": "f5bb0bfc600ca8cde78bd961903cb683deec5c52",
    "size": 14473,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Prohibited.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Prohibited.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5bb0bfc600ca8cde78bd961903cb683deec5c52",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Prohibited.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Prohibited.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f5bb0bfc600ca8cde78bd961903cb683deec5c52",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Prohibited.png"
    }
  },
  {
    "name": "Purple Circle.png",
    "path": "Emojis/Symbols/Purple Circle.png",
    "sha": "beceb47a3ddc58896d372b2be0980428cda98163",
    "size": 16870,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Purple%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Purple%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/beceb47a3ddc58896d372b2be0980428cda98163",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Purple%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Purple%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/beceb47a3ddc58896d372b2be0980428cda98163",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Purple%20Circle.png"
    }
  },
  {
    "name": "Purple Square.png",
    "path": "Emojis/Symbols/Purple Square.png",
    "sha": "41ab9e5c6698405af2b074c78ba1b44439a02680",
    "size": 7893,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Purple%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Purple%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41ab9e5c6698405af2b074c78ba1b44439a02680",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Purple%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Purple%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41ab9e5c6698405af2b074c78ba1b44439a02680",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Purple%20Square.png"
    }
  },
  {
    "name": "Radio Button.png",
    "path": "Emojis/Symbols/Radio Button.png",
    "sha": "091c6915066d571bf66642dabb2f565e9e232202",
    "size": 16314,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Radio%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Radio%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/091c6915066d571bf66642dabb2f565e9e232202",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Radio%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Radio%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/091c6915066d571bf66642dabb2f565e9e232202",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Radio%20Button.png"
    }
  },
  {
    "name": "Radioactive.png",
    "path": "Emojis/Symbols/Radioactive.png",
    "sha": "eb453db8237569e2cce014afcffaace59ba4e4a4",
    "size": 15102,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Radioactive.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Radioactive.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb453db8237569e2cce014afcffaace59ba4e4a4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Radioactive.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Radioactive.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb453db8237569e2cce014afcffaace59ba4e4a4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Radioactive.png"
    }
  },
  {
    "name": "Record Button.png",
    "path": "Emojis/Symbols/Record Button.png",
    "sha": "c6c09945dbf5cb86c1fd4831e9b724330e1edbae",
    "size": 13429,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Record%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Record%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c6c09945dbf5cb86c1fd4831e9b724330e1edbae",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Record%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Record%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c6c09945dbf5cb86c1fd4831e9b724330e1edbae",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Record%20Button.png"
    }
  },
  {
    "name": "Recycling Symbol.png",
    "path": "Emojis/Symbols/Recycling Symbol.png",
    "sha": "2213b326ae5e90921b58356d6c7ecc3d55e3addd",
    "size": 11431,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Recycling%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Recycling%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2213b326ae5e90921b58356d6c7ecc3d55e3addd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Recycling%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Recycling%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2213b326ae5e90921b58356d6c7ecc3d55e3addd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Recycling%20Symbol.png"
    }
  },
  {
    "name": "Red Circle.png",
    "path": "Emojis/Symbols/Red Circle.png",
    "sha": "c317d95dbadcc2d84dd8b46c6ccfaa23777d8bd6",
    "size": 16821,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c317d95dbadcc2d84dd8b46c6ccfaa23777d8bd6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c317d95dbadcc2d84dd8b46c6ccfaa23777d8bd6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Circle.png"
    }
  },
  {
    "name": "Red Exclamation Mark.png",
    "path": "Emojis/Symbols/Red Exclamation Mark.png",
    "sha": "4c4caac46bb0b87522911fb85b9ce836661ae945",
    "size": 4415,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Exclamation%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Exclamation%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c4caac46bb0b87522911fb85b9ce836661ae945",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Exclamation%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Exclamation%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4c4caac46bb0b87522911fb85b9ce836661ae945",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Exclamation%20Mark.png"
    }
  },
  {
    "name": "Red Question Mark.png",
    "path": "Emojis/Symbols/Red Question Mark.png",
    "sha": "5b6867b63198fe7ac869bfc6ab65de2df26ed052",
    "size": 8286,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Question%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Question%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5b6867b63198fe7ac869bfc6ab65de2df26ed052",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Question%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Question%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5b6867b63198fe7ac869bfc6ab65de2df26ed052",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Question%20Mark.png"
    }
  },
  {
    "name": "Red Square.png",
    "path": "Emojis/Symbols/Red Square.png",
    "sha": "9cf8f166b8cb50b93d820f20ba89d5ebb3626c64",
    "size": 9566,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cf8f166b8cb50b93d820f20ba89d5ebb3626c64",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cf8f166b8cb50b93d820f20ba89d5ebb3626c64",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Square.png"
    }
  },
  {
    "name": "Red Triangle Pointed Down.png",
    "path": "Emojis/Symbols/Red Triangle Pointed Down.png",
    "sha": "a34b2467946e47a811113018660ecc818bfe69de",
    "size": 4366,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Triangle%20Pointed%20Down.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Down.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a34b2467946e47a811113018660ecc818bfe69de",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Down.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Triangle%20Pointed%20Down.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a34b2467946e47a811113018660ecc818bfe69de",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Down.png"
    }
  },
  {
    "name": "Red Triangle Pointed Up.png",
    "path": "Emojis/Symbols/Red Triangle Pointed Up.png",
    "sha": "9ec926f88890b237913c9327d1957cc27502d793",
    "size": 4370,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Triangle%20Pointed%20Up.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Up.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ec926f88890b237913c9327d1957cc27502d793",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Up.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Red%20Triangle%20Pointed%20Up.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ec926f88890b237913c9327d1957cc27502d793",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Red%20Triangle%20Pointed%20Up.png"
    }
  },
  {
    "name": "Registered.png",
    "path": "Emojis/Symbols/Registered.png",
    "sha": "2041879642363760fffbe3bef9e66c244c0daf8e",
    "size": 14761,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Registered.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Registered.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2041879642363760fffbe3bef9e66c244c0daf8e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Registered.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Registered.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2041879642363760fffbe3bef9e66c244c0daf8e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Registered.png"
    }
  },
  {
    "name": "Repeat Button.png",
    "path": "Emojis/Symbols/Repeat Button.png",
    "sha": "2283e15239081a7701eb3cec957bd77f1ce0f52a",
    "size": 14854,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Repeat%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Repeat%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2283e15239081a7701eb3cec957bd77f1ce0f52a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Repeat%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Repeat%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2283e15239081a7701eb3cec957bd77f1ce0f52a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Repeat%20Button.png"
    }
  },
  {
    "name": "Repeat Single Button.png",
    "path": "Emojis/Symbols/Repeat Single Button.png",
    "sha": "60e32d8350d21235c1c273eeebc15fada2f8e91c",
    "size": 15590,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Repeat%20Single%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Repeat%20Single%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/60e32d8350d21235c1c273eeebc15fada2f8e91c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Repeat%20Single%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Repeat%20Single%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/60e32d8350d21235c1c273eeebc15fada2f8e91c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Repeat%20Single%20Button.png"
    }
  },
  {
    "name": "Restroom.png",
    "path": "Emojis/Symbols/Restroom.png",
    "sha": "5ad40d5381ab2ec145931fa03bd7314c95a0575e",
    "size": 16630,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Restroom.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Restroom.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5ad40d5381ab2ec145931fa03bd7314c95a0575e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Restroom.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Restroom.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5ad40d5381ab2ec145931fa03bd7314c95a0575e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Restroom.png"
    }
  },
  {
    "name": "Reverse Button.png",
    "path": "Emojis/Symbols/Reverse Button.png",
    "sha": "4d59ccd841490da174d0a21ea83ce1ae1c6e5e77",
    "size": 13634,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Reverse%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Reverse%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d59ccd841490da174d0a21ea83ce1ae1c6e5e77",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Reverse%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Reverse%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4d59ccd841490da174d0a21ea83ce1ae1c6e5e77",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Reverse%20Button.png"
    }
  },
  {
    "name": "Right Arrow Curving Down.png",
    "path": "Emojis/Symbols/Right Arrow Curving Down.png",
    "sha": "b070e939ddb3016a6aa23f640deb8ae269b350c2",
    "size": 14015,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Down.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Down.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b070e939ddb3016a6aa23f640deb8ae269b350c2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Right%20Arrow%20Curving%20Down.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Down.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b070e939ddb3016a6aa23f640deb8ae269b350c2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Down.png"
    }
  },
  {
    "name": "Right Arrow Curving Left.png",
    "path": "Emojis/Symbols/Right Arrow Curving Left.png",
    "sha": "eda7693a703f9f33966cca25a1a211a13f9da281",
    "size": 14458,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Left.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Left.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eda7693a703f9f33966cca25a1a211a13f9da281",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Right%20Arrow%20Curving%20Left.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Left.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eda7693a703f9f33966cca25a1a211a13f9da281",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Left.png"
    }
  },
  {
    "name": "Right Arrow Curving Up.png",
    "path": "Emojis/Symbols/Right Arrow Curving Up.png",
    "sha": "87905c06b480f631b0447fb306c83b3c399c1607",
    "size": 14289,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Up.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Up.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87905c06b480f631b0447fb306c83b3c399c1607",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Right%20Arrow%20Curving%20Up.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow%20Curving%20Up.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87905c06b480f631b0447fb306c83b3c399c1607",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow%20Curving%20Up.png"
    }
  },
  {
    "name": "Right Arrow.png",
    "path": "Emojis/Symbols/Right Arrow.png",
    "sha": "4968765a84fd1a8913f9e316de720d42f5e2b478",
    "size": 13331,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4968765a84fd1a8913f9e316de720d42f5e2b478",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Right%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Right%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4968765a84fd1a8913f9e316de720d42f5e2b478",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Right%20Arrow.png"
    }
  },
  {
    "name": "SOS Button.png",
    "path": "Emojis/Symbols/SOS Button.png",
    "sha": "834f6cf31950f3c7ca7d370c326a771f3dbe7f98",
    "size": 15489,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/SOS%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/SOS%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/834f6cf31950f3c7ca7d370c326a771f3dbe7f98",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/SOS%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/SOS%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/834f6cf31950f3c7ca7d370c326a771f3dbe7f98",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/SOS%20Button.png"
    }
  },
  {
    "name": "Sagittarius.png",
    "path": "Emojis/Symbols/Sagittarius.png",
    "sha": "9bfdf0fc6b1ba9516f98a62bade8e68c3b5307fa",
    "size": 16592,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Sagittarius.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Sagittarius.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9bfdf0fc6b1ba9516f98a62bade8e68c3b5307fa",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Sagittarius.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Sagittarius.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9bfdf0fc6b1ba9516f98a62bade8e68c3b5307fa",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Sagittarius.png"
    }
  },
  {
    "name": "Scorpio.png",
    "path": "Emojis/Symbols/Scorpio.png",
    "sha": "334bced4701c0c7c4336dac382a6bcc65c600b83",
    "size": 17902,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Scorpio.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Scorpio.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/334bced4701c0c7c4336dac382a6bcc65c600b83",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Scorpio.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Scorpio.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/334bced4701c0c7c4336dac382a6bcc65c600b83",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Scorpio.png"
    }
  },
  {
    "name": "Shuffle Tracks Button.png",
    "path": "Emojis/Symbols/Shuffle Tracks Button.png",
    "sha": "0dc54b4e4e72890b9cde157a64964b3ba564992e",
    "size": 15724,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Shuffle%20Tracks%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Shuffle%20Tracks%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0dc54b4e4e72890b9cde157a64964b3ba564992e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Shuffle%20Tracks%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Shuffle%20Tracks%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0dc54b4e4e72890b9cde157a64964b3ba564992e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Shuffle%20Tracks%20Button.png"
    }
  },
  {
    "name": "Small Blue Diamond.png",
    "path": "Emojis/Symbols/Small Blue Diamond.png",
    "sha": "0156125a546fecbe731ad18dbdfd8fdc88e9385d",
    "size": 3589,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Small%20Blue%20Diamond.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Small%20Blue%20Diamond.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0156125a546fecbe731ad18dbdfd8fdc88e9385d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Small%20Blue%20Diamond.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Small%20Blue%20Diamond.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0156125a546fecbe731ad18dbdfd8fdc88e9385d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Small%20Blue%20Diamond.png"
    }
  },
  {
    "name": "Small Orange Diamond.png",
    "path": "Emojis/Symbols/Small Orange Diamond.png",
    "sha": "ac41aa497b636733e1c6b4d529505a082c8ceb9f",
    "size": 3567,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Small%20Orange%20Diamond.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Small%20Orange%20Diamond.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac41aa497b636733e1c6b4d529505a082c8ceb9f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Small%20Orange%20Diamond.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Small%20Orange%20Diamond.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac41aa497b636733e1c6b4d529505a082c8ceb9f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Small%20Orange%20Diamond.png"
    }
  },
  {
    "name": "Soon Arrow.png",
    "path": "Emojis/Symbols/Soon Arrow.png",
    "sha": "709c5c39a2c0c7200ff4ade0c583f99158ac8b26",
    "size": 15912,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Soon%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Soon%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/709c5c39a2c0c7200ff4ade0c583f99158ac8b26",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Soon%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Soon%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/709c5c39a2c0c7200ff4ade0c583f99158ac8b26",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Soon%20Arrow.png"
    }
  },
  {
    "name": "Sparkle.png",
    "path": "Emojis/Symbols/Sparkle.png",
    "sha": "bd4e64054a8407fe7efe6e91c311c5989795c432",
    "size": 14342,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Sparkle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Sparkle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd4e64054a8407fe7efe6e91c311c5989795c432",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Sparkle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Sparkle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd4e64054a8407fe7efe6e91c311c5989795c432",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Sparkle.png"
    }
  },
  {
    "name": "Star and Crescent.png",
    "path": "Emojis/Symbols/Star and Crescent.png",
    "sha": "a6dc736b3e6b2875b884f38fd2037baaa01b0fc9",
    "size": 16933,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Star%20and%20Crescent.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Star%20and%20Crescent.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a6dc736b3e6b2875b884f38fd2037baaa01b0fc9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Star%20and%20Crescent.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Star%20and%20Crescent.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a6dc736b3e6b2875b884f38fd2037baaa01b0fc9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Star%20and%20Crescent.png"
    }
  },
  {
    "name": "Star of David.png",
    "path": "Emojis/Symbols/Star of David.png",
    "sha": "d582a6b5592380fc2c64d3e262dc59eb9ea95baf",
    "size": 18628,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Star%20of%20David.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Star%20of%20David.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d582a6b5592380fc2c64d3e262dc59eb9ea95baf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Star%20of%20David.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Star%20of%20David.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d582a6b5592380fc2c64d3e262dc59eb9ea95baf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Star%20of%20David.png"
    }
  },
  {
    "name": "Stop Button.png",
    "path": "Emojis/Symbols/Stop Button.png",
    "sha": "440a7e4c2021a9a4a4b9a951fc3054b734dd6577",
    "size": 12246,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Stop%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Stop%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/440a7e4c2021a9a4a4b9a951fc3054b734dd6577",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Stop%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Stop%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/440a7e4c2021a9a4a4b9a951fc3054b734dd6577",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Stop%20Button.png"
    }
  },
  {
    "name": "Taurus.png",
    "path": "Emojis/Symbols/Taurus.png",
    "sha": "67e5c254a423b5b88be77bd73f6d8e5cece541ea",
    "size": 18457,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Taurus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Taurus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/67e5c254a423b5b88be77bd73f6d8e5cece541ea",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Taurus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Taurus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/67e5c254a423b5b88be77bd73f6d8e5cece541ea",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Taurus.png"
    }
  },
  {
    "name": "Top Arrow.png",
    "path": "Emojis/Symbols/Top Arrow.png",
    "sha": "6befcff427d3f2be2cd1de1db6ce18b5a5c45e70",
    "size": 10864,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Top%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Top%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6befcff427d3f2be2cd1de1db6ce18b5a5c45e70",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Top%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Top%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6befcff427d3f2be2cd1de1db6ce18b5a5c45e70",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Top%20Arrow.png"
    }
  },
  {
    "name": "Trade Mark.png",
    "path": "Emojis/Symbols/Trade Mark.png",
    "sha": "78073c36daf44f275936420b582194b8a6924c48",
    "size": 5365,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Trade%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Trade%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/78073c36daf44f275936420b582194b8a6924c48",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Trade%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Trade%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/78073c36daf44f275936420b582194b8a6924c48",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Trade%20Mark.png"
    }
  },
  {
    "name": "Transgender Symbol.png",
    "path": "Emojis/Symbols/Transgender Symbol.png",
    "sha": "4ab2249b515d10fe389fc1a2a54a48d8422dea36",
    "size": 18498,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Transgender%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Transgender%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab2249b515d10fe389fc1a2a54a48d8422dea36",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Transgender%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Transgender%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab2249b515d10fe389fc1a2a54a48d8422dea36",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Transgender%20Symbol.png"
    }
  },
  {
    "name": "Triangular Flag.png",
    "path": "Emojis/Symbols/Triangular Flag.png",
    "sha": "6b9b54b0cb142b767dc06b790b6bf793cab52f9d",
    "size": 536160,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Triangular%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Triangular%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b9b54b0cb142b767dc06b790b6bf793cab52f9d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Triangular%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Triangular%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6b9b54b0cb142b767dc06b790b6bf793cab52f9d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Triangular%20Flag.png"
    }
  },
  {
    "name": "Trident Emblem.png",
    "path": "Emojis/Symbols/Trident Emblem.png",
    "sha": "171f053234f284f7b99369992aa7ebbf5c22ea2f",
    "size": 12901,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Trident%20Emblem.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Trident%20Emblem.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/171f053234f284f7b99369992aa7ebbf5c22ea2f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Trident%20Emblem.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Trident%20Emblem.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/171f053234f284f7b99369992aa7ebbf5c22ea2f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Trident%20Emblem.png"
    }
  },
  {
    "name": "Up Arrow.png",
    "path": "Emojis/Symbols/Up Arrow.png",
    "sha": "7c6850aa7e383c219efb1f1a84e7634f0e13fb2c",
    "size": 14046,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c6850aa7e383c219efb1f1a84e7634f0e13fb2c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Up%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c6850aa7e383c219efb1f1a84e7634f0e13fb2c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up%20Arrow.png"
    }
  },
  {
    "name": "Up! Button.png",
    "path": "Emojis/Symbols/Up! Button.png",
    "sha": "99fe68f9617817a5b43bf169f5abbb8deb218416",
    "size": 15244,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up!%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up!%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/99fe68f9617817a5b43bf169f5abbb8deb218416",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Up!%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up!%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/99fe68f9617817a5b43bf169f5abbb8deb218416",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up!%20Button.png"
    }
  },
  {
    "name": "Up-Down Arrow.png",
    "path": "Emojis/Symbols/Up-Down Arrow.png",
    "sha": "9cee8a06050a1929ba33ba2729900c601a039bf7",
    "size": 14764,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Down%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Down%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cee8a06050a1929ba33ba2729900c601a039bf7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Up-Down%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Down%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9cee8a06050a1929ba33ba2729900c601a039bf7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Down%20Arrow.png"
    }
  },
  {
    "name": "Up-Left Arrow.png",
    "path": "Emojis/Symbols/Up-Left Arrow.png",
    "sha": "0f0b625e6f3192c655b4bef2827365120b42308d",
    "size": 14086,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Left%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Left%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f0b625e6f3192c655b4bef2827365120b42308d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Up-Left%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Left%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f0b625e6f3192c655b4bef2827365120b42308d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Left%20Arrow.png"
    }
  },
  {
    "name": "Up-Right Arrow.png",
    "path": "Emojis/Symbols/Up-Right Arrow.png",
    "sha": "6f002767a1bc8ff9830908ce1fc7881b02bac214",
    "size": 13921,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Right%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Right%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6f002767a1bc8ff9830908ce1fc7881b02bac214",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Up-Right%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Up-Right%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6f002767a1bc8ff9830908ce1fc7881b02bac214",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Up-Right%20Arrow.png"
    }
  },
  {
    "name": "Upwards Button.png",
    "path": "Emojis/Symbols/Upwards Button.png",
    "sha": "0773b1c0df456589aacdac0ffaab934be2511068",
    "size": 13403,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Upwards%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Upwards%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0773b1c0df456589aacdac0ffaab934be2511068",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Upwards%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Upwards%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0773b1c0df456589aacdac0ffaab934be2511068",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Upwards%20Button.png"
    }
  },
  {
    "name": "Vibration Mode.png",
    "path": "Emojis/Symbols/Vibration Mode.png",
    "sha": "d5aaa309c7d99f35902b68d22272e3c615904ec0",
    "size": 14971,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Vibration%20Mode.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Vibration%20Mode.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5aaa309c7d99f35902b68d22272e3c615904ec0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Vibration%20Mode.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Vibration%20Mode.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5aaa309c7d99f35902b68d22272e3c615904ec0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Vibration%20Mode.png"
    }
  },
  {
    "name": "Virgo.png",
    "path": "Emojis/Symbols/Virgo.png",
    "sha": "e6cf885cd247a2c116c2d311cd158e57d85ccbc9",
    "size": 18676,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Virgo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Virgo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6cf885cd247a2c116c2d311cd158e57d85ccbc9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Virgo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Virgo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6cf885cd247a2c116c2d311cd158e57d85ccbc9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Virgo.png"
    }
  },
  {
    "name": "Vs Button.png",
    "path": "Emojis/Symbols/Vs Button.png",
    "sha": "1ead8b56e6d22e1ccdaf8d1fc1e56e62dca4d2bd",
    "size": 14373,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Vs%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Vs%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1ead8b56e6d22e1ccdaf8d1fc1e56e62dca4d2bd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Vs%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Vs%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1ead8b56e6d22e1ccdaf8d1fc1e56e62dca4d2bd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Vs%20Button.png"
    }
  },
  {
    "name": "Warning.png",
    "path": "Emojis/Symbols/Warning.png",
    "sha": "91b82a77cfdedcc7b794b5075068fcc889b29b8a",
    "size": 8747,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Warning.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Warning.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91b82a77cfdedcc7b794b5075068fcc889b29b8a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Warning.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Warning.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91b82a77cfdedcc7b794b5075068fcc889b29b8a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Warning.png"
    }
  },
  {
    "name": "Water Closet.png",
    "path": "Emojis/Symbols/Water Closet.png",
    "sha": "fa21e72cc0c830f1f8f9ded4c2726a1d0e1f1260",
    "size": 15114,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Water%20Closet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Water%20Closet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fa21e72cc0c830f1f8f9ded4c2726a1d0e1f1260",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Water%20Closet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Water%20Closet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fa21e72cc0c830f1f8f9ded4c2726a1d0e1f1260",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Water%20Closet.png"
    }
  },
  {
    "name": "Wavy Dash.png",
    "path": "Emojis/Symbols/Wavy Dash.png",
    "sha": "586708bf07e7890a2209fb520637e16102fae2b8",
    "size": 6394,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wavy%20Dash.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wavy%20Dash.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/586708bf07e7890a2209fb520637e16102fae2b8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Wavy%20Dash.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wavy%20Dash.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/586708bf07e7890a2209fb520637e16102fae2b8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wavy%20Dash.png"
    }
  },
  {
    "name": "Wheel of Dharma.png",
    "path": "Emojis/Symbols/Wheel of Dharma.png",
    "sha": "6511c534b6c5314a6d294307b2652514d09813e9",
    "size": 20744,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wheel%20of%20Dharma.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wheel%20of%20Dharma.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6511c534b6c5314a6d294307b2652514d09813e9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Wheel%20of%20Dharma.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wheel%20of%20Dharma.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6511c534b6c5314a6d294307b2652514d09813e9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wheel%20of%20Dharma.png"
    }
  },
  {
    "name": "Wheelchair Symbol.png",
    "path": "Emojis/Symbols/Wheelchair Symbol.png",
    "sha": "2785c86693985cc6dfc49758c10161bab5afb3d9",
    "size": 16292,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wheelchair%20Symbol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wheelchair%20Symbol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2785c86693985cc6dfc49758c10161bab5afb3d9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Wheelchair%20Symbol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Wheelchair%20Symbol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2785c86693985cc6dfc49758c10161bab5afb3d9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Wheelchair%20Symbol.png"
    }
  },
  {
    "name": "White Circle.png",
    "path": "Emojis/Symbols/White Circle.png",
    "sha": "caa4612e03d274a4c23880e3005346caf32d2eec",
    "size": 15102,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/caa4612e03d274a4c23880e3005346caf32d2eec",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/caa4612e03d274a4c23880e3005346caf32d2eec",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Circle.png"
    }
  },
  {
    "name": "White Exclamation Mark.png",
    "path": "Emojis/Symbols/White Exclamation Mark.png",
    "sha": "421e67a0477247ecd2b1a46d8bb60d79c6e775d0",
    "size": 4784,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Exclamation%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Exclamation%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/421e67a0477247ecd2b1a46d8bb60d79c6e775d0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Exclamation%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Exclamation%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/421e67a0477247ecd2b1a46d8bb60d79c6e775d0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Exclamation%20Mark.png"
    }
  },
  {
    "name": "White Flag.png",
    "path": "Emojis/Symbols/White Flag.png",
    "sha": "073306d967258b6d8b399cf0a998b800ad0b8ecc",
    "size": 635003,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/073306d967258b6d8b399cf0a998b800ad0b8ecc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/073306d967258b6d8b399cf0a998b800ad0b8ecc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Flag.png"
    }
  },
  {
    "name": "White Large Square.png",
    "path": "Emojis/Symbols/White Large Square.png",
    "sha": "3e87952d9a122df64bea0ea4936ae926ec468ed8",
    "size": 10457,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Large%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Large%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e87952d9a122df64bea0ea4936ae926ec468ed8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Large%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Large%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e87952d9a122df64bea0ea4936ae926ec468ed8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Large%20Square.png"
    }
  },
  {
    "name": "White Medium Square.png",
    "path": "Emojis/Symbols/White Medium Square.png",
    "sha": "c7852cac26acd9140419759c860aeb84072ca9c7",
    "size": 8137,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Medium%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Medium%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c7852cac26acd9140419759c860aeb84072ca9c7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Medium%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Medium%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c7852cac26acd9140419759c860aeb84072ca9c7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Medium%20Square.png"
    }
  },
  {
    "name": "White Medium-Small Square.png",
    "path": "Emojis/Symbols/White Medium-Small Square.png",
    "sha": "d4ee35d3518b3703710c54ae762097700bb59452",
    "size": 5852,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Medium-Small%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Medium-Small%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d4ee35d3518b3703710c54ae762097700bb59452",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Medium-Small%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Medium-Small%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d4ee35d3518b3703710c54ae762097700bb59452",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Medium-Small%20Square.png"
    }
  },
  {
    "name": "White Question Mark.png",
    "path": "Emojis/Symbols/White Question Mark.png",
    "sha": "ef7525b75bf6f93dd9e5be4746279bdcdcc95c90",
    "size": 8467,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Question%20Mark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Question%20Mark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef7525b75bf6f93dd9e5be4746279bdcdcc95c90",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Question%20Mark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Question%20Mark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef7525b75bf6f93dd9e5be4746279bdcdcc95c90",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Question%20Mark.png"
    }
  },
  {
    "name": "White Small Square.png",
    "path": "Emojis/Symbols/White Small Square.png",
    "sha": "b559eed168021ad46c0a67f91437a5a47bf8ba6a",
    "size": 3870,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Small%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Small%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b559eed168021ad46c0a67f91437a5a47bf8ba6a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Small%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Small%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b559eed168021ad46c0a67f91437a5a47bf8ba6a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Small%20Square.png"
    }
  },
  {
    "name": "White Square Button.png",
    "path": "Emojis/Symbols/White Square Button.png",
    "sha": "c32edb5259a10ee955309ad75ffccb0509f30d50",
    "size": 11441,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Square%20Button.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Square%20Button.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c32edb5259a10ee955309ad75ffccb0509f30d50",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/White%20Square%20Button.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/White%20Square%20Button.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c32edb5259a10ee955309ad75ffccb0509f30d50",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/White%20Square%20Button.png"
    }
  },
  {
    "name": "Women’s Room.png",
    "path": "Emojis/Symbols/Women’s Room.png",
    "sha": "4b5b031061dd812ce80dd83562e888b5981e697a",
    "size": 17845,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Women%E2%80%99s%20Room.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Women%E2%80%99s%20Room.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b5b031061dd812ce80dd83562e888b5981e697a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Women’s Room.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Women%E2%80%99s%20Room.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b5b031061dd812ce80dd83562e888b5981e697a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Women%E2%80%99s%20Room.png"
    }
  },
  {
    "name": "Yellow Circle.png",
    "path": "Emojis/Symbols/Yellow Circle.png",
    "sha": "295b07ce6a6e2b84d5be80d5cf0702cb5c03b133",
    "size": 16707,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yellow%20Circle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yellow%20Circle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/295b07ce6a6e2b84d5be80d5cf0702cb5c03b133",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Yellow%20Circle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yellow%20Circle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/295b07ce6a6e2b84d5be80d5cf0702cb5c03b133",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yellow%20Circle.png"
    }
  },
  {
    "name": "Yellow Square.png",
    "path": "Emojis/Symbols/Yellow Square.png",
    "sha": "e76761a99ba4be0b37c785c1e5dd2bc7623ce0fb",
    "size": 8999,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yellow%20Square.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yellow%20Square.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e76761a99ba4be0b37c785c1e5dd2bc7623ce0fb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Yellow%20Square.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yellow%20Square.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e76761a99ba4be0b37c785c1e5dd2bc7623ce0fb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yellow%20Square.png"
    }
  },
  {
    "name": "Yin Yang.png",
    "path": "Emojis/Symbols/Yin Yang.png",
    "sha": "7350a819de2300d96a0523880a804f44957ec2b1",
    "size": 18063,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yin%20Yang.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yin%20Yang.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7350a819de2300d96a0523880a804f44957ec2b1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Symbols/Yin%20Yang.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Symbols/Yin%20Yang.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7350a819de2300d96a0523880a804f44957ec2b1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Symbols/Yin%20Yang.png"
    }
  }
]

export default {
  id: 'symbols',
  name: 'Symbols',
  emojis: customEmojis.map((emoji) => {
    return {
      id: emoji.sha,
      name: emoji.name,
      skins: [{ src: emoji.download_url }]
    }
  })
}