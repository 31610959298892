import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { post } from "../services/api";

export default function ServiceCreate({ isModalOpen, setIsModalOpen, onServiceCreate}) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values, 
      endDate: values.endDate ? moment(values.endDate.unix()*1000).endOf('day') : null
    };
    if(!values.endDate) {
      delete data.endDate;
    }
    const service = await post('/services', data);
    setIsModalOpen(false);
    onServiceCreate(service);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return <Modal title="Add Service" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addServiceForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addServiceForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input service name!',
          }]}>
          <Input autoFocus={true} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Rate"
          name="rate"
          rules={[{
            required: true,
            message: 'Please input service rate!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Quantity"
          name="quantity"
          rules={[{
            required: true,
            message: 'Please input service quantity!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Renew"
          name="period"
          >
          <Select>
            <Select.Option value="year">yearly</Select.Option>
            <Select.Option value="quarter">quaterly</Select.Option>
            <Select.Option value="month">monthly</Select.Option>
          </Select>
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}