const customEmojis = [
  {
    "name": "Aerial Tramway.png",
    "path": "Emojis/Travel and places/Aerial Tramway.png",
    "sha": "0a94631b4258859266a77392cae412a3e522c45f",
    "size": 542717,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Aerial%20Tramway.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Aerial%20Tramway.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a94631b4258859266a77392cae412a3e522c45f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Aerial%20Tramway.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Aerial%20Tramway.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0a94631b4258859266a77392cae412a3e522c45f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Aerial%20Tramway.png"
    }
  },
  {
    "name": "Airplane Arrival.png",
    "path": "Emojis/Travel and places/Airplane Arrival.png",
    "sha": "11bf37458b673ee141d7c68d944bb6c9ec0672ab",
    "size": 727501,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane%20Arrival.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane%20Arrival.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11bf37458b673ee141d7c68d944bb6c9ec0672ab",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Airplane%20Arrival.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane%20Arrival.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11bf37458b673ee141d7c68d944bb6c9ec0672ab",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane%20Arrival.png"
    }
  },
  {
    "name": "Airplane Departure.png",
    "path": "Emojis/Travel and places/Airplane Departure.png",
    "sha": "757add447783d3a73a0dcc0f90997c970c7799e8",
    "size": 686567,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane%20Departure.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane%20Departure.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/757add447783d3a73a0dcc0f90997c970c7799e8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Airplane%20Departure.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane%20Departure.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/757add447783d3a73a0dcc0f90997c970c7799e8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane%20Departure.png"
    }
  },
  {
    "name": "Airplane.png",
    "path": "Emojis/Travel and places/Airplane.png",
    "sha": "29d9776130881564afd8dc058f7e0eea55e36c3c",
    "size": 800650,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/29d9776130881564afd8dc058f7e0eea55e36c3c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Airplane.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Airplane.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/29d9776130881564afd8dc058f7e0eea55e36c3c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Airplane.png"
    }
  },
  {
    "name": "Alarm Clock.png",
    "path": "Emojis/Travel and places/Alarm Clock.png",
    "sha": "bb6004fbf06c42b6c95d2da9a58a8dbee1499be5",
    "size": 474236,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Alarm%20Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Alarm%20Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb6004fbf06c42b6c95d2da9a58a8dbee1499be5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Alarm%20Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Alarm%20Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb6004fbf06c42b6c95d2da9a58a8dbee1499be5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Alarm%20Clock.png"
    }
  },
  {
    "name": "Ambulance.png",
    "path": "Emojis/Travel and places/Ambulance.png",
    "sha": "0fc88c3380e99da65b759e555bc2a59e97c94e5c",
    "size": 768366,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ambulance.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ambulance.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0fc88c3380e99da65b759e555bc2a59e97c94e5c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ambulance.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ambulance.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0fc88c3380e99da65b759e555bc2a59e97c94e5c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ambulance.png"
    }
  },
  {
    "name": "Anchor.png",
    "path": "Emojis/Travel and places/Anchor.png",
    "sha": "af472f4bfe9d79d0ecfc0e02e0deeeba84a8f172",
    "size": 9962,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Anchor.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Anchor.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af472f4bfe9d79d0ecfc0e02e0deeeba84a8f172",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Anchor.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Anchor.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af472f4bfe9d79d0ecfc0e02e0deeeba84a8f172",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Anchor.png"
    }
  },
  {
    "name": "Articulated Lorry.png",
    "path": "Emojis/Travel and places/Articulated Lorry.png",
    "sha": "e18f4a298ae7460d05da916ebbb7ef295bb5b2c8",
    "size": 931524,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Articulated%20Lorry.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Articulated%20Lorry.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e18f4a298ae7460d05da916ebbb7ef295bb5b2c8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Articulated%20Lorry.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Articulated%20Lorry.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e18f4a298ae7460d05da916ebbb7ef295bb5b2c8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Articulated%20Lorry.png"
    }
  },
  {
    "name": "Auto Rickshaw.png",
    "path": "Emojis/Travel and places/Auto Rickshaw.png",
    "sha": "0228872c5c4df7fa385893deb1cecf9892d2b30d",
    "size": 703522,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Auto%20Rickshaw.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Auto%20Rickshaw.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0228872c5c4df7fa385893deb1cecf9892d2b30d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Auto%20Rickshaw.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Auto%20Rickshaw.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0228872c5c4df7fa385893deb1cecf9892d2b30d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Auto%20Rickshaw.png"
    }
  },
  {
    "name": "Automobile.png",
    "path": "Emojis/Travel and places/Automobile.png",
    "sha": "df73f76808c051e021924226b5f29d14e83bb403",
    "size": 723614,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Automobile.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Automobile.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df73f76808c051e021924226b5f29d14e83bb403",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Automobile.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Automobile.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df73f76808c051e021924226b5f29d14e83bb403",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Automobile.png"
    }
  },
  {
    "name": "Bank.png",
    "path": "Emojis/Travel and places/Bank.png",
    "sha": "3aac7a3d5d16bcca5094ec1062dbf70acd35009b",
    "size": 19757,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bank.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bank.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3aac7a3d5d16bcca5094ec1062dbf70acd35009b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bank.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bank.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3aac7a3d5d16bcca5094ec1062dbf70acd35009b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bank.png"
    }
  },
  {
    "name": "Barber Pole.png",
    "path": "Emojis/Travel and places/Barber Pole.png",
    "sha": "89e58fc33e5583623ce0453bf0af2f6bfbd6fb53",
    "size": 574683,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Barber%20Pole.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Barber%20Pole.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89e58fc33e5583623ce0453bf0af2f6bfbd6fb53",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Barber%20Pole.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Barber%20Pole.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89e58fc33e5583623ce0453bf0af2f6bfbd6fb53",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Barber%20Pole.png"
    }
  },
  {
    "name": "Beach with Umbrella.png",
    "path": "Emojis/Travel and places/Beach with Umbrella.png",
    "sha": "b066c596b38d6b2196828f3402b1e30ac50ba484",
    "size": 13591,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Beach%20with%20Umbrella.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Beach%20with%20Umbrella.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b066c596b38d6b2196828f3402b1e30ac50ba484",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Beach%20with%20Umbrella.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Beach%20with%20Umbrella.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b066c596b38d6b2196828f3402b1e30ac50ba484",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Beach%20with%20Umbrella.png"
    }
  },
  {
    "name": "Bellhop Bell.png",
    "path": "Emojis/Travel and places/Bellhop Bell.png",
    "sha": "18df320d1caca847f21b34110ec8f737a66cfdcd",
    "size": 12893,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bellhop%20Bell.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bellhop%20Bell.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18df320d1caca847f21b34110ec8f737a66cfdcd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bellhop%20Bell.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bellhop%20Bell.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18df320d1caca847f21b34110ec8f737a66cfdcd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bellhop%20Bell.png"
    }
  },
  {
    "name": "Bicycle.png",
    "path": "Emojis/Travel and places/Bicycle.png",
    "sha": "897e0ca3bdaecbe41e0258c3a857281456a56377",
    "size": 10996,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bicycle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bicycle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/897e0ca3bdaecbe41e0258c3a857281456a56377",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bicycle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bicycle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/897e0ca3bdaecbe41e0258c3a857281456a56377",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bicycle.png"
    }
  },
  {
    "name": "Brick.png",
    "path": "Emojis/Travel and places/Brick.png",
    "sha": "9d1dd6ea536af72cd947e11664f5ea3a3610e5d5",
    "size": 17333,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Brick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Brick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d1dd6ea536af72cd947e11664f5ea3a3610e5d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Brick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Brick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9d1dd6ea536af72cd947e11664f5ea3a3610e5d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Brick.png"
    }
  },
  {
    "name": "Bridge at Night.png",
    "path": "Emojis/Travel and places/Bridge at Night.png",
    "sha": "16b226e5a51791ff068022dea9c933b6ee8cc411",
    "size": 16516,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bridge%20at%20Night.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bridge%20at%20Night.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/16b226e5a51791ff068022dea9c933b6ee8cc411",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bridge%20at%20Night.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bridge%20at%20Night.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/16b226e5a51791ff068022dea9c933b6ee8cc411",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bridge%20at%20Night.png"
    }
  },
  {
    "name": "Building Construction.png",
    "path": "Emojis/Travel and places/Building Construction.png",
    "sha": "46c289b186162bbd681b43d405063490e22806e7",
    "size": 10590,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Building%20Construction.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Building%20Construction.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/46c289b186162bbd681b43d405063490e22806e7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Building%20Construction.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Building%20Construction.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/46c289b186162bbd681b43d405063490e22806e7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Building%20Construction.png"
    }
  },
  {
    "name": "Bullet Train.png",
    "path": "Emojis/Travel and places/Bullet Train.png",
    "sha": "3a55c45cf1f87ce775384db5c4a5fd7c9ed8a4b8",
    "size": 11817,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bullet%20Train.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bullet%20Train.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3a55c45cf1f87ce775384db5c4a5fd7c9ed8a4b8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bullet%20Train.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bullet%20Train.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3a55c45cf1f87ce775384db5c4a5fd7c9ed8a4b8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bullet%20Train.png"
    }
  },
  {
    "name": "Bus Stop.png",
    "path": "Emojis/Travel and places/Bus Stop.png",
    "sha": "3e4460db7bc00988581543668410c447fec284ac",
    "size": 13224,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bus%20Stop.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bus%20Stop.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e4460db7bc00988581543668410c447fec284ac",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bus%20Stop.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bus%20Stop.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e4460db7bc00988581543668410c447fec284ac",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bus%20Stop.png"
    }
  },
  {
    "name": "Bus.png",
    "path": "Emojis/Travel and places/Bus.png",
    "sha": "bf569da9678cf674456c3a8ccf70fdca8cc149a1",
    "size": 961182,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bf569da9678cf674456c3a8ccf70fdca8cc149a1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Bus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Bus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bf569da9678cf674456c3a8ccf70fdca8cc149a1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Bus.png"
    }
  },
  {
    "name": "Camping.png",
    "path": "Emojis/Travel and places/Camping.png",
    "sha": "d5528fca8a6caed3e955144356fa18497514213f",
    "size": 14488,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Camping.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Camping.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5528fca8a6caed3e955144356fa18497514213f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Camping.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Camping.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5528fca8a6caed3e955144356fa18497514213f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Camping.png"
    }
  },
  {
    "name": "Canoe.png",
    "path": "Emojis/Travel and places/Canoe.png",
    "sha": "301862bac96ed6f3153ba5507750dabec79fcb74",
    "size": 517341,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Canoe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Canoe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/301862bac96ed6f3153ba5507750dabec79fcb74",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Canoe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Canoe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/301862bac96ed6f3153ba5507750dabec79fcb74",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Canoe.png"
    }
  },
  {
    "name": "Carousel Horse.png",
    "path": "Emojis/Travel and places/Carousel Horse.png",
    "sha": "3e1fb1bf0fc8020f03f8b8a5b2bec80ca2784063",
    "size": 14228,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Carousel%20Horse.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Carousel%20Horse.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e1fb1bf0fc8020f03f8b8a5b2bec80ca2784063",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Carousel%20Horse.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Carousel%20Horse.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e1fb1bf0fc8020f03f8b8a5b2bec80ca2784063",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Carousel%20Horse.png"
    }
  },
  {
    "name": "Castle.png",
    "path": "Emojis/Travel and places/Castle.png",
    "sha": "41ad0fc21be73125b63c5c1346d2bbba1478e493",
    "size": 13672,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Castle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Castle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41ad0fc21be73125b63c5c1346d2bbba1478e493",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Castle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Castle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41ad0fc21be73125b63c5c1346d2bbba1478e493",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Castle.png"
    }
  },
  {
    "name": "Church.png",
    "path": "Emojis/Travel and places/Church.png",
    "sha": "bfecdda7502b0eb1efb0fb98336c207add395fc1",
    "size": 13195,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Church.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Church.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfecdda7502b0eb1efb0fb98336c207add395fc1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Church.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Church.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfecdda7502b0eb1efb0fb98336c207add395fc1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Church.png"
    }
  },
  {
    "name": "Circus Tent.png",
    "path": "Emojis/Travel and places/Circus Tent.png",
    "sha": "2321cedcf3d4503572ee5628994cb7e4960459be",
    "size": 12930,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Circus%20Tent.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Circus%20Tent.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2321cedcf3d4503572ee5628994cb7e4960459be",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Circus%20Tent.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Circus%20Tent.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2321cedcf3d4503572ee5628994cb7e4960459be",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Circus%20Tent.png"
    }
  },
  {
    "name": "Cityscape at Dusk.png",
    "path": "Emojis/Travel and places/Cityscape at Dusk.png",
    "sha": "64c08a1474eee02bab42803935115cd2ec0fa036",
    "size": 14117,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cityscape%20at%20Dusk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cityscape%20at%20Dusk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/64c08a1474eee02bab42803935115cd2ec0fa036",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cityscape%20at%20Dusk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cityscape%20at%20Dusk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/64c08a1474eee02bab42803935115cd2ec0fa036",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cityscape%20at%20Dusk.png"
    }
  },
  {
    "name": "Cityscape.png",
    "path": "Emojis/Travel and places/Cityscape.png",
    "sha": "f250c2496e349a6c7684a0b4a6b29881fbdfad19",
    "size": 16182,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cityscape.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cityscape.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f250c2496e349a6c7684a0b4a6b29881fbdfad19",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cityscape.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cityscape.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f250c2496e349a6c7684a0b4a6b29881fbdfad19",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cityscape.png"
    }
  },
  {
    "name": "Classical Building.png",
    "path": "Emojis/Travel and places/Classical Building.png",
    "sha": "8c8e3a4b125faa221fbd7016ccf5f818222af716",
    "size": 16383,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Classical%20Building.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Classical%20Building.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c8e3a4b125faa221fbd7016ccf5f818222af716",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Classical%20Building.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Classical%20Building.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c8e3a4b125faa221fbd7016ccf5f818222af716",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Classical%20Building.png"
    }
  },
  {
    "name": "Closed Umbrella.png",
    "path": "Emojis/Travel and places/Closed Umbrella.png",
    "sha": "5c06e39d6b221c327b012c977dc73a7c96eb40a3",
    "size": 723387,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Closed%20Umbrella.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Closed%20Umbrella.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c06e39d6b221c327b012c977dc73a7c96eb40a3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Closed%20Umbrella.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Closed%20Umbrella.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5c06e39d6b221c327b012c977dc73a7c96eb40a3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Closed%20Umbrella.png"
    }
  },
  {
    "name": "Cloud with Lightning and Rain.png",
    "path": "Emojis/Travel and places/Cloud with Lightning and Rain.png",
    "sha": "2536321402c5157b5e087d0150a1a3c7bb875cfd",
    "size": 1130829,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Lightning%20and%20Rain.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning%20and%20Rain.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2536321402c5157b5e087d0150a1a3c7bb875cfd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning%20and%20Rain.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Lightning%20and%20Rain.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2536321402c5157b5e087d0150a1a3c7bb875cfd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning%20and%20Rain.png"
    }
  },
  {
    "name": "Cloud with Lightning.png",
    "path": "Emojis/Travel and places/Cloud with Lightning.png",
    "sha": "373b6bd41ba4a485bc40a3e0a7bceb8f92e027ec",
    "size": 913344,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Lightning.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/373b6bd41ba4a485bc40a3e0a7bceb8f92e027ec",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Lightning.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/373b6bd41ba4a485bc40a3e0a7bceb8f92e027ec",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Lightning.png"
    }
  },
  {
    "name": "Cloud with Rain.png",
    "path": "Emojis/Travel and places/Cloud with Rain.png",
    "sha": "e734dc2cd71c8a77af87807e7d5798b3099faad2",
    "size": 890019,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Rain.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Rain.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e734dc2cd71c8a77af87807e7d5798b3099faad2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud%20with%20Rain.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Rain.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e734dc2cd71c8a77af87807e7d5798b3099faad2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Rain.png"
    }
  },
  {
    "name": "Cloud with Snow.png",
    "path": "Emojis/Travel and places/Cloud with Snow.png",
    "sha": "c5b41755cc8e546a5a542787ce9cf0ec8e158782",
    "size": 997919,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c5b41755cc8e546a5a542787ce9cf0ec8e158782",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c5b41755cc8e546a5a542787ce9cf0ec8e158782",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud%20with%20Snow.png"
    }
  },
  {
    "name": "Cloud.png",
    "path": "Emojis/Travel and places/Cloud.png",
    "sha": "e9983edb6733bd79cb14dcbbdc7d87b272ffa60f",
    "size": 755053,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9983edb6733bd79cb14dcbbdc7d87b272ffa60f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cloud.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cloud.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9983edb6733bd79cb14dcbbdc7d87b272ffa60f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cloud.png"
    }
  },
  {
    "name": "Comet.png",
    "path": "Emojis/Travel and places/Comet.png",
    "sha": "b80211e1b4528515347188dd33fdc30c41a882cd",
    "size": 664676,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Comet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Comet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b80211e1b4528515347188dd33fdc30c41a882cd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Comet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Comet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b80211e1b4528515347188dd33fdc30c41a882cd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Comet.png"
    }
  },
  {
    "name": "Compass.png",
    "path": "Emojis/Travel and places/Compass.png",
    "sha": "540d9abcfc3f7d2c7982be1e01b463e99ae2c134",
    "size": 1179448,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Compass.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Compass.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/540d9abcfc3f7d2c7982be1e01b463e99ae2c134",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Compass.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Compass.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/540d9abcfc3f7d2c7982be1e01b463e99ae2c134",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Compass.png"
    }
  },
  {
    "name": "Construction.png",
    "path": "Emojis/Travel and places/Construction.png",
    "sha": "a8ec4834d4a8578d0efafe6457bda8d2ff733890",
    "size": 8078,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Construction.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Construction.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a8ec4834d4a8578d0efafe6457bda8d2ff733890",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Construction.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Construction.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a8ec4834d4a8578d0efafe6457bda8d2ff733890",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Construction.png"
    }
  },
  {
    "name": "Convenience Store.png",
    "path": "Emojis/Travel and places/Convenience Store.png",
    "sha": "c9f97d21e4656f404da802410f2f8b4e3eeb2bc7",
    "size": 11817,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Convenience%20Store.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Convenience%20Store.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c9f97d21e4656f404da802410f2f8b4e3eeb2bc7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Convenience%20Store.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Convenience%20Store.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c9f97d21e4656f404da802410f2f8b4e3eeb2bc7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Convenience%20Store.png"
    }
  },
  {
    "name": "Crescent Moon.png",
    "path": "Emojis/Travel and places/Crescent Moon.png",
    "sha": "79b4f2681e85b9fc4148aa242efa454fe1fe12c6",
    "size": 9930,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Crescent%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Crescent%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79b4f2681e85b9fc4148aa242efa454fe1fe12c6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Crescent%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Crescent%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/79b4f2681e85b9fc4148aa242efa454fe1fe12c6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Crescent%20Moon.png"
    }
  },
  {
    "name": "Cyclone.png",
    "path": "Emojis/Travel and places/Cyclone.png",
    "sha": "9c9a1d3b1ff116d89d3abcc640079e647f62df60",
    "size": 1262965,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cyclone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cyclone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c9a1d3b1ff116d89d3abcc640079e647f62df60",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Cyclone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Cyclone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9c9a1d3b1ff116d89d3abcc640079e647f62df60",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Cyclone.png"
    }
  },
  {
    "name": "Delivery Truck.png",
    "path": "Emojis/Travel and places/Delivery Truck.png",
    "sha": "0d714e2ef11298760cec85dad7c462ae388bd34d",
    "size": 917764,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Delivery%20Truck.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Delivery%20Truck.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d714e2ef11298760cec85dad7c462ae388bd34d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Delivery%20Truck.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Delivery%20Truck.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d714e2ef11298760cec85dad7c462ae388bd34d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Delivery%20Truck.png"
    }
  },
  {
    "name": "Department Store.png",
    "path": "Emojis/Travel and places/Department Store.png",
    "sha": "299341e6903317c68d7432c916f17ccc2a3a36ff",
    "size": 13137,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Department%20Store.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Department%20Store.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/299341e6903317c68d7432c916f17ccc2a3a36ff",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Department%20Store.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Department%20Store.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/299341e6903317c68d7432c916f17ccc2a3a36ff",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Department%20Store.png"
    }
  },
  {
    "name": "Derelict House.png",
    "path": "Emojis/Travel and places/Derelict House.png",
    "sha": "ce0951a69cdb7f6453d41f88cb2c19b21bc2999b",
    "size": 14056,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Derelict%20House.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Derelict%20House.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce0951a69cdb7f6453d41f88cb2c19b21bc2999b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Derelict%20House.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Derelict%20House.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce0951a69cdb7f6453d41f88cb2c19b21bc2999b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Derelict%20House.png"
    }
  },
  {
    "name": "Desert Island.png",
    "path": "Emojis/Travel and places/Desert Island.png",
    "sha": "eb05fe4c285b7faaec865bd59058b4cbee758b8b",
    "size": 11490,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Desert%20Island.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Desert%20Island.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb05fe4c285b7faaec865bd59058b4cbee758b8b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Desert%20Island.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Desert%20Island.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eb05fe4c285b7faaec865bd59058b4cbee758b8b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Desert%20Island.png"
    }
  },
  {
    "name": "Desert.png",
    "path": "Emojis/Travel and places/Desert.png",
    "sha": "fb2a9cc5528c0ca2b1e6cfd0b91a4c23885e6d2d",
    "size": 17913,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Desert.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Desert.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb2a9cc5528c0ca2b1e6cfd0b91a4c23885e6d2d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Desert.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Desert.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb2a9cc5528c0ca2b1e6cfd0b91a4c23885e6d2d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Desert.png"
    }
  },
  {
    "name": "Droplet.png",
    "path": "Emojis/Travel and places/Droplet.png",
    "sha": "2fe80adfd4f318e8c8b456918ccb8c79b44cd199",
    "size": 324840,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Droplet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Droplet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fe80adfd4f318e8c8b456918ccb8c79b44cd199",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Droplet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Droplet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fe80adfd4f318e8c8b456918ccb8c79b44cd199",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Droplet.png"
    }
  },
  {
    "name": "Eight O’Clock.png",
    "path": "Emojis/Travel and places/Eight O’Clock.png",
    "sha": "1078d4e5788af73061053c30b8d3905ec401f2b4",
    "size": 774729,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eight%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eight%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1078d4e5788af73061053c30b8d3905ec401f2b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Eight O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eight%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1078d4e5788af73061053c30b8d3905ec401f2b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eight%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Eight-Thirty.png",
    "path": "Emojis/Travel and places/Eight-Thirty.png",
    "sha": "d1bf697e43d61a105c69b39e884bcd1a6aadecbe",
    "size": 1011659,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eight-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eight-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1bf697e43d61a105c69b39e884bcd1a6aadecbe",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Eight-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eight-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1bf697e43d61a105c69b39e884bcd1a6aadecbe",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eight-Thirty.png"
    }
  },
  {
    "name": "Eleven O’Clock.png",
    "path": "Emojis/Travel and places/Eleven O’Clock.png",
    "sha": "15308b75ce20656824ec66493404d5487e220cac",
    "size": 990680,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eleven%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eleven%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/15308b75ce20656824ec66493404d5487e220cac",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Eleven O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eleven%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/15308b75ce20656824ec66493404d5487e220cac",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eleven%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Eleven-Thirty.png",
    "path": "Emojis/Travel and places/Eleven-Thirty.png",
    "sha": "b09627cf0a82326fdc1a8547879c4470a3e6a241",
    "size": 891919,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eleven-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eleven-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b09627cf0a82326fdc1a8547879c4470a3e6a241",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Eleven-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Eleven-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b09627cf0a82326fdc1a8547879c4470a3e6a241",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Eleven-Thirty.png"
    }
  },
  {
    "name": "Factory.png",
    "path": "Emojis/Travel and places/Factory.png",
    "sha": "277bfa26e1677eef1e3ad6071169e51712315054",
    "size": 11051,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Factory.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Factory.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/277bfa26e1677eef1e3ad6071169e51712315054",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Factory.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Factory.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/277bfa26e1677eef1e3ad6071169e51712315054",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Factory.png"
    }
  },
  {
    "name": "Ferris Wheel.png",
    "path": "Emojis/Travel and places/Ferris Wheel.png",
    "sha": "b329aff79f8575d13052b668b62fbef72c8160dc",
    "size": 1016388,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ferris%20Wheel.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ferris%20Wheel.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b329aff79f8575d13052b668b62fbef72c8160dc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ferris%20Wheel.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ferris%20Wheel.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b329aff79f8575d13052b668b62fbef72c8160dc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ferris%20Wheel.png"
    }
  },
  {
    "name": "Ferry.png",
    "path": "Emojis/Travel and places/Ferry.png",
    "sha": "70b617091bb90280fc17eba0ccf4ea6cbb8eca87",
    "size": 966943,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ferry.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ferry.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/70b617091bb90280fc17eba0ccf4ea6cbb8eca87",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ferry.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ferry.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/70b617091bb90280fc17eba0ccf4ea6cbb8eca87",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ferry.png"
    }
  },
  {
    "name": "Fire Engine.png",
    "path": "Emojis/Travel and places/Fire Engine.png",
    "sha": "1c17a2c13da2f2ac406e4c0a9f966111aa94f6a2",
    "size": 818047,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fire%20Engine.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fire%20Engine.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c17a2c13da2f2ac406e4c0a9f966111aa94f6a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Fire%20Engine.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fire%20Engine.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c17a2c13da2f2ac406e4c0a9f966111aa94f6a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fire%20Engine.png"
    }
  },
  {
    "name": "Fire.png",
    "path": "Emojis/Travel and places/Fire.png",
    "sha": "893568a9cb14345a39398c5e2df91b395a8848bb",
    "size": 609594,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fire.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fire.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/893568a9cb14345a39398c5e2df91b395a8848bb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Fire.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fire.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/893568a9cb14345a39398c5e2df91b395a8848bb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fire.png"
    }
  },
  {
    "name": "First Quarter Moon Face.png",
    "path": "Emojis/Travel and places/First Quarter Moon Face.png",
    "sha": "c3318812bf106f0bcc36d5748defee1704341c10",
    "size": 769395,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/First%20Quarter%20Moon%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c3318812bf106f0bcc36d5748defee1704341c10",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/First%20Quarter%20Moon%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c3318812bf106f0bcc36d5748defee1704341c10",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon%20Face.png"
    }
  },
  {
    "name": "First Quarter Moon.png",
    "path": "Emojis/Travel and places/First Quarter Moon.png",
    "sha": "78ab7dfb9810f39fe8350658d0c51fd45a99fc02",
    "size": 17443,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/First%20Quarter%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/78ab7dfb9810f39fe8350658d0c51fd45a99fc02",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/First%20Quarter%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/78ab7dfb9810f39fe8350658d0c51fd45a99fc02",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/First%20Quarter%20Moon.png"
    }
  },
  {
    "name": "Five O’Clock.png",
    "path": "Emojis/Travel and places/Five O’Clock.png",
    "sha": "e2832f5c29477d9f7f55fdab7d6f152e582eca9e",
    "size": 855977,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Five%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Five%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2832f5c29477d9f7f55fdab7d6f152e582eca9e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Five O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Five%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2832f5c29477d9f7f55fdab7d6f152e582eca9e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Five%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Five-Thirty.png",
    "path": "Emojis/Travel and places/Five-Thirty.png",
    "sha": "3951085c23eb1660ba6d58def9f4ee54f20fb9ed",
    "size": 755811,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Five-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Five-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3951085c23eb1660ba6d58def9f4ee54f20fb9ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Five-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Five-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3951085c23eb1660ba6d58def9f4ee54f20fb9ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Five-Thirty.png"
    }
  },
  {
    "name": "Flying Saucer.png",
    "path": "Emojis/Travel and places/Flying Saucer.png",
    "sha": "f636626c5972a625485508ae094d117c9b118f0e",
    "size": 756587,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Flying%20Saucer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Flying%20Saucer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f636626c5972a625485508ae094d117c9b118f0e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Flying%20Saucer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Flying%20Saucer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f636626c5972a625485508ae094d117c9b118f0e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Flying%20Saucer.png"
    }
  },
  {
    "name": "Fog.png",
    "path": "Emojis/Travel and places/Fog.png",
    "sha": "b6d213e1018d57a0abf0ee7ff318bd3897a3ef47",
    "size": 833079,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fog.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fog.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b6d213e1018d57a0abf0ee7ff318bd3897a3ef47",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Fog.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fog.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b6d213e1018d57a0abf0ee7ff318bd3897a3ef47",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fog.png"
    }
  },
  {
    "name": "Foggy.png",
    "path": "Emojis/Travel and places/Foggy.png",
    "sha": "136aaf188b83bb512a3ac469139270df98381644",
    "size": 15556,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Foggy.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Foggy.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/136aaf188b83bb512a3ac469139270df98381644",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Foggy.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Foggy.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/136aaf188b83bb512a3ac469139270df98381644",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Foggy.png"
    }
  },
  {
    "name": "Fountain.png",
    "path": "Emojis/Travel and places/Fountain.png",
    "sha": "944f3a9d93af165469045ef5276a8fe50b136bdb",
    "size": 115105,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fountain.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fountain.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/944f3a9d93af165469045ef5276a8fe50b136bdb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Fountain.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fountain.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/944f3a9d93af165469045ef5276a8fe50b136bdb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fountain.png"
    }
  },
  {
    "name": "Four O’Clock.png",
    "path": "Emojis/Travel and places/Four O’Clock.png",
    "sha": "bdb74cff7e419c134527113edced11cdf032cb2c",
    "size": 972365,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Four%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Four%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdb74cff7e419c134527113edced11cdf032cb2c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Four O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Four%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdb74cff7e419c134527113edced11cdf032cb2c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Four%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Four-Thirty.png",
    "path": "Emojis/Travel and places/Four-Thirty.png",
    "sha": "0bf2eb2d7eefdfbb4ef3f61d1f897361a8c2937e",
    "size": 958745,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Four-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Four-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0bf2eb2d7eefdfbb4ef3f61d1f897361a8c2937e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Four-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Four-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0bf2eb2d7eefdfbb4ef3f61d1f897361a8c2937e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Four-Thirty.png"
    }
  },
  {
    "name": "Fuel Pump.png",
    "path": "Emojis/Travel and places/Fuel Pump.png",
    "sha": "2a5958f3ff177c51daa180aa4aef20c8da31c918",
    "size": 7997,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fuel%20Pump.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fuel%20Pump.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2a5958f3ff177c51daa180aa4aef20c8da31c918",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Fuel%20Pump.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Fuel%20Pump.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2a5958f3ff177c51daa180aa4aef20c8da31c918",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Fuel%20Pump.png"
    }
  },
  {
    "name": "Full Moon Face.png",
    "path": "Emojis/Travel and places/Full Moon Face.png",
    "sha": "8ac3006b06b6e397d0cefe29e9cfcb13f465e0fe",
    "size": 1325803,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Full%20Moon%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Full%20Moon%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ac3006b06b6e397d0cefe29e9cfcb13f465e0fe",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Full%20Moon%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Full%20Moon%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ac3006b06b6e397d0cefe29e9cfcb13f465e0fe",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Full%20Moon%20Face.png"
    }
  },
  {
    "name": "Full Moon.png",
    "path": "Emojis/Travel and places/Full Moon.png",
    "sha": "cf064218c54e3cff8fd7b36bd165870a13ccac6e",
    "size": 18264,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Full%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Full%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cf064218c54e3cff8fd7b36bd165870a13ccac6e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Full%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Full%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cf064218c54e3cff8fd7b36bd165870a13ccac6e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Full%20Moon.png"
    }
  },
  {
    "name": "Globe Showing Americas.png",
    "path": "Emojis/Travel and places/Globe Showing Americas.png",
    "sha": "b7f8949226bad3f7fdee9abfff88606394624aaa",
    "size": 17112,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Americas.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Americas.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b7f8949226bad3f7fdee9abfff88606394624aaa",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Globe%20Showing%20Americas.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Americas.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b7f8949226bad3f7fdee9abfff88606394624aaa",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Americas.png"
    }
  },
  {
    "name": "Globe Showing Asia-Australia.png",
    "path": "Emojis/Travel and places/Globe Showing Asia-Australia.png",
    "sha": "860b4c4d5dbcc2e8ce728d8316d7429afb65631e",
    "size": 17252,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Asia-Australia.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Asia-Australia.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/860b4c4d5dbcc2e8ce728d8316d7429afb65631e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Globe%20Showing%20Asia-Australia.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Asia-Australia.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/860b4c4d5dbcc2e8ce728d8316d7429afb65631e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Asia-Australia.png"
    }
  },
  {
    "name": "Globe Showing Europe-Africa.png",
    "path": "Emojis/Travel and places/Globe Showing Europe-Africa.png",
    "sha": "2217289ce3138d144e8267338e3f3caf378eeac4",
    "size": 17226,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Europe-Africa.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Europe-Africa.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2217289ce3138d144e8267338e3f3caf378eeac4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Globe%20Showing%20Europe-Africa.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20Showing%20Europe-Africa.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2217289ce3138d144e8267338e3f3caf378eeac4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20Showing%20Europe-Africa.png"
    }
  },
  {
    "name": "Globe with Meridians.png",
    "path": "Emojis/Travel and places/Globe with Meridians.png",
    "sha": "d0d3e4df14cc499ef9e737768cb377f5b33905a7",
    "size": 17560,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20with%20Meridians.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20with%20Meridians.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0d3e4df14cc499ef9e737768cb377f5b33905a7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Globe%20with%20Meridians.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Globe%20with%20Meridians.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0d3e4df14cc499ef9e737768cb377f5b33905a7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Globe%20with%20Meridians.png"
    }
  },
  {
    "name": "Glowing Star.png",
    "path": "Emojis/Travel and places/Glowing Star.png",
    "sha": "f3acca20221503df88998f0320fb0e822c545e0d",
    "size": 730512,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Glowing%20Star.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Glowing%20Star.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f3acca20221503df88998f0320fb0e822c545e0d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Glowing%20Star.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Glowing%20Star.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f3acca20221503df88998f0320fb0e822c545e0d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Glowing%20Star.png"
    }
  },
  {
    "name": "Heavy Equals Sign.png",
    "path": "Emojis/Travel and places/Heavy Equals Sign.png",
    "sha": "d44b6bd16199a119d390b9eee4d9195b37dc7997",
    "size": 16578,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Heavy%20Equals%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Heavy%20Equals%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d44b6bd16199a119d390b9eee4d9195b37dc7997",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Heavy%20Equals%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Heavy%20Equals%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d44b6bd16199a119d390b9eee4d9195b37dc7997",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Heavy%20Equals%20Sign.png"
    }
  },
  {
    "name": "Helicopter.png",
    "path": "Emojis/Travel and places/Helicopter.png",
    "sha": "4de1cb03be3db4cf661f3d076056f746e623931e",
    "size": 696432,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Helicopter.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Helicopter.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4de1cb03be3db4cf661f3d076056f746e623931e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Helicopter.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Helicopter.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4de1cb03be3db4cf661f3d076056f746e623931e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Helicopter.png"
    }
  },
  {
    "name": "High Voltage.png",
    "path": "Emojis/Travel and places/High Voltage.png",
    "sha": "d0747151d1e860c508b63bbfaf0ff858081dd1c0",
    "size": 349444,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/High%20Voltage.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/High%20Voltage.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0747151d1e860c508b63bbfaf0ff858081dd1c0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/High%20Voltage.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/High%20Voltage.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d0747151d1e860c508b63bbfaf0ff858081dd1c0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/High%20Voltage.png"
    }
  },
  {
    "name": "High-Speed Train.png",
    "path": "Emojis/Travel and places/High-Speed Train.png",
    "sha": "2c1ec50ff0a75001278e7977376a70e51183f26b",
    "size": 10562,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/High-Speed%20Train.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/High-Speed%20Train.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c1ec50ff0a75001278e7977376a70e51183f26b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/High-Speed%20Train.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/High-Speed%20Train.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c1ec50ff0a75001278e7977376a70e51183f26b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/High-Speed%20Train.png"
    }
  },
  {
    "name": "Hindu Temple.png",
    "path": "Emojis/Travel and places/Hindu Temple.png",
    "sha": "6d1ecab1c8ebb2eb201f16ceeab6d8f8fd29a002",
    "size": 12588,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hindu%20Temple.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hindu%20Temple.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d1ecab1c8ebb2eb201f16ceeab6d8f8fd29a002",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hindu%20Temple.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hindu%20Temple.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d1ecab1c8ebb2eb201f16ceeab6d8f8fd29a002",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hindu%20Temple.png"
    }
  },
  {
    "name": "Horizontal Traffic Light.png",
    "path": "Emojis/Travel and places/Horizontal Traffic Light.png",
    "sha": "d1ce8061f8b79fecbc5c0c04f677fe5a3974bd4b",
    "size": 8831,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Horizontal%20Traffic%20Light.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Horizontal%20Traffic%20Light.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1ce8061f8b79fecbc5c0c04f677fe5a3974bd4b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Horizontal%20Traffic%20Light.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Horizontal%20Traffic%20Light.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1ce8061f8b79fecbc5c0c04f677fe5a3974bd4b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Horizontal%20Traffic%20Light.png"
    }
  },
  {
    "name": "Hospital.png",
    "path": "Emojis/Travel and places/Hospital.png",
    "sha": "1debea3507699f39f0b8dbc292fbdbf1d957c8b5",
    "size": 12805,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hospital.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hospital.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1debea3507699f39f0b8dbc292fbdbf1d957c8b5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hospital.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hospital.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1debea3507699f39f0b8dbc292fbdbf1d957c8b5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hospital.png"
    }
  },
  {
    "name": "Hot Springs.png",
    "path": "Emojis/Travel and places/Hot Springs.png",
    "sha": "2c5004f76e7c1b2f105f1ec087c3ce601e2cdda1",
    "size": 11488,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hot%20Springs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hot%20Springs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c5004f76e7c1b2f105f1ec087c3ce601e2cdda1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hot%20Springs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hot%20Springs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c5004f76e7c1b2f105f1ec087c3ce601e2cdda1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hot%20Springs.png"
    }
  },
  {
    "name": "Hotel.png",
    "path": "Emojis/Travel and places/Hotel.png",
    "sha": "ed269db2e7a9c2d3f9e9e2a029133e991df89c6c",
    "size": 16638,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hotel.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hotel.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ed269db2e7a9c2d3f9e9e2a029133e991df89c6c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hotel.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hotel.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ed269db2e7a9c2d3f9e9e2a029133e991df89c6c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hotel.png"
    }
  },
  {
    "name": "Hourglass Done.png",
    "path": "Emojis/Travel and places/Hourglass Done.png",
    "sha": "284f453178b9587ea45bed76cad8509411537a0c",
    "size": 776408,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hourglass%20Done.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hourglass%20Done.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/284f453178b9587ea45bed76cad8509411537a0c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hourglass%20Done.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hourglass%20Done.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/284f453178b9587ea45bed76cad8509411537a0c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hourglass%20Done.png"
    }
  },
  {
    "name": "Hourglass Not Done.png",
    "path": "Emojis/Travel and places/Hourglass Not Done.png",
    "sha": "2730dd56048c718b9af8ded42944388f5b8a3d01",
    "size": 775052,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hourglass%20Not%20Done.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hourglass%20Not%20Done.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2730dd56048c718b9af8ded42944388f5b8a3d01",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hourglass%20Not%20Done.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hourglass%20Not%20Done.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2730dd56048c718b9af8ded42944388f5b8a3d01",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hourglass%20Not%20Done.png"
    }
  },
  {
    "name": "House with Garden.png",
    "path": "Emojis/Travel and places/House with Garden.png",
    "sha": "b034ec3694621baca18e7a39e7454e8e0fa93ef1",
    "size": 12340,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/House%20with%20Garden.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/House%20with%20Garden.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b034ec3694621baca18e7a39e7454e8e0fa93ef1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/House%20with%20Garden.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/House%20with%20Garden.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b034ec3694621baca18e7a39e7454e8e0fa93ef1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/House%20with%20Garden.png"
    }
  },
  {
    "name": "House.png",
    "path": "Emojis/Travel and places/House.png",
    "sha": "cd9bc4e3e63942bf28b84970c67cf8d6619b5d7c",
    "size": 12772,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/House.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/House.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd9bc4e3e63942bf28b84970c67cf8d6619b5d7c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/House.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/House.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd9bc4e3e63942bf28b84970c67cf8d6619b5d7c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/House.png"
    }
  },
  {
    "name": "Houses.png",
    "path": "Emojis/Travel and places/Houses.png",
    "sha": "ae2939f3384c2fce547a3ae5b057dfb5d2e8b9c3",
    "size": 15917,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Houses.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Houses.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ae2939f3384c2fce547a3ae5b057dfb5d2e8b9c3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Houses.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Houses.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ae2939f3384c2fce547a3ae5b057dfb5d2e8b9c3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Houses.png"
    }
  },
  {
    "name": "Hut.png",
    "path": "Emojis/Travel and places/Hut.png",
    "sha": "188b08b15957d0057a9b55283675a14972f24266",
    "size": 739941,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hut.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hut.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/188b08b15957d0057a9b55283675a14972f24266",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Hut.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Hut.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/188b08b15957d0057a9b55283675a14972f24266",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Hut.png"
    }
  },
  {
    "name": "Japanese Castle.png",
    "path": "Emojis/Travel and places/Japanese Castle.png",
    "sha": "692af67208b5129906ae7c8b790a23d1257c894c",
    "size": 14131,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Japanese%20Castle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Japanese%20Castle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/692af67208b5129906ae7c8b790a23d1257c894c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Japanese%20Castle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Japanese%20Castle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/692af67208b5129906ae7c8b790a23d1257c894c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Japanese%20Castle.png"
    }
  },
  {
    "name": "Japanese Post Office.png",
    "path": "Emojis/Travel and places/Japanese Post Office.png",
    "sha": "b34df50bdec8d2108b8581f4c9eafecdeaac0a39",
    "size": 14217,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Japanese%20Post%20Office.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Japanese%20Post%20Office.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b34df50bdec8d2108b8581f4c9eafecdeaac0a39",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Japanese%20Post%20Office.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Japanese%20Post%20Office.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b34df50bdec8d2108b8581f4c9eafecdeaac0a39",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Japanese%20Post%20Office.png"
    }
  },
  {
    "name": "Kaaba.png",
    "path": "Emojis/Travel and places/Kaaba.png",
    "sha": "00eebbabf25106ee429930313c24054d91a30c34",
    "size": 14852,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Kaaba.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Kaaba.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00eebbabf25106ee429930313c24054d91a30c34",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Kaaba.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Kaaba.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00eebbabf25106ee429930313c24054d91a30c34",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Kaaba.png"
    }
  },
  {
    "name": "Kick Scooter.png",
    "path": "Emojis/Travel and places/Kick Scooter.png",
    "sha": "4b6dd8249a839a7863753b5eee174d8472f602ff",
    "size": 5005,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Kick%20Scooter.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Kick%20Scooter.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b6dd8249a839a7863753b5eee174d8472f602ff",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Kick%20Scooter.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Kick%20Scooter.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b6dd8249a839a7863753b5eee174d8472f602ff",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Kick%20Scooter.png"
    }
  },
  {
    "name": "Last Quarter Moon Face.png",
    "path": "Emojis/Travel and places/Last Quarter Moon Face.png",
    "sha": "7cbff6262e3a0c551c9a04f17e54d653d364e49f",
    "size": 747810,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Last%20Quarter%20Moon%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7cbff6262e3a0c551c9a04f17e54d653d364e49f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Last%20Quarter%20Moon%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7cbff6262e3a0c551c9a04f17e54d653d364e49f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon%20Face.png"
    }
  },
  {
    "name": "Last Quarter Moon.png",
    "path": "Emojis/Travel and places/Last Quarter Moon.png",
    "sha": "7f8881a9b944fe23bb0cc5227f003a2bc0232d8f",
    "size": 17381,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Last%20Quarter%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7f8881a9b944fe23bb0cc5227f003a2bc0232d8f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Last%20Quarter%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7f8881a9b944fe23bb0cc5227f003a2bc0232d8f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Last%20Quarter%20Moon.png"
    }
  },
  {
    "name": "Light Rail.png",
    "path": "Emojis/Travel and places/Light Rail.png",
    "sha": "09c6df8b1cf9c781e2d906276f50e36b44542416",
    "size": 657724,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Light%20Rail.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Light%20Rail.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/09c6df8b1cf9c781e2d906276f50e36b44542416",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Light%20Rail.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Light%20Rail.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/09c6df8b1cf9c781e2d906276f50e36b44542416",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Light%20Rail.png"
    }
  },
  {
    "name": "Locomotive.png",
    "path": "Emojis/Travel and places/Locomotive.png",
    "sha": "b17b7c8ecf7b1f43234845b9f4f1809404d1c03f",
    "size": 866610,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Locomotive.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Locomotive.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b17b7c8ecf7b1f43234845b9f4f1809404d1c03f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Locomotive.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Locomotive.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b17b7c8ecf7b1f43234845b9f4f1809404d1c03f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Locomotive.png"
    }
  },
  {
    "name": "Love Hotel.png",
    "path": "Emojis/Travel and places/Love Hotel.png",
    "sha": "44b7f8d31a7fcb9c737d8dbb5f031ff2fc1b1884",
    "size": 17012,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Love%20Hotel.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Love%20Hotel.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44b7f8d31a7fcb9c737d8dbb5f031ff2fc1b1884",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Love%20Hotel.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Love%20Hotel.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44b7f8d31a7fcb9c737d8dbb5f031ff2fc1b1884",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Love%20Hotel.png"
    }
  },
  {
    "name": "Luggage.png",
    "path": "Emojis/Travel and places/Luggage.png",
    "sha": "bb85c7b2e10142afd71c550498efec16b74146de",
    "size": 11530,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Luggage.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Luggage.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb85c7b2e10142afd71c550498efec16b74146de",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Luggage.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Luggage.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb85c7b2e10142afd71c550498efec16b74146de",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Luggage.png"
    }
  },
  {
    "name": "Mantelpiece Clock.png",
    "path": "Emojis/Travel and places/Mantelpiece Clock.png",
    "sha": "6cdc1ca1a0ff7ae8660bf5d511e095329fa8be4a",
    "size": 981228,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mantelpiece%20Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mantelpiece%20Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6cdc1ca1a0ff7ae8660bf5d511e095329fa8be4a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mantelpiece%20Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mantelpiece%20Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6cdc1ca1a0ff7ae8660bf5d511e095329fa8be4a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mantelpiece%20Clock.png"
    }
  },
  {
    "name": "Manual Wheelchair.png",
    "path": "Emojis/Travel and places/Manual Wheelchair.png",
    "sha": "041774c873b18eff38be93d015e2bbac60c2e426",
    "size": 13188,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Manual%20Wheelchair.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Manual%20Wheelchair.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/041774c873b18eff38be93d015e2bbac60c2e426",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Manual%20Wheelchair.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Manual%20Wheelchair.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/041774c873b18eff38be93d015e2bbac60c2e426",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Manual%20Wheelchair.png"
    }
  },
  {
    "name": "Map of Japan.png",
    "path": "Emojis/Travel and places/Map of Japan.png",
    "sha": "6210b03c0db6c484313af7ab72e378a4e84a4481",
    "size": 8226,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Map%20of%20Japan.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Map%20of%20Japan.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6210b03c0db6c484313af7ab72e378a4e84a4481",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Map%20of%20Japan.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Map%20of%20Japan.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6210b03c0db6c484313af7ab72e378a4e84a4481",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Map%20of%20Japan.png"
    }
  },
  {
    "name": "Metro.png",
    "path": "Emojis/Travel and places/Metro.png",
    "sha": "74edf7c134eb0b67cb203c0c526bf35429c6d9a1",
    "size": 13550,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Metro.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Metro.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/74edf7c134eb0b67cb203c0c526bf35429c6d9a1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Metro.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Metro.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/74edf7c134eb0b67cb203c0c526bf35429c6d9a1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Metro.png"
    }
  },
  {
    "name": "Milky Way.png",
    "path": "Emojis/Travel and places/Milky Way.png",
    "sha": "865a9fbd8eb4dc5c045719605aa1b36d205cdbc6",
    "size": 546356,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Milky%20Way.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Milky%20Way.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/865a9fbd8eb4dc5c045719605aa1b36d205cdbc6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Milky%20Way.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Milky%20Way.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/865a9fbd8eb4dc5c045719605aa1b36d205cdbc6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Milky%20Way.png"
    }
  },
  {
    "name": "Minibus.png",
    "path": "Emojis/Travel and places/Minibus.png",
    "sha": "406abd76b0f93aabfa87164ff018124037aa1c03",
    "size": 841397,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Minibus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Minibus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/406abd76b0f93aabfa87164ff018124037aa1c03",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Minibus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Minibus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/406abd76b0f93aabfa87164ff018124037aa1c03",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Minibus.png"
    }
  },
  {
    "name": "Monorail.png",
    "path": "Emojis/Travel and places/Monorail.png",
    "sha": "7beeb89c3ed5cac1b9076592c2812b49392d14cc",
    "size": 637045,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Monorail.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Monorail.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7beeb89c3ed5cac1b9076592c2812b49392d14cc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Monorail.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Monorail.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7beeb89c3ed5cac1b9076592c2812b49392d14cc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Monorail.png"
    }
  },
  {
    "name": "Mosque.png",
    "path": "Emojis/Travel and places/Mosque.png",
    "sha": "6fb03f000a66a388586146594fea93997e3ccafb",
    "size": 15583,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mosque.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mosque.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fb03f000a66a388586146594fea93997e3ccafb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mosque.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mosque.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6fb03f000a66a388586146594fea93997e3ccafb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mosque.png"
    }
  },
  {
    "name": "Motor Boat.png",
    "path": "Emojis/Travel and places/Motor Boat.png",
    "sha": "c5b5430c426dbd00b719637ad549e78b9dc4b59d",
    "size": 615939,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motor%20Boat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motor%20Boat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c5b5430c426dbd00b719637ad549e78b9dc4b59d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Motor%20Boat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motor%20Boat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c5b5430c426dbd00b719637ad549e78b9dc4b59d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motor%20Boat.png"
    }
  },
  {
    "name": "Motor Scooter.png",
    "path": "Emojis/Travel and places/Motor Scooter.png",
    "sha": "4b1d716ca99e262260c7edbb5171d2e07eb44e4b",
    "size": 10343,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motor%20Scooter.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motor%20Scooter.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b1d716ca99e262260c7edbb5171d2e07eb44e4b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Motor%20Scooter.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motor%20Scooter.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b1d716ca99e262260c7edbb5171d2e07eb44e4b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motor%20Scooter.png"
    }
  },
  {
    "name": "Motorcycle.png",
    "path": "Emojis/Travel and places/Motorcycle.png",
    "sha": "a084a8f1eadc731d868c5316918b0686fc839315",
    "size": 663654,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorcycle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorcycle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a084a8f1eadc731d868c5316918b0686fc839315",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Motorcycle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorcycle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a084a8f1eadc731d868c5316918b0686fc839315",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorcycle.png"
    }
  },
  {
    "name": "Motorized Wheelchair.png",
    "path": "Emojis/Travel and places/Motorized Wheelchair.png",
    "sha": "e7aa68e11e027e987e2ded518271e89472e8bf1e",
    "size": 10737,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorized%20Wheelchair.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorized%20Wheelchair.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e7aa68e11e027e987e2ded518271e89472e8bf1e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Motorized%20Wheelchair.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorized%20Wheelchair.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e7aa68e11e027e987e2ded518271e89472e8bf1e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorized%20Wheelchair.png"
    }
  },
  {
    "name": "Motorway.png",
    "path": "Emojis/Travel and places/Motorway.png",
    "sha": "3c79c315015fa2ecfb787688e8733aaea497fc3b",
    "size": 16635,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorway.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorway.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c79c315015fa2ecfb787688e8733aaea497fc3b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Motorway.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Motorway.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c79c315015fa2ecfb787688e8733aaea497fc3b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Motorway.png"
    }
  },
  {
    "name": "Mount Fuji.png",
    "path": "Emojis/Travel and places/Mount Fuji.png",
    "sha": "694d619bd44d864bf4db94d6bbbe4115058564e5",
    "size": 10547,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mount%20Fuji.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mount%20Fuji.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/694d619bd44d864bf4db94d6bbbe4115058564e5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mount%20Fuji.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mount%20Fuji.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/694d619bd44d864bf4db94d6bbbe4115058564e5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mount%20Fuji.png"
    }
  },
  {
    "name": "Mountain Cableway.png",
    "path": "Emojis/Travel and places/Mountain Cableway.png",
    "sha": "b81843ff8fb34e8eb4260165dce9db4b2e091c38",
    "size": 829117,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain%20Cableway.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain%20Cableway.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b81843ff8fb34e8eb4260165dce9db4b2e091c38",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mountain%20Cableway.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain%20Cableway.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b81843ff8fb34e8eb4260165dce9db4b2e091c38",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain%20Cableway.png"
    }
  },
  {
    "name": "Mountain Railway.png",
    "path": "Emojis/Travel and places/Mountain Railway.png",
    "sha": "b68defee5aaf117e37d85e3f25d48aa8371fb3fc",
    "size": 13831,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain%20Railway.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain%20Railway.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b68defee5aaf117e37d85e3f25d48aa8371fb3fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mountain%20Railway.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain%20Railway.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b68defee5aaf117e37d85e3f25d48aa8371fb3fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain%20Railway.png"
    }
  },
  {
    "name": "Mountain.png",
    "path": "Emojis/Travel and places/Mountain.png",
    "sha": "a5d6cb4a50a411036491e290aa5243d1de822412",
    "size": 12947,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a5d6cb4a50a411036491e290aa5243d1de822412",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Mountain.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Mountain.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a5d6cb4a50a411036491e290aa5243d1de822412",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Mountain.png"
    }
  },
  {
    "name": "National Park.png",
    "path": "Emojis/Travel and places/National Park.png",
    "sha": "c4d12370e81c24e758c0d878234c9ea6f1a2952a",
    "size": 16493,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/National%20Park.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/National%20Park.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c4d12370e81c24e758c0d878234c9ea6f1a2952a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/National%20Park.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/National%20Park.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c4d12370e81c24e758c0d878234c9ea6f1a2952a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/National%20Park.png"
    }
  },
  {
    "name": "New Moon Face.png",
    "path": "Emojis/Travel and places/New Moon Face.png",
    "sha": "a44565de8169a9e0456aab6715524a5534e8ce82",
    "size": 1245928,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/New%20Moon%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/New%20Moon%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a44565de8169a9e0456aab6715524a5534e8ce82",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/New%20Moon%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/New%20Moon%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a44565de8169a9e0456aab6715524a5534e8ce82",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/New%20Moon%20Face.png"
    }
  },
  {
    "name": "New Moon.png",
    "path": "Emojis/Travel and places/New Moon.png",
    "sha": "fe374a6482ba9d102e0170d4205064309087d1ef",
    "size": 17784,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/New%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/New%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe374a6482ba9d102e0170d4205064309087d1ef",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/New%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/New%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fe374a6482ba9d102e0170d4205064309087d1ef",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/New%20Moon.png"
    }
  },
  {
    "name": "Night with Stars.png",
    "path": "Emojis/Travel and places/Night with Stars.png",
    "sha": "052323d8e2c0c6f2058aac99a2368ce68e1b548e",
    "size": 13308,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Night%20with%20Stars.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Night%20with%20Stars.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/052323d8e2c0c6f2058aac99a2368ce68e1b548e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Night%20with%20Stars.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Night%20with%20Stars.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/052323d8e2c0c6f2058aac99a2368ce68e1b548e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Night%20with%20Stars.png"
    }
  },
  {
    "name": "Nine O’Clock.png",
    "path": "Emojis/Travel and places/Nine O’Clock.png",
    "sha": "7d74cfdfbe011dad08f09b154515c065a03f5e46",
    "size": 766883,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Nine%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Nine%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d74cfdfbe011dad08f09b154515c065a03f5e46",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Nine O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Nine%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d74cfdfbe011dad08f09b154515c065a03f5e46",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Nine%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Nine-Thirty.png",
    "path": "Emojis/Travel and places/Nine-Thirty.png",
    "sha": "419ac670512cbd0336fdca7df87d7c92ac13682a",
    "size": 996860,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Nine-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Nine-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/419ac670512cbd0336fdca7df87d7c92ac13682a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Nine-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Nine-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/419ac670512cbd0336fdca7df87d7c92ac13682a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Nine-Thirty.png"
    }
  },
  {
    "name": "Office Building.png",
    "path": "Emojis/Travel and places/Office Building.png",
    "sha": "f8beb4097b7872a2b938ca97d222a9b6853e174a",
    "size": 14842,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Office%20Building.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Office%20Building.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f8beb4097b7872a2b938ca97d222a9b6853e174a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Office%20Building.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Office%20Building.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f8beb4097b7872a2b938ca97d222a9b6853e174a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Office%20Building.png"
    }
  },
  {
    "name": "Oil Drum.png",
    "path": "Emojis/Travel and places/Oil Drum.png",
    "sha": "eddbf7f521c7b96b15f4468ae7e627f3c846ae82",
    "size": 12463,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oil%20Drum.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oil%20Drum.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eddbf7f521c7b96b15f4468ae7e627f3c846ae82",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Oil%20Drum.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oil%20Drum.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/eddbf7f521c7b96b15f4468ae7e627f3c846ae82",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oil%20Drum.png"
    }
  },
  {
    "name": "Oncoming Automobile.png",
    "path": "Emojis/Travel and places/Oncoming Automobile.png",
    "sha": "bb69c7d692671dc45fe01ff4731568babaa49f2f",
    "size": 544895,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Automobile.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Automobile.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb69c7d692671dc45fe01ff4731568babaa49f2f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Oncoming%20Automobile.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Automobile.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bb69c7d692671dc45fe01ff4731568babaa49f2f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Automobile.png"
    }
  },
  {
    "name": "Oncoming Bus.png",
    "path": "Emojis/Travel and places/Oncoming Bus.png",
    "sha": "53cd5a2f4d48e15bcd25185dbb15e0cd7ee9c3e4",
    "size": 11413,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Bus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Bus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53cd5a2f4d48e15bcd25185dbb15e0cd7ee9c3e4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Oncoming%20Bus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Bus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53cd5a2f4d48e15bcd25185dbb15e0cd7ee9c3e4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Bus.png"
    }
  },
  {
    "name": "Oncoming Police Car.png",
    "path": "Emojis/Travel and places/Oncoming Police Car.png",
    "sha": "2653bcbac715d1c9d7aa1821bd862dce07ed8b0a",
    "size": 538254,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Police%20Car.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Police%20Car.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2653bcbac715d1c9d7aa1821bd862dce07ed8b0a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Oncoming%20Police%20Car.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Police%20Car.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2653bcbac715d1c9d7aa1821bd862dce07ed8b0a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Police%20Car.png"
    }
  },
  {
    "name": "Oncoming Taxi.png",
    "path": "Emojis/Travel and places/Oncoming Taxi.png",
    "sha": "2897d8ce26d9fedb669fc53dbbb5a0fd86ded5c8",
    "size": 585884,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Taxi.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Taxi.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2897d8ce26d9fedb669fc53dbbb5a0fd86ded5c8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Oncoming%20Taxi.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Oncoming%20Taxi.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2897d8ce26d9fedb669fc53dbbb5a0fd86ded5c8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Oncoming%20Taxi.png"
    }
  },
  {
    "name": "One O’Clock.png",
    "path": "Emojis/Travel and places/One O’Clock.png",
    "sha": "bd2e78d6c65be81d67b6f596eabdff23d707e948",
    "size": 932152,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/One%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/One%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd2e78d6c65be81d67b6f596eabdff23d707e948",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/One O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/One%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd2e78d6c65be81d67b6f596eabdff23d707e948",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/One%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "One-Thirty.png",
    "path": "Emojis/Travel and places/One-Thirty.png",
    "sha": "330e9a37c7e339be4c93d40c9c9b923bb67037d7",
    "size": 926371,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/One-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/One-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/330e9a37c7e339be4c93d40c9c9b923bb67037d7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/One-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/One-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/330e9a37c7e339be4c93d40c9c9b923bb67037d7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/One-Thirty.png"
    }
  },
  {
    "name": "Parachute.png",
    "path": "Emojis/Travel and places/Parachute.png",
    "sha": "138c1a17030e6a09edac6e2a8dbd5d98ab84257e",
    "size": 788592,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Parachute.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Parachute.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/138c1a17030e6a09edac6e2a8dbd5d98ab84257e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Parachute.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Parachute.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/138c1a17030e6a09edac6e2a8dbd5d98ab84257e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Parachute.png"
    }
  },
  {
    "name": "Passenger Ship.png",
    "path": "Emojis/Travel and places/Passenger Ship.png",
    "sha": "8d5a3adedc9161df462542515d5ac94e3a443e45",
    "size": 868814,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Passenger%20Ship.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Passenger%20Ship.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8d5a3adedc9161df462542515d5ac94e3a443e45",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Passenger%20Ship.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Passenger%20Ship.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8d5a3adedc9161df462542515d5ac94e3a443e45",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Passenger%20Ship.png"
    }
  },
  {
    "name": "Pickup Truck.png",
    "path": "Emojis/Travel and places/Pickup Truck.png",
    "sha": "d295ad3b37729d87889a664dcac22bf4e69fdec4",
    "size": 9257,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Pickup%20Truck.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Pickup%20Truck.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d295ad3b37729d87889a664dcac22bf4e69fdec4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Pickup%20Truck.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Pickup%20Truck.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d295ad3b37729d87889a664dcac22bf4e69fdec4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Pickup%20Truck.png"
    }
  },
  {
    "name": "Playground Slide.png",
    "path": "Emojis/Travel and places/Playground Slide.png",
    "sha": "9fbbc8e0a9a93e9c934eb83be7271a345e624baf",
    "size": 35990,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Playground%20Slide.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Playground%20Slide.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fbbc8e0a9a93e9c934eb83be7271a345e624baf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Playground%20Slide.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Playground%20Slide.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fbbc8e0a9a93e9c934eb83be7271a345e624baf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Playground%20Slide.png"
    }
  },
  {
    "name": "Police Car Light.png",
    "path": "Emojis/Travel and places/Police Car Light.png",
    "sha": "2e43d5ba2d739272f221e701384ad5b1960896be",
    "size": 9049,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Police%20Car%20Light.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Police%20Car%20Light.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e43d5ba2d739272f221e701384ad5b1960896be",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Police%20Car%20Light.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Police%20Car%20Light.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2e43d5ba2d739272f221e701384ad5b1960896be",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Police%20Car%20Light.png"
    }
  },
  {
    "name": "Police Car.png",
    "path": "Emojis/Travel and places/Police Car.png",
    "sha": "3ac59e8f7b1afb13ca2205d02328bb8e6589e13d",
    "size": 760406,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Police%20Car.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Police%20Car.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ac59e8f7b1afb13ca2205d02328bb8e6589e13d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Police%20Car.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Police%20Car.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ac59e8f7b1afb13ca2205d02328bb8e6589e13d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Police%20Car.png"
    }
  },
  {
    "name": "Post Office.png",
    "path": "Emojis/Travel and places/Post Office.png",
    "sha": "59110ed9e9786adf824b0235320eacf0c336776e",
    "size": 13798,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Post%20Office.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Post%20Office.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59110ed9e9786adf824b0235320eacf0c336776e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Post%20Office.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Post%20Office.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59110ed9e9786adf824b0235320eacf0c336776e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Post%20Office.png"
    }
  },
  {
    "name": "Racing Car.png",
    "path": "Emojis/Travel and places/Racing Car.png",
    "sha": "ef08eddbd4f7026816b6fb9826e16fffaed5234d",
    "size": 490979,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Racing%20Car.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Racing%20Car.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef08eddbd4f7026816b6fb9826e16fffaed5234d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Racing%20Car.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Racing%20Car.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef08eddbd4f7026816b6fb9826e16fffaed5234d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Racing%20Car.png"
    }
  },
  {
    "name": "Railway Car.png",
    "path": "Emojis/Travel and places/Railway Car.png",
    "sha": "37cb6061d2ce23155b5fd5023b8b03f2dc005f1f",
    "size": 11193,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Railway%20Car.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Railway%20Car.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/37cb6061d2ce23155b5fd5023b8b03f2dc005f1f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Railway%20Car.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Railway%20Car.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/37cb6061d2ce23155b5fd5023b8b03f2dc005f1f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Railway%20Car.png"
    }
  },
  {
    "name": "Railway Track.png",
    "path": "Emojis/Travel and places/Railway Track.png",
    "sha": "19b680c340ec8198edcbf43c4c5c6ddbeb3800d6",
    "size": 16663,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Railway%20Track.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Railway%20Track.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/19b680c340ec8198edcbf43c4c5c6ddbeb3800d6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Railway%20Track.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Railway%20Track.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/19b680c340ec8198edcbf43c4c5c6ddbeb3800d6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Railway%20Track.png"
    }
  },
  {
    "name": "Rainbow.png",
    "path": "Emojis/Travel and places/Rainbow.png",
    "sha": "38121aaa5f2423f1035454d2e86cc9c4b3172f07",
    "size": 798110,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rainbow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rainbow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/38121aaa5f2423f1035454d2e86cc9c4b3172f07",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Rainbow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rainbow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/38121aaa5f2423f1035454d2e86cc9c4b3172f07",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rainbow.png"
    }
  },
  {
    "name": "Ringed Planet.png",
    "path": "Emojis/Travel and places/Ringed Planet.png",
    "sha": "8eb92de6f3444d06349cd9dbfa4b49f43dfdf1cc",
    "size": 709280,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ringed%20Planet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ringed%20Planet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8eb92de6f3444d06349cd9dbfa4b49f43dfdf1cc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ringed%20Planet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ringed%20Planet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8eb92de6f3444d06349cd9dbfa4b49f43dfdf1cc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ringed%20Planet.png"
    }
  },
  {
    "name": "Rock.png",
    "path": "Emojis/Travel and places/Rock.png",
    "sha": "811c8eaa7013b4b110ab2707b8e0ecc98d404d08",
    "size": 12327,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/811c8eaa7013b4b110ab2707b8e0ecc98d404d08",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Rock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/811c8eaa7013b4b110ab2707b8e0ecc98d404d08",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rock.png"
    }
  },
  {
    "name": "Rocket.png",
    "path": "Emojis/Travel and places/Rocket.png",
    "sha": "d8986e40cdc342b6d1d4c73921d1de6ad6478299",
    "size": 870552,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rocket.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rocket.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8986e40cdc342b6d1d4c73921d1de6ad6478299",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Rocket.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Rocket.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8986e40cdc342b6d1d4c73921d1de6ad6478299",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Rocket.png"
    }
  },
  {
    "name": "Roller Coaster.png",
    "path": "Emojis/Travel and places/Roller Coaster.png",
    "sha": "318aa148e2342b94ff9e2b1516e20fed6bd094a8",
    "size": 626158,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Roller%20Coaster.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Roller%20Coaster.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/318aa148e2342b94ff9e2b1516e20fed6bd094a8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Roller%20Coaster.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Roller%20Coaster.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/318aa148e2342b94ff9e2b1516e20fed6bd094a8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Roller%20Coaster.png"
    }
  },
  {
    "name": "Roller Skate.png",
    "path": "Emojis/Travel and places/Roller Skate.png",
    "sha": "915d1c59ccbf64edd1c28f26a71a78f9ec31c403",
    "size": 931325,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Roller%20Skate.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Roller%20Skate.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/915d1c59ccbf64edd1c28f26a71a78f9ec31c403",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Roller%20Skate.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Roller%20Skate.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/915d1c59ccbf64edd1c28f26a71a78f9ec31c403",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Roller%20Skate.png"
    }
  },
  {
    "name": "Sailboat.png",
    "path": "Emojis/Travel and places/Sailboat.png",
    "sha": "1f5eff4394e25f4d6fe454113cb89036ae4ef733",
    "size": 578973,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sailboat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sailboat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f5eff4394e25f4d6fe454113cb89036ae4ef733",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sailboat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sailboat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f5eff4394e25f4d6fe454113cb89036ae4ef733",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sailboat.png"
    }
  },
  {
    "name": "Satellite.png",
    "path": "Emojis/Travel and places/Satellite.png",
    "sha": "110e12ec712716743606f2b3e4af638ca61ed51a",
    "size": 12452,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Satellite.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Satellite.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/110e12ec712716743606f2b3e4af638ca61ed51a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Satellite.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Satellite.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/110e12ec712716743606f2b3e4af638ca61ed51a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Satellite.png"
    }
  },
  {
    "name": "School.png",
    "path": "Emojis/Travel and places/School.png",
    "sha": "f9956a9283dafebd884162b7ac401586babc2076",
    "size": 13335,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/School.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/School.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f9956a9283dafebd884162b7ac401586babc2076",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/School.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/School.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f9956a9283dafebd884162b7ac401586babc2076",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/School.png"
    }
  },
  {
    "name": "Seat.png",
    "path": "Emojis/Travel and places/Seat.png",
    "sha": "34b5229a2c7fac7b96730c8729139cd32af49d90",
    "size": 531192,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/34b5229a2c7fac7b96730c8729139cd32af49d90",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Seat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/34b5229a2c7fac7b96730c8729139cd32af49d90",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seat.png"
    }
  },
  {
    "name": "Seven O’Clock.png",
    "path": "Emojis/Travel and places/Seven O’Clock.png",
    "sha": "7d4e92a560665cec32696c72358ab86adaf25731",
    "size": 875729,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seven%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seven%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d4e92a560665cec32696c72358ab86adaf25731",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Seven O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seven%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d4e92a560665cec32696c72358ab86adaf25731",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seven%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Seven-Thirty.png",
    "path": "Emojis/Travel and places/Seven-Thirty.png",
    "sha": "bc35adbe30cdf90eecebb1db45eef165ca18cbcd",
    "size": 744697,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seven-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seven-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bc35adbe30cdf90eecebb1db45eef165ca18cbcd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Seven-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Seven-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bc35adbe30cdf90eecebb1db45eef165ca18cbcd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Seven-Thirty.png"
    }
  },
  {
    "name": "Shinto Shrine.png",
    "path": "Emojis/Travel and places/Shinto Shrine.png",
    "sha": "25bdb2f04fe41481b2108a117ec079b23208e71b",
    "size": 8603,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Shinto%20Shrine.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Shinto%20Shrine.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25bdb2f04fe41481b2108a117ec079b23208e71b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Shinto%20Shrine.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Shinto%20Shrine.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25bdb2f04fe41481b2108a117ec079b23208e71b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Shinto%20Shrine.png"
    }
  },
  {
    "name": "Ship.png",
    "path": "Emojis/Travel and places/Ship.png",
    "sha": "373e7df8853d71cb217f22ac191bdadfc5e7c5cf",
    "size": 865621,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ship.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ship.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/373e7df8853d71cb217f22ac191bdadfc5e7c5cf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ship.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ship.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/373e7df8853d71cb217f22ac191bdadfc5e7c5cf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ship.png"
    }
  },
  {
    "name": "Shooting Star.png",
    "path": "Emojis/Travel and places/Shooting Star.png",
    "sha": "8c3db7fcf3076656d97e96eccf73ae7191bbc139",
    "size": 1011460,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Shooting%20Star.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Shooting%20Star.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c3db7fcf3076656d97e96eccf73ae7191bbc139",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Shooting%20Star.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Shooting%20Star.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8c3db7fcf3076656d97e96eccf73ae7191bbc139",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Shooting%20Star.png"
    }
  },
  {
    "name": "Six O’Clock.png",
    "path": "Emojis/Travel and places/Six O’Clock.png",
    "sha": "f4e690e60fe342d607f3a8d233d03e8312ad35ed",
    "size": 992654,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Six%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Six%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4e690e60fe342d607f3a8d233d03e8312ad35ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Six O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Six%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4e690e60fe342d607f3a8d233d03e8312ad35ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Six%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Six-Thirty.png",
    "path": "Emojis/Travel and places/Six-Thirty.png",
    "sha": "2fe059330352d9d0ca561af09b850eb48b2302d8",
    "size": 823510,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Six-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Six-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fe059330352d9d0ca561af09b850eb48b2302d8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Six-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Six-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2fe059330352d9d0ca561af09b850eb48b2302d8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Six-Thirty.png"
    }
  },
  {
    "name": "Skateboard.png",
    "path": "Emojis/Travel and places/Skateboard.png",
    "sha": "3b9cb65e7227cc8963b44d3cf04861c3ec8d72f2",
    "size": 10968,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Skateboard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Skateboard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b9cb65e7227cc8963b44d3cf04861c3ec8d72f2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Skateboard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Skateboard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b9cb65e7227cc8963b44d3cf04861c3ec8d72f2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Skateboard.png"
    }
  },
  {
    "name": "Small Airplane.png",
    "path": "Emojis/Travel and places/Small Airplane.png",
    "sha": "5bd4b6f06b29645c33f980a0d438959089a30ad9",
    "size": 706800,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Small%20Airplane.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Small%20Airplane.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5bd4b6f06b29645c33f980a0d438959089a30ad9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Small%20Airplane.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Small%20Airplane.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5bd4b6f06b29645c33f980a0d438959089a30ad9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Small%20Airplane.png"
    }
  },
  {
    "name": "Snow-Capped Mountain.png",
    "path": "Emojis/Travel and places/Snow-Capped Mountain.png",
    "sha": "2f92df11d3089131f620bba43d85305381e2036a",
    "size": 11984,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snow-Capped%20Mountain.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snow-Capped%20Mountain.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2f92df11d3089131f620bba43d85305381e2036a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Snow-Capped%20Mountain.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snow-Capped%20Mountain.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2f92df11d3089131f620bba43d85305381e2036a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snow-Capped%20Mountain.png"
    }
  },
  {
    "name": "Snowflake.png",
    "path": "Emojis/Travel and places/Snowflake.png",
    "sha": "3b8c21c7781cc8bc8e8dd04bd3346f05284c9a5f",
    "size": 986722,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowflake.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowflake.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b8c21c7781cc8bc8e8dd04bd3346f05284c9a5f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Snowflake.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowflake.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3b8c21c7781cc8bc8e8dd04bd3346f05284c9a5f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowflake.png"
    }
  },
  {
    "name": "Snowman Without Snow.png",
    "path": "Emojis/Travel and places/Snowman Without Snow.png",
    "sha": "b8a82552f0b1ae895a42e2f76f9752274fb04999",
    "size": 934850,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowman%20Without%20Snow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowman%20Without%20Snow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b8a82552f0b1ae895a42e2f76f9752274fb04999",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Snowman%20Without%20Snow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowman%20Without%20Snow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b8a82552f0b1ae895a42e2f76f9752274fb04999",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowman%20Without%20Snow.png"
    }
  },
  {
    "name": "Snowman.png",
    "path": "Emojis/Travel and places/Snowman.png",
    "sha": "21f1074f88b54f893c8fc8ac9221b44cc430f65e",
    "size": 1072888,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowman.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowman.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21f1074f88b54f893c8fc8ac9221b44cc430f65e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Snowman.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Snowman.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/21f1074f88b54f893c8fc8ac9221b44cc430f65e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Snowman.png"
    }
  },
  {
    "name": "Speedboat.png",
    "path": "Emojis/Travel and places/Speedboat.png",
    "sha": "9b5105be5ab33807d43a03a9cf70567bed84bdfb",
    "size": 615691,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Speedboat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Speedboat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9b5105be5ab33807d43a03a9cf70567bed84bdfb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Speedboat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Speedboat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9b5105be5ab33807d43a03a9cf70567bed84bdfb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Speedboat.png"
    }
  },
  {
    "name": "Sport Utility Vehicle.png",
    "path": "Emojis/Travel and places/Sport Utility Vehicle.png",
    "sha": "bd37f09e3002be9808658c9a29b38351e5034232",
    "size": 920596,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sport%20Utility%20Vehicle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sport%20Utility%20Vehicle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd37f09e3002be9808658c9a29b38351e5034232",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sport%20Utility%20Vehicle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sport%20Utility%20Vehicle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd37f09e3002be9808658c9a29b38351e5034232",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sport%20Utility%20Vehicle.png"
    }
  },
  {
    "name": "Stadium.png",
    "path": "Emojis/Travel and places/Stadium.png",
    "sha": "7d99a8ddf772f44f06700aeff0aa6daf72940e70",
    "size": 11786,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stadium.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stadium.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d99a8ddf772f44f06700aeff0aa6daf72940e70",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Stadium.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stadium.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d99a8ddf772f44f06700aeff0aa6daf72940e70",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stadium.png"
    }
  },
  {
    "name": "Star.png",
    "path": "Emojis/Travel and places/Star.png",
    "sha": "92474ad6abc597da5e7814a43885b6f07bc30eed",
    "size": 636804,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Star.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Star.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92474ad6abc597da5e7814a43885b6f07bc30eed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Star.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Star.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92474ad6abc597da5e7814a43885b6f07bc30eed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Star.png"
    }
  },
  {
    "name": "Station.png",
    "path": "Emojis/Travel and places/Station.png",
    "sha": "5463021b47e25bc81b90490cd56177f093e3417a",
    "size": 10603,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Station.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Station.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5463021b47e25bc81b90490cd56177f093e3417a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Station.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Station.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5463021b47e25bc81b90490cd56177f093e3417a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Station.png"
    }
  },
  {
    "name": "Statue of Liberty.png",
    "path": "Emojis/Travel and places/Statue of Liberty.png",
    "sha": "f1d83e4a02ca24e2e2eaf150b373584cdcde2a0a",
    "size": 13760,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Statue%20of%20Liberty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Statue%20of%20Liberty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1d83e4a02ca24e2e2eaf150b373584cdcde2a0a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Statue%20of%20Liberty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Statue%20of%20Liberty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1d83e4a02ca24e2e2eaf150b373584cdcde2a0a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Statue%20of%20Liberty.png"
    }
  },
  {
    "name": "Stop Sign.png",
    "path": "Emojis/Travel and places/Stop Sign.png",
    "sha": "b3c732672bd1598dc29e7d9ffd7ccaa06c7c3e7f",
    "size": 14406,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stop%20Sign.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stop%20Sign.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3c732672bd1598dc29e7d9ffd7ccaa06c7c3e7f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Stop%20Sign.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stop%20Sign.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3c732672bd1598dc29e7d9ffd7ccaa06c7c3e7f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stop%20Sign.png"
    }
  },
  {
    "name": "Stopwatch.png",
    "path": "Emojis/Travel and places/Stopwatch.png",
    "sha": "d3476bf89bb3e086c0160029dbffd64ca2ef121b",
    "size": 1022032,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stopwatch.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stopwatch.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d3476bf89bb3e086c0160029dbffd64ca2ef121b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Stopwatch.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Stopwatch.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d3476bf89bb3e086c0160029dbffd64ca2ef121b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Stopwatch.png"
    }
  },
  {
    "name": "Sun Behind Cloud.png",
    "path": "Emojis/Travel and places/Sun Behind Cloud.png",
    "sha": "f6c802cb906a5dfd34707090163c7219c117e908",
    "size": 966681,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Cloud.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Cloud.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f6c802cb906a5dfd34707090163c7219c117e908",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20Behind%20Cloud.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Cloud.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f6c802cb906a5dfd34707090163c7219c117e908",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Cloud.png"
    }
  },
  {
    "name": "Sun Behind Large Cloud.png",
    "path": "Emojis/Travel and places/Sun Behind Large Cloud.png",
    "sha": "e9bc6228945d781a37f733dc84dfff0b35a76aa3",
    "size": 958959,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Large%20Cloud.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Large%20Cloud.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9bc6228945d781a37f733dc84dfff0b35a76aa3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20Behind%20Large%20Cloud.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Large%20Cloud.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9bc6228945d781a37f733dc84dfff0b35a76aa3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Large%20Cloud.png"
    }
  },
  {
    "name": "Sun Behind Rain Cloud.png",
    "path": "Emojis/Travel and places/Sun Behind Rain Cloud.png",
    "sha": "0563aea8f26aaa458f9a592bd0098bf9807933c0",
    "size": 496424,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Rain%20Cloud.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Rain%20Cloud.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0563aea8f26aaa458f9a592bd0098bf9807933c0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20Behind%20Rain%20Cloud.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Rain%20Cloud.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0563aea8f26aaa458f9a592bd0098bf9807933c0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Rain%20Cloud.png"
    }
  },
  {
    "name": "Sun Behind Small Cloud.png",
    "path": "Emojis/Travel and places/Sun Behind Small Cloud.png",
    "sha": "f578ff0a07b201f7605bbbec2fff63f2b83314a2",
    "size": 1029239,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Small%20Cloud.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Small%20Cloud.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f578ff0a07b201f7605bbbec2fff63f2b83314a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20Behind%20Small%20Cloud.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20Behind%20Small%20Cloud.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f578ff0a07b201f7605bbbec2fff63f2b83314a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20Behind%20Small%20Cloud.png"
    }
  },
  {
    "name": "Sun with Face.png",
    "path": "Emojis/Travel and places/Sun with Face.png",
    "sha": "732fb76e42214526a754ad71332006f18fb261ca",
    "size": 1208193,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20with%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20with%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/732fb76e42214526a754ad71332006f18fb261ca",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun%20with%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun%20with%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/732fb76e42214526a754ad71332006f18fb261ca",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun%20with%20Face.png"
    }
  },
  {
    "name": "Sun.png",
    "path": "Emojis/Travel and places/Sun.png",
    "sha": "5e3ebe777ccd34ac813f7d4da4f0c2bb6ed1167f",
    "size": 983812,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5e3ebe777ccd34ac813f7d4da4f0c2bb6ed1167f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sun.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sun.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5e3ebe777ccd34ac813f7d4da4f0c2bb6ed1167f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sun.png"
    }
  },
  {
    "name": "Sunrise Over Mountains.png",
    "path": "Emojis/Travel and places/Sunrise Over Mountains.png",
    "sha": "9f58db1274e989c5eb6b624117f67954b52cb5e4",
    "size": 16376,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunrise%20Over%20Mountains.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunrise%20Over%20Mountains.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f58db1274e989c5eb6b624117f67954b52cb5e4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sunrise%20Over%20Mountains.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunrise%20Over%20Mountains.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f58db1274e989c5eb6b624117f67954b52cb5e4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunrise%20Over%20Mountains.png"
    }
  },
  {
    "name": "Sunrise.png",
    "path": "Emojis/Travel and places/Sunrise.png",
    "sha": "e8cdcaab793aee8c8b8e722336b361d28762df38",
    "size": 16144,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunrise.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunrise.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8cdcaab793aee8c8b8e722336b361d28762df38",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sunrise.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunrise.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8cdcaab793aee8c8b8e722336b361d28762df38",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunrise.png"
    }
  },
  {
    "name": "Sunset.png",
    "path": "Emojis/Travel and places/Sunset.png",
    "sha": "efa1e1729cfbe5432ed5b12064e5fba6bf37cafe",
    "size": 16246,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunset.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunset.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/efa1e1729cfbe5432ed5b12064e5fba6bf37cafe",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Sunset.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Sunset.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/efa1e1729cfbe5432ed5b12064e5fba6bf37cafe",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Sunset.png"
    }
  },
  {
    "name": "Suspension Railway.png",
    "path": "Emojis/Travel and places/Suspension Railway.png",
    "sha": "6692a6fa7cb622fef96d3b341ad1f3b9ff8edff0",
    "size": 929602,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Suspension%20Railway.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Suspension%20Railway.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6692a6fa7cb622fef96d3b341ad1f3b9ff8edff0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Suspension%20Railway.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Suspension%20Railway.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6692a6fa7cb622fef96d3b341ad1f3b9ff8edff0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Suspension%20Railway.png"
    }
  },
  {
    "name": "Synagogue.png",
    "path": "Emojis/Travel and places/Synagogue.png",
    "sha": "b0f6145bac92412575a730386ee27d927c21554d",
    "size": 15240,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Synagogue.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Synagogue.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0f6145bac92412575a730386ee27d927c21554d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Synagogue.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Synagogue.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0f6145bac92412575a730386ee27d927c21554d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Synagogue.png"
    }
  },
  {
    "name": "Taxi.png",
    "path": "Emojis/Travel and places/Taxi.png",
    "sha": "636364666c7037df0fc8fcade36b57c4ed62b98b",
    "size": 744952,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Taxi.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Taxi.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/636364666c7037df0fc8fcade36b57c4ed62b98b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Taxi.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Taxi.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/636364666c7037df0fc8fcade36b57c4ed62b98b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Taxi.png"
    }
  },
  {
    "name": "Ten O’Clock.png",
    "path": "Emojis/Travel and places/Ten O’Clock.png",
    "sha": "e1d6077d2904da93e20d611d04ccb662521a7ce9",
    "size": 883726,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ten%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ten%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e1d6077d2904da93e20d611d04ccb662521a7ce9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ten O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ten%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e1d6077d2904da93e20d611d04ccb662521a7ce9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ten%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Ten-Thirty.png",
    "path": "Emojis/Travel and places/Ten-Thirty.png",
    "sha": "d8ff6bef0d83b65c826abb86bf962e07c9545480",
    "size": 829911,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ten-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ten-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8ff6bef0d83b65c826abb86bf962e07c9545480",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Ten-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Ten-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d8ff6bef0d83b65c826abb86bf962e07c9545480",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Ten-Thirty.png"
    }
  },
  {
    "name": "Tent.png",
    "path": "Emojis/Travel and places/Tent.png",
    "sha": "bf73a4407a202f515f73f824d1ba7a947a8faf0c",
    "size": 9455,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tent.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tent.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bf73a4407a202f515f73f824d1ba7a947a8faf0c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tent.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tent.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bf73a4407a202f515f73f824d1ba7a947a8faf0c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tent.png"
    }
  },
  {
    "name": "Thermometer.png",
    "path": "Emojis/Travel and places/Thermometer.png",
    "sha": "81ff261550561a7b74a75cb4456f863262b06f88",
    "size": 10090,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Thermometer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Thermometer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/81ff261550561a7b74a75cb4456f863262b06f88",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Thermometer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Thermometer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/81ff261550561a7b74a75cb4456f863262b06f88",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Thermometer.png"
    }
  },
  {
    "name": "Three O’Clock.png",
    "path": "Emojis/Travel and places/Three O’Clock.png",
    "sha": "ee43d2c42ac5dc8a9d5fe1f7b57fe09c6dde73ed",
    "size": 953415,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Three%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Three%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee43d2c42ac5dc8a9d5fe1f7b57fe09c6dde73ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Three O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Three%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee43d2c42ac5dc8a9d5fe1f7b57fe09c6dde73ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Three%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Three-Thirty.png",
    "path": "Emojis/Travel and places/Three-Thirty.png",
    "sha": "2866b532d4655ce30af95f63e44b5261097ce4ba",
    "size": 844575,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Three-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Three-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2866b532d4655ce30af95f63e44b5261097ce4ba",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Three-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Three-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2866b532d4655ce30af95f63e44b5261097ce4ba",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Three-Thirty.png"
    }
  },
  {
    "name": "Timer Clock.png",
    "path": "Emojis/Travel and places/Timer Clock.png",
    "sha": "63559b74b0bb7b0ee4b6efd6562c98f08570a3d7",
    "size": 1204124,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Timer%20Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Timer%20Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/63559b74b0bb7b0ee4b6efd6562c98f08570a3d7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Timer%20Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Timer%20Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/63559b74b0bb7b0ee4b6efd6562c98f08570a3d7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Timer%20Clock.png"
    }
  },
  {
    "name": "Tokyo Tower.png",
    "path": "Emojis/Travel and places/Tokyo Tower.png",
    "sha": "867c02a5d1c288c0f9b54fee7c2fd1cbb35e2e66",
    "size": 9005,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tokyo%20Tower.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tokyo%20Tower.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/867c02a5d1c288c0f9b54fee7c2fd1cbb35e2e66",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tokyo%20Tower.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tokyo%20Tower.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/867c02a5d1c288c0f9b54fee7c2fd1cbb35e2e66",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tokyo%20Tower.png"
    }
  },
  {
    "name": "Tornado.png",
    "path": "Emojis/Travel and places/Tornado.png",
    "sha": "cc788c6a0275c4223fae434b25d5ae2ba9b54d55",
    "size": 1216004,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tornado.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tornado.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cc788c6a0275c4223fae434b25d5ae2ba9b54d55",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tornado.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tornado.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cc788c6a0275c4223fae434b25d5ae2ba9b54d55",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tornado.png"
    }
  },
  {
    "name": "Tractor.png",
    "path": "Emojis/Travel and places/Tractor.png",
    "sha": "dffb6bb1b2a1b104d552764cef0ab60a4f00dcb5",
    "size": 905775,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tractor.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tractor.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dffb6bb1b2a1b104d552764cef0ab60a4f00dcb5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tractor.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tractor.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dffb6bb1b2a1b104d552764cef0ab60a4f00dcb5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tractor.png"
    }
  },
  {
    "name": "Train.png",
    "path": "Emojis/Travel and places/Train.png",
    "sha": "85815c617f98ee03e65593dafa3bc932b3c49546",
    "size": 12359,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Train.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Train.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/85815c617f98ee03e65593dafa3bc932b3c49546",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Train.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Train.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/85815c617f98ee03e65593dafa3bc932b3c49546",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Train.png"
    }
  },
  {
    "name": "Tram Car.png",
    "path": "Emojis/Travel and places/Tram Car.png",
    "sha": "adb722a5abf7345b38efa242352452c6506807a6",
    "size": 975843,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tram%20Car.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tram%20Car.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adb722a5abf7345b38efa242352452c6506807a6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tram%20Car.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tram%20Car.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/adb722a5abf7345b38efa242352452c6506807a6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tram%20Car.png"
    }
  },
  {
    "name": "Tram.png",
    "path": "Emojis/Travel and places/Tram.png",
    "sha": "11eb3d27499ee83ebb7c87d6eb2fa2267233d167",
    "size": 9407,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tram.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tram.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11eb3d27499ee83ebb7c87d6eb2fa2267233d167",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Tram.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Tram.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/11eb3d27499ee83ebb7c87d6eb2fa2267233d167",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Tram.png"
    }
  },
  {
    "name": "Trolleybus.png",
    "path": "Emojis/Travel and places/Trolleybus.png",
    "sha": "ee32e841721f79e333408c0d40dacb778e2af1be",
    "size": 849228,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Trolleybus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Trolleybus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee32e841721f79e333408c0d40dacb778e2af1be",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Trolleybus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Trolleybus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee32e841721f79e333408c0d40dacb778e2af1be",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Trolleybus.png"
    }
  },
  {
    "name": "Twelve O’Clock.png",
    "path": "Emojis/Travel and places/Twelve O’Clock.png",
    "sha": "d5cc6b453d5e9f7c6e1b214dee422ef8159b0cb8",
    "size": 957204,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Twelve%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Twelve%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5cc6b453d5e9f7c6e1b214dee422ef8159b0cb8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Twelve O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Twelve%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5cc6b453d5e9f7c6e1b214dee422ef8159b0cb8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Twelve%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Twelve-Thirty.png",
    "path": "Emojis/Travel and places/Twelve-Thirty.png",
    "sha": "3e0b97b795876e56240f9e374a270cb5f4cbc1b4",
    "size": 953766,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Twelve-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Twelve-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e0b97b795876e56240f9e374a270cb5f4cbc1b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Twelve-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Twelve-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e0b97b795876e56240f9e374a270cb5f4cbc1b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Twelve-Thirty.png"
    }
  },
  {
    "name": "Two O’Clock.png",
    "path": "Emojis/Travel and places/Two O’Clock.png",
    "sha": "fb7a9d5b30f5bcd084ab9784ddaf7b9082eed14c",
    "size": 884836,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Two%20O%E2%80%99Clock.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Two%20O%E2%80%99Clock.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb7a9d5b30f5bcd084ab9784ddaf7b9082eed14c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Two O’Clock.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Two%20O%E2%80%99Clock.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fb7a9d5b30f5bcd084ab9784ddaf7b9082eed14c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Two%20O%E2%80%99Clock.png"
    }
  },
  {
    "name": "Two-Thirty.png",
    "path": "Emojis/Travel and places/Two-Thirty.png",
    "sha": "9fc7cd24cd9aa29fcd477fec777c3a2f38f74333",
    "size": 799331,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Two-Thirty.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Two-Thirty.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fc7cd24cd9aa29fcd477fec777c3a2f38f74333",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Two-Thirty.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Two-Thirty.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fc7cd24cd9aa29fcd477fec777c3a2f38f74333",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Two-Thirty.png"
    }
  },
  {
    "name": "Umbrella on Ground.png",
    "path": "Emojis/Travel and places/Umbrella on Ground.png",
    "sha": "0b04ac34e2e75cdeb77a60a339972c0a0eeca4ce",
    "size": 13959,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella%20on%20Ground.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella%20on%20Ground.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0b04ac34e2e75cdeb77a60a339972c0a0eeca4ce",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Umbrella%20on%20Ground.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella%20on%20Ground.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0b04ac34e2e75cdeb77a60a339972c0a0eeca4ce",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella%20on%20Ground.png"
    }
  },
  {
    "name": "Umbrella with Rain Drops.png",
    "path": "Emojis/Travel and places/Umbrella with Rain Drops.png",
    "sha": "fd11ed7d7d0903801fdbc2f9498ab303f63f8de7",
    "size": 828254,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella%20with%20Rain%20Drops.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella%20with%20Rain%20Drops.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd11ed7d7d0903801fdbc2f9498ab303f63f8de7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Umbrella%20with%20Rain%20Drops.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella%20with%20Rain%20Drops.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fd11ed7d7d0903801fdbc2f9498ab303f63f8de7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella%20with%20Rain%20Drops.png"
    }
  },
  {
    "name": "Umbrella.png",
    "path": "Emojis/Travel and places/Umbrella.png",
    "sha": "ad312ddb7e00dd73aa1ae3413170213b42d92108",
    "size": 734245,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad312ddb7e00dd73aa1ae3413170213b42d92108",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Umbrella.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Umbrella.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad312ddb7e00dd73aa1ae3413170213b42d92108",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Umbrella.png"
    }
  },
  {
    "name": "Vertical Traffic Light.png",
    "path": "Emojis/Travel and places/Vertical Traffic Light.png",
    "sha": "752de57d2af68d6f7047f4dd89509a31241e1915",
    "size": 8867,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Vertical%20Traffic%20Light.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Vertical%20Traffic%20Light.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/752de57d2af68d6f7047f4dd89509a31241e1915",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Vertical%20Traffic%20Light.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Vertical%20Traffic%20Light.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/752de57d2af68d6f7047f4dd89509a31241e1915",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Vertical%20Traffic%20Light.png"
    }
  },
  {
    "name": "Volcano.png",
    "path": "Emojis/Travel and places/Volcano.png",
    "sha": "3ff053bd3484fc09633809a23163552c1788239d",
    "size": 11191,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Volcano.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Volcano.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ff053bd3484fc09633809a23163552c1788239d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Volcano.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Volcano.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ff053bd3484fc09633809a23163552c1788239d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Volcano.png"
    }
  },
  {
    "name": "Waning Crescent Moon.png",
    "path": "Emojis/Travel and places/Waning Crescent Moon.png",
    "sha": "e3756d8238f07d7941e6b28783751dd85ab40eda",
    "size": 18155,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waning%20Crescent%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waning%20Crescent%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e3756d8238f07d7941e6b28783751dd85ab40eda",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Waning%20Crescent%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waning%20Crescent%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e3756d8238f07d7941e6b28783751dd85ab40eda",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waning%20Crescent%20Moon.png"
    }
  },
  {
    "name": "Waning Gibbous Moon.png",
    "path": "Emojis/Travel and places/Waning Gibbous Moon.png",
    "sha": "ef2233e77b61f35af914550e594237c6891a27ee",
    "size": 17612,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waning%20Gibbous%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waning%20Gibbous%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef2233e77b61f35af914550e594237c6891a27ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Waning%20Gibbous%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waning%20Gibbous%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ef2233e77b61f35af914550e594237c6891a27ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waning%20Gibbous%20Moon.png"
    }
  },
  {
    "name": "Watch.png",
    "path": "Emojis/Travel and places/Watch.png",
    "sha": "f13a2a9bc00f48f654052d49a867c530ca30ae1e",
    "size": 700341,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Watch.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Watch.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f13a2a9bc00f48f654052d49a867c530ca30ae1e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Watch.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Watch.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f13a2a9bc00f48f654052d49a867c530ca30ae1e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Watch.png"
    }
  },
  {
    "name": "Water Wave.png",
    "path": "Emojis/Travel and places/Water Wave.png",
    "sha": "e4064ae7752b948c517549936091af8e5be9ae67",
    "size": 956153,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Water%20Wave.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Water%20Wave.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e4064ae7752b948c517549936091af8e5be9ae67",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Water%20Wave.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Water%20Wave.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e4064ae7752b948c517549936091af8e5be9ae67",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Water%20Wave.png"
    }
  },
  {
    "name": "Waxing Crescent Moon.png",
    "path": "Emojis/Travel and places/Waxing Crescent Moon.png",
    "sha": "28fcce0bfe860e586131f4b9841c937b437c2646",
    "size": 17346,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waxing%20Crescent%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waxing%20Crescent%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/28fcce0bfe860e586131f4b9841c937b437c2646",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Waxing%20Crescent%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waxing%20Crescent%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/28fcce0bfe860e586131f4b9841c937b437c2646",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waxing%20Crescent%20Moon.png"
    }
  },
  {
    "name": "Waxing Gibbous Moon.png",
    "path": "Emojis/Travel and places/Waxing Gibbous Moon.png",
    "sha": "1b1c911f554326c31fb9add1cdef4de822b1b8bf",
    "size": 18374,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waxing%20Gibbous%20Moon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waxing%20Gibbous%20Moon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1b1c911f554326c31fb9add1cdef4de822b1b8bf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Waxing%20Gibbous%20Moon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Waxing%20Gibbous%20Moon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1b1c911f554326c31fb9add1cdef4de822b1b8bf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Waxing%20Gibbous%20Moon.png"
    }
  },
  {
    "name": "Wedding.png",
    "path": "Emojis/Travel and places/Wedding.png",
    "sha": "3015a4c283397f2b688f9336a8a877e9836f4d28",
    "size": 12223,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wedding.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wedding.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3015a4c283397f2b688f9336a8a877e9836f4d28",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Wedding.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wedding.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3015a4c283397f2b688f9336a8a877e9836f4d28",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wedding.png"
    }
  },
  {
    "name": "Wind Face.png",
    "path": "Emojis/Travel and places/Wind Face.png",
    "sha": "648d37fa62efbcd9ea28bfbe65e129d074ccc187",
    "size": 927930,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wind%20Face.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wind%20Face.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/648d37fa62efbcd9ea28bfbe65e129d074ccc187",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Wind%20Face.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wind%20Face.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/648d37fa62efbcd9ea28bfbe65e129d074ccc187",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wind%20Face.png"
    }
  },
  {
    "name": "Wood.png",
    "path": "Emojis/Travel and places/Wood.png",
    "sha": "80cd8cf7ff48cffd208e8a7bd5b0abd06ed21283",
    "size": 715209,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wood.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wood.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/80cd8cf7ff48cffd208e8a7bd5b0abd06ed21283",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/Wood.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/Wood.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/80cd8cf7ff48cffd208e8a7bd5b0abd06ed21283",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/Wood.png"
    }
  },
  {
    "name": "World Map.png",
    "path": "Emojis/Travel and places/World Map.png",
    "sha": "36a7cd0ba9e5b1a63019a8604e9f878f5505991e",
    "size": 15994,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/World%20Map.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/World%20Map.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/36a7cd0ba9e5b1a63019a8604e9f878f5505991e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Travel%20and%20places/World%20Map.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Travel%20and%20places/World%20Map.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/36a7cd0ba9e5b1a63019a8604e9f878f5505991e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Travel%20and%20places/World%20Map.png"
    }
  }
]

export default {
  id: 'places',
  name: 'Places',
  emojis: customEmojis.map((emoji) => {
    return {
      id: emoji.sha,
      name: emoji.name,
      skins: [{ src: emoji.download_url }]
    }
  })
}