import { createSlice } from '@reduxjs/toolkit';

const currentTimeLogSlice = createSlice({
  name: 'currentTimeLog',
  initialState: null,
  reducers: {
    setCurrentTimeLog: (state, action) => {
      return action.payload;
    },
  },
});

export const currentTimeLogReducer = currentTimeLogSlice.reducer;

// Action creators are generated for each case reducer function
export const { setCurrentTimeLog } = currentTimeLogSlice.actions;