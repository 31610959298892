import { CheckCircleTwoTone, PlusOutlined, UserOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons"
import { Avatar, Button, Flex, Table, Tabs, Typography, Popconfirm } from "antd"
import { useEffect, useState } from "react";
import { get, xdelete } from "../services/api";
import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';
import relativeTime from 'dayjs/plugin/relativeTime';
import TransactionCreate from "../components/TransactionCreate";
import TransactionEdit from "../components/TransactionEdit";
dayjs.extend(relativeTime)
dayjs.extend(calendar)

export default function Transactions () {
  const [transactions, setTransactions] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (name) => {
        return <div className="avatar-with-profile">
          <Avatar size={40} style={{ minWidth: "40px" }} icon={<UserOutlined />} />
          <div className="heading-with-tagline">
            <a href="#">{name}</a>
            <span></span>
          </div>
        </div>
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount, {startAt, endAt}) => {
        console.log(startAt, endAt);
        return <div className="heading-with-tagline">
          <Typography.Text className="amount" type={ amount < 0 ? "danger" : "success" }>{
            new Intl.NumberFormat('en-IN', {
              style: 'currency',
              currency: 'INR',
              maximumFractionDigits: 0
            }).format(Math.abs(amount))
          }</Typography.Text>
          <span>for { startAt && endAt ? dayjs(startAt).to(endAt, true) : 'a unit' }</span>
        </div>
      },
    },
    {
      title: 'Start Date',
      render: ({startAt, amount, createdAt}) => {
        return <div className="heading-with-tagline">
          <Typography.Text style={{ fontSize: "14px" }} type={ amount < 0 ? "primary" : "primary" }>
            { startAt ? dayjs(startAt).format('MMMM D, YYYY') : dayjs(createdAt).format('MMMM D, YYYY') }
          </Typography.Text>
        </div>
      },
    },
    {
      title: 'End Date',
      render: ({endAt, amount, createdAt}) => {
        return <div className="heading-with-tagline">
          <Typography.Text style={{ fontSize: "14px" }} type={ amount < 0 ? "primary" : "primary" }>
            { endAt ? dayjs(endAt).subtract(1, 'second').format('MMMM D, YYYY') : dayjs(createdAt).format('MMMM D, YYYY') }
          </Typography.Text>
        </div>
      },
    },
    {
      title: 'Actions',
      render: (transaction) => {
        return transaction?.settled ?  <CheckCircleTwoTone /> : <>
            <Button onClick={() => showEditModal(transaction)} type="default" shape="circle" icon={<EditOutlined />}></Button>

            <Popconfirm
              title="Delete Transaction"
              placement="bottomRight"
              description="Are you sure to delete this Transaction?"
              onConfirm={() => deleteTransaction(transaction)}
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm></>
        },
      }
  ];


  const deleteTransaction = async (transaction) => {
    await xdelete(`/transactions/${transaction.id}`);
    await refreshTransactions();
  };

  const showEditModal = (transaction) => {
    setTransaction(transaction)
    setIsEditModalOpen(true);
  };
  const refreshTransactions = async (filter = { order: 'startAt ASC' }) => {
    await get("/transactions", filter).then(setTransactions);
  }
  const selectType = async (type) => {
    if(type === 'all') { return refreshTransactions() }
    else if(type === 'settled') { await refreshTransactions({ order: 'startAt ASC', where : { settled: 1 } }) }
    else if(type === 'unsettled') { await refreshTransactions({ order: 'startAt ASC', where : { settled: null } }) }
  }
  useEffect(() => {
    refreshTransactions();
  }, []);
  return <Flex gap={40}>
    <div>
      <Button type="primary" className="align bold" icon={<PlusOutlined />} style={{ margin: "10px 0 36px" }} onClick={showModal}>
        Add Transaction
      </Button>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `All`,
          key: 'all'
        },{
          label: `Settled`,
          key: 'settled'
        },{
          label: `Unsettled`,
          key: 'unsettled'
        }]}
      />
    </div>
    <Table style={{ flex: '1' }} scroll={{ y: window.innerHeight-120 }} pagination={false} dataSource={transactions} rowKey={'id'} columns={columns} />
    <TransactionCreate isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} onTransactionCreate={refreshTransactions}/>
    <TransactionEdit isModalOpen={isEditModalOpen} setIsModalOpen={setIsEditModalOpen} onTransactionEdit={refreshTransactions} transaction={transaction} setTransaction={setTransaction}/>
  </Flex>
}