import { createSlice } from '@reduxjs/toolkit';

const assetsSlice = createSlice({
  name: 'assets',
  initialState: [],
  reducers: {
    setAssets: (state, action) => {
      window.Assets = action.payload;
      return action.payload;
    },
  },
});

export const assetsReducer = assetsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setAssets } = assetsSlice.actions;