import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { post } from "../services/api";
import dayjs from "dayjs";

export default function AssetCreate({ isModalOpen, setIsModalOpen, onAssetCreate}) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values, 
      lastUsed: values.lastUsed ? moment(values.lastUsed.unix()*1000) : null
    };
    if (!values.lastUsed) {
      delete data.lastUsed;
    }
    const asset = await post('/assets', data);
    setIsModalOpen(false);
    onAssetCreate(asset);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return <Modal title="Add Asset" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addAssetForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addAssetForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Please input asset name!',
          }]}>
          <Input autoFocus={true} />
        </Form.Item>
        </Flex>
      
    </Form>
  </Modal>
}