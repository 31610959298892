import { createSlice } from '@reduxjs/toolkit';

const projectsSlice = createSlice({
  name: 'projects',
  initialState: [],
  reducers: {
    setProjects: (state, action) => {
      window.projects = action.payload;
      return action.payload;
    },
  },
});

export const projectsReducer = projectsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setProjects } = projectsSlice.actions;