import { createSlice } from '@reduxjs/toolkit';

const humansSlice = createSlice({
  name: 'humans',
  initialState: [],
  reducers: {
    setHumans: (state, action) => {
      // window.humans = action.payload;
      return action.payload;
    },
  },
});

export const humansReducer = humansSlice.reducer;

// Action creators are generated for each case reducer function
export const { setHumans } = humansSlice.actions;