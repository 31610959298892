import { Button, Form, Popover, Select } from "antd";
import { useEffect, useState } from "react";
import { patch } from "../services/api";

const { Option } = Select;
const timeOptions = [
  { label: "15 mins", value: 0.25 },
  { label: "30 mins", value: 0.5 },
  { label: "45 mins", value: 0.75 },
  { label: "1 hour", value: 1 },
  { label: "1.5 hours", value: 1.5 },
  { label: "2 hours", value: 2 },
  { label: "2.5 hours", value: 2.5 },
  { label: "3 hours", value: 3 },
  { label: "4 hours", value: 4 },
  { label: "5 hours", value: 5 },
  { label: "6 hours", value: 6 }, // A longer day
  { label: "7 hours", value: 7 },
  { label: "8 hours (1 day)", value: 8 },
  { label: "1.5 days", value: 12 },
  { label: "2 days", value: 16 },
  { label: "3 days", value: 24 },
  { label: "4 days", value: 32 },
  { label: "5 days", value: 40 }, // A full workweek
  { label: "1 week", value: 40 },
  { label: "1.5 weeks", value: 60 },
  { label: "2 weeks", value: 80 },
  { label: "3 weeks", value: 120 },
  { label: "1 month", value: 160 },
  { label: "1.5 months", value: 240 },
  { label: "2 months", value: 320 },
  { label: "3 months", value: 480 },
  { label: "6 months", value: 960 },
  { label: "1 year", value: 1920 },
];

export default function EstimatedTimePicker({ task, onUpdateEstTime }) {
  const [options, setOptions] = useState(timeOptions);

  useEffect(() => {
    // Reset options to predefined if the input is cleared
    if (task.estTime === undefined) {
      setOptions(timeOptions);
    }
  }, [task.estTime]);

  const handleSearch = (value) => {
    if (!value) {
      setOptions(timeOptions);
      return;
    }
    // Check if the input value is already an option
    const isExistingOption = timeOptions.some(option => option.value.toString() === value);
    if (!isExistingOption && !isNaN(value)) {
      // Add a custom option based on the input if it's a number and not already an option
      setOptions([...timeOptions, { label: `${value} hours`, value: parseFloat(value) }]);
    } else {
      setOptions(timeOptions);
    }
  };

  const handleChange = (value) => {
    onUpdateEstTime(task.id, value);
  }

  return <Select
    showSearch
    autoFocus={true}
    style={{ width: 200 }}
    placeholder="Select or type estimated hours"
    optionFilterProp="children"
    onChange={handleChange}
    onSearch={handleSearch}
    filterOption={(input, option) =>
      (option.children || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
    value={task.estTime}>
    {options.map((option, index) => (
      <Option key={index} value={option.value}>{option.label}</Option>
    ))}
  </Select>

  return <Popover placement="bottom" content={(
    <Form layout="vertical">
      <Form.Item
        style={{ flex: 1 }}
        label="Estimated Time Required"
        name="estTime">
      </Form.Item>
      <Form.Item>
        <Button type="primary" onClick={() => { }}>Add</Button>
      </Form.Item>
    </Form>
  )} trigger="click">
    { task.estTime || "Add" }
  </Popover>
}