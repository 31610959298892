const customEmojis = [
  {
    "name": "Abacus.png",
    "path": "Emojis/Objects/Abacus.png",
    "sha": "733e5fa8e71813b158f51b0865f4c47103de1c0d",
    "size": 13254,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Abacus.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Abacus.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/733e5fa8e71813b158f51b0865f4c47103de1c0d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Abacus.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Abacus.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/733e5fa8e71813b158f51b0865f4c47103de1c0d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Abacus.png"
    }
  },
  {
    "name": "Accordion.png",
    "path": "Emojis/Objects/Accordion.png",
    "sha": "7d5de25143600c4b52b3019fea184dccefd80d18",
    "size": 17247,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Accordion.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Accordion.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d5de25143600c4b52b3019fea184dccefd80d18",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Accordion.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Accordion.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7d5de25143600c4b52b3019fea184dccefd80d18",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Accordion.png"
    }
  },
  {
    "name": "Alembic.png",
    "path": "Emojis/Objects/Alembic.png",
    "sha": "4768664b5270d5c74ee11312c0a64ef02b5a10c5",
    "size": 11555,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Alembic.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Alembic.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4768664b5270d5c74ee11312c0a64ef02b5a10c5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Alembic.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Alembic.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4768664b5270d5c74ee11312c0a64ef02b5a10c5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Alembic.png"
    }
  },
  {
    "name": "Axe.png",
    "path": "Emojis/Objects/Axe.png",
    "sha": "0d519fdb1bcf57b04ae1002421b0e619c0189c5a",
    "size": 7277,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Axe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Axe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d519fdb1bcf57b04ae1002421b0e619c0189c5a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Axe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Axe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d519fdb1bcf57b04ae1002421b0e619c0189c5a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Axe.png"
    }
  },
  {
    "name": "Backpack.png",
    "path": "Emojis/Objects/Backpack.png",
    "sha": "696397921fc11fec30fa3c56afa07340523c13e3",
    "size": 15421,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Backpack.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Backpack.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/696397921fc11fec30fa3c56afa07340523c13e3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Backpack.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Backpack.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/696397921fc11fec30fa3c56afa07340523c13e3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Backpack.png"
    }
  },
  {
    "name": "Balance Scale.png",
    "path": "Emojis/Objects/Balance Scale.png",
    "sha": "b8a601bbff24fa227c188e83894f0e41277253fb",
    "size": 12060,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Balance%20Scale.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Balance%20Scale.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b8a601bbff24fa227c188e83894f0e41277253fb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Balance%20Scale.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Balance%20Scale.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b8a601bbff24fa227c188e83894f0e41277253fb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Balance%20Scale.png"
    }
  },
  {
    "name": "Ballet Shoes.png",
    "path": "Emojis/Objects/Ballet Shoes.png",
    "sha": "a2d06a0bfc9e0938da61a5324aa6160dec7dc9eb",
    "size": 19526,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ballet%20Shoes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ballet%20Shoes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a2d06a0bfc9e0938da61a5324aa6160dec7dc9eb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ballet%20Shoes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ballet%20Shoes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a2d06a0bfc9e0938da61a5324aa6160dec7dc9eb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ballet%20Shoes.png"
    }
  },
  {
    "name": "Ballot Box with Ballot.png",
    "path": "Emojis/Objects/Ballot Box with Ballot.png",
    "sha": "e3b59c7853e525c619e488e3db9746e0abcd4f0b",
    "size": 10800,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ballot%20Box%20with%20Ballot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ballot%20Box%20with%20Ballot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e3b59c7853e525c619e488e3db9746e0abcd4f0b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ballot%20Box%20with%20Ballot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ballot%20Box%20with%20Ballot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e3b59c7853e525c619e488e3db9746e0abcd4f0b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ballot%20Box%20with%20Ballot.png"
    }
  },
  {
    "name": "Banjo.png",
    "path": "Emojis/Objects/Banjo.png",
    "sha": "33abfcfea4c65e45e3e9a47d2699dc6b07d6f3a2",
    "size": 10625,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Banjo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Banjo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/33abfcfea4c65e45e3e9a47d2699dc6b07d6f3a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Banjo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Banjo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/33abfcfea4c65e45e3e9a47d2699dc6b07d6f3a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Banjo.png"
    }
  },
  {
    "name": "Bar Chart.png",
    "path": "Emojis/Objects/Bar Chart.png",
    "sha": "9146c8bd6b6fc7df9d49923f58efbf45b62a53f5",
    "size": 16083,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bar%20Chart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bar%20Chart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9146c8bd6b6fc7df9d49923f58efbf45b62a53f5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bar%20Chart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bar%20Chart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9146c8bd6b6fc7df9d49923f58efbf45b62a53f5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bar%20Chart.png"
    }
  },
  {
    "name": "Basket.png",
    "path": "Emojis/Objects/Basket.png",
    "sha": "df14a84feaf67d2594ebe4fde379e72bf147be43",
    "size": 15885,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Basket.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Basket.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df14a84feaf67d2594ebe4fde379e72bf147be43",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Basket.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Basket.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df14a84feaf67d2594ebe4fde379e72bf147be43",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Basket.png"
    }
  },
  {
    "name": "Bathtub.png",
    "path": "Emojis/Objects/Bathtub.png",
    "sha": "c43232248c55dd18aa9d82c8432ec6dc79764649",
    "size": 10644,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bathtub.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bathtub.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c43232248c55dd18aa9d82c8432ec6dc79764649",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bathtub.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bathtub.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c43232248c55dd18aa9d82c8432ec6dc79764649",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bathtub.png"
    }
  },
  {
    "name": "Battery.png",
    "path": "Emojis/Objects/Battery.png",
    "sha": "59b1cf4aae18310808cd672090c108971a526dca",
    "size": 6970,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Battery.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Battery.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59b1cf4aae18310808cd672090c108971a526dca",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Battery.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Battery.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59b1cf4aae18310808cd672090c108971a526dca",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Battery.png"
    }
  },
  {
    "name": "Bed.png",
    "path": "Emojis/Objects/Bed.png",
    "sha": "3c624af823e3ccd5add93def916ceb77e2949d08",
    "size": 7354,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bed.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bed.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c624af823e3ccd5add93def916ceb77e2949d08",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bed.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bed.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3c624af823e3ccd5add93def916ceb77e2949d08",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bed.png"
    }
  },
  {
    "name": "Bell with Slash.png",
    "path": "Emojis/Objects/Bell with Slash.png",
    "sha": "4415b1b3526f825cb2e59f8fe7b351c3be66a6b0",
    "size": 13001,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bell%20with%20Slash.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bell%20with%20Slash.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4415b1b3526f825cb2e59f8fe7b351c3be66a6b0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bell%20with%20Slash.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bell%20with%20Slash.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4415b1b3526f825cb2e59f8fe7b351c3be66a6b0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bell%20with%20Slash.png"
    }
  },
  {
    "name": "Bell.png",
    "path": "Emojis/Objects/Bell.png",
    "sha": "1c8fab292087309b2b359a9aeede61fe933b2343",
    "size": 12399,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bell.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bell.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c8fab292087309b2b359a9aeede61fe933b2343",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bell.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bell.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c8fab292087309b2b359a9aeede61fe933b2343",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bell.png"
    }
  },
  {
    "name": "Bikini.png",
    "path": "Emojis/Objects/Bikini.png",
    "sha": "7c83684b7ae32201711c87e20ba267785683fc74",
    "size": 15218,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bikini.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bikini.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c83684b7ae32201711c87e20ba267785683fc74",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bikini.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bikini.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c83684b7ae32201711c87e20ba267785683fc74",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bikini.png"
    }
  },
  {
    "name": "Billed Cap.png",
    "path": "Emojis/Objects/Billed Cap.png",
    "sha": "df5e29b7d482591c4405a11ebf897767adbc9315",
    "size": 9478,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Billed%20Cap.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Billed%20Cap.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df5e29b7d482591c4405a11ebf897767adbc9315",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Billed%20Cap.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Billed%20Cap.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df5e29b7d482591c4405a11ebf897767adbc9315",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Billed%20Cap.png"
    }
  },
  {
    "name": "Black Nib.png",
    "path": "Emojis/Objects/Black Nib.png",
    "sha": "49cef0a3274ed6e81c56cc5433a46c3cb36b6457",
    "size": 9330,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Black%20Nib.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Black%20Nib.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/49cef0a3274ed6e81c56cc5433a46c3cb36b6457",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Black%20Nib.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Black%20Nib.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/49cef0a3274ed6e81c56cc5433a46c3cb36b6457",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Black%20Nib.png"
    }
  },
  {
    "name": "Blue Book.png",
    "path": "Emojis/Objects/Blue Book.png",
    "sha": "383287c5bc33e3f5d0c5c55db113e52be6d960a6",
    "size": 8416,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Blue%20Book.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Blue%20Book.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/383287c5bc33e3f5d0c5c55db113e52be6d960a6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Blue%20Book.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Blue%20Book.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/383287c5bc33e3f5d0c5c55db113e52be6d960a6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Blue%20Book.png"
    }
  },
  {
    "name": "Bookmark Tabs.png",
    "path": "Emojis/Objects/Bookmark Tabs.png",
    "sha": "92a7cfe0d70262b91246ee8fd4336411e671c5b9",
    "size": 13922,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bookmark%20Tabs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bookmark%20Tabs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92a7cfe0d70262b91246ee8fd4336411e671c5b9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bookmark%20Tabs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bookmark%20Tabs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92a7cfe0d70262b91246ee8fd4336411e671c5b9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bookmark%20Tabs.png"
    }
  },
  {
    "name": "Bookmark.png",
    "path": "Emojis/Objects/Bookmark.png",
    "sha": "c8f776f196384a1f5e456053edfd7bb82730d0d3",
    "size": 8286,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bookmark.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bookmark.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8f776f196384a1f5e456053edfd7bb82730d0d3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bookmark.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bookmark.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8f776f196384a1f5e456053edfd7bb82730d0d3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bookmark.png"
    }
  },
  {
    "name": "Books.png",
    "path": "Emojis/Objects/Books.png",
    "sha": "ee5037077cd858e08f340f89a1a33e4490856a35",
    "size": 11210,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Books.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Books.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee5037077cd858e08f340f89a1a33e4490856a35",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Books.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Books.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee5037077cd858e08f340f89a1a33e4490856a35",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Books.png"
    }
  },
  {
    "name": "Boomerang.png",
    "path": "Emojis/Objects/Boomerang.png",
    "sha": "5a1cab50ef4cd4154588087d68964b273271b4e7",
    "size": 10220,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Boomerang.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Boomerang.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a1cab50ef4cd4154588087d68964b273271b4e7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Boomerang.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Boomerang.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a1cab50ef4cd4154588087d68964b273271b4e7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Boomerang.png"
    }
  },
  {
    "name": "Bow and Arrow.png",
    "path": "Emojis/Objects/Bow and Arrow.png",
    "sha": "a84036986cf2e8561ea75e6513f12aeecb9aa73c",
    "size": 12158,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bow%20and%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bow%20and%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a84036986cf2e8561ea75e6513f12aeecb9aa73c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bow%20and%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bow%20and%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a84036986cf2e8561ea75e6513f12aeecb9aa73c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bow%20and%20Arrow.png"
    }
  },
  {
    "name": "Briefcase.png",
    "path": "Emojis/Objects/Briefcase.png",
    "sha": "89f9a43ed264cc4f1287a3ff0d102ae0fef9670f",
    "size": 9660,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Briefcase.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Briefcase.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89f9a43ed264cc4f1287a3ff0d102ae0fef9670f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Briefcase.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Briefcase.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89f9a43ed264cc4f1287a3ff0d102ae0fef9670f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Briefcase.png"
    }
  },
  {
    "name": "Briefs.png",
    "path": "Emojis/Objects/Briefs.png",
    "sha": "5a4d9b1e16d7a4a34bd7949cb63bdcd0031cf53b",
    "size": 10263,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Briefs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Briefs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a4d9b1e16d7a4a34bd7949cb63bdcd0031cf53b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Briefs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Briefs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a4d9b1e16d7a4a34bd7949cb63bdcd0031cf53b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Briefs.png"
    }
  },
  {
    "name": "Broom.png",
    "path": "Emojis/Objects/Broom.png",
    "sha": "851295d2b89d6b6c7e0b1f6aff48e34e344126e0",
    "size": 6153,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Broom.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Broom.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/851295d2b89d6b6c7e0b1f6aff48e34e344126e0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Broom.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Broom.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/851295d2b89d6b6c7e0b1f6aff48e34e344126e0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Broom.png"
    }
  },
  {
    "name": "Bucket.png",
    "path": "Emojis/Objects/Bucket.png",
    "sha": "7905051714c17244601f0654d6e1b96f50afc4ee",
    "size": 12911,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bucket.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bucket.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7905051714c17244601f0654d6e1b96f50afc4ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Bucket.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Bucket.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7905051714c17244601f0654d6e1b96f50afc4ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Bucket.png"
    }
  },
  {
    "name": "Calendar.png",
    "path": "Emojis/Objects/Calendar.png",
    "sha": "e8d4955162c8b49efe06d5b2a705410a6e7ece9d",
    "size": 13486,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Calendar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Calendar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8d4955162c8b49efe06d5b2a705410a6e7ece9d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Calendar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Calendar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8d4955162c8b49efe06d5b2a705410a6e7ece9d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Calendar.png"
    }
  },
  {
    "name": "Camera with Flash.png",
    "path": "Emojis/Objects/Camera with Flash.png",
    "sha": "d9391db500b1b55a86589142ecc0e6f20722a99e",
    "size": 602027,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Camera%20with%20Flash.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Camera%20with%20Flash.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9391db500b1b55a86589142ecc0e6f20722a99e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Camera%20with%20Flash.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Camera%20with%20Flash.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9391db500b1b55a86589142ecc0e6f20722a99e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Camera%20with%20Flash.png"
    }
  },
  {
    "name": "Camera.png",
    "path": "Emojis/Objects/Camera.png",
    "sha": "54e9985528af577e63f0bb46bf64349f939aa391",
    "size": 13317,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Camera.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Camera.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54e9985528af577e63f0bb46bf64349f939aa391",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Camera.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Camera.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54e9985528af577e63f0bb46bf64349f939aa391",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Camera.png"
    }
  },
  {
    "name": "Candle.png",
    "path": "Emojis/Objects/Candle.png",
    "sha": "e73fc0c02225ad322c6c7fcb197a85154e69879f",
    "size": 8218,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Candle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Candle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e73fc0c02225ad322c6c7fcb197a85154e69879f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Candle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Candle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e73fc0c02225ad322c6c7fcb197a85154e69879f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Candle.png"
    }
  },
  {
    "name": "Card File Box.png",
    "path": "Emojis/Objects/Card File Box.png",
    "sha": "91247ad819b17935e5718b39b844163a8c154902",
    "size": 12518,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20File%20Box.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20File%20Box.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91247ad819b17935e5718b39b844163a8c154902",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Card%20File%20Box.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20File%20Box.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91247ad819b17935e5718b39b844163a8c154902",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20File%20Box.png"
    }
  },
  {
    "name": "Card Index Dividers.png",
    "path": "Emojis/Objects/Card Index Dividers.png",
    "sha": "b0a2ff616d80875c2ef27bdff367d901885e7269",
    "size": 10297,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20Index%20Dividers.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20Index%20Dividers.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0a2ff616d80875c2ef27bdff367d901885e7269",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Card%20Index%20Dividers.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20Index%20Dividers.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b0a2ff616d80875c2ef27bdff367d901885e7269",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20Index%20Dividers.png"
    }
  },
  {
    "name": "Card Index.png",
    "path": "Emojis/Objects/Card Index.png",
    "sha": "f480008a87ef5c56001810fd8dded0ca26ed47c9",
    "size": 13320,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20Index.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20Index.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f480008a87ef5c56001810fd8dded0ca26ed47c9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Card%20Index.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Card%20Index.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f480008a87ef5c56001810fd8dded0ca26ed47c9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Card%20Index.png"
    }
  },
  {
    "name": "Carpentry Saw.png",
    "path": "Emojis/Objects/Carpentry Saw.png",
    "sha": "248c6f29027afe172a199d7ef12ab6810281ad5c",
    "size": 6896,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Carpentry%20Saw.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Carpentry%20Saw.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/248c6f29027afe172a199d7ef12ab6810281ad5c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Carpentry%20Saw.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Carpentry%20Saw.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/248c6f29027afe172a199d7ef12ab6810281ad5c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Carpentry%20Saw.png"
    }
  },
  {
    "name": "Chains.png",
    "path": "Emojis/Objects/Chains.png",
    "sha": "483dfd85494bfa29b17214df635886a19225bdb6",
    "size": 18534,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chains.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chains.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/483dfd85494bfa29b17214df635886a19225bdb6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Chains.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chains.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/483dfd85494bfa29b17214df635886a19225bdb6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chains.png"
    }
  },
  {
    "name": "Chair.png",
    "path": "Emojis/Objects/Chair.png",
    "sha": "a5c3c935bfe8df5adb10e6276a18d2233e918a51",
    "size": 7984,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chair.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chair.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a5c3c935bfe8df5adb10e6276a18d2233e918a51",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Chair.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chair.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a5c3c935bfe8df5adb10e6276a18d2233e918a51",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chair.png"
    }
  },
  {
    "name": "Chart Decreasing.png",
    "path": "Emojis/Objects/Chart Decreasing.png",
    "sha": "83b7b6d46860e9ea498c5961e07b246a75b05bed",
    "size": 15718,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Decreasing.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Decreasing.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/83b7b6d46860e9ea498c5961e07b246a75b05bed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Chart%20Decreasing.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Decreasing.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/83b7b6d46860e9ea498c5961e07b246a75b05bed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Decreasing.png"
    }
  },
  {
    "name": "Chart Increasing with Yen.png",
    "path": "Emojis/Objects/Chart Increasing with Yen.png",
    "sha": "3dda2045e651d914ae2212c3f84e978a9ce44ddf",
    "size": 14384,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Increasing%20with%20Yen.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Increasing%20with%20Yen.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3dda2045e651d914ae2212c3f84e978a9ce44ddf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Chart%20Increasing%20with%20Yen.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Increasing%20with%20Yen.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3dda2045e651d914ae2212c3f84e978a9ce44ddf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Increasing%20with%20Yen.png"
    }
  },
  {
    "name": "Chart Increasing.png",
    "path": "Emojis/Objects/Chart Increasing.png",
    "sha": "d5cff7b95a7863fbc2b0b7d639516662ce354c92",
    "size": 15599,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Increasing.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Increasing.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5cff7b95a7863fbc2b0b7d639516662ce354c92",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Chart%20Increasing.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Chart%20Increasing.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d5cff7b95a7863fbc2b0b7d639516662ce354c92",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Chart%20Increasing.png"
    }
  },
  {
    "name": "Cigarette.png",
    "path": "Emojis/Objects/Cigarette.png",
    "sha": "566480d93d5cf0d77870d6885e5d8957f89c62a3",
    "size": 7387,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Cigarette.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Cigarette.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/566480d93d5cf0d77870d6885e5d8957f89c62a3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Cigarette.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Cigarette.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/566480d93d5cf0d77870d6885e5d8957f89c62a3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Cigarette.png"
    }
  },
  {
    "name": "Clamp.png",
    "path": "Emojis/Objects/Clamp.png",
    "sha": "5da59eb4088c00c2daf1b956a736e2c7269b569e",
    "size": 9184,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clamp.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clamp.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5da59eb4088c00c2daf1b956a736e2c7269b569e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Clamp.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clamp.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5da59eb4088c00c2daf1b956a736e2c7269b569e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clamp.png"
    }
  },
  {
    "name": "Clapper Board.png",
    "path": "Emojis/Objects/Clapper Board.png",
    "sha": "6d9f6f65548f68735eb1c50d4bf2037afdaa1157",
    "size": 10551,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clapper%20Board.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clapper%20Board.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d9f6f65548f68735eb1c50d4bf2037afdaa1157",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Clapper%20Board.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clapper%20Board.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d9f6f65548f68735eb1c50d4bf2037afdaa1157",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clapper%20Board.png"
    }
  },
  {
    "name": "Clipboard.png",
    "path": "Emojis/Objects/Clipboard.png",
    "sha": "58d9a045decdc95cd67792659d7e62da79ac79cb",
    "size": 11382,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clipboard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clipboard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/58d9a045decdc95cd67792659d7e62da79ac79cb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Clipboard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clipboard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/58d9a045decdc95cd67792659d7e62da79ac79cb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clipboard.png"
    }
  },
  {
    "name": "Closed Book.png",
    "path": "Emojis/Objects/Closed Book.png",
    "sha": "ee7cfa1f7eec2869b89f6ea333d82ed508d8cd3e",
    "size": 7707,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Book.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Book.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee7cfa1f7eec2869b89f6ea333d82ed508d8cd3e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Closed%20Book.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Book.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ee7cfa1f7eec2869b89f6ea333d82ed508d8cd3e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Book.png"
    }
  },
  {
    "name": "Closed Mailbox with Lowered Flag.png",
    "path": "Emojis/Objects/Closed Mailbox with Lowered Flag.png",
    "sha": "3ca895f11192aad64f65bb0ea279ebedcd1db023",
    "size": 8422,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Mailbox%20with%20Lowered%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Mailbox%20with%20Lowered%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ca895f11192aad64f65bb0ea279ebedcd1db023",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Closed%20Mailbox%20with%20Lowered%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Mailbox%20with%20Lowered%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3ca895f11192aad64f65bb0ea279ebedcd1db023",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Mailbox%20with%20Lowered%20Flag.png"
    }
  },
  {
    "name": "Closed Mailbox with Raised Flag.png",
    "path": "Emojis/Objects/Closed Mailbox with Raised Flag.png",
    "sha": "ecb7cfa8f71d064cc26275a3d3ba33be86de4b58",
    "size": 8093,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Mailbox%20with%20Raised%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Mailbox%20with%20Raised%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ecb7cfa8f71d064cc26275a3d3ba33be86de4b58",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Closed%20Mailbox%20with%20Raised%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Closed%20Mailbox%20with%20Raised%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ecb7cfa8f71d064cc26275a3d3ba33be86de4b58",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Closed%20Mailbox%20with%20Raised%20Flag.png"
    }
  },
  {
    "name": "Clutch Bag.png",
    "path": "Emojis/Objects/Clutch Bag.png",
    "sha": "7c0f3f77b7a6129d2dfb0a64093267ce6039c4c0",
    "size": 9771,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clutch%20Bag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clutch%20Bag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c0f3f77b7a6129d2dfb0a64093267ce6039c4c0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Clutch%20Bag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Clutch%20Bag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c0f3f77b7a6129d2dfb0a64093267ce6039c4c0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Clutch%20Bag.png"
    }
  },
  {
    "name": "Coat.png",
    "path": "Emojis/Objects/Coat.png",
    "sha": "7662c1238492b48359ea688e2c485998f5889544",
    "size": 11224,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7662c1238492b48359ea688e2c485998f5889544",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Coat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7662c1238492b48359ea688e2c485998f5889544",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coat.png"
    }
  },
  {
    "name": "Coffin.png",
    "path": "Emojis/Objects/Coffin.png",
    "sha": "1f64d8a0132028b1470dbdd500e78f52160cfd57",
    "size": 10149,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coffin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coffin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f64d8a0132028b1470dbdd500e78f52160cfd57",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Coffin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coffin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f64d8a0132028b1470dbdd500e78f52160cfd57",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coffin.png"
    }
  },
  {
    "name": "Coin.png",
    "path": "Emojis/Objects/Coin.png",
    "sha": "612dfd622698d28e84e131d614b9b0da84eba0f0",
    "size": 13171,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/612dfd622698d28e84e131d614b9b0da84eba0f0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Coin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Coin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/612dfd622698d28e84e131d614b9b0da84eba0f0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Coin.png"
    }
  },
  {
    "name": "Computer Disk.png",
    "path": "Emojis/Objects/Computer Disk.png",
    "sha": "44b0e544eac11fea80bf133e390590afe9684738",
    "size": 15014,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Computer%20Disk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Computer%20Disk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44b0e544eac11fea80bf133e390590afe9684738",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Computer%20Disk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Computer%20Disk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/44b0e544eac11fea80bf133e390590afe9684738",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Computer%20Disk.png"
    }
  },
  {
    "name": "Computer Mouse.png",
    "path": "Emojis/Objects/Computer Mouse.png",
    "sha": "dd96bfb686e95abb433b8259f2a5b17c92120ccd",
    "size": 9170,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Computer%20Mouse.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Computer%20Mouse.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dd96bfb686e95abb433b8259f2a5b17c92120ccd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Computer%20Mouse.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Computer%20Mouse.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dd96bfb686e95abb433b8259f2a5b17c92120ccd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Computer%20Mouse.png"
    }
  },
  {
    "name": "Control Knobs.png",
    "path": "Emojis/Objects/Control Knobs.png",
    "sha": "326a47b68f249aa1a514e67c4c512cc55d12526f",
    "size": 19299,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Control%20Knobs.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Control%20Knobs.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/326a47b68f249aa1a514e67c4c512cc55d12526f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Control%20Knobs.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Control%20Knobs.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/326a47b68f249aa1a514e67c4c512cc55d12526f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Control%20Knobs.png"
    }
  },
  {
    "name": "Couch and Lamp.png",
    "path": "Emojis/Objects/Couch and Lamp.png",
    "sha": "8ecb425c210bf25cf2e8ec7b0eaacc5982e3c6c2",
    "size": 9519,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Couch%20and%20Lamp.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Couch%20and%20Lamp.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ecb425c210bf25cf2e8ec7b0eaacc5982e3c6c2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Couch%20and%20Lamp.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Couch%20and%20Lamp.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ecb425c210bf25cf2e8ec7b0eaacc5982e3c6c2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Couch%20and%20Lamp.png"
    }
  },
  {
    "name": "Crayon.png",
    "path": "Emojis/Objects/Crayon.png",
    "sha": "9e32558554132970dd14e9f7b123875fcf0f4717",
    "size": 8381,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crayon.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crayon.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9e32558554132970dd14e9f7b123875fcf0f4717",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Crayon.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crayon.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9e32558554132970dd14e9f7b123875fcf0f4717",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crayon.png"
    }
  },
  {
    "name": "Credit Card.png",
    "path": "Emojis/Objects/Credit Card.png",
    "sha": "5cad5cfe0ae9cfd99c9992806ff1f4f32b52e9b1",
    "size": 7186,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Credit%20Card.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Credit%20Card.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5cad5cfe0ae9cfd99c9992806ff1f4f32b52e9b1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Credit%20Card.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Credit%20Card.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5cad5cfe0ae9cfd99c9992806ff1f4f32b52e9b1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Credit%20Card.png"
    }
  },
  {
    "name": "Crossed Swords.png",
    "path": "Emojis/Objects/Crossed Swords.png",
    "sha": "8b8241a9f7fabd4611fd53fb7ded97034a2dcf2a",
    "size": 10416,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crossed%20Swords.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crossed%20Swords.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8b8241a9f7fabd4611fd53fb7ded97034a2dcf2a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Crossed%20Swords.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crossed%20Swords.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8b8241a9f7fabd4611fd53fb7ded97034a2dcf2a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crossed%20Swords.png"
    }
  },
  {
    "name": "Crown.png",
    "path": "Emojis/Objects/Crown.png",
    "sha": "cd9ab0a9e4b257277adc2f3d117623873facc6ac",
    "size": 13342,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crown.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crown.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd9ab0a9e4b257277adc2f3d117623873facc6ac",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Crown.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crown.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd9ab0a9e4b257277adc2f3d117623873facc6ac",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crown.png"
    }
  },
  {
    "name": "Crutch.png",
    "path": "Emojis/Objects/Crutch.png",
    "sha": "8ab129f0e25662dbb86a476a8036fcba85686657",
    "size": 1208333,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crutch.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crutch.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ab129f0e25662dbb86a476a8036fcba85686657",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Crutch.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Crutch.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ab129f0e25662dbb86a476a8036fcba85686657",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Crutch.png"
    }
  },
  {
    "name": "DNA.png",
    "path": "Emojis/Objects/DNA.png",
    "sha": "4de0cc2259e0f1c6a28582c082ba6bd0f8c76f91",
    "size": 10078,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/DNA.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/DNA.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4de0cc2259e0f1c6a28582c082ba6bd0f8c76f91",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/DNA.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/DNA.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4de0cc2259e0f1c6a28582c082ba6bd0f8c76f91",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/DNA.png"
    }
  },
  {
    "name": "DVD.png",
    "path": "Emojis/Objects/DVD.png",
    "sha": "9914d7687478a1e5cf9b5b0ddb412d3ba47848b0",
    "size": 12600,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/DVD.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/DVD.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9914d7687478a1e5cf9b5b0ddb412d3ba47848b0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/DVD.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/DVD.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9914d7687478a1e5cf9b5b0ddb412d3ba47848b0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/DVD.png"
    }
  },
  {
    "name": "Dagger.png",
    "path": "Emojis/Objects/Dagger.png",
    "sha": "5a3f7be3c3de285f064df06b75a5d5978a66055f",
    "size": 8767,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dagger.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dagger.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a3f7be3c3de285f064df06b75a5d5978a66055f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Dagger.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dagger.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5a3f7be3c3de285f064df06b75a5d5978a66055f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dagger.png"
    }
  },
  {
    "name": "Desktop Computer.png",
    "path": "Emojis/Objects/Desktop Computer.png",
    "sha": "ad884577377966197ab4b8845b4420cdf765f967",
    "size": 13821,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Desktop%20Computer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Desktop%20Computer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad884577377966197ab4b8845b4420cdf765f967",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Desktop%20Computer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Desktop%20Computer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ad884577377966197ab4b8845b4420cdf765f967",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Desktop%20Computer.png"
    }
  },
  {
    "name": "Diya Lamp.png",
    "path": "Emojis/Objects/Diya Lamp.png",
    "sha": "1a8d921e98b117de6d9c0dc73af15320c272f7d5",
    "size": 14700,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Diya%20Lamp.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Diya%20Lamp.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a8d921e98b117de6d9c0dc73af15320c272f7d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Diya%20Lamp.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Diya%20Lamp.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1a8d921e98b117de6d9c0dc73af15320c272f7d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Diya%20Lamp.png"
    }
  },
  {
    "name": "Dollar Banknote.png",
    "path": "Emojis/Objects/Dollar Banknote.png",
    "sha": "1c98966f3d614ce90076ff96be174002e124ad11",
    "size": 10464,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dollar%20Banknote.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dollar%20Banknote.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c98966f3d614ce90076ff96be174002e124ad11",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Dollar%20Banknote.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dollar%20Banknote.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1c98966f3d614ce90076ff96be174002e124ad11",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dollar%20Banknote.png"
    }
  },
  {
    "name": "Door.png",
    "path": "Emojis/Objects/Door.png",
    "sha": "f7158a5aca94167796c48962e47967320c1eaa7f",
    "size": 8946,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Door.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Door.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7158a5aca94167796c48962e47967320c1eaa7f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Door.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Door.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f7158a5aca94167796c48962e47967320c1eaa7f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Door.png"
    }
  },
  {
    "name": "Dress.png",
    "path": "Emojis/Objects/Dress.png",
    "sha": "e25bb5d7e1876ed6deb353f6788eff8a88c5d700",
    "size": 10333,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dress.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dress.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e25bb5d7e1876ed6deb353f6788eff8a88c5d700",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Dress.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Dress.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e25bb5d7e1876ed6deb353f6788eff8a88c5d700",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Dress.png"
    }
  },
  {
    "name": "Drop of Blood.png",
    "path": "Emojis/Objects/Drop of Blood.png",
    "sha": "6a61dcaf6bfb535f60024b2d50b436803f22d726",
    "size": 11543,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Drop%20of%20Blood.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Drop%20of%20Blood.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6a61dcaf6bfb535f60024b2d50b436803f22d726",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Drop%20of%20Blood.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Drop%20of%20Blood.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6a61dcaf6bfb535f60024b2d50b436803f22d726",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Drop%20of%20Blood.png"
    }
  },
  {
    "name": "Drum.png",
    "path": "Emojis/Objects/Drum.png",
    "sha": "4782927d9d18756afb870d52b76536fb9f9b5b8b",
    "size": 15090,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Drum.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Drum.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4782927d9d18756afb870d52b76536fb9f9b5b8b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Drum.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Drum.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4782927d9d18756afb870d52b76536fb9f9b5b8b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Drum.png"
    }
  },
  {
    "name": "E-Mail.png",
    "path": "Emojis/Objects/E-Mail.png",
    "sha": "919ecde9f6fe5275152527231eb1b8606c5db645",
    "size": 14768,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/E-Mail.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/E-Mail.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/919ecde9f6fe5275152527231eb1b8606c5db645",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/E-Mail.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/E-Mail.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/919ecde9f6fe5275152527231eb1b8606c5db645",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/E-Mail.png"
    }
  },
  {
    "name": "Electric Plug.png",
    "path": "Emojis/Objects/Electric Plug.png",
    "sha": "91dc65021853311eeb49956b2f7dff465b82cbb7",
    "size": 7062,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Electric%20Plug.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Electric%20Plug.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91dc65021853311eeb49956b2f7dff465b82cbb7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Electric%20Plug.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Electric%20Plug.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/91dc65021853311eeb49956b2f7dff465b82cbb7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Electric%20Plug.png"
    }
  },
  {
    "name": "Elevator.png",
    "path": "Emojis/Objects/Elevator.png",
    "sha": "9ffd6ac2767b948d55fa4ec80b461481a68af8d6",
    "size": 16845,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Elevator.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Elevator.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ffd6ac2767b948d55fa4ec80b461481a68af8d6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Elevator.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Elevator.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9ffd6ac2767b948d55fa4ec80b461481a68af8d6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Elevator.png"
    }
  },
  {
    "name": "Envelope with Arrow.png",
    "path": "Emojis/Objects/Envelope with Arrow.png",
    "sha": "4fab1c87cb17a2b75517a0d1a27a07e3823f4eee",
    "size": 13366,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Envelope%20with%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Envelope%20with%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4fab1c87cb17a2b75517a0d1a27a07e3823f4eee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Envelope%20with%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Envelope%20with%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4fab1c87cb17a2b75517a0d1a27a07e3823f4eee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Envelope%20with%20Arrow.png"
    }
  },
  {
    "name": "Envelope.png",
    "path": "Emojis/Objects/Envelope.png",
    "sha": "00d5c8ae2fbc05e32184966eaa40890cb553b81e",
    "size": 12318,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Envelope.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Envelope.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00d5c8ae2fbc05e32184966eaa40890cb553b81e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Envelope.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Envelope.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/00d5c8ae2fbc05e32184966eaa40890cb553b81e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Envelope.png"
    }
  },
  {
    "name": "Euro Banknote.png",
    "path": "Emojis/Objects/Euro Banknote.png",
    "sha": "b86c202feda0074010fb99325ba6797c92d61fd8",
    "size": 11889,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Euro%20Banknote.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Euro%20Banknote.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b86c202feda0074010fb99325ba6797c92d61fd8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Euro%20Banknote.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Euro%20Banknote.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b86c202feda0074010fb99325ba6797c92d61fd8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Euro%20Banknote.png"
    }
  },
  {
    "name": "Fax Machine.png",
    "path": "Emojis/Objects/Fax Machine.png",
    "sha": "92ee4431795db710fbb47f51801a895b15f39842",
    "size": 15570,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fax%20Machine.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fax%20Machine.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92ee4431795db710fbb47f51801a895b15f39842",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Fax%20Machine.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fax%20Machine.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/92ee4431795db710fbb47f51801a895b15f39842",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fax%20Machine.png"
    }
  },
  {
    "name": "File Cabinet.png",
    "path": "Emojis/Objects/File Cabinet.png",
    "sha": "2d1044d0144dd8abb476b9e4055e38ddcca562bc",
    "size": 8858,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/File%20Cabinet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/File%20Cabinet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2d1044d0144dd8abb476b9e4055e38ddcca562bc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/File%20Cabinet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/File%20Cabinet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2d1044d0144dd8abb476b9e4055e38ddcca562bc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/File%20Cabinet.png"
    }
  },
  {
    "name": "File Folder.png",
    "path": "Emojis/Objects/File Folder.png",
    "sha": "029497bd74b39b5be9383abe4ac05b7ce0f41b7a",
    "size": 8617,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/File%20Folder.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/File%20Folder.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/029497bd74b39b5be9383abe4ac05b7ce0f41b7a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/File%20Folder.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/File%20Folder.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/029497bd74b39b5be9383abe4ac05b7ce0f41b7a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/File%20Folder.png"
    }
  },
  {
    "name": "Film Frames.png",
    "path": "Emojis/Objects/Film Frames.png",
    "sha": "ba49155e354e5d2636657f50e2c4f1e93012ff63",
    "size": 13083,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Film%20Frames.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Film%20Frames.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ba49155e354e5d2636657f50e2c4f1e93012ff63",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Film%20Frames.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Film%20Frames.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ba49155e354e5d2636657f50e2c4f1e93012ff63",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Film%20Frames.png"
    }
  },
  {
    "name": "Film Projector.png",
    "path": "Emojis/Objects/Film Projector.png",
    "sha": "687e3f2429bf83d4ababf539893687c1af74f5f4",
    "size": 14286,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Film%20Projector.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Film%20Projector.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/687e3f2429bf83d4ababf539893687c1af74f5f4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Film%20Projector.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Film%20Projector.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/687e3f2429bf83d4ababf539893687c1af74f5f4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Film%20Projector.png"
    }
  },
  {
    "name": "Fire Extinguisher.png",
    "path": "Emojis/Objects/Fire Extinguisher.png",
    "sha": "b40b12694aa4628093436611dc8eb599513f2cad",
    "size": 11326,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fire%20Extinguisher.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fire%20Extinguisher.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b40b12694aa4628093436611dc8eb599513f2cad",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Fire%20Extinguisher.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fire%20Extinguisher.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b40b12694aa4628093436611dc8eb599513f2cad",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fire%20Extinguisher.png"
    }
  },
  {
    "name": "Flashlight.png",
    "path": "Emojis/Objects/Flashlight.png",
    "sha": "4ab1e8f6cde6302dd3cec21a59af8c8eb4173767",
    "size": 8745,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flashlight.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flashlight.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab1e8f6cde6302dd3cec21a59af8c8eb4173767",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Flashlight.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flashlight.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab1e8f6cde6302dd3cec21a59af8c8eb4173767",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flashlight.png"
    }
  },
  {
    "name": "Flat Shoe.png",
    "path": "Emojis/Objects/Flat Shoe.png",
    "sha": "e2e2f858f29b8714e259f881214887819b1c3f9a",
    "size": 5377,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flat%20Shoe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flat%20Shoe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2e2f858f29b8714e259f881214887819b1c3f9a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Flat%20Shoe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flat%20Shoe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e2e2f858f29b8714e259f881214887819b1c3f9a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flat%20Shoe.png"
    }
  },
  {
    "name": "Floppy Disk.png",
    "path": "Emojis/Objects/Floppy Disk.png",
    "sha": "ea4fa461918cc1b7c71ecfedce9adf9a14b65287",
    "size": 12617,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Floppy%20Disk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Floppy%20Disk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ea4fa461918cc1b7c71ecfedce9adf9a14b65287",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Floppy%20Disk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Floppy%20Disk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ea4fa461918cc1b7c71ecfedce9adf9a14b65287",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Floppy%20Disk.png"
    }
  },
  {
    "name": "Flute.png",
    "path": "Emojis/Objects/Flute.png",
    "sha": "dcde609dab6961c0478fac7ce27f9be1bc902995",
    "size": 26426,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flute.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flute.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dcde609dab6961c0478fac7ce27f9be1bc902995",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Flute.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Flute.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dcde609dab6961c0478fac7ce27f9be1bc902995",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Flute.png"
    }
  },
  {
    "name": "Folding Hand Fan.png",
    "path": "Emojis/Objects/Folding Hand Fan.png",
    "sha": "9090e14162d221a1d60ed3739bd3bddb2c1b231f",
    "size": 58567,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Folding%20Hand%20Fan.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Folding%20Hand%20Fan.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9090e14162d221a1d60ed3739bd3bddb2c1b231f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Folding%20Hand%20Fan.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Folding%20Hand%20Fan.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9090e14162d221a1d60ed3739bd3bddb2c1b231f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Folding%20Hand%20Fan.png"
    }
  },
  {
    "name": "Fountain Pen.png",
    "path": "Emojis/Objects/Fountain Pen.png",
    "sha": "c41874c35fa7f929791210121b45414341411d2b",
    "size": 6869,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fountain%20Pen.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fountain%20Pen.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c41874c35fa7f929791210121b45414341411d2b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Fountain%20Pen.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Fountain%20Pen.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c41874c35fa7f929791210121b45414341411d2b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Fountain%20Pen.png"
    }
  },
  {
    "name": "Funeral Urn.png",
    "path": "Emojis/Objects/Funeral Urn.png",
    "sha": "e8d2e5852653d3ead4d43f03ffea0b55fce02774",
    "size": 14358,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Funeral%20Urn.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Funeral%20Urn.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8d2e5852653d3ead4d43f03ffea0b55fce02774",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Funeral%20Urn.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Funeral%20Urn.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e8d2e5852653d3ead4d43f03ffea0b55fce02774",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Funeral%20Urn.png"
    }
  },
  {
    "name": "Gear.png",
    "path": "Emojis/Objects/Gear.png",
    "sha": "b496505dc8c7614eb2325d3224d6252068e512c2",
    "size": 15847,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gear.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gear.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b496505dc8c7614eb2325d3224d6252068e512c2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Gear.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gear.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b496505dc8c7614eb2325d3224d6252068e512c2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gear.png"
    }
  },
  {
    "name": "Gem Stone.png",
    "path": "Emojis/Objects/Gem Stone.png",
    "sha": "db72087646ef6d1dbe422cb319c6e4772b87b713",
    "size": 9825,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gem%20Stone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gem%20Stone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/db72087646ef6d1dbe422cb319c6e4772b87b713",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Gem%20Stone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gem%20Stone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/db72087646ef6d1dbe422cb319c6e4772b87b713",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gem%20Stone.png"
    }
  },
  {
    "name": "Glasses.png",
    "path": "Emojis/Objects/Glasses.png",
    "sha": "807e3f5a12c15d36cfadf583f202a456868cd563",
    "size": 8540,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Glasses.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Glasses.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/807e3f5a12c15d36cfadf583f202a456868cd563",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Glasses.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Glasses.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/807e3f5a12c15d36cfadf583f202a456868cd563",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Glasses.png"
    }
  },
  {
    "name": "Gloves.png",
    "path": "Emojis/Objects/Gloves.png",
    "sha": "8514f4c2021c5982cb3e17d9feb0ce0f8c2cf66f",
    "size": 16156,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gloves.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gloves.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8514f4c2021c5982cb3e17d9feb0ce0f8c2cf66f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Gloves.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Gloves.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8514f4c2021c5982cb3e17d9feb0ce0f8c2cf66f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Gloves.png"
    }
  },
  {
    "name": "Goggles.png",
    "path": "Emojis/Objects/Goggles.png",
    "sha": "39680f50dbfeb078ffa6f4829f1d0fa89a53d525",
    "size": 13331,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Goggles.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Goggles.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/39680f50dbfeb078ffa6f4829f1d0fa89a53d525",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Goggles.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Goggles.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/39680f50dbfeb078ffa6f4829f1d0fa89a53d525",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Goggles.png"
    }
  },
  {
    "name": "Graduation Cap.png",
    "path": "Emojis/Objects/Graduation Cap.png",
    "sha": "86ba7e7e952ad611da40bba3959bcfc8305caec4",
    "size": 9708,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Graduation%20Cap.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Graduation%20Cap.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86ba7e7e952ad611da40bba3959bcfc8305caec4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Graduation%20Cap.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Graduation%20Cap.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86ba7e7e952ad611da40bba3959bcfc8305caec4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Graduation%20Cap.png"
    }
  },
  {
    "name": "Green Book.png",
    "path": "Emojis/Objects/Green Book.png",
    "sha": "4ea797b2afaccdb7a5e8716f8dcb3a234ed95b8f",
    "size": 9312,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Green%20Book.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Green%20Book.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ea797b2afaccdb7a5e8716f8dcb3a234ed95b8f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Green%20Book.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Green%20Book.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ea797b2afaccdb7a5e8716f8dcb3a234ed95b8f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Green%20Book.png"
    }
  },
  {
    "name": "Guitar.png",
    "path": "Emojis/Objects/Guitar.png",
    "sha": "baacd729b26ce37270c5324e7f1528d25904b49e",
    "size": 9290,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Guitar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Guitar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/baacd729b26ce37270c5324e7f1528d25904b49e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Guitar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Guitar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/baacd729b26ce37270c5324e7f1528d25904b49e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Guitar.png"
    }
  },
  {
    "name": "Hair Pick.png",
    "path": "Emojis/Objects/Hair Pick.png",
    "sha": "3587cc180fca128418689720fb1b96160280835a",
    "size": 32713,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hair%20Pick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hair%20Pick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3587cc180fca128418689720fb1b96160280835a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hair%20Pick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hair%20Pick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3587cc180fca128418689720fb1b96160280835a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hair%20Pick.png"
    }
  },
  {
    "name": "Hammer and Pick.png",
    "path": "Emojis/Objects/Hammer and Pick.png",
    "sha": "f3f6b8082f9352f1504dcdd4dffd4e06d00e1a1b",
    "size": 10479,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer%20and%20Pick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer%20and%20Pick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f3f6b8082f9352f1504dcdd4dffd4e06d00e1a1b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hammer%20and%20Pick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer%20and%20Pick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f3f6b8082f9352f1504dcdd4dffd4e06d00e1a1b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer%20and%20Pick.png"
    }
  },
  {
    "name": "Hammer and Wrench.png",
    "path": "Emojis/Objects/Hammer and Wrench.png",
    "sha": "0040f822531c60514b6957fe3e9342ce12de3755",
    "size": 12537,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer%20and%20Wrench.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer%20and%20Wrench.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0040f822531c60514b6957fe3e9342ce12de3755",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hammer%20and%20Wrench.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer%20and%20Wrench.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0040f822531c60514b6957fe3e9342ce12de3755",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer%20and%20Wrench.png"
    }
  },
  {
    "name": "Hammer.png",
    "path": "Emojis/Objects/Hammer.png",
    "sha": "89d61ddb37633a33ad7dcd0c28fe66c8c011e62c",
    "size": 7021,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89d61ddb37633a33ad7dcd0c28fe66c8c011e62c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hammer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hammer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89d61ddb37633a33ad7dcd0c28fe66c8c011e62c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hammer.png"
    }
  },
  {
    "name": "Handbag.png",
    "path": "Emojis/Objects/Handbag.png",
    "sha": "8ca52b0d5313ee0a292f6bcd094b1b1397fa7011",
    "size": 12436,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Handbag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Handbag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ca52b0d5313ee0a292f6bcd094b1b1397fa7011",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Handbag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Handbag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ca52b0d5313ee0a292f6bcd094b1b1397fa7011",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Handbag.png"
    }
  },
  {
    "name": "Headphone.png",
    "path": "Emojis/Objects/Headphone.png",
    "sha": "50e0ecad975ef7172a84f7144998d0b4be423387",
    "size": 12463,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Headphone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Headphone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/50e0ecad975ef7172a84f7144998d0b4be423387",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Headphone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Headphone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/50e0ecad975ef7172a84f7144998d0b4be423387",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Headphone.png"
    }
  },
  {
    "name": "Headstone.png",
    "path": "Emojis/Objects/Headstone.png",
    "sha": "3d8d711d0d70e978297d4a0749496e8e32dd7e1b",
    "size": 10496,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Headstone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Headstone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d8d711d0d70e978297d4a0749496e8e32dd7e1b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Headstone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Headstone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d8d711d0d70e978297d4a0749496e8e32dd7e1b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Headstone.png"
    }
  },
  {
    "name": "High-Heeled Shoe.png",
    "path": "Emojis/Objects/High-Heeled Shoe.png",
    "sha": "f1f489df285f8d55c88106f7c98cea8009f8d5b4",
    "size": 8839,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/High-Heeled%20Shoe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/High-Heeled%20Shoe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1f489df285f8d55c88106f7c98cea8009f8d5b4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/High-Heeled%20Shoe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/High-Heeled%20Shoe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f1f489df285f8d55c88106f7c98cea8009f8d5b4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/High-Heeled%20Shoe.png"
    }
  },
  {
    "name": "Hiking Boot.png",
    "path": "Emojis/Objects/Hiking Boot.png",
    "sha": "1f698c58ace178e12e7e27e836ba20b6ca729e2e",
    "size": 11527,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hiking%20Boot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hiking%20Boot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f698c58ace178e12e7e27e836ba20b6ca729e2e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hiking%20Boot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hiking%20Boot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1f698c58ace178e12e7e27e836ba20b6ca729e2e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hiking%20Boot.png"
    }
  },
  {
    "name": "Hook.png",
    "path": "Emojis/Objects/Hook.png",
    "sha": "7c16e2ad57ca7bfaec38b8920ac6bca7277e1f5b",
    "size": 7880,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hook.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hook.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c16e2ad57ca7bfaec38b8920ac6bca7277e1f5b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Hook.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Hook.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7c16e2ad57ca7bfaec38b8920ac6bca7277e1f5b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Hook.png"
    }
  },
  {
    "name": "Identification Card.png",
    "path": "Emojis/Objects/Identification Card.png",
    "sha": "d332bf6773872b3f1dba1ad450e885d3da73cbff",
    "size": 22388,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Identification%20Card.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Identification%20Card.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d332bf6773872b3f1dba1ad450e885d3da73cbff",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Identification%20Card.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Identification%20Card.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d332bf6773872b3f1dba1ad450e885d3da73cbff",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Identification%20Card.png"
    }
  },
  {
    "name": "Inbox Tray.png",
    "path": "Emojis/Objects/Inbox Tray.png",
    "sha": "ac1999cb7135a8408057f6e8ac5968d204daaac6",
    "size": 9880,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Inbox%20Tray.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Inbox%20Tray.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac1999cb7135a8408057f6e8ac5968d204daaac6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Inbox%20Tray.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Inbox%20Tray.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ac1999cb7135a8408057f6e8ac5968d204daaac6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Inbox%20Tray.png"
    }
  },
  {
    "name": "Incoming Envelope.png",
    "path": "Emojis/Objects/Incoming Envelope.png",
    "sha": "85e2124a446f3425ef1ceaab2162f596c650de3a",
    "size": 9456,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Incoming%20Envelope.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Incoming%20Envelope.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/85e2124a446f3425ef1ceaab2162f596c650de3a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Incoming%20Envelope.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Incoming%20Envelope.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/85e2124a446f3425ef1ceaab2162f596c650de3a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Incoming%20Envelope.png"
    }
  },
  {
    "name": "Jar.png",
    "path": "Emojis/Objects/Jar.png",
    "sha": "bec4e335b6aa52e2e52a415a6647e44b1f4e6f81",
    "size": 20819,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Jar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Jar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bec4e335b6aa52e2e52a415a6647e44b1f4e6f81",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Jar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Jar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bec4e335b6aa52e2e52a415a6647e44b1f4e6f81",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Jar.png"
    }
  },
  {
    "name": "Jeans.png",
    "path": "Emojis/Objects/Jeans.png",
    "sha": "01800d7910e913bbc5ebdd71d9e38ec4bed4c4d5",
    "size": 12488,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Jeans.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Jeans.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/01800d7910e913bbc5ebdd71d9e38ec4bed4c4d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Jeans.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Jeans.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/01800d7910e913bbc5ebdd71d9e38ec4bed4c4d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Jeans.png"
    }
  },
  {
    "name": "Key.png",
    "path": "Emojis/Objects/Key.png",
    "sha": "bfce36b3a9ef55313c23c4f4dc4ea582a2130046",
    "size": 8999,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Key.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Key.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfce36b3a9ef55313c23c4f4dc4ea582a2130046",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Key.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Key.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfce36b3a9ef55313c23c4f4dc4ea582a2130046",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Key.png"
    }
  },
  {
    "name": "Keyboard.png",
    "path": "Emojis/Objects/Keyboard.png",
    "sha": "75c5df92b7ef6b4b1aaa33296b6c46194deef62a",
    "size": 14022,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Keyboard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Keyboard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75c5df92b7ef6b4b1aaa33296b6c46194deef62a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Keyboard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Keyboard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75c5df92b7ef6b4b1aaa33296b6c46194deef62a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Keyboard.png"
    }
  },
  {
    "name": "Kimono.png",
    "path": "Emojis/Objects/Kimono.png",
    "sha": "6addf4ef4debb50295bcf769ec5f7415a9546937",
    "size": 13676,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Kimono.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Kimono.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6addf4ef4debb50295bcf769ec5f7415a9546937",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Kimono.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Kimono.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6addf4ef4debb50295bcf769ec5f7415a9546937",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Kimono.png"
    }
  },
  {
    "name": "Lab Coat.png",
    "path": "Emojis/Objects/Lab Coat.png",
    "sha": "ce6a6017ac8f143040831bdad1b7e2b7e9e03b20",
    "size": 11896,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lab%20Coat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lab%20Coat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce6a6017ac8f143040831bdad1b7e2b7e9e03b20",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Lab%20Coat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lab%20Coat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ce6a6017ac8f143040831bdad1b7e2b7e9e03b20",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lab%20Coat.png"
    }
  },
  {
    "name": "Label.png",
    "path": "Emojis/Objects/Label.png",
    "sha": "0337efed5b7c87c90ebbd6da151f3cbf3abd7c0d",
    "size": 8042,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Label.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Label.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0337efed5b7c87c90ebbd6da151f3cbf3abd7c0d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Label.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Label.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0337efed5b7c87c90ebbd6da151f3cbf3abd7c0d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Label.png"
    }
  },
  {
    "name": "Ladder.png",
    "path": "Emojis/Objects/Ladder.png",
    "sha": "9a2dda95b97a56849daf72937e7e9471efe1ec9c",
    "size": 11943,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ladder.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ladder.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9a2dda95b97a56849daf72937e7e9471efe1ec9c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ladder.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ladder.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9a2dda95b97a56849daf72937e7e9471efe1ec9c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ladder.png"
    }
  },
  {
    "name": "Laptop.png",
    "path": "Emojis/Objects/Laptop.png",
    "sha": "07a9fc3e38b08b8533b2ab91ba6cfc990054f478",
    "size": 11224,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Laptop.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Laptop.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/07a9fc3e38b08b8533b2ab91ba6cfc990054f478",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Laptop.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Laptop.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/07a9fc3e38b08b8533b2ab91ba6cfc990054f478",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Laptop.png"
    }
  },
  {
    "name": "Ledger.png",
    "path": "Emojis/Objects/Ledger.png",
    "sha": "67ddfaddab620e05922d409deb37fb365ba2edde",
    "size": 11641,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ledger.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ledger.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/67ddfaddab620e05922d409deb37fb365ba2edde",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ledger.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ledger.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/67ddfaddab620e05922d409deb37fb365ba2edde",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ledger.png"
    }
  },
  {
    "name": "Level Slider.png",
    "path": "Emojis/Objects/Level Slider.png",
    "sha": "25bdbb24976957369a38ff8fd6a25c80a2d8a8cc",
    "size": 13087,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Level%20Slider.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Level%20Slider.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25bdbb24976957369a38ff8fd6a25c80a2d8a8cc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Level%20Slider.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Level%20Slider.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/25bdbb24976957369a38ff8fd6a25c80a2d8a8cc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Level%20Slider.png"
    }
  },
  {
    "name": "Light Bulb.png",
    "path": "Emojis/Objects/Light Bulb.png",
    "sha": "99f2a4ddd6b8789932426379e26ee038a1e53536",
    "size": 10288,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Light%20Bulb.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Light%20Bulb.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/99f2a4ddd6b8789932426379e26ee038a1e53536",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Light%20Bulb.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Light%20Bulb.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/99f2a4ddd6b8789932426379e26ee038a1e53536",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Light%20Bulb.png"
    }
  },
  {
    "name": "Link.png",
    "path": "Emojis/Objects/Link.png",
    "sha": "45bf14e5025157d9c1930f32b2246e9c2f41313a",
    "size": 12907,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Link.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Link.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/45bf14e5025157d9c1930f32b2246e9c2f41313a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Link.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Link.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/45bf14e5025157d9c1930f32b2246e9c2f41313a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Link.png"
    }
  },
  {
    "name": "Linked Paperclips.png",
    "path": "Emojis/Objects/Linked Paperclips.png",
    "sha": "c1d01f40223c8abf34225616fe757e78ef230dcd",
    "size": 11359,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Linked%20Paperclips.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Linked%20Paperclips.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c1d01f40223c8abf34225616fe757e78ef230dcd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Linked%20Paperclips.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Linked%20Paperclips.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c1d01f40223c8abf34225616fe757e78ef230dcd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Linked%20Paperclips.png"
    }
  },
  {
    "name": "Lipstick.png",
    "path": "Emojis/Objects/Lipstick.png",
    "sha": "68759afc90d84e241d19f7c64c2204ad6cd84b8c",
    "size": 8066,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lipstick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lipstick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/68759afc90d84e241d19f7c64c2204ad6cd84b8c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Lipstick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lipstick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/68759afc90d84e241d19f7c64c2204ad6cd84b8c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lipstick.png"
    }
  },
  {
    "name": "Locked with Key.png",
    "path": "Emojis/Objects/Locked with Key.png",
    "sha": "b3213940725a583bac0fc895bf1e73a0c9744fd4",
    "size": 14126,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked%20with%20Key.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked%20with%20Key.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3213940725a583bac0fc895bf1e73a0c9744fd4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Locked%20with%20Key.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked%20with%20Key.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3213940725a583bac0fc895bf1e73a0c9744fd4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked%20with%20Key.png"
    }
  },
  {
    "name": "Locked with Pen.png",
    "path": "Emojis/Objects/Locked with Pen.png",
    "sha": "c182538aafd3fba777d87247885180e1fbcdf95e",
    "size": 13462,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked%20with%20Pen.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked%20with%20Pen.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c182538aafd3fba777d87247885180e1fbcdf95e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Locked%20with%20Pen.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked%20with%20Pen.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c182538aafd3fba777d87247885180e1fbcdf95e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked%20with%20Pen.png"
    }
  },
  {
    "name": "Locked.png",
    "path": "Emojis/Objects/Locked.png",
    "sha": "ea4f5b64d102f0cc1668e89931a6b818a70f09f0",
    "size": 10820,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ea4f5b64d102f0cc1668e89931a6b818a70f09f0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Locked.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Locked.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ea4f5b64d102f0cc1668e89931a6b818a70f09f0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Locked.png"
    }
  },
  {
    "name": "Long Drum.png",
    "path": "Emojis/Objects/Long Drum.png",
    "sha": "0d2f1701e9cc62e063407cf57cc9eaa9a5287eb5",
    "size": 11435,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Long%20Drum.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Long%20Drum.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d2f1701e9cc62e063407cf57cc9eaa9a5287eb5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Long%20Drum.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Long%20Drum.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d2f1701e9cc62e063407cf57cc9eaa9a5287eb5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Long%20Drum.png"
    }
  },
  {
    "name": "Lotion Bottle.png",
    "path": "Emojis/Objects/Lotion Bottle.png",
    "sha": "f472623506ab1cdba3fe3797d2e3ef895d3387d9",
    "size": 10300,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lotion%20Bottle.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lotion%20Bottle.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f472623506ab1cdba3fe3797d2e3ef895d3387d9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Lotion%20Bottle.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Lotion%20Bottle.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f472623506ab1cdba3fe3797d2e3ef895d3387d9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Lotion%20Bottle.png"
    }
  },
  {
    "name": "Loudspeaker.png",
    "path": "Emojis/Objects/Loudspeaker.png",
    "sha": "af2f280b7683eb4d651ddfc9d7f819e856136b30",
    "size": 13438,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Loudspeaker.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Loudspeaker.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af2f280b7683eb4d651ddfc9d7f819e856136b30",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Loudspeaker.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Loudspeaker.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/af2f280b7683eb4d651ddfc9d7f819e856136b30",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Loudspeaker.png"
    }
  },
  {
    "name": "Low Battery.png",
    "path": "Emojis/Objects/Low Battery.png",
    "sha": "458f220cb65f31d78aba73d6749d3bfd66fb4269",
    "size": 1414035,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Low%20Battery.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Low%20Battery.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/458f220cb65f31d78aba73d6749d3bfd66fb4269",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Low%20Battery.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Low%20Battery.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/458f220cb65f31d78aba73d6749d3bfd66fb4269",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Low%20Battery.png"
    }
  },
  {
    "name": "Magnet.png",
    "path": "Emojis/Objects/Magnet.png",
    "sha": "c34019d8410b560d9b106dc474026be28ce11754",
    "size": 10241,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c34019d8410b560d9b106dc474026be28ce11754",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Magnet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c34019d8410b560d9b106dc474026be28ce11754",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnet.png"
    }
  },
  {
    "name": "Magnifying Glass Tilted Left.png",
    "path": "Emojis/Objects/Magnifying Glass Tilted Left.png",
    "sha": "41f6ed556731e7c8500101b474ca7411617037fb",
    "size": 13336,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnifying%20Glass%20Tilted%20Left.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Left.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41f6ed556731e7c8500101b474ca7411617037fb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Left.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnifying%20Glass%20Tilted%20Left.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/41f6ed556731e7c8500101b474ca7411617037fb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Left.png"
    }
  },
  {
    "name": "Magnifying Glass Tilted Right.png",
    "path": "Emojis/Objects/Magnifying Glass Tilted Right.png",
    "sha": "8852c6f1d372856a7589e6b7d6392ccef026a881",
    "size": 12945,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnifying%20Glass%20Tilted%20Right.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Right.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8852c6f1d372856a7589e6b7d6392ccef026a881",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Right.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Magnifying%20Glass%20Tilted%20Right.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8852c6f1d372856a7589e6b7d6392ccef026a881",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Magnifying%20Glass%20Tilted%20Right.png"
    }
  },
  {
    "name": "Man’s Shoe.png",
    "path": "Emojis/Objects/Man’s Shoe.png",
    "sha": "7b592e287ed1dd150e34e7c4a5f1c095c700dcec",
    "size": 6884,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Man%E2%80%99s%20Shoe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Man%E2%80%99s%20Shoe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b592e287ed1dd150e34e7c4a5f1c095c700dcec",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Man’s Shoe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Man%E2%80%99s%20Shoe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7b592e287ed1dd150e34e7c4a5f1c095c700dcec",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Man%E2%80%99s%20Shoe.png"
    }
  },
  {
    "name": "Maracas.png",
    "path": "Emojis/Objects/Maracas.png",
    "sha": "c8facf73311a3b5623aa5184a2c598fc7c54e233",
    "size": 2748383,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Maracas.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Maracas.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8facf73311a3b5623aa5184a2c598fc7c54e233",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Maracas.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Maracas.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c8facf73311a3b5623aa5184a2c598fc7c54e233",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Maracas.png"
    }
  },
  {
    "name": "Megaphone.png",
    "path": "Emojis/Objects/Megaphone.png",
    "sha": "86c30dc16db9e612c972253d682e1bc1893c635b",
    "size": 13857,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Megaphone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Megaphone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86c30dc16db9e612c972253d682e1bc1893c635b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Megaphone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Megaphone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/86c30dc16db9e612c972253d682e1bc1893c635b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Megaphone.png"
    }
  },
  {
    "name": "Memo.png",
    "path": "Emojis/Objects/Memo.png",
    "sha": "567c7d9010047caf134c7801609c88501a7c2b88",
    "size": 14024,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Memo.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Memo.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/567c7d9010047caf134c7801609c88501a7c2b88",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Memo.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Memo.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/567c7d9010047caf134c7801609c88501a7c2b88",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Memo.png"
    }
  },
  {
    "name": "Microphone.png",
    "path": "Emojis/Objects/Microphone.png",
    "sha": "59a6522410ee1ac3f60b6f66b7dc9ba1f577b654",
    "size": 9702,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Microphone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Microphone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59a6522410ee1ac3f60b6f66b7dc9ba1f577b654",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Microphone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Microphone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/59a6522410ee1ac3f60b6f66b7dc9ba1f577b654",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Microphone.png"
    }
  },
  {
    "name": "Microscope.png",
    "path": "Emojis/Objects/Microscope.png",
    "sha": "8ba311ab9f810504c28b328e81210b4b7aa7282d",
    "size": 10101,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Microscope.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Microscope.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ba311ab9f810504c28b328e81210b4b7aa7282d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Microscope.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Microscope.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8ba311ab9f810504c28b328e81210b4b7aa7282d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Microscope.png"
    }
  },
  {
    "name": "Military Helmet.png",
    "path": "Emojis/Objects/Military Helmet.png",
    "sha": "cd39f34e5030461fcd955191ac394d9f24fdafde",
    "size": 12367,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Military%20Helmet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Military%20Helmet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd39f34e5030461fcd955191ac394d9f24fdafde",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Military%20Helmet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Military%20Helmet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cd39f34e5030461fcd955191ac394d9f24fdafde",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Military%20Helmet.png"
    }
  },
  {
    "name": "Mirror Ball.png",
    "path": "Emojis/Objects/Mirror Ball.png",
    "sha": "27df4b35b220887bc6c463a3209e87227f4cbf74",
    "size": 2540127,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mirror%20Ball.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mirror%20Ball.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27df4b35b220887bc6c463a3209e87227f4cbf74",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Mirror%20Ball.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mirror%20Ball.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/27df4b35b220887bc6c463a3209e87227f4cbf74",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mirror%20Ball.png"
    }
  },
  {
    "name": "Mirror.png",
    "path": "Emojis/Objects/Mirror.png",
    "sha": "315ad2e95054288ed1e15fd31f2ef682b48427d2",
    "size": 11712,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mirror.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mirror.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/315ad2e95054288ed1e15fd31f2ef682b48427d2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Mirror.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mirror.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/315ad2e95054288ed1e15fd31f2ef682b48427d2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mirror.png"
    }
  },
  {
    "name": "Moai.png",
    "path": "Emojis/Objects/Moai.png",
    "sha": "c2f2b992d8769a69c0e8f7b1347eff1353c1de6b",
    "size": 14878,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Moai.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Moai.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2f2b992d8769a69c0e8f7b1347eff1353c1de6b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Moai.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Moai.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c2f2b992d8769a69c0e8f7b1347eff1353c1de6b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Moai.png"
    }
  },
  {
    "name": "Mobile Phone with Arrow.png",
    "path": "Emojis/Objects/Mobile Phone with Arrow.png",
    "sha": "423f868921670afab3570f56e301a4640b3cb69a",
    "size": 12575,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mobile%20Phone%20with%20Arrow.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mobile%20Phone%20with%20Arrow.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/423f868921670afab3570f56e301a4640b3cb69a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Mobile%20Phone%20with%20Arrow.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mobile%20Phone%20with%20Arrow.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/423f868921670afab3570f56e301a4640b3cb69a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mobile%20Phone%20with%20Arrow.png"
    }
  },
  {
    "name": "Mobile Phone.png",
    "path": "Emojis/Objects/Mobile Phone.png",
    "sha": "77d785e0721c6980b88b59f8666b4b8948bbf84a",
    "size": 10554,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mobile%20Phone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mobile%20Phone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77d785e0721c6980b88b59f8666b4b8948bbf84a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Mobile%20Phone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mobile%20Phone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/77d785e0721c6980b88b59f8666b4b8948bbf84a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mobile%20Phone.png"
    }
  },
  {
    "name": "Money Bag.png",
    "path": "Emojis/Objects/Money Bag.png",
    "sha": "51e4e137db2b3d3545458673eb70485579c9399c",
    "size": 13999,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Money%20Bag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Money%20Bag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/51e4e137db2b3d3545458673eb70485579c9399c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Money%20Bag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Money%20Bag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/51e4e137db2b3d3545458673eb70485579c9399c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Money%20Bag.png"
    }
  },
  {
    "name": "Money with Wings.png",
    "path": "Emojis/Objects/Money with Wings.png",
    "sha": "e6e80392b3610e69767278cbb2f4417d8976854d",
    "size": 11800,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Money%20with%20Wings.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Money%20with%20Wings.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6e80392b3610e69767278cbb2f4417d8976854d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Money%20with%20Wings.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Money%20with%20Wings.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e6e80392b3610e69767278cbb2f4417d8976854d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Money%20with%20Wings.png"
    }
  },
  {
    "name": "Mouse Trap.png",
    "path": "Emojis/Objects/Mouse Trap.png",
    "sha": "1cef704fd4f058d98849e45487f3305c0e676b21",
    "size": 10426,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mouse%20Trap.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mouse%20Trap.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1cef704fd4f058d98849e45487f3305c0e676b21",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Mouse%20Trap.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Mouse%20Trap.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1cef704fd4f058d98849e45487f3305c0e676b21",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Mouse%20Trap.png"
    }
  },
  {
    "name": "Movie Camera.png",
    "path": "Emojis/Objects/Movie Camera.png",
    "sha": "047dd7c6d78a22ecc682609ab7db5de4eb08ef59",
    "size": 13932,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Movie%20Camera.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Movie%20Camera.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/047dd7c6d78a22ecc682609ab7db5de4eb08ef59",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Movie%20Camera.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Movie%20Camera.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/047dd7c6d78a22ecc682609ab7db5de4eb08ef59",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Movie%20Camera.png"
    }
  },
  {
    "name": "Musical Keyboard.png",
    "path": "Emojis/Objects/Musical Keyboard.png",
    "sha": "6d8c94cadd39767770bb8ddbc43e28bf30d50bd0",
    "size": 12118,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Keyboard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Keyboard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d8c94cadd39767770bb8ddbc43e28bf30d50bd0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Musical%20Keyboard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Keyboard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6d8c94cadd39767770bb8ddbc43e28bf30d50bd0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Keyboard.png"
    }
  },
  {
    "name": "Musical Note.png",
    "path": "Emojis/Objects/Musical Note.png",
    "sha": "37be12718b90d66a7e42b51e73467d3aecf7fedd",
    "size": 13227,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Note.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Note.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/37be12718b90d66a7e42b51e73467d3aecf7fedd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Musical%20Note.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Note.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/37be12718b90d66a7e42b51e73467d3aecf7fedd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Note.png"
    }
  },
  {
    "name": "Musical Notes.png",
    "path": "Emojis/Objects/Musical Notes.png",
    "sha": "bfd7b4e779c81bf73c213f3d097a452ca555ab52",
    "size": 981166,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Notes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Notes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfd7b4e779c81bf73c213f3d097a452ca555ab52",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Musical%20Notes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Notes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bfd7b4e779c81bf73c213f3d097a452ca555ab52",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Notes.png"
    }
  },
  {
    "name": "Musical Score.png",
    "path": "Emojis/Objects/Musical Score.png",
    "sha": "e81507c5214bcac77d72a99f022b938fd53588d5",
    "size": 16374,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Score.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Score.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e81507c5214bcac77d72a99f022b938fd53588d5",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Musical%20Score.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Musical%20Score.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e81507c5214bcac77d72a99f022b938fd53588d5",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Musical%20Score.png"
    }
  },
  {
    "name": "Muted Speaker.png",
    "path": "Emojis/Objects/Muted Speaker.png",
    "sha": "87957a8bb1f693ac2107a6247eac5b36b97327f9",
    "size": 9265,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Muted%20Speaker.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Muted%20Speaker.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87957a8bb1f693ac2107a6247eac5b36b97327f9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Muted%20Speaker.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Muted%20Speaker.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/87957a8bb1f693ac2107a6247eac5b36b97327f9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Muted%20Speaker.png"
    }
  },
  {
    "name": "Necktie.png",
    "path": "Emojis/Objects/Necktie.png",
    "sha": "75b9fc5e699fee188af639a8a8a0add428bfdd9e",
    "size": 15372,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Necktie.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Necktie.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75b9fc5e699fee188af639a8a8a0add428bfdd9e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Necktie.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Necktie.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/75b9fc5e699fee188af639a8a8a0add428bfdd9e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Necktie.png"
    }
  },
  {
    "name": "Newspaper.png",
    "path": "Emojis/Objects/Newspaper.png",
    "sha": "043f65a7efdc90649d022c630f4f60b65e26dac0",
    "size": 15815,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Newspaper.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Newspaper.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/043f65a7efdc90649d022c630f4f60b65e26dac0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Newspaper.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Newspaper.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/043f65a7efdc90649d022c630f4f60b65e26dac0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Newspaper.png"
    }
  },
  {
    "name": "Notebook with Decorative Cover.png",
    "path": "Emojis/Objects/Notebook with Decorative Cover.png",
    "sha": "d45582802bfee1526b46770fccac88631e38ab81",
    "size": 11107,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Notebook%20with%20Decorative%20Cover.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Notebook%20with%20Decorative%20Cover.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d45582802bfee1526b46770fccac88631e38ab81",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Notebook%20with%20Decorative%20Cover.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Notebook%20with%20Decorative%20Cover.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d45582802bfee1526b46770fccac88631e38ab81",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Notebook%20with%20Decorative%20Cover.png"
    }
  },
  {
    "name": "Notebook.png",
    "path": "Emojis/Objects/Notebook.png",
    "sha": "19c95ac1478915ee1aa12ddea696dc47fbd960ee",
    "size": 9177,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Notebook.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Notebook.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/19c95ac1478915ee1aa12ddea696dc47fbd960ee",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Notebook.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Notebook.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/19c95ac1478915ee1aa12ddea696dc47fbd960ee",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Notebook.png"
    }
  },
  {
    "name": "Nut and Bolt.png",
    "path": "Emojis/Objects/Nut and Bolt.png",
    "sha": "5e4da839869d1afecbb9c78028092327a979c144",
    "size": 11884,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Nut%20and%20Bolt.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Nut%20and%20Bolt.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5e4da839869d1afecbb9c78028092327a979c144",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Nut%20and%20Bolt.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Nut%20and%20Bolt.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5e4da839869d1afecbb9c78028092327a979c144",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Nut%20and%20Bolt.png"
    }
  },
  {
    "name": "Old Key.png",
    "path": "Emojis/Objects/Old Key.png",
    "sha": "f4c127a272f277484e302152e1a514ef4d3fb6f7",
    "size": 10370,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Old%20Key.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Old%20Key.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4c127a272f277484e302152e1a514ef4d3fb6f7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Old%20Key.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Old%20Key.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f4c127a272f277484e302152e1a514ef4d3fb6f7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Old%20Key.png"
    }
  },
  {
    "name": "One-Piece Swimsuit.png",
    "path": "Emojis/Objects/One-Piece Swimsuit.png",
    "sha": "cb808be7f461f41e84b878fe08a010ab44094006",
    "size": 9795,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/One-Piece%20Swimsuit.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/One-Piece%20Swimsuit.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cb808be7f461f41e84b878fe08a010ab44094006",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/One-Piece%20Swimsuit.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/One-Piece%20Swimsuit.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cb808be7f461f41e84b878fe08a010ab44094006",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/One-Piece%20Swimsuit.png"
    }
  },
  {
    "name": "Open Book.png",
    "path": "Emojis/Objects/Open Book.png",
    "sha": "361564d2deafc9108e6882789797c5cd451f3f79",
    "size": 10632,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Book.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Book.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/361564d2deafc9108e6882789797c5cd451f3f79",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Open%20Book.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Book.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/361564d2deafc9108e6882789797c5cd451f3f79",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Book.png"
    }
  },
  {
    "name": "Open File Folder.png",
    "path": "Emojis/Objects/Open File Folder.png",
    "sha": "e325ea89ae5b5a51cae174796891961336cdf10a",
    "size": 9288,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20File%20Folder.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20File%20Folder.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e325ea89ae5b5a51cae174796891961336cdf10a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Open%20File%20Folder.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20File%20Folder.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e325ea89ae5b5a51cae174796891961336cdf10a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20File%20Folder.png"
    }
  },
  {
    "name": "Open Mailbox with Lowered Flag.png",
    "path": "Emojis/Objects/Open Mailbox with Lowered Flag.png",
    "sha": "0953f35456b6f298b30c516ccbc4d56cc3157c27",
    "size": 9155,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Mailbox%20with%20Lowered%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Mailbox%20with%20Lowered%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0953f35456b6f298b30c516ccbc4d56cc3157c27",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Open%20Mailbox%20with%20Lowered%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Mailbox%20with%20Lowered%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0953f35456b6f298b30c516ccbc4d56cc3157c27",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Mailbox%20with%20Lowered%20Flag.png"
    }
  },
  {
    "name": "Open Mailbox with Raised Flag.png",
    "path": "Emojis/Objects/Open Mailbox with Raised Flag.png",
    "sha": "d1b2d8657046c7c0ef36daeda10088e936ac8618",
    "size": 10446,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Mailbox%20with%20Raised%20Flag.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Mailbox%20with%20Raised%20Flag.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1b2d8657046c7c0ef36daeda10088e936ac8618",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Open%20Mailbox%20with%20Raised%20Flag.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Open%20Mailbox%20with%20Raised%20Flag.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d1b2d8657046c7c0ef36daeda10088e936ac8618",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Open%20Mailbox%20with%20Raised%20Flag.png"
    }
  },
  {
    "name": "Optical Disk.png",
    "path": "Emojis/Objects/Optical Disk.png",
    "sha": "d9f7c9f82c9529ebc4a8f4d0614296f41b7ba2dc",
    "size": 14946,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Optical%20Disk.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Optical%20Disk.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9f7c9f82c9529ebc4a8f4d0614296f41b7ba2dc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Optical%20Disk.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Optical%20Disk.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d9f7c9f82c9529ebc4a8f4d0614296f41b7ba2dc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Optical%20Disk.png"
    }
  },
  {
    "name": "Orange Book.png",
    "path": "Emojis/Objects/Orange Book.png",
    "sha": "95ce72b1293ea896d6ba117cbeb4b8eeaee7324e",
    "size": 8405,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Orange%20Book.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Orange%20Book.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/95ce72b1293ea896d6ba117cbeb4b8eeaee7324e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Orange%20Book.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Orange%20Book.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/95ce72b1293ea896d6ba117cbeb4b8eeaee7324e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Orange%20Book.png"
    }
  },
  {
    "name": "Outbox Tray.png",
    "path": "Emojis/Objects/Outbox Tray.png",
    "sha": "f20408bb1083e37391493f927ed043ca3974e243",
    "size": 10122,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Outbox%20Tray.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Outbox%20Tray.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f20408bb1083e37391493f927ed043ca3974e243",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Outbox%20Tray.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Outbox%20Tray.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f20408bb1083e37391493f927ed043ca3974e243",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Outbox%20Tray.png"
    }
  },
  {
    "name": "Package.png",
    "path": "Emojis/Objects/Package.png",
    "sha": "8062f88259a10b20d03c7f33bfc0fc2ae1607242",
    "size": 12499,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Package.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Package.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8062f88259a10b20d03c7f33bfc0fc2ae1607242",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Package.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Package.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8062f88259a10b20d03c7f33bfc0fc2ae1607242",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Package.png"
    }
  },
  {
    "name": "Page Facing Up.png",
    "path": "Emojis/Objects/Page Facing Up.png",
    "sha": "aed10d84a416f216821452227c749d056a1b99bb",
    "size": 11774,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Page%20Facing%20Up.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Page%20Facing%20Up.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/aed10d84a416f216821452227c749d056a1b99bb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Page%20Facing%20Up.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Page%20Facing%20Up.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/aed10d84a416f216821452227c749d056a1b99bb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Page%20Facing%20Up.png"
    }
  },
  {
    "name": "Page with Curl.png",
    "path": "Emojis/Objects/Page with Curl.png",
    "sha": "3205affa2c9ef4026396e359d5b47659fe97bd1e",
    "size": 9752,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Page%20with%20Curl.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Page%20with%20Curl.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3205affa2c9ef4026396e359d5b47659fe97bd1e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Page%20with%20Curl.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Page%20with%20Curl.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3205affa2c9ef4026396e359d5b47659fe97bd1e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Page%20with%20Curl.png"
    }
  },
  {
    "name": "Pager.png",
    "path": "Emojis/Objects/Pager.png",
    "sha": "5878ba646e5c7160a34b564f388f6e203ec2b771",
    "size": 9157,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pager.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pager.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5878ba646e5c7160a34b564f388f6e203ec2b771",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pager.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pager.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5878ba646e5c7160a34b564f388f6e203ec2b771",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pager.png"
    }
  },
  {
    "name": "Paintbrush.png",
    "path": "Emojis/Objects/Paintbrush.png",
    "sha": "17a9fcddc92fa668894f8a9acdf106b1db984e1f",
    "size": 6853,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Paintbrush.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Paintbrush.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/17a9fcddc92fa668894f8a9acdf106b1db984e1f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Paintbrush.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Paintbrush.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/17a9fcddc92fa668894f8a9acdf106b1db984e1f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Paintbrush.png"
    }
  },
  {
    "name": "Paperclip.png",
    "path": "Emojis/Objects/Paperclip.png",
    "sha": "53e90c591f08aa1482de503f7ccb90961fd7f9e3",
    "size": 11102,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Paperclip.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Paperclip.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53e90c591f08aa1482de503f7ccb90961fd7f9e3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Paperclip.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Paperclip.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/53e90c591f08aa1482de503f7ccb90961fd7f9e3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Paperclip.png"
    }
  },
  {
    "name": "Pen.png",
    "path": "Emojis/Objects/Pen.png",
    "sha": "f082492002a4c2d9864555b5f160e8dc16245aac",
    "size": 8771,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pen.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pen.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f082492002a4c2d9864555b5f160e8dc16245aac",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pen.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pen.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f082492002a4c2d9864555b5f160e8dc16245aac",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pen.png"
    }
  },
  {
    "name": "Pencil.png",
    "path": "Emojis/Objects/Pencil.png",
    "sha": "43afed49bf565ecb5552178cc21b77a9dd79bfaf",
    "size": 8284,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pencil.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pencil.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43afed49bf565ecb5552178cc21b77a9dd79bfaf",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pencil.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pencil.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43afed49bf565ecb5552178cc21b77a9dd79bfaf",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pencil.png"
    }
  },
  {
    "name": "Petri Dish.png",
    "path": "Emojis/Objects/Petri Dish.png",
    "sha": "7bb1aa9439873dea1f27064468d9863679493c65",
    "size": 15743,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Petri%20Dish.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Petri%20Dish.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7bb1aa9439873dea1f27064468d9863679493c65",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Petri%20Dish.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Petri%20Dish.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7bb1aa9439873dea1f27064468d9863679493c65",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Petri%20Dish.png"
    }
  },
  {
    "name": "Pick.png",
    "path": "Emojis/Objects/Pick.png",
    "sha": "902e6c1e7556c413eb62e8dca5268e978a966a7e",
    "size": 6734,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pick.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pick.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/902e6c1e7556c413eb62e8dca5268e978a966a7e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pick.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pick.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/902e6c1e7556c413eb62e8dca5268e978a966a7e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pick.png"
    }
  },
  {
    "name": "Pill.png",
    "path": "Emojis/Objects/Pill.png",
    "sha": "3e67f57648808d9c7673667dd5a9822727417a7f",
    "size": 11817,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pill.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pill.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e67f57648808d9c7673667dd5a9822727417a7f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pill.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pill.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3e67f57648808d9c7673667dd5a9822727417a7f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pill.png"
    }
  },
  {
    "name": "Pinata.png",
    "path": "Emojis/Objects/Pinata.png",
    "sha": "43a8166dd6be9aa6e21e9939afc4c6fd2c9f5cbc",
    "size": 11636,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pinata.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pinata.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43a8166dd6be9aa6e21e9939afc4c6fd2c9f5cbc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pinata.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pinata.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/43a8166dd6be9aa6e21e9939afc4c6fd2c9f5cbc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pinata.png"
    }
  },
  {
    "name": "Placard.png",
    "path": "Emojis/Objects/Placard.png",
    "sha": "35705fa301b02005558da1bd984135a17c533bc7",
    "size": 9128,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Placard.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Placard.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35705fa301b02005558da1bd984135a17c533bc7",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Placard.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Placard.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/35705fa301b02005558da1bd984135a17c533bc7",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Placard.png"
    }
  },
  {
    "name": "Plunger.png",
    "path": "Emojis/Objects/Plunger.png",
    "sha": "2afd12c110834d35cbdeec2ce63c86e9d2e39a43",
    "size": 7195,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Plunger.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Plunger.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2afd12c110834d35cbdeec2ce63c86e9d2e39a43",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Plunger.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Plunger.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2afd12c110834d35cbdeec2ce63c86e9d2e39a43",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Plunger.png"
    }
  },
  {
    "name": "Postal Horn.png",
    "path": "Emojis/Objects/Postal Horn.png",
    "sha": "430aaa80a45d360447d926721f689bac68f79366",
    "size": 9814,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Postal%20Horn.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Postal%20Horn.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/430aaa80a45d360447d926721f689bac68f79366",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Postal%20Horn.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Postal%20Horn.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/430aaa80a45d360447d926721f689bac68f79366",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Postal%20Horn.png"
    }
  },
  {
    "name": "Postbox.png",
    "path": "Emojis/Objects/Postbox.png",
    "sha": "a00a5dea81c717f94ecc41fab0e2215dc535a90b",
    "size": 11706,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Postbox.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Postbox.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a00a5dea81c717f94ecc41fab0e2215dc535a90b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Postbox.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Postbox.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a00a5dea81c717f94ecc41fab0e2215dc535a90b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Postbox.png"
    }
  },
  {
    "name": "Pound Banknote.png",
    "path": "Emojis/Objects/Pound Banknote.png",
    "sha": "a297fcf3e159187e63b8fe9aab2f68fb11156125",
    "size": 11859,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pound%20Banknote.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pound%20Banknote.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a297fcf3e159187e63b8fe9aab2f68fb11156125",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pound%20Banknote.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pound%20Banknote.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/a297fcf3e159187e63b8fe9aab2f68fb11156125",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pound%20Banknote.png"
    }
  },
  {
    "name": "Pouring Liquid.png",
    "path": "Emojis/Objects/Pouring Liquid.png",
    "sha": "626b9b1ac167c4fef4791800170a7863f855b013",
    "size": 1691317,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pouring%20Liquid.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pouring%20Liquid.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/626b9b1ac167c4fef4791800170a7863f855b013",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pouring%20Liquid.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pouring%20Liquid.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/626b9b1ac167c4fef4791800170a7863f855b013",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pouring%20Liquid.png"
    }
  },
  {
    "name": "Prayer Beads.png",
    "path": "Emojis/Objects/Prayer Beads.png",
    "sha": "22e8efc048ca6d2bb9a178d8c8650bf713cc8e27",
    "size": 11880,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Prayer%20Beads.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Prayer%20Beads.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/22e8efc048ca6d2bb9a178d8c8650bf713cc8e27",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Prayer%20Beads.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Prayer%20Beads.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/22e8efc048ca6d2bb9a178d8c8650bf713cc8e27",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Prayer%20Beads.png"
    }
  },
  {
    "name": "Printer.png",
    "path": "Emojis/Objects/Printer.png",
    "sha": "5094aea3836bb79341e09161d48dac45e299f5a4",
    "size": 11395,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Printer.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Printer.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5094aea3836bb79341e09161d48dac45e299f5a4",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Printer.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Printer.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5094aea3836bb79341e09161d48dac45e299f5a4",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Printer.png"
    }
  },
  {
    "name": "Purse.png",
    "path": "Emojis/Objects/Purse.png",
    "sha": "4eceef3a04d97c5408ecb278832c41e8151fefda",
    "size": 14058,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Purse.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Purse.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4eceef3a04d97c5408ecb278832c41e8151fefda",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Purse.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Purse.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4eceef3a04d97c5408ecb278832c41e8151fefda",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Purse.png"
    }
  },
  {
    "name": "Pushpin.png",
    "path": "Emojis/Objects/Pushpin.png",
    "sha": "604320ec8e9667f4f6ca74cf20ef0b430f62fdb1",
    "size": 10265,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pushpin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pushpin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/604320ec8e9667f4f6ca74cf20ef0b430f62fdb1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Pushpin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Pushpin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/604320ec8e9667f4f6ca74cf20ef0b430f62fdb1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Pushpin.png"
    }
  },
  {
    "name": "Radio.png",
    "path": "Emojis/Objects/Radio.png",
    "sha": "d831b42e318a68904e703348826be7ad88319791",
    "size": 11816,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Radio.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Radio.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d831b42e318a68904e703348826be7ad88319791",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Radio.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Radio.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d831b42e318a68904e703348826be7ad88319791",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Radio.png"
    }
  },
  {
    "name": "Razor.png",
    "path": "Emojis/Objects/Razor.png",
    "sha": "3edb07fe298164bff2125c75f8f262a32e74b4b6",
    "size": 7412,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Razor.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Razor.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3edb07fe298164bff2125c75f8f262a32e74b4b6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Razor.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Razor.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3edb07fe298164bff2125c75f8f262a32e74b4b6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Razor.png"
    }
  },
  {
    "name": "Receipt.png",
    "path": "Emojis/Objects/Receipt.png",
    "sha": "d2d9a615df416286f975dadfe83bcbce33c526aa",
    "size": 11838,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Receipt.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Receipt.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2d9a615df416286f975dadfe83bcbce33c526aa",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Receipt.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Receipt.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/d2d9a615df416286f975dadfe83bcbce33c526aa",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Receipt.png"
    }
  },
  {
    "name": "Red Paper Lantern.png",
    "path": "Emojis/Objects/Red Paper Lantern.png",
    "sha": "6a776a2a8194de84933de2a4f12b1de08ed4b03b",
    "size": 11096,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Red%20Paper%20Lantern.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Red%20Paper%20Lantern.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6a776a2a8194de84933de2a4f12b1de08ed4b03b",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Red%20Paper%20Lantern.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Red%20Paper%20Lantern.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6a776a2a8194de84933de2a4f12b1de08ed4b03b",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Red%20Paper%20Lantern.png"
    }
  },
  {
    "name": "Rescue Worker’s Helmet.png",
    "path": "Emojis/Objects/Rescue Worker’s Helmet.png",
    "sha": "e9dfa348a2fe933c36ff9eba5053d6a0a03f6b83",
    "size": 11818,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Rescue%20Worker%E2%80%99s%20Helmet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Rescue%20Worker%E2%80%99s%20Helmet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9dfa348a2fe933c36ff9eba5053d6a0a03f6b83",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Rescue Worker’s Helmet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Rescue%20Worker%E2%80%99s%20Helmet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/e9dfa348a2fe933c36ff9eba5053d6a0a03f6b83",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Rescue%20Worker%E2%80%99s%20Helmet.png"
    }
  },
  {
    "name": "Ring Buoy.png",
    "path": "Emojis/Objects/Ring Buoy.png",
    "sha": "6633424147c06f928c2c4c31fc2b8ef1666e37fc",
    "size": 4541137,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ring%20Buoy.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ring%20Buoy.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6633424147c06f928c2c4c31fc2b8ef1666e37fc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ring%20Buoy.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ring%20Buoy.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6633424147c06f928c2c4c31fc2b8ef1666e37fc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ring%20Buoy.png"
    }
  },
  {
    "name": "Ring.png",
    "path": "Emojis/Objects/Ring.png",
    "sha": "722e3a31e3fa311c007114fb4d26bf61e474b4c8",
    "size": 9359,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ring.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ring.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/722e3a31e3fa311c007114fb4d26bf61e474b4c8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Ring.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Ring.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/722e3a31e3fa311c007114fb4d26bf61e474b4c8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Ring.png"
    }
  },
  {
    "name": "Roll of Paper.png",
    "path": "Emojis/Objects/Roll of Paper.png",
    "sha": "fc62653d935bf8bff7671254313ffefe30778c07",
    "size": 12817,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Roll%20of%20Paper.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Roll%20of%20Paper.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc62653d935bf8bff7671254313ffefe30778c07",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Roll%20of%20Paper.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Roll%20of%20Paper.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fc62653d935bf8bff7671254313ffefe30778c07",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Roll%20of%20Paper.png"
    }
  },
  {
    "name": "Rolled-Up Newspaper.png",
    "path": "Emojis/Objects/Rolled-Up Newspaper.png",
    "sha": "0f9210fbc4570095c5d4cc22a170c0ff7f085ee8",
    "size": 12996,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Rolled-Up%20Newspaper.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Rolled-Up%20Newspaper.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f9210fbc4570095c5d4cc22a170c0ff7f085ee8",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Rolled-Up%20Newspaper.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Rolled-Up%20Newspaper.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0f9210fbc4570095c5d4cc22a170c0ff7f085ee8",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Rolled-Up%20Newspaper.png"
    }
  },
  {
    "name": "Round Pushpin.png",
    "path": "Emojis/Objects/Round Pushpin.png",
    "sha": "b3c8961e0a44b23c7a42392569ae428741c09adb",
    "size": 7895,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Round%20Pushpin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Round%20Pushpin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3c8961e0a44b23c7a42392569ae428741c09adb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Round%20Pushpin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Round%20Pushpin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3c8961e0a44b23c7a42392569ae428741c09adb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Round%20Pushpin.png"
    }
  },
  {
    "name": "Running Shoe.png",
    "path": "Emojis/Objects/Running Shoe.png",
    "sha": "c063f7421e1a370ae9710a9603e9a9c599447a79",
    "size": 11127,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Running%20Shoe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Running%20Shoe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c063f7421e1a370ae9710a9603e9a9c599447a79",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Running%20Shoe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Running%20Shoe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c063f7421e1a370ae9710a9603e9a9c599447a79",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Running%20Shoe.png"
    }
  },
  {
    "name": "Safety Pin.png",
    "path": "Emojis/Objects/Safety Pin.png",
    "sha": "40898c25848d65dbf446d4acd744bf4cf72d2c75",
    "size": 12543,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Safety%20Pin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Safety%20Pin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/40898c25848d65dbf446d4acd744bf4cf72d2c75",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Safety%20Pin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Safety%20Pin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/40898c25848d65dbf446d4acd744bf4cf72d2c75",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Safety%20Pin.png"
    }
  },
  {
    "name": "Safety Vest.png",
    "path": "Emojis/Objects/Safety Vest.png",
    "sha": "dc999e91ac5789186d0110d3ac0ba1ec2b1382dd",
    "size": 15174,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Safety%20Vest.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Safety%20Vest.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dc999e91ac5789186d0110d3ac0ba1ec2b1382dd",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Safety%20Vest.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Safety%20Vest.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/dc999e91ac5789186d0110d3ac0ba1ec2b1382dd",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Safety%20Vest.png"
    }
  },
  {
    "name": "Sari.png",
    "path": "Emojis/Objects/Sari.png",
    "sha": "b53ecb043dede14d7586298a29d8f63668a9ecfc",
    "size": 12649,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Sari.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Sari.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b53ecb043dede14d7586298a29d8f63668a9ecfc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Sari.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Sari.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b53ecb043dede14d7586298a29d8f63668a9ecfc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Sari.png"
    }
  },
  {
    "name": "Satellite Antenna.png",
    "path": "Emojis/Objects/Satellite Antenna.png",
    "sha": "0d4d7bbcc865a2a72c76ec9e7e0a6918ed46905c",
    "size": 10597,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Satellite%20Antenna.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Satellite%20Antenna.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d4d7bbcc865a2a72c76ec9e7e0a6918ed46905c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Satellite%20Antenna.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Satellite%20Antenna.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d4d7bbcc865a2a72c76ec9e7e0a6918ed46905c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Satellite%20Antenna.png"
    }
  },
  {
    "name": "Saxophone.png",
    "path": "Emojis/Objects/Saxophone.png",
    "sha": "54e1706fb0ef13ff62deda91c771b9928e3c5fb1",
    "size": 10264,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Saxophone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Saxophone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54e1706fb0ef13ff62deda91c771b9928e3c5fb1",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Saxophone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Saxophone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54e1706fb0ef13ff62deda91c771b9928e3c5fb1",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Saxophone.png"
    }
  },
  {
    "name": "Scarf.png",
    "path": "Emojis/Objects/Scarf.png",
    "sha": "9fd2623c827291af391e575d24135dccee61efb3",
    "size": 13674,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scarf.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scarf.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fd2623c827291af391e575d24135dccee61efb3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Scarf.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scarf.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9fd2623c827291af391e575d24135dccee61efb3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scarf.png"
    }
  },
  {
    "name": "Scissors.png",
    "path": "Emojis/Objects/Scissors.png",
    "sha": "2c8faf74eebe258eee1331de5e0c4d9742302629",
    "size": 12160,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scissors.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scissors.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c8faf74eebe258eee1331de5e0c4d9742302629",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Scissors.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scissors.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c8faf74eebe258eee1331de5e0c4d9742302629",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scissors.png"
    }
  },
  {
    "name": "Screwdriver.png",
    "path": "Emojis/Objects/Screwdriver.png",
    "sha": "8efd2314dd83076a8f0596e160b3d1e2c1cca25d",
    "size": 8060,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Screwdriver.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Screwdriver.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8efd2314dd83076a8f0596e160b3d1e2c1cca25d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Screwdriver.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Screwdriver.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/8efd2314dd83076a8f0596e160b3d1e2c1cca25d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Screwdriver.png"
    }
  },
  {
    "name": "Scroll.png",
    "path": "Emojis/Objects/Scroll.png",
    "sha": "575e3dee5aafd48f08bc7e4fc3a9599b5fb6ec28",
    "size": 10531,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scroll.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scroll.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/575e3dee5aafd48f08bc7e4fc3a9599b5fb6ec28",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Scroll.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Scroll.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/575e3dee5aafd48f08bc7e4fc3a9599b5fb6ec28",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Scroll.png"
    }
  },
  {
    "name": "Shield.png",
    "path": "Emojis/Objects/Shield.png",
    "sha": "f234df6bfdf32d1bdb4ed4340873763077294ea3",
    "size": 10660,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shield.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shield.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f234df6bfdf32d1bdb4ed4340873763077294ea3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Shield.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shield.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f234df6bfdf32d1bdb4ed4340873763077294ea3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shield.png"
    }
  },
  {
    "name": "Shopping Bags.png",
    "path": "Emojis/Objects/Shopping Bags.png",
    "sha": "22c601fb400f32f8c71984d749483b005ffd9df6",
    "size": 11650,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shopping%20Bags.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shopping%20Bags.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/22c601fb400f32f8c71984d749483b005ffd9df6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Shopping%20Bags.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shopping%20Bags.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/22c601fb400f32f8c71984d749483b005ffd9df6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shopping%20Bags.png"
    }
  },
  {
    "name": "Shopping Cart.png",
    "path": "Emojis/Objects/Shopping Cart.png",
    "sha": "9f5934a5c1ec6ecca29d4a5afe3885b906790b72",
    "size": 13218,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shopping%20Cart.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shopping%20Cart.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f5934a5c1ec6ecca29d4a5afe3885b906790b72",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Shopping%20Cart.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shopping%20Cart.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9f5934a5c1ec6ecca29d4a5afe3885b906790b72",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shopping%20Cart.png"
    }
  },
  {
    "name": "Shorts.png",
    "path": "Emojis/Objects/Shorts.png",
    "sha": "9bec96770ed6f5e0b2be25b3c8f2a9d5eb5527e9",
    "size": 17214,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shorts.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shorts.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9bec96770ed6f5e0b2be25b3c8f2a9d5eb5527e9",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Shorts.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shorts.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/9bec96770ed6f5e0b2be25b3c8f2a9d5eb5527e9",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shorts.png"
    }
  },
  {
    "name": "Shower.png",
    "path": "Emojis/Objects/Shower.png",
    "sha": "b48a5993336744616824808ed1919a611e8e7506",
    "size": 10184,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shower.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shower.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b48a5993336744616824808ed1919a611e8e7506",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Shower.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Shower.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b48a5993336744616824808ed1919a611e8e7506",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Shower.png"
    }
  },
  {
    "name": "Soap.png",
    "path": "Emojis/Objects/Soap.png",
    "sha": "1dbb9d0ba76784ec50d0a4094d42f17c9dbfc7d2",
    "size": 16324,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Soap.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Soap.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1dbb9d0ba76784ec50d0a4094d42f17c9dbfc7d2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Soap.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Soap.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1dbb9d0ba76784ec50d0a4094d42f17c9dbfc7d2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Soap.png"
    }
  },
  {
    "name": "Socks.png",
    "path": "Emojis/Objects/Socks.png",
    "sha": "bd75f70e4b254d73f69037bca8440535bcfa9f48",
    "size": 11579,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Socks.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Socks.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd75f70e4b254d73f69037bca8440535bcfa9f48",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Socks.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Socks.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd75f70e4b254d73f69037bca8440535bcfa9f48",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Socks.png"
    }
  },
  {
    "name": "Speaker High Volume.png",
    "path": "Emojis/Objects/Speaker High Volume.png",
    "sha": "7f7dbc7be4df84f9a7a19563cd8270dbb2d0dbab",
    "size": 11546,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20High%20Volume.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20High%20Volume.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7f7dbc7be4df84f9a7a19563cd8270dbb2d0dbab",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Speaker%20High%20Volume.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20High%20Volume.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/7f7dbc7be4df84f9a7a19563cd8270dbb2d0dbab",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20High%20Volume.png"
    }
  },
  {
    "name": "Speaker Low Volume.png",
    "path": "Emojis/Objects/Speaker Low Volume.png",
    "sha": "ccde8b92d6f4daddddb28a6cbfb1334d406db7a2",
    "size": 10689,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20Low%20Volume.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20Low%20Volume.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ccde8b92d6f4daddddb28a6cbfb1334d406db7a2",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Speaker%20Low%20Volume.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20Low%20Volume.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ccde8b92d6f4daddddb28a6cbfb1334d406db7a2",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20Low%20Volume.png"
    }
  },
  {
    "name": "Speaker Medium Volume.png",
    "path": "Emojis/Objects/Speaker Medium Volume.png",
    "sha": "58c62e623282aeb090dd82be442f598d795f966c",
    "size": 9799,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20Medium%20Volume.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20Medium%20Volume.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/58c62e623282aeb090dd82be442f598d795f966c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Speaker%20Medium%20Volume.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Speaker%20Medium%20Volume.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/58c62e623282aeb090dd82be442f598d795f966c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Speaker%20Medium%20Volume.png"
    }
  },
  {
    "name": "Spiral Calendar.png",
    "path": "Emojis/Objects/Spiral Calendar.png",
    "sha": "334a228c1fe41e18c2f4eb0ff959ab90ec22ca17",
    "size": 756723,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Spiral%20Calendar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Spiral%20Calendar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/334a228c1fe41e18c2f4eb0ff959ab90ec22ca17",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Spiral%20Calendar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Spiral%20Calendar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/334a228c1fe41e18c2f4eb0ff959ab90ec22ca17",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Spiral%20Calendar.png"
    }
  },
  {
    "name": "Spiral Notepad.png",
    "path": "Emojis/Objects/Spiral Notepad.png",
    "sha": "bd89202e8214b0feb97ad92a7c7a3a41632fa503",
    "size": 13831,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Spiral%20Notepad.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Spiral%20Notepad.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd89202e8214b0feb97ad92a7c7a3a41632fa503",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Spiral%20Notepad.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Spiral%20Notepad.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bd89202e8214b0feb97ad92a7c7a3a41632fa503",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Spiral%20Notepad.png"
    }
  },
  {
    "name": "Sponge.png",
    "path": "Emojis/Objects/Sponge.png",
    "sha": "582191f302a642b6f00ddd9d499c765be986f245",
    "size": 13550,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Sponge.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Sponge.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/582191f302a642b6f00ddd9d499c765be986f245",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Sponge.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Sponge.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/582191f302a642b6f00ddd9d499c765be986f245",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Sponge.png"
    }
  },
  {
    "name": "Stethoscope.png",
    "path": "Emojis/Objects/Stethoscope.png",
    "sha": "0d02915f089652acad09797b86db9a738a06c4eb",
    "size": 8937,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Stethoscope.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Stethoscope.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d02915f089652acad09797b86db9a738a06c4eb",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Stethoscope.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Stethoscope.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/0d02915f089652acad09797b86db9a738a06c4eb",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Stethoscope.png"
    }
  },
  {
    "name": "Straight Ruler.png",
    "path": "Emojis/Objects/Straight Ruler.png",
    "sha": "3d710880d0dde432b366769993d151e06418e70a",
    "size": 9355,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Straight%20Ruler.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Straight%20Ruler.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d710880d0dde432b366769993d151e06418e70a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Straight%20Ruler.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Straight%20Ruler.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3d710880d0dde432b366769993d151e06418e70a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Straight%20Ruler.png"
    }
  },
  {
    "name": "Studio Microphone.png",
    "path": "Emojis/Objects/Studio Microphone.png",
    "sha": "47ea51f186d52b6a317dbbe3e1d73c73b9282608",
    "size": 11954,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Studio%20Microphone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Studio%20Microphone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47ea51f186d52b6a317dbbe3e1d73c73b9282608",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Studio%20Microphone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Studio%20Microphone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/47ea51f186d52b6a317dbbe3e1d73c73b9282608",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Studio%20Microphone.png"
    }
  },
  {
    "name": "Syringe.png",
    "path": "Emojis/Objects/Syringe.png",
    "sha": "1fcb93efb4add3a119ac601cb181e4197cdd6b3c",
    "size": 7122,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Syringe.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Syringe.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1fcb93efb4add3a119ac601cb181e4197cdd6b3c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Syringe.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Syringe.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1fcb93efb4add3a119ac601cb181e4197cdd6b3c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Syringe.png"
    }
  },
  {
    "name": "T-Shirt.png",
    "path": "Emojis/Objects/T-Shirt.png",
    "sha": "ab508ac3ff196952fb75c2dd3b3a209b74d681a3",
    "size": 11311,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/T-Shirt.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/T-Shirt.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ab508ac3ff196952fb75c2dd3b3a209b74d681a3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/T-Shirt.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/T-Shirt.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ab508ac3ff196952fb75c2dd3b3a209b74d681a3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/T-Shirt.png"
    }
  },
  {
    "name": "Tear-Off Calendar.png",
    "path": "Emojis/Objects/Tear-Off Calendar.png",
    "sha": "2c00afde1374349d03fad34b4c036e2a3f28f40a",
    "size": 14951,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Tear-Off%20Calendar.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Tear-Off%20Calendar.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c00afde1374349d03fad34b4c036e2a3f28f40a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Tear-Off%20Calendar.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Tear-Off%20Calendar.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/2c00afde1374349d03fad34b4c036e2a3f28f40a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Tear-Off%20Calendar.png"
    }
  },
  {
    "name": "Telephone Receiver.png",
    "path": "Emojis/Objects/Telephone Receiver.png",
    "sha": "df0da22ec4875dba94d04a8ba87f02ad477150d0",
    "size": 679263,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telephone%20Receiver.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telephone%20Receiver.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df0da22ec4875dba94d04a8ba87f02ad477150d0",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Telephone%20Receiver.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telephone%20Receiver.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/df0da22ec4875dba94d04a8ba87f02ad477150d0",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telephone%20Receiver.png"
    }
  },
  {
    "name": "Telephone.png",
    "path": "Emojis/Objects/Telephone.png",
    "sha": "18e800dcd7928eea63900c317801aeff7db4c3b6",
    "size": 14756,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telephone.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telephone.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18e800dcd7928eea63900c317801aeff7db4c3b6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Telephone.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telephone.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/18e800dcd7928eea63900c317801aeff7db4c3b6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telephone.png"
    }
  },
  {
    "name": "Telescope.png",
    "path": "Emojis/Objects/Telescope.png",
    "sha": "cad739b228076df7ee689473d9b7e26080012d19",
    "size": 9249,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telescope.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telescope.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cad739b228076df7ee689473d9b7e26080012d19",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Telescope.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Telescope.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/cad739b228076df7ee689473d9b7e26080012d19",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Telescope.png"
    }
  },
  {
    "name": "Television.png",
    "path": "Emojis/Objects/Television.png",
    "sha": "b35b6d26d1d6af881f1384fee63f5353083a4f6d",
    "size": 11166,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Television.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Television.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b35b6d26d1d6af881f1384fee63f5353083a4f6d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Television.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Television.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b35b6d26d1d6af881f1384fee63f5353083a4f6d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Television.png"
    }
  },
  {
    "name": "Test Tube.png",
    "path": "Emojis/Objects/Test Tube.png",
    "sha": "4ab57da09feba1fd8776ff4aaf205d484ede9b11",
    "size": 8280,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Test%20Tube.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Test%20Tube.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab57da09feba1fd8776ff4aaf205d484ede9b11",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Test%20Tube.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Test%20Tube.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4ab57da09feba1fd8776ff4aaf205d484ede9b11",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Test%20Tube.png"
    }
  },
  {
    "name": "Thong Sandal.png",
    "path": "Emojis/Objects/Thong Sandal.png",
    "sha": "991de0da753c6f25b12ba61981e3dc1c02ea53da",
    "size": 10396,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Thong%20Sandal.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Thong%20Sandal.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/991de0da753c6f25b12ba61981e3dc1c02ea53da",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Thong%20Sandal.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Thong%20Sandal.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/991de0da753c6f25b12ba61981e3dc1c02ea53da",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Thong%20Sandal.png"
    }
  },
  {
    "name": "Toilet.png",
    "path": "Emojis/Objects/Toilet.png",
    "sha": "ebab7404dab7842cc92671709cbd41279e5eec44",
    "size": 10690,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toilet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toilet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ebab7404dab7842cc92671709cbd41279e5eec44",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Toilet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toilet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/ebab7404dab7842cc92671709cbd41279e5eec44",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toilet.png"
    }
  },
  {
    "name": "Toolbox.png",
    "path": "Emojis/Objects/Toolbox.png",
    "sha": "4b3e69cf12403c5efb1abab176f669b6e05fff65",
    "size": 13181,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toolbox.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toolbox.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b3e69cf12403c5efb1abab176f669b6e05fff65",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Toolbox.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toolbox.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/4b3e69cf12403c5efb1abab176f669b6e05fff65",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toolbox.png"
    }
  },
  {
    "name": "Toothbrush.png",
    "path": "Emojis/Objects/Toothbrush.png",
    "sha": "bdb69b78b27c9121f2bd1d08c2af3ffd23cdb6e6",
    "size": 7285,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toothbrush.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toothbrush.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdb69b78b27c9121f2bd1d08c2af3ffd23cdb6e6",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Toothbrush.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Toothbrush.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/bdb69b78b27c9121f2bd1d08c2af3ffd23cdb6e6",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Toothbrush.png"
    }
  },
  {
    "name": "Top Hat.png",
    "path": "Emojis/Objects/Top Hat.png",
    "sha": "fabdaaede0b1461e96e9b2e83eadc0a8df6d5e87",
    "size": 13521,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Top%20Hat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Top%20Hat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fabdaaede0b1461e96e9b2e83eadc0a8df6d5e87",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Top%20Hat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Top%20Hat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/fabdaaede0b1461e96e9b2e83eadc0a8df6d5e87",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Top%20Hat.png"
    }
  },
  {
    "name": "Trackball.png",
    "path": "Emojis/Objects/Trackball.png",
    "sha": "3fe4aaa1479f375b93c4475521da3101e247cd27",
    "size": 11182,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Trackball.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Trackball.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3fe4aaa1479f375b93c4475521da3101e247cd27",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Trackball.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Trackball.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3fe4aaa1479f375b93c4475521da3101e247cd27",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Trackball.png"
    }
  },
  {
    "name": "Triangular Ruler.png",
    "path": "Emojis/Objects/Triangular Ruler.png",
    "sha": "54cbb4c9cf03b794b9171aa7ca58a3c9ddae2905",
    "size": 11201,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Triangular%20Ruler.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Triangular%20Ruler.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54cbb4c9cf03b794b9171aa7ca58a3c9ddae2905",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Triangular%20Ruler.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Triangular%20Ruler.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/54cbb4c9cf03b794b9171aa7ca58a3c9ddae2905",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Triangular%20Ruler.png"
    }
  },
  {
    "name": "Trumpet.png",
    "path": "Emojis/Objects/Trumpet.png",
    "sha": "275306337d317c152bd50c3b6b2bae64dd83b120",
    "size": 13332,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Trumpet.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Trumpet.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/275306337d317c152bd50c3b6b2bae64dd83b120",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Trumpet.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Trumpet.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/275306337d317c152bd50c3b6b2bae64dd83b120",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Trumpet.png"
    }
  },
  {
    "name": "Unlocked.png",
    "path": "Emojis/Objects/Unlocked.png",
    "sha": "3add51900df36dad4c9bfd3a98bd638e4af99f01",
    "size": 10936,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Unlocked.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Unlocked.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3add51900df36dad4c9bfd3a98bd638e4af99f01",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Unlocked.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Unlocked.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/3add51900df36dad4c9bfd3a98bd638e4af99f01",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Unlocked.png"
    }
  },
  {
    "name": "Video Camera.png",
    "path": "Emojis/Objects/Video Camera.png",
    "sha": "c095606bf76fed0e181f9b402a6a237d39a3c9ce",
    "size": 10745,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Video%20Camera.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Video%20Camera.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c095606bf76fed0e181f9b402a6a237d39a3c9ce",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Video%20Camera.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Video%20Camera.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c095606bf76fed0e181f9b402a6a237d39a3c9ce",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Video%20Camera.png"
    }
  },
  {
    "name": "Videocassette.png",
    "path": "Emojis/Objects/Videocassette.png",
    "sha": "042d8d04b859c9eb1b0cfd262c1b1a4c2e34399f",
    "size": 11334,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Videocassette.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Videocassette.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/042d8d04b859c9eb1b0cfd262c1b1a4c2e34399f",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Videocassette.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Videocassette.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/042d8d04b859c9eb1b0cfd262c1b1a4c2e34399f",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Videocassette.png"
    }
  },
  {
    "name": "Violin.png",
    "path": "Emojis/Objects/Violin.png",
    "sha": "1528bbcae41eba1f6d228a2248df63731b4f809d",
    "size": 9906,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Violin.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Violin.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1528bbcae41eba1f6d228a2248df63731b4f809d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Violin.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Violin.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/1528bbcae41eba1f6d228a2248df63731b4f809d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Violin.png"
    }
  },
  {
    "name": "Wastebasket.png",
    "path": "Emojis/Objects/Wastebasket.png",
    "sha": "b3b9072af376cdfd9a4cc51cb1f68a075d97a87a",
    "size": 21820,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wastebasket.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wastebasket.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3b9072af376cdfd9a4cc51cb1f68a075d97a87a",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Wastebasket.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wastebasket.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b3b9072af376cdfd9a4cc51cb1f68a075d97a87a",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wastebasket.png"
    }
  },
  {
    "name": "Water Pistol.png",
    "path": "Emojis/Objects/Water Pistol.png",
    "sha": "f06c4b8380136fa58b9e4285eb2ead75f757d97c",
    "size": 7857,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Water%20Pistol.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Water%20Pistol.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f06c4b8380136fa58b9e4285eb2ead75f757d97c",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Water%20Pistol.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Water%20Pistol.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/f06c4b8380136fa58b9e4285eb2ead75f757d97c",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Water%20Pistol.png"
    }
  },
  {
    "name": "White Cane.png",
    "path": "Emojis/Objects/White Cane.png",
    "sha": "b4e32c90a4400a4b1b86ef370918acbd4854060d",
    "size": 4759,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/White%20Cane.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/White%20Cane.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b4e32c90a4400a4b1b86ef370918acbd4854060d",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/White%20Cane.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/White%20Cane.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b4e32c90a4400a4b1b86ef370918acbd4854060d",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/White%20Cane.png"
    }
  },
  {
    "name": "Window.png",
    "path": "Emojis/Objects/Window.png",
    "sha": "b15e5a3d5fa0bd02be6354f1b3ccbdea74d95141",
    "size": 13784,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Window.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Window.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b15e5a3d5fa0bd02be6354f1b3ccbdea74d95141",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Window.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Window.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/b15e5a3d5fa0bd02be6354f1b3ccbdea74d95141",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Window.png"
    }
  },
  {
    "name": "Wireless.png",
    "path": "Emojis/Objects/Wireless.png",
    "sha": "57c622f70ac97fcf6696f0195f22815923f484ed",
    "size": 39229,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wireless.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wireless.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/57c622f70ac97fcf6696f0195f22815923f484ed",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Wireless.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wireless.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/57c622f70ac97fcf6696f0195f22815923f484ed",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wireless.png"
    }
  },
  {
    "name": "Woman’s Boot.png",
    "path": "Emojis/Objects/Woman’s Boot.png",
    "sha": "89cf133009eaefddc0539442a3647a7a1abb2951",
    "size": 10709,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Boot.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Boot.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89cf133009eaefddc0539442a3647a7a1abb2951",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Woman’s Boot.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Boot.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/89cf133009eaefddc0539442a3647a7a1abb2951",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Boot.png"
    }
  },
  {
    "name": "Woman’s Clothes.png",
    "path": "Emojis/Objects/Woman’s Clothes.png",
    "sha": "c111593a6901198d1f584fab571919a018eb9b5e",
    "size": 11103,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Clothes.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Clothes.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c111593a6901198d1f584fab571919a018eb9b5e",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Woman’s Clothes.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Clothes.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/c111593a6901198d1f584fab571919a018eb9b5e",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Clothes.png"
    }
  },
  {
    "name": "Woman’s Hat.png",
    "path": "Emojis/Objects/Woman’s Hat.png",
    "sha": "5f4f9fd9466d14dff971d1e9b94dc4bd600413f3",
    "size": 12645,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Hat.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Hat.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f4f9fd9466d14dff971d1e9b94dc4bd600413f3",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Woman’s Hat.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Hat.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5f4f9fd9466d14dff971d1e9b94dc4bd600413f3",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Hat.png"
    }
  },
  {
    "name": "Woman’s Sandal.png",
    "path": "Emojis/Objects/Woman’s Sandal.png",
    "sha": "6ac8e1f742ae590a9b82eccbc8c7629e726558dc",
    "size": 7749,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Sandal.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Sandal.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ac8e1f742ae590a9b82eccbc8c7629e726558dc",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Woman’s Sandal.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Woman%E2%80%99s%20Sandal.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/6ac8e1f742ae590a9b82eccbc8c7629e726558dc",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Woman%E2%80%99s%20Sandal.png"
    }
  },
  {
    "name": "Wrench.png",
    "path": "Emojis/Objects/Wrench.png",
    "sha": "282f2ac83d0c55878e196a1d0043acfc669ff481",
    "size": 8057,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wrench.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wrench.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/282f2ac83d0c55878e196a1d0043acfc669ff481",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Wrench.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Wrench.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/282f2ac83d0c55878e196a1d0043acfc669ff481",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Wrench.png"
    }
  },
  {
    "name": "X-Ray.png",
    "path": "Emojis/Objects/X-Ray.png",
    "sha": "28d32b3572201ef8ff44bae5797cb00a3ccea587",
    "size": 3048430,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/X-Ray.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/X-Ray.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/28d32b3572201ef8ff44bae5797cb00a3ccea587",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/X-Ray.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/X-Ray.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/28d32b3572201ef8ff44bae5797cb00a3ccea587",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/X-Ray.png"
    }
  },
  {
    "name": "Yen Banknote.png",
    "path": "Emojis/Objects/Yen Banknote.png",
    "sha": "5330e0025dd204a35245ee42650ca6c68ea67e32",
    "size": 12295,
    "url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Yen%20Banknote.png?ref=master",
    "html_url": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Yen%20Banknote.png",
    "git_url": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5330e0025dd204a35245ee42650ca6c68ea67e32",
    "download_url": "https://raw.githubusercontent.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/master/Emojis/Objects/Yen%20Banknote.png",
    "type": "file",
    "_links": {
      "self": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/contents/Emojis/Objects/Yen%20Banknote.png?ref=master",
      "git": "https://api.github.com/repos/Tarikul-Islam-Anik/Animated-Fluent-Emojis/git/blobs/5330e0025dd204a35245ee42650ca6c68ea67e32",
      "html": "https://github.com/Tarikul-Islam-Anik/Animated-Fluent-Emojis/blob/master/Emojis/Objects/Yen%20Banknote.png"
    }
  }
]

export default {
  id: 'objects',
  name: 'Objects',
  emojis: customEmojis.map((emoji) => {
    return {
      id: emoji.sha,
      name: emoji.name,
      skins: [{ src: emoji.download_url }]
    }
  })
}