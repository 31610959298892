import { Button, Flex } from "antd"
import { useLocation, useNavigate } from "react-router-dom";
import Messages from "../components/Messages";
import Tasks from "../components/Tasks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, post, xdelete } from "../services/api";
import { CalendarOutlined, MessageOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { setList } from "../features/list";


const _tasks = [{
  id: 1,
  title: "Testimonials From Our Happy Clients needs improvements",
  due_date: new Date(),
  assignee: [{
    name: "Naveen Raghuvanshi"
  }],
  priority: "high",
  time_estimated: 60,
  time_tracked: 30,
  status: 'inactive'
}, {
  id: 2,
  title: "Test Task 2",
  due_date: new Date(),
  assignee: [{
    name: "Naveen Raghuvanshi"
  }],
  priority: "high",
  time_estimated: 60,
  time_tracked: 30,
  status: 'inactive'
}];

export default function List ({list, project}) {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const [showChat, setShowChat] = useState(true);
  const [tasks, setTasks] = useState([]);
  const dispatch = useDispatch()

  const updateTask = async (tasks) => {
    refreshTasks();
    setTasks(tasks);
  }

  const deleteTasks = async (tasksIds) => {
    var res = await xdelete(`/users/${user.id}/tasks`, {
      id: {
        inq: tasksIds
      }
    });
    if(res.count > 0) {
      refreshTasks();
    }
  }

  const refreshTasks = () => {
    get(`/users/${user.id}/lists/${list.id}/tasks`).then(tasks => {
      setTasks(tasks);
    })
  }

  useEffect(() => {
    refreshTasks();
    if(list.id) {
      get(`/projects/${project.id}/lists`, {
        where: {id: list.id},
        include: [{
          relation: "listAccesses",
          // scope: {
          //   where: {
          //     userId: {
          //       neq: user.id
          //     }
          //   }
          // }
        },{
          relation: "project"
        }]
      }).then((lists) => {
        dispatch(setList(lists[0]));
      });
    }
    return ()=>{
      dispatch(setList(null))
    }
  }, [list.id, user.id]);

  const openChat = () => {
    navigate(".", {
      state: {
        chat: !location.state?.chat
      },
      replace: true
    })
  }

  useEffect(() => {
    if(location.state && location.state.chat) {
      setShowChat(true);
    } else if(list.notifications > 0){
      navigate(".", {
        state: {
          chat: true
        },
        replace: true
      })
    } else {
      setShowChat(false);
    }
  }, [location.state]);
  
  return <div>
    <div className="left-bg-overlay">
      <Button type="primary" icon={<MessageOutlined />} size={'large'}  onClick={openChat} />
      <Button type="primary" icon={<CalendarOutlined  />} size={'large'} />
      <Button type="primary" icon={<UnorderedListOutlined />} size={'large'} />
    </div>
    <Messages users={users} user={user} list={list} userId={user.id} showChat={showChat} />
    <Tasks users={users} list={list} tasks={tasks} setTasks={setTasks} onDelete={deleteTasks} onUpdateTask={updateTask} showChat={showChat} setShowChat={setShowChat} />
  </div>

  // return <Flex justify="space-between" vertical style={{ height: "100%" }}>
  //   <Flex gap={0} style={{ flex: 1, height: "100%" }}>
  //     {showChat && <Flex style={{ flex: 1, height: "100%", flexDirection: "column-reverse" }}>
  //       <Messages users={users} list={list} userId={user.id}/>
  //     </Flex>}
  //     <div style={{ flex: 1, height: "100%", overflowY: "hidden", flexDirection: "column-reverse" }}>
  //       <Tasks users={users} list={list} tasks={tasks} setTasks={setTasks} onDelete={deleteTasks} onUpdateTask={updateTask} showChat={showChat} setShowChat={setShowChat} />
  //     </div>
  //   </Flex>
  //   <Flex gap={0} style={{ flex: 0 }}>
  //     {/* <div style={{ flex: 1 }}>
  //       <SendMessageInput user={user} list={list} openChat={ () => { !showChat && navigate(".", {
  //         state: {
  //           chat: !location.state.chat
  //         },
  //         replace: true
  //       }) } } />
  //     </div> */}
  //     {/* <div style={{ flex: 1 }}>
  //       <CreateTaskInput onCreateTask={addTask}/>
  //     </div> */}
  //   </Flex>
  // </Flex>
}