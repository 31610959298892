import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Flex, Popconfirm, Table, Tabs, Typography } from "antd";
import Column from "antd/es/table/Column";
import { useEffect, useState } from "react";
import AvatarPicker from '../components/AvatarPicker';
import { get, patch, post, remove, slugify, xdelete } from '../services/api';
import { useDispatch, useSelector } from "react-redux";
import { setProjects } from "../features/projects";

// Example user data

export default function Project ({project}) {
  const [lists, setLists] = useState([]);
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users.filter(u => u.id !== user.id));
  const projects = useSelector((state) => state.projects);
  const dispatch = useDispatch();
  const selectType = () => {

  }
  const addList = async () => {
    const list = await post(`/users/${user.id}/lists`, {
      "name": "List "+(lists.length+1),
      "projectId": project.id
    });
    const _lists = [
      ...lists,
      list
    ];
    setLists(_lists);
    dispatch(setProjects(projects.map(p => p.id === project.id ? {...p, lists: _lists } : p)));
  }
  const updateListName = async (name, list) => {
    const res = await patch(`/users/${user.id}/lists`, { name }, { id: list.id });
    if(res.count) {
      const _lists = lists.map(l => l.id === list.id ? {...l, name } : l);
      setLists(_lists);
      dispatch(setProjects(projects.map(p => p.id === project.id ? {...p, lists: _lists } : p)));
    }
  }
  const deleteList = async (list) => {
    const res = await xdelete(`/users/${user.id}/lists`, { id: list.id });
    if(res.count) {
      const _lists = lists.filter(l => l.id !== list.id);
      setLists(_lists);
      dispatch(setProjects(projects.map(p => p.id === project.id ? {...p, lists: _lists } : p)));
    }
  }
  const onAddUser = async (user, list, access) => {
    const access_granted = await post(`/lists/${list.id}/list-accesses`, {
      userId: user,
      access
    });
    if(access_granted) {
      setLists(lists.map((l) => {
        return l.id === list.id ? {
          ...l,
          listAccesses: [ ...(l.listAccesses || []), access_granted ]
        } : l
      }));
      console.log('access_granted', access_granted);
    }
  }
  const onRemoveUser = async (user, list) => {
    const access_removed = await xdelete(`/lists/${list.id}/list-accesses`, {
      userId: user
    });
    if(access_removed) {
      setLists(lists.map((l) => {
        return l.id === list.id ? {
          ...l,
          listAccesses: l.listAccesses.filter(la => la.userId !== user)
        } : l
      }));
      console.log('access_removed', access_removed);
      console.log('remove', user, list);
    }
  }
  useEffect(() => {
    setLists(project.lists.filter(l => l));
  }, [project.lists]);
  useEffect(() => {
    get(`/projects/${project.id}/lists`, {
      include: [{
        relation: "listAccesses",
        scope: {
          where: {
            userId: {
              neq: user.id
            }
          }
        }
      }]
    }).then((lists) => {
      setLists(lists);
    });
  }, [project.id, user.id]);
  return <Flex gap={40}>
    <div>
      <Tabs
        defaultActiveKey="1"
        tabPosition={'left'}
        size="small"
        style={{ width: "200px" }}
        onChange={selectType}
        items={[{
          label: `Overview`,
          key: 'overview'
        }]}
      />
    </div>
    <div style={{ flex: 1 }}>
      <Table
        dataSource={lists}
        rowKey={(list) => (slugify(list ? list.name : ''))}
        showHeader={true}
        pagination={false}
        size={"small"}
        title={() => <Typography.Title level={3} style={{ marginTop: "-10px" }}>Lists</Typography.Title>}
        footer={() => (
          <>
            <div style={{ textAlign: "right" }}>
              <Button type="primary" onClick={addList}>Add</Button>
            </div>
          </>
        )}
      >
        <Column
          title="List"
          dataIndex="name"
          key="name"
          width={"50%"}
          render={(name, list, i) => {
            return <Typography.Text editable={{ onChange: (name) => updateListName(name, list) }} style={{ height: "100%", top: "7px", left: "0", color: name ? "inherit" : "#888" }}>{name || 'List '+i}</Typography.Text>;
          }}
        />
        <Column
          title="All Mode"
          dataIndex="appointed"
          key="access_all"
          width={"25%"}
          render={(t, list) => {
            const readOnly = project.lists.findIndex(l => l && l.id === list.id) < 0;
            return <AvatarPicker readOnly={readOnly} defaultSelectedUsers={ list.listAccesses ? list.listAccesses.filter(la => la.access).map(la => la.userId) : [] } users={
              users.filter(u => list.listAccesses ? !list.listAccesses.find(la => la.userId === u.id && la.access === false) : true )
            } onRemoveUser={ (u)=>{ onRemoveUser(u, list) }} onAddUser={ (u)=>{ onAddUser(u, list, true) }}/>
          }}
        />
        <Column
          title="Me Mode"
          dataIndex="appointed"
          key="access_me"
          width={"25%"}
          render={(t, list) => {
            const readOnly = project.lists.findIndex(l => l && l.id == list.id) < 0;
            return <AvatarPicker readOnly={readOnly} defaultSelectedUsers={ list.listAccesses ? list.listAccesses.filter(la => !la.access).map(la => la.userId) : [] } users={
              users.filter(u => list.listAccesses ? !list.listAccesses.find(la => la.userId === u.id && la.access === true) : true )
            } onRemoveUser={ (u)=>{ onRemoveUser(u, list) }} onAddUser={ (u)=>{ onAddUser(u, list, false) }}/>
          }}
        />
        <Column
          render={(tmp, list) => {
            return <Popconfirm
              title="Delete List"
              placement="bottomRight"
              description="Are you sure to delete this list?"
              onConfirm={ () => deleteList(list) }
              onCancel={console.log}
              okText="Yes"
              cancelText="No"
            >
              <Button shape="circle" icon={<DeleteOutlined />} />
            </Popconfirm>
          }}
        />
      </Table>
    </div>
  </Flex>
}