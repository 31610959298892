import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { post } from "../services/api";
import dayjs from "dayjs";
import { useEffect } from "react";

export default function HumanTransactionCreate({ isModalOpen, setIsModalOpen, onHumanTransactionCreate, human, setHuman}) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setHuman(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      amount: -values.amount,
      endAt: values.endAt ? moment(values.endAt.unix()*1000).endOf('day') : null
    };
    if(!values.endAt) {
      delete data.endAt;
    }
    const transaction = await post(`/humans/${human.id}/transactions`, data);
    setIsModalOpen(false);
    onHumanTransactionCreate(transaction);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  if(!human) { return null; }
  return <Modal title="Add Human Transaction" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addHumanTransactionForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addHumanTransactionForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={human.name}
          rules={[{
            required: true,
            message: 'Please input human name!',
          }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Amount"
          name="amount"
          initialValue={human.ctc/12}
          rules={[{
            required: true,
            message: 'Please input human ctc!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Start"
          name="startAt"
          initialValue={ human.transactions && human.transactions.length ? dayjs(human.transactions[0].endAt) : dayjs().startOf('month') }
          >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="End"
          name="endAt"
          initialValue={ human.transactions && human.transactions.length ? dayjs(human.transactions[0].endAt).endOf('month').endOf('day') : dayjs().endOf('month').endOf('day') }
          >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}