import { DatePicker } from "antd";
import dayjs from "dayjs";

export default function DueDatePicker ({task, onUpdateDueDate}) {

  const handleChange = (value) => {
    onUpdateDueDate(task.id, value);
  }

  return <DatePicker value={task.dueDate ? dayjs(task.dueDate) : null} onChange={handleChange} />
}