import { Button, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import { patch } from "../services/api";

export default function ServiceEdit({ isModalOpen, setIsModalOpen, onServiceEdit, service, setService }) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setService(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values, 
    };
    if(!values.endDate) {
      delete data.endDate;
    }
    const resp = await patch(`/services/${service.id}`, data);
    setIsModalOpen(false);
    onServiceEdit(resp);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  if (!service) { return null; }
  return <Modal title="Edit Service" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="editServiceForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="editServiceForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={service.name}
          rules={[{
            required: true,
            message: 'Please input service name!',
          }]}>
          <Input autoFocus={true} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Rate"
          name="rate"
          initialValue={service.rate}
          rules={[{
            required: true,
            message: 'Please input service rate!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Quantity"
          name="quantity"
          initialValue={service.quantity}
          rules={[{
            required: true,
            message: 'Please input service quantity!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Renew"
          name="period"
          initialValue={service.period}
          >
          <Select>
            <Select.Option value="year">yearly</Select.Option>
            <Select.Option value="quarter">quaterly</Select.Option>
            <Select.Option value="month">monthly</Select.Option>
          </Select>
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}