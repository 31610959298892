import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { patch, put } from "../services/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";


export default function TransactionEdit({ isModalOpen, setIsModalOpen, onTransactionEdit, transaction, setTransaction, id }) {
  const humans = useSelector((state) => state.humans);
  const assets = useSelector((state) => state.assets);
  const services = useSelector((state) => state.services);
  const [settled, setSettled] = useState(false);
  const [transactionType, setTransactionType] = useState("");

  if (!transaction) { return null; }
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setTransaction(null);
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    const data = {
      ...values,
      amount: -values.amount,
      // endDate: values.endDate ? moment(values.endDate.unix() * 1000).endOf('day') : null
    };
    if (settled) {
      data.settled = settled
    }
    if (values.transactionType) {
      delete data.transactionType;
    }
    if (!values.endDate) {
      delete data.endDate;
    }
    const resp = await patch(`/transactions/${transaction.id}`, data);
    setIsModalOpen(false);
    onTransactionEdit();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };




  const handleFormValuesChange = (changedValues, allValues) => {
    if ('transactionType' in changedValues) {
      setTransactionType(changedValues.transactionType);
      // alert(changedValues.transactionType);
    }
    if ('settled' in changedValues) {
      setSettled(changedValues.settled);
      // alert(changedValues.transactionType);
    }

    // You can handle other form field changes here if needed
  };
  if (!transaction) { return null; }
  return <Modal title="Add Transaction" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="editTransaction" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="editTransaction"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleFormValuesChange}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      
      <Form.Item
        style={{ flex: 1 }}
        label="Transaction Name"
        name="name"
        initialValue={transaction.name}
        rules={[{
          required: true,
          message: 'Please input Transaction name!',
        }]}>
        <Input autoFocus={true} />
      </Form.Item>
      <Flex gap={'middle'}>

        
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Amount"
          name="amount"
          initialValue={-transaction.amount}
          rules={[{
            required: true,
            message: 'Please input Amount!',
          }]}>
          <InputNumber style={{ width: '100%' }} autoFocus={true} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Settled"
          name="settled"
          initialValue={transaction.settled}
        >
          <input type="checkbox" onChange={(e) => {
            setSettled(!settled)
          }} autoFocus={true} />
        </Form.Item>
      </Flex>

      {/* <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Start"
          name="startAt"
          initialValue={transaction.startAt && transaction.startAt}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="End"
          name="endAt"
          initialValue={transaction.endAt && transaction.endAt}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Flex> */}


    </Form>
  </Modal >
}