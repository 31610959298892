import { Menu } from "antd";
import Sider from "antd/lib/layout/Sider";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

let all = [];
const iterate = (obj, i) => {
  Object.keys(obj).forEach((key, _i) => {
    obj[key].key = i ? [i, _i].join(".") : "" + _i;
    all.push(obj[key]);
    if (obj[key].children) {
      iterate(obj[key].children, obj[key].key);
    }
  });
};

let _menu = null;
const getMenu = (links) => {
  if (_menu) {
    // return _menu;
  }
  all = [];
  iterate(links, "");
  _menu = [];
  links.forEach((link, index) => {
    const menu = { ...link, key: index, children: null };
    _menu.push(menu);
  });
  return _menu;
};

let _items = null;
const fetchMenuItems = (links, i) => {
  _items = [];
  links[i].children.forEach((item, _i) => {
    _items.push({
      ...item,
      key: [i, _i].join("."),
      children: item.children
        ? item.children.map((_item, __i) => ({
          ..._item,
          key: [i, _i, __i].join("."),
        }))
        : null,
    });
  });
  return _items;
};

const keyMap = new Map();

const Sidebar = ({ links }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const sections = getMenu(links);
  const projects = useSelector((state) => state.projects);
  const [menu, setMenu] = useState(0);
  const [selectedMenu, setSelectedMenu] = useState([]);
  const [opened, setOpened] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const items = fetchMenuItems(links, menu);

  useEffect(() => {
    // return () => {
      var active_link = all.find((a) => a.path === location.pathname);
      if (active_link && active_link.key) {
        // console.log('active_link', active_link.key);
        let keys = active_link.key.split(".");
        const project = `${keys[0]}.${keys[1]}`;
        if(keys.length === 3) {
          const list = `${keys[0]}.${keys[1]}.${keys[2]}`;
          keyMap.set(project, list);
        } else if(keys.length === 2) {
          const list = `${keys[0]}.${keys[1]}.${0}`;
          console.log(list);
          keyMap.set(project, list);
        }
        setMenu(keys[0]);
        setSelectedMenu([keys[0]]);
        if (keys.length === 2) {
          setOpened([active_link.key]);
          setSelectedKeys(active_link.key);
        } else if (keys.length === 3) {
          setOpened([keys[0] + "." + keys[1]]);
          setSelectedKeys(active_link.key);
        }
        // select(active_link.key);
      }
    // };
  }, [location.pathname]);
  const onMenuChange = (e) => {
    setMenu(e.key);
    setSelectedMenu([e.key]);
    setSelectedKeys([e.key + ".0"]);
    setOpened([]);
    select(e.key + ".0");
  };
  const select = (e) => {
    // console.log('select', e);
    // const tree = e.split(".");
    // const project = `${tree[0]}.${tree[1]}`;
    // const list = `${tree[0]}.${tree[1]}.${tree[2]}`;
    // if(tree.length === 3) {
    //   keyMap.set(project, list);
    // }
    // if(keypath && keypath.length > 1) {
    //   keyMap.set(keypath[1], keypath[0]);
    // } else if (!keypath && keyMap.get(e.split(".").slice(0, -1).join("."))) {
    //   var elem = all.find((a) => a.key === keyMap.get(e.split(".").slice(0, -1).join(".")));
    //   // document.getElementById("pageTitle").innerText = elem.label.toUpperCase();
    //   setSelectedKeys([elem.key]);
    //   return elem && elem.path ? navigate(elem.path) : null;
    // }
    var elem = all.find((a) => a.key === e);
    return elem && elem.path ? navigate(elem.path, { state: { chat: false } }) : null;
  };
  const onOpenChange = (e) => {
    console.log('onOpenChange', e);
    if (e.length > 1) {
      e.shift();
      select(keyMap.get(e[0]) || e[0]+".0");
    } else if (e.length === 1) {
      if(keyMap.get(e[0])) {
        select(keyMap.get(e[0]) || e[0]+".0");
      }
      setOpened(e);
    } else if (!e.length) {
      // select(opened[0]+".0");
      setOpened([]);
    }
  };
  const onSelect = (e) => {
    console.log('onSelect', e);
    // if (e.keyPath.length === 1) {
    //   setOpened([]);
    // }
    // // console.log(e.key);
    // setSelectedKeys([e.key]);
    select(e.key);
  };
  return (
    <Sider width={250} theme="light" collapsedWidth={0} breakpoint="lg" collapsible={true}>
      <Menu
        theme="light"
        mode="horizontal"
        className="topMenu"
        selectedKeys={selectedMenu}
        onSelect={onMenuChange}
        items={sections}
      />
      <Menu
        theme="light"
        inlineIndent={10}
        multiple={false}
        mode="inline"
        items={items}
        openKeys={opened}
        selectedKeys={selectedKeys}
        onSelect={onSelect}
        onOpenChange={onOpenChange}
      />
    </Sider>
  );
};

export default Sidebar;