import { useEffect } from "react";
import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { post } from "../services/api";
import dayjs from "dayjs";
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export default function ServiceTransactionCreate({ isModalOpen, setIsModalOpen, onServiceTransactionCreate, service, setService}) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setService(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      amount: -values.amount,
      endAt: values.endAt ? moment(values.endAt.unix()*1000).endOf('day') : null
    };
    if(!values.endAt) {
      delete data.endAt;
    }
    const transaction = await post(`/services/${service.id}/transactions`, data);
    setIsModalOpen(false);
    onServiceTransactionCreate(transaction);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  if(!service) { return null; }
  return <Modal title="Add Service Transaction" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addServiceTransactionForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addServiceTransactionForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={service.name}
          rules={[{
            required: true,
            message: 'Please input service name!',
          }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Amount"
          name="amount"
          initialValue={service.rate*service.quantity}
          rules={[{
            required: true,
            message: 'Please input human ctc!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Start"
          name="startAt"
          initialValue={ service.transactions && service.transactions.length ? dayjs(service.transactions[0].endAt) : dayjs().startOf(service.period) }
          >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="End"
          name="endAt"
          initialValue={ service.transactions && service.transactions.length ? dayjs(service.transactions[0].endAt).add(1, service.period).endOf('day') : dayjs().endOf(service.period).endOf('day') }
          >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}