import { Button, Flex, Form, Input, InputNumber, Modal, Select, DatePicker } from "antd";
import moment from "moment";
import { get, post } from "../services/api";
import { useSelector } from "react-redux";
import AvatarPicker from './AvatarPicker';
import { useState } from "react";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";


export default function TransactionCreate({ isModalOpen, setIsModalOpen, onTransactionCreate }) {
  const projects = useSelector((state) => state.projects);
  const users = useSelector((state) => state.users);
  const humans = useSelector((state) => state.humans);
  const assets = useSelector((state) => state.assets);
  const services = useSelector((state) => state.services);
  const [user, setUser] = useState(null);
  const [transactionName, setTransactionName] = useState("");
  const [transactionId, setTransactionId] = useState("");
  const [settled, setSettled] = useState(false);
  const [transactionType, setTransactionType] = useState("");
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      amount: -values.amount,
      endDate: values.endDate ? moment(values.endDate.unix() * 1000).endOf('day') : null
    };
    if (settled) {
      data.settled = settled
    }
    if (values.transactionType) {
      delete data.transactionType;
    }
    if (!values.endDate) {
      delete data.endDate;
    }
    const transactions = await post('/transactions', data);
    setIsModalOpen(false);
    onTransactionCreate();
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };




  const handleFormValuesChange = (changedValues, allValues) => {
    if ('transactionType' in changedValues) {
      setTransactionType(changedValues.transactionType);
      // alert(changedValues.transactionType);
    }
    if ('settled' in changedValues) {
      setSettled(changedValues.settled);
      // alert(changedValues.transactionType);
    }

    // You can handle other form field changes here if needed
  };
  console.log("transactionType : " + transactionType)
  return <Modal title="Add Transaction" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addTransaction" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addTransaction"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleFormValuesChange}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Transaction Type"
          name="transactionType"
        >
          <Select>
            <Select.Option value="human">Human</Select.Option>
            <Select.Option value="asset">Asset</Select.Option>
            <Select.Option value="service">Service</Select.Option>
          </Select>
        </Form.Item>
        {/* ===== Input field based on Transaction Type ====== */}
        <Form.Item
          style={{ flex: 1 }}
          label={transactionType === 'human' ? 'Select Human' : transactionType === 'asset' ? 'Select Asset' : transactionType === "service" ? 'Select Service' : 'Select'}
          name={transactionType === 'human' ? 'humanId' : transactionType === 'asset' ? 'assetId' : transactionType === "service" ? 'serviceId' : ''}
          rules={[{
            required: true,
            message: 'Please select Humans!',
          }]}>
          <Select >
            {
              transactionType === 'human' ? humans.map(h => <Select.Option key={h.id} value={h.id}>{h.name}</Select.Option>) :
                transactionType === 'asset' ? assets.map(a => <Select.Option key={a.id} value={a.id}>{a.name}</Select.Option>) :
                  transactionType === "service" ? services.map(s => s && s.id && <Select.Option key={s.id} value={s.id}>{s.name}</Select.Option>) :
                    null

            }
          </Select>
        </Form.Item> :
      </Flex>
      <Form.Item
        style={{ flex: 1 }}
        label="Transaction Name"
        name="name"
        rules={[{
          required: true,
          message: 'Please input Transaction name!',
        }]}>
        <Input autoFocus={true} />
      </Form.Item>
      <Flex gap={'middle'}>

        {transactionType === 'asset' && 
        <Form.Item
          style={{ flex: 1 }}
          label="Description"
          name="description"
          rules={[{
            required: true,
            message: 'Please input Description!',
          }]}>
          <TextArea rows={4} placeholder="e.g. Initial Purchase" />
        </Form.Item>}
        </Flex>
        <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Amount"
          name="amount"
          rules={[{
            required: true,
            message: 'Please input Amount!',
          }]}>
          <InputNumber style={{ width: '100%' }} autoFocus={true} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Settled"
          name="settled"
        >
          <input type="checkbox" onChange={(e) => {
            setSettled(!settled)
          }} autoFocus={true} />
        </Form.Item>
      </Flex>

      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Start"
          name="startAt"
          initialValue={dayjs().startOf('month')}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="End"
          name="endAt"
          initialValue={dayjs().endOf('month').endOf('day')}
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
      </Flex>


    </Form>
  </Modal >
}