import { Button, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { patch, post } from "../services/api";
import { useSelector } from "react-redux";
import AvatarPicker from './AvatarPicker';
import { useRef, useState } from "react";
import dayjs from "dayjs";
import { SyncOutlined } from "@ant-design/icons";

export default function ContractEdit({ isModalOpen, setIsModalOpen, onContractEdit, contract, setContract }) {
  const formRef = useRef(null);
  const projects = useSelector((state) => state.projects);
  const users = useSelector((state) => state.users);
  const [lists, setLists] = useState([]);
  const [user, setUser] = useState(null);
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setContract(null)
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      userId: user,
      deadLine: values.deadLine ? moment(values.deadLine.unix() * 1000).endOf('day') : null
    };
    if (!values.endDate) {
      delete data.endDate;
    }
    const resp = await patch(`/contracts/${contract.id}`, data);
    setIsModalOpen(false);
    onContractEdit(resp);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const renderLists = (projectId) => {
    setLists(projects.find(project => project.id == projectId).lists)
  }

  const calculate = (ent) => {
    const rate = formRef.current.getFieldValue('rate');
    const limit = formRef.current.getFieldValue('limit');
    const budget = formRef.current.getFieldValue('budget');
    if(ent == 'rate' && budget && limit) {
      formRef.current.setFieldValue('rate', budget/limit);
    } else if (ent == 'limit' && budget && rate) {
      formRef.current.setFieldValue('limit', budget/rate);
    } else if (ent == 'budget' && rate && limit) {
      formRef.current.setFieldValue('budget', rate*limit);
    }
  }

  if (!contract) { return null; }

  return <Modal title="Edit Contract" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="editContractForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="editContractForm"
      ref={formRef}
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Project"
          name="projectId"
          initialValue={contract.projectId}
          rules={[{
            required: true,
            message: 'Please select project!',
          }]}>
          <Select disabled onChange={renderLists}>
            {projects.map(p => <Select.Option key={p.id} value={p.id}>{p.name}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="List"
          name="listId"
          initialValue={contract.listId}
          >
          <Select disabled>
            {lists.map(l => l && l.id && <Select.Option key={l.id} value={l.id}>{l.name}</Select.Option>)}
          </Select>
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={contract.name}
          rules={[{
            required: true,
            message: 'Please input contract name!',
          }]}>
          <Input autoFocus={true} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Payment Frequency"
          name="paymentFreq"
          initialValue={contract.paymentFreq}
        >
          <Select >
            <Select.Option value="week">weekly</Select.Option>
            <Select.Option value="month">monthly</Select.Option>
            <Select.Option value="manual">manually</Select.Option>
          </Select>
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Track"
          name="track"
          initialValue={contract.track}
        >
          <Select disabled>
            <Select.Option value="hours">hours</Select.Option>
            <Select.Option value="tasks">tasks</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label={<>Rate&nbsp;<SyncOutlined onClick={ () => calculate('rate') } /></>}
          name="rate"
          initialValue={contract.rate}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label={<>Limit&nbsp;<SyncOutlined onClick={ () => calculate('limit') } /></>}
          name="limit"
          initialValue={contract.limit}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label={<>Budget&nbsp;<SyncOutlined onClick={ () => calculate('budget') } /></>}
          name="budget"
          initialValue={contract.budget}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Tracking By"
          name="track_by"
          initialValue={contract.track_by}
        >
          <AvatarPicker defaultSelectedUsers={[]} users={users} onAddUser={setUser} onRemoveUser={() => setUser(null)} single={true} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Deadline"
          name="deadLine"
        >
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        {/* <Form.Item
          style={{ flex: 1 }}
          label="Transaction Start Date"
          name="currentTransactionStartDate"
          initialValue={dayjs(contract.currentTransactionStartDate)}        >
          <DatePicker showTime style={{ width: "100%" }} />
        </Form.Item> */}
      </Flex>
    </Form>
  </Modal>
}