import { createSlice } from '@reduxjs/toolkit';

const servicesSlice = createSlice({
  name: 'services',
  initialState: [],
  reducers: {
    setServices: (state, action) => {
      window.services = action.payload;
      return action.payload;
    },
  },
});

export const servicesReducer = servicesSlice.reducer;

// Action creators are generated for each case reducer function

export const { setServices } = servicesSlice.actions;