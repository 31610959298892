import { Flex } from "antd"
import { useLocation, useNavigate } from "react-router-dom";
import Messages from "../components/Messages";
import Tasks from "../components/Tasks";
import { useEffect, useState } from "react";
import SendMessageInput from "../components/SendMessageInput";
import CreateTaskInput from '../components/CreateTaskInput';
import { useSelector } from "react-redux";
import { get, post, xdelete } from "../services/api";

export default function Everything () {
  const user = useSelector((state) => state.user);
  const users = useSelector((state) => state.users);
  const [tasks, setTasks] = useState([]);

  const refreshTasks = () => {
    get(`/users/${user.id}/tasks`, {
      include: [{
        relation: 'taskAccesses'
      }, {
        relation: 'timeLogs'
      },{
        relation : 'list',
        scope : {
          include : [{
            relation : 'project'
          }]
        }
      }
       
    ]
    }).then(tasks => {
      setTasks(tasks);
    })
  }

  const updateTask = async (tasks) => {
    setTasks(tasks);
  }

  const deleteTasks = async (tasksIds) => {
    var res = await xdelete(`/users/${user.id}/tasks`, {
      id: {
        inq: tasksIds
      }
    });
    if(res.count > 0) {
      refreshTasks();
    }
  }

  useEffect(() => {
    refreshTasks();
  }, [user.id]);
  
  return <Flex justify="space-between" vertical style={{ height: "100%", overflowY: "scroll" }}>
    <Flex gap={0} style={{ flex: 1, height: "100%", overflowY: "scroll" }}>
      <div style={{ flex: 1, height: "100%", overflowY: "scroll", flexDirection: "column-reverse" }}>
        <Tasks users={users} list={null} tasks={tasks} onDelete={deleteTasks} onUpdateTask={updateTask} refreshTasks={refreshTasks} />
      </div>
    </Flex>
  </Flex>
}