import { Avatar, Button, Checkbox, DatePicker, Flex, Form, Input, InputNumber, Modal, Select } from "antd";
import moment from "moment";
import { patch, put } from "../services/api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


export default function HumanEdit({ isModalOpen, setIsModalOpen, onHumanEdit, human, setHuman, id }) {
  const [isFemale, setIsFemale] = useState(false);
  const [birthDate, setBirthDate] = useState();
  const [user, setUser] = useState(null);
  const users = useSelector((state) => state.users);


 
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setHuman(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      isFemale: isFemale,
      birthDate: birthDate,
      userId: user
    };
    if (!values.endAt) {
      delete data.endAt;
    }
    const transaction = await patch(`/humans/${human.id}`, data);
    setIsModalOpen(false);
    onHumanEdit(transaction);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    if ('birthDate' in changedValues) {
      setBirthDate(changedValues.birthDate);
      // alert(changedValues.transactionType);
    }
    
  }


  if (!human) { return null; }
  return <Modal title="Edit Human Details" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="editHumanForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="editHumanForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={handleFormValuesChange}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={human.name}
          rules={[{
            required: true,
            message: 'Please input human name!',
          }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Position"
          name="position"
          initialValue={human.position}
          rules={[{
            required: true,
            message: 'Please input human position!',
          }]}>
          <Input />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="CTC"
          name="ctc"
          initialValue={human.ctc}
          rules={[{
            required: true,
            message: 'Please input human ctc!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Birthdate"
          name="birthDate"
          rules={[{
            required: true,
            message: 'Please Select Birth Date!',
          }]}>
          <DatePicker style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Are You Female ?"
          name="isFemale"
          initialValue={human.isFemale}
        >
          <Checkbox onChange={() => {
            setIsFemale(!isFemale)
          }}></Checkbox>
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Select User"
          name="userId"
        >
          <Avatar defaultSelectedUsers={[]} users={users} onAddUser={setUser} onRemoveUser={() => setUser(null)} single={true} />
        </Form.Item>
      </Flex>
    </Form>

  </Modal>
}