import React from 'react';
import { Menu, Dropdown, Avatar, Button } from 'antd';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';

const ProfileDropdown = () => {
  const handleLogout = () => {
    console.log('Logout action triggered');
    localStorage.clear();
    window.location.href = ("/");
  };

  const items = [
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      onClick: handleLogout,
      label: 'Logout',
    }
  ];

  return (
    <Dropdown menu={{
      items,
      selectable: true,
      defaultSelectedKeys: ['3'],
    }} placement="bottomRight">
      <Button type="link" onClick={e => e.preventDefault()} style={{ height: "auto", padding: "0" }}>
        <Avatar size="default" icon={<UserOutlined />} />
      </Button>
    </Dropdown>
  );
};

export default ProfileDropdown;
