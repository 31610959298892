import { createSlice } from '@reduxjs/toolkit';

const usersSlice = createSlice({
  name: 'users',
  initialState: [],
  reducers: {
    setUsers: (state, action) => {
      return action.payload;
    },
  },
});

export const usersReducer = usersSlice.reducer;

// Action creators are generated for each case reducer function
export const { setUsers } = usersSlice.actions;