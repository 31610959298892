import { AudioOutlined, EditOutlined, PaperClipOutlined, SendOutlined, SmileOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import Picker from '@emoji-mart/react'
import 'react-quill/dist/quill.snow.css';
import { post } from '../services/api';
import smiles from './emojis/smiles';
import actions from './emojis/actions';
import activities from './emojis/activities';
import animals from './emojis/animals';
import foods from './emojis/foods';
import gestures from './emojis/gestures';
import objects from './emojis/objects';
import people from './emojis/people';
import places from './emojis/places';
import professions from './emojis/professions';
import symbols from './emojis/symbols';

export default function SendMessageInput({ user, list }) {
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [emojiVisible, setEmojiVisible] = useState(false);
  const [actionsVisible, setActionsVisible] = useState(true);
  const quillRef = useRef();
  const modules = {
    toolbar: [['bold', 'italic', 'code-block', 'blockquote', 'strike', { 'header': 1 }, { 'header': 2 }, { 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }]]
  }

  const onSendMessage = async ({ type, content }) => {
    await post(`/users/${user.id}/messages`, {
      listId: list.id,
      content,
    });
    setEmojiVisible(false);
    setToolbarVisible(false);
  }

  const handleKeyPress = (e) => {
    const editor = quillRef.current.getEditor();
    if(actionsVisible && editor.getText().trim()) { setActionsVisible(false) }
    else if(!actionsVisible && (!editor.getText().trim())) { setActionsVisible(true) }
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const editor = quillRef.current.getEditor();
      const fullContents = editor.getContents();
      editor.root.querySelectorAll('p').forEach(p => {
        const hasOnlyImage = p.childNodes.length === 1 && p.childNodes[0].nodeName === 'IMG';
        const isEmptyOrWhitespace = !p.textContent.trim() && p.innerHTML.includes('<img');
        if (hasOnlyImage || isEmptyOrWhitespace) {
          p.classList.add('only-img');
        }
      });
      let html = editor.root.innerHTML.replace("<p><br></p>", '');
      if(html) {
        onSendMessage({ type: 'rich-text', content: html });
      }
      editor.setText('');
    }
  };

  const onSendButtonClick = () => {
    handleKeyPress({ key: "Enter", preventDefault: () => { } })
  }

  const onEmojiSelect = (e) => {
    const quill = quillRef.current.getEditor();
    window.quill = quill
    const range = quill.getSelection(true);
    if (range) {
      const position = range.index;
      quill.insertEmbed(position, 'image', e.src);
      quill.setSelection(position + 1, 0);
      quill.insertText(position + 1, ' ');
      quill.setSelection(position + 2, 0);
      quill.focus();
      if(actionsVisible) {setActionsVisible(false)}
    }
  }

  return <div className="send-message-container">
    <div className={ (toolbarVisible ? "show-toolbar" : "") + " send-message-input"}>
      <Button size='large' shape="circle" icon={<SmileOutlined />} onClick={() => setEmojiVisible(!emojiVisible)} className="emoji-btn" />
      { emojiVisible && <div className='emoji-picker'>
        <Picker custom={[smiles]} categories={["smiles"]} onEmojiSelect={onEmojiSelect} navPosition='none' />
      </div> }
      <div onKeyDownCapture={handleKeyPress}>
        <ReactQuill modules={modules} ref={quillRef} theme="snow" placeholder='Do your chat here...' />
      </div>
      <Button size='large' shape="circle" icon={<EditOutlined />} onClick={() => setToolbarVisible(!toolbarVisible)} className="toolbar-btn" />
    </div>
    <div className="send-message-actions">
      { actionsVisible && <Tooltip title="Attach file">
        <Button size='large' type='primary' shape="circle" icon={<PaperClipOutlined />} />
      </Tooltip> }
      { actionsVisible && <Tooltip title="Voice message">
        <Button size='large' type='primary' shape="circle" icon={<AudioOutlined />} />
      </Tooltip> }
      { !actionsVisible && <Tooltip title="Send Message">
        <Button size='large' type='primary' shape="circle" icon={<SendOutlined />} onClick={onSendButtonClick} />
      </Tooltip> }
    </div>
  </div>
}