import { Select } from "antd";

export default function StatusPicker({ task, onUpdateStatus }) {
  const handleChange = (value) => {
    onUpdateStatus(task.id, value);
  }
  return <Select
    value={task.status}
    variant="filled"
    suffixIcon={null}
    onChange={handleChange}
    style={{ backgroundColor: "lavender", minWidth: "100px", textAlign: "center", fontWeight: "bold" }}
    popupMatchSelectWidth={false}
    placement="bottomRight"
    options={[
      { value: 'todo', label: <b>Todo</b> },
      { value: 'doing', label: <b>Doing</b> },
      { value: 'done', label: <b>Done</b> },
    ]}
  />
}