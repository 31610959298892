import React from 'react';
import { Button, Form, Input } from 'antd';
import { get, post, setToken } from '../services/api';
import { setUser } from '../features/user';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
const Login = () => {
  const dispatch = useDispatch();
  const onFinish = async (values) => {
    var login_data = await post("/users/login", values);
    if(login_data.token) {
      localStorage.setItem('token', login_data.token);
      setToken(login_data.token);
      const user = await get("/users/me");
      if(user.human) {
        user.status = !user.human.checkedIn ? 'out' : !user.human.onBreak ? 'working' : 'break'; 
      }
      dispatch(setUser(user));
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return <>
   <Form
  name="basic"
  labelCol={{
      span: 8,
    }}
    wrapperCol={{
      span: 16,
    }}
    style={{
      maxWidth: 600,
    }}
    initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    onFinishFailed={onFinishFailed}
    autoComplete="off"
  >
    
    <Form.Item
      label="Email"
      name="email"
      rules={[
        {
          required: true,
          message: 'Please input your email!',
        },
      ]}
    >
      <Input />
    </Form.Item>

    <Form.Item
      label="Password"
      name="password"
      rules={[
        {
          required: true,
          message: 'Please input your password!',
        },
      ]}
    >
      <Input.Password />
    </Form.Item>
    <Form.Item
      wrapperCol={{
        offset: 8,
        span: 16,
      }}
    >
      <Button type="primary" htmlType="submit">
        Submit
      </Button>
    </Form.Item>
  </Form>
  <NavLink to={'/registration'}> Don't have an Account?</NavLink>
  </>
};
export default Login;