import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: [],
  reducers: {
    addNotification: (state, action) => {
      // Add a new notification
      state.push(action.payload);
    },
    setNotifications: (state, action) => {
      return [...action.payload];
    },
  },
});

export const notificationsReducer = notificationsSlice.reducer;

// Action creators are generated for each case reducer function
export const { setNotifications, addNotification } = notificationsSlice.actions;