import { useState } from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Avatar, Button, DatePicker, List, Popconfirm, Popover, Space, TimePicker } from "antd";
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'; // Import necessary plugins
import { generateHexDarkColor } from "../services/api";
import moment from "moment";

dayjs.extend(isSameOrBefore); // Extend dayjs with the isSameOrBefore plugin

const formatDuration = (duration) => {
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();

  let formatted = '';
  if (days > 0) {
      formatted += `${days}d `;
  }
  if (hours > 0) {
      formatted += `${hours}h `;
  }
  if (minutes > 0) {
      formatted += `${minutes}m`;
  }

  return formatted.trim();
};

export const millisecondsToTime = (milliseconds) => {
  const seconds = Math.floor((milliseconds / 1000) % 60);
  const minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
  const hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
  return `${hours ? hours.toString().padStart(2, '0') + ':' : ''}${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

export default function TrackedPicker ({ task, currentTaskTimerRef, currentTimeLog, onAddTrackedTime, onDeleteTrackedTime, onUpdateTrackedTime, users }) {
  const current = dayjs(); // Get current date and time
  const roundedCurrent = current.subtract(current.minute() % 10, 'minute').second(0); // Round down to the last 10 minutes
  const oneHourBefore = roundedCurrent.subtract(1, 'hour'); // Calculate one hour before

  const [editTimeLog, setEditTimeLog] = useState(null);
  const [date, setDate] = useState(roundedCurrent);
  const [times, setTimes] = useState([oneHourBefore, roundedCurrent]);
  const [open, setOpen] = useState(false);

  const resetState = () => {
    setEditTimeLog(null);
    setDate(roundedCurrent);
    setTimes([oneHourBefore, roundedCurrent]);
  }

  const EditButton = ({timeLog}) => <Button
    type="secondary"
    danger={true}
    onClick={() => {
      if(!editTimeLog) {
        setEditTimeLog(timeLog);
        setDate(dayjs(timeLog.createdAt));
        setTimes([dayjs(timeLog.createdAt), dayjs(timeLog.stoppedAt)]);
      } else if(editTimeLog) {
        resetState();
      }
    }}
    shape="circle" icon={<EditOutlined />}>
  </Button>

  const DeleteButton = ({timeLog}) => <Popconfirm
    title="Delete Time Log"
    placement="bottomRight"
    description="Are you sure to delete this Time Log?"
    onConfirm={() => { onDeleteTrackedTime(timeLog); }}
    okText="Yes"
    cancelText="No">
    <Button shape="circle" icon={<DeleteOutlined />} />
  </Popconfirm>

  const handleCalendarChange = (value) => {
    setTimes(value);
  }
  
  const handleAdd = () => {
    if (!date || !times[0] || !times[1]) {
      console.error("Please ensure all fields are filled correctly.");
      return; // Ensuring all data is available
    }
  
    // Assume `times[0]` and `times[1]` are DayJS objects with correct time but not necessarily date
    let startDate = times[0].year(date.year());
    startDate = startDate.month(date.month());
    startDate = startDate.date(date.date());
  
    let endDate = times[1].year(date.year());
    endDate = endDate.month(date.month());
    endDate = endDate.date(date.date());

    onAddTrackedTime(task.id, startDate, endDate);
    resetState();
    setOpen(false);
  };

  const handleEdit = () => {
    if (!date || !times[0] || !times[1]) {
      console.error("Please ensure all fields are filled correctly.");
      return; // Ensuring all data is available
    }
  
    // Assume `times[0]` and `times[1]` are DayJS objects with correct time but not necessarily date
    let startDate = times[0].year(date.year());
    startDate = startDate.month(date.month());
    startDate = startDate.date(date.date());
  
    let endDate = times[1].year(date.year());
    endDate = endDate.month(date.month());
    endDate = endDate.date(date.date());

    onUpdateTrackedTime(editTimeLog, startDate, endDate);
    resetState();
    setOpen(false);
  };
  console.log(users);

  return <Popover placement="bottom" trigger="click"
    open={open}
    onOpenChange={() => setOpen(!open)}
    content={ <>
      <Space.Compact block>
        <DatePicker value={date} onChange={setDate} />
        <TimePicker.RangePicker
          value={times}
          minuteStep={10}
          format="HH:mm"
          onCalendarChange={handleCalendarChange}
        />
        { !editTimeLog && <Button type="primary" onClick={handleAdd}>Add</Button>}
        { editTimeLog && <Button type="primary" onClick={handleEdit}>Edit</Button>}
      </Space.Compact>
      <List
        size="small"
        style={{maxHeight:'250px', overflowY:'scroll'}}
        bordered
        dataSource={task.timeLogs || []}
        renderItem={(timeLog) => <List.Item actions={[ 
        // <EditButton timeLog={timeLog}/>, 
        <DeleteButton timeLog={timeLog}/> ]}>
          <Avatar style={{ backgroundColor: generateHexDarkColor(timeLog.userId), fontSize: "12px" }}>{users.find(u => u.id == timeLog.userId).firstName[0]}{users.find(u => u.id == timeLog.userId).lastName[0]}</Avatar>&nbsp;
          {moment(timeLog.createdAt).format('ll')} {formatDuration(moment.duration(moment(timeLog.stoppedAt).diff(moment(timeLog.createdAt))))}
          
        </List.Item>}
      />
    </> }>
    <div className="avatar-with-profile" >
      <div className="heading-with-tagline">
        <span ref={currentTimeLog?.taskId === task.id ? currentTaskTimerRef : null}>{millisecondsToTime((task.timeLogs || []).reduce((total, log) => {
          return total + (new Date(log.stoppedAt || Date.now()) - new Date(log.createdAt));
        }, 0))}</span>
        {currentTimeLog?.taskId === task.id && <span>{millisecondsToTime((task.timeLogs || []).reduce((total, log) => {
          return total + (log.stoppedAt ? (new Date(log.stoppedAt || Date.now()) - new Date(log.createdAt)) : 0);
        }, 0))}</span>}
      </div>
    </div>
  </Popover>
}