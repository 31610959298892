import { Button, Flex, Form, Input, InputNumber, Modal } from "antd";
import moment from "moment";
import { post } from "../services/api";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";

export default function AssetTransactionCreate({ isModalOpen, setIsModalOpen, onAssetTransactionCreate, asset, setAsset}) {
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setAsset(null);
    setIsModalOpen(false);
  };
  const onFinish = async (values) => {
    const data = {
      ...values,
      amount: -values.amount,
      endAt: values.endAt ? moment(values.endAt.unix()*1000).endOf('day') : null
    };
    if(!values.endAt) {
      delete data.endAt;
    }
    const transaction = await post(`/assets/${asset.id}/transactions`, data);
    setIsModalOpen(false);
    onAssetTransactionCreate(transaction);
  };
  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  if(!asset) { return null; }
  if(asset.transactions && asset.transactions.length) {
    window.d = dayjs(asset.transactions[0].endAt);
    console.log(window.d);
  }
  return <Modal title="Add Asset Transaction" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={[
    <Button type="primary" className="bold" form="addAssetTransactionForm" key="submit" htmlType="submit">
      Submit
    </Button>
  ]}>
    <Form
      id="addAssetTransactionForm"
      name="basic"
      layout="vertical"
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off">
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Name"
          name="name"
          initialValue={asset.name}
          rules={[{
            required: true,
            message: 'Please input asset name!',
          }]}>
          <Input />
        </Form.Item>
        <Form.Item
          style={{ flex: 1 }}
          label="Amount"
          name="amount"
          initialValue={asset.ctc/12}
          rules={[{
            required: true,
            message: 'Please input asset ctc!',
          }]}>
          <InputNumber style={{ width: "100%" }} />
        </Form.Item>
      </Flex>
      <Flex gap={'middle'}>
        <Form.Item
          style={{ flex: 1 }}
          label="Description"
          name="description"
          >
          <TextArea rows={4} placeholder="e.g. Initial Purchase" />
        </Form.Item>
      </Flex>
    </Form>
  </Modal>
}